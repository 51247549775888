import {Box, CircularProgress, Typography} from '@mui/material';
import {DataGrid, DataGridProps, GridColDef, GridOverlay, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid';
import {ArrowForward} from 'components/icons/ArrowForward';
import {format} from 'date-fns';
import {nb} from 'date-fns/locale';
import {InquiryState} from 'lib/dtos/Inquiry';
import {PaginationDto} from 'lib/dtos/Pagination';
import {Inquiry} from 'lib/types/Admin';
import {useContext, VFC} from 'react';
import {useHistory} from 'react-router-dom';
import {colors} from 'theme';
import {useLanguage} from '../../../../language/LanguageProvider';
import {EmployeeBaseDto} from '../../../../lib/dtos/User';
import {AdminAppContext} from '../../../../store/adminAppContext';
import {StatusElement} from '../../../StatusElement';
import {getInquiryByIDPath} from '../AdminPages';

export const gridColDefProps: Partial<GridColDef> = {
    hideable: false,
};
// todo add translation
const columns: GridColDef[] = [
    {
        ...gridColDefProps,
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams<InquiryState>) => (
            <StatusElement text={inquiryStatus[params.value].text} iconColor={inquiryStatus[params.value].color} />
        ),
        minWidth: 120,
    },
    {
        ...gridColDefProps,
        field: 'projectId',
        headerName: 'ID',
        renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
        ...gridColDefProps,
        field: 'date',
        headerName: 'Dato',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Typography>{format(params.value, 'P', {locale: nb})}</Typography>
        ),
    },
    {
        ...gridColDefProps,
        field: 'title',
        headerName: 'Tittel',
        renderCell: ({value}) => <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{value}</Typography>,
        flex: 1,
    },
    {
        ...gridColDefProps,
        field: 'projectLeader',
        headerName: 'Prosjektleder',
        renderCell: (params: GridRenderCellParams<EmployeeBaseDto>) => (
            <Typography>{params.value ? `${params.value.firstName} ${params.value.lastName}` : ''}</Typography>
        ),
        minWidth: 180,
    },
    {
        ...gridColDefProps,
        field: 'seeDetails',
        hideSortIcons: true,
        disableColumnMenu: true,
        headerName: '',
        sortable: false,
        renderCell: () => (
            <Box display={'flex'} justifyContent={'flex-end'} width={20} style={{cursor: 'pointer'}}>
                <ArrowForward size={20} />
            </Box>
        ),
        minWidth: 20,
        width: 36,
    },
];

export const InquirePageDataGrid: VFC<
    {
        data: Inquiry[];
        pagination: PaginationDto | undefined;
        rowsPerPage: number;
        minHeight: number;
    } & Pick<DataGridProps, 'onPageChange' | 'onPageSizeChange' | 'loading'>
> = ({data, pagination, rowsPerPage, minHeight, ...props}) => {
    const history = useHistory();
    const {bgColor} = useContext(AdminAppContext);
    const {getLangString} = useLanguage();
    return (
        <DataGrid
            {...props}
            sx={{minHeight}}
            rowsPerPageOptions={[5, 10, 25, 50]}
            pageSize={rowsPerPage}
            components={{
                NoRowsOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <Typography>{getLangString('NO_DATA')}</Typography>
                    </GridOverlay>
                ),
                LoadingOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <CircularProgress />
                    </GridOverlay>
                ),
            }}
            componentsProps={{loadingOverlay: {background: bgColor}, noResultsOverlay: {background: bgColor}}}
            rowCount={pagination?.total ?? 0}
            columns={columns}
            rows={data.map((d) => ({...d, id: d.inquiryId}))}
            onRowClick={(params: GridRowParams) => {
                history.push(getInquiryByIDPath(params.row.projectId, params.row.inquiryId));
            }}
        />
    );
};

const inquiryStatus: Record<InquiryState, {text: string; color: string}> = {
    [InquiryState.New]: {text: 'Ny', color: colors.fair},
    [InquiryState.Started]: {text: 'Sendt til bedrift', color: colors.orange},
    [InquiryState.Closed]: {text: 'Tilbud opprettet', color: colors.yellow},
    [InquiryState.Canceled]: {text: 'Avbrutt', color: colors.red},
};
