import {Box, Link, useTheme} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../graphql/helpers';
import {ButtonTertiaryMedium} from '../Buttons';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    backgroundImage: {
        url: string;
    }[];
    overline: string;
    heading: string;
    primaryButton: string;
    secondaryButton: string;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Banner: React.FC<Props> = (props) => {
    const theme = useTheme();
    const primaryButton = deconstructHTML(props.primaryButton);
    const secondaryButton = deconstructHTML(props.secondaryButton);

    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            position: 'relative',
                            [theme.breakpoints.up('lg')]: {
                                gridColumn: '2 / span 6',
                            },
                        }}
                    >
                        <Box sx={{position: 'absolute', top: '0', right: '0', left: '0', bottom: '0'}}>
                            <img
                                style={{objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%'}}
                                src={props.backgroundImage[0].url}
                                alt=''
                            />
                        </Box>

                        <Box
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                left: '0',
                                bottom: '0',
                                backgroundColor: 'rgba(0,0,0,0.6)',
                            }}
                        ></Box>

                        <Box
                            sx={{
                                position: 'relative',
                                color: 'tertiary.main',
                                textAlign: 'center',
                                pt: 5,
                                px: 2.5,
                                pb: 6,
                                [theme.breakpoints.up('sm')]: {
                                    pt: 12,
                                    px: 8,
                                    pb: 7,
                                },
                                [theme.breakpoints.up('md')]: {
                                    pt: 16,
                                    px: 13,
                                    pb: 9,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: '0.875rem',
                                    lineHeight: '1.57',
                                    [theme.breakpoints.up('md')]: {
                                        fontSize: '1.0625rem',
                                        lineHeight: '1.47',
                                    },
                                }}
                            >
                                {props.overline}
                            </Box>
                            <Box
                                sx={{
                                    fontFamily: 'SuisseWorks-Regular, serif',
                                    fontSize: '2rem',
                                    lineHeight: '1.29',
                                    mt: 2.5,
                                    [theme.breakpoints.up('md')]: {
                                        fontSize: '2.375rem',
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '3.375rem',
                                        lineHeight: '1.20',
                                    },
                                }}
                            >
                                {props.heading}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: 4.5,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 10,
                                    },
                                }}
                            >
                                {primaryButton !== null && (
                                    <ButtonTertiaryMedium href={primaryButton.url}>
                                        {primaryButton.text}
                                    </ButtonTertiaryMedium>
                                )}

                                {secondaryButton !== null && (
                                    <Link
                                        href={secondaryButton.url}
                                        sx={{
                                            display: 'inline-block',
                                            textDecoration: 'none',
                                            color: 'tertiary.main',
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'tertiary.main',
                                            mt: 3.5,
                                            [theme.breakpoints.up('md')]: {
                                                mt: 3,
                                            },
                                        }}
                                    >
                                        {secondaryButton.text}
                                    </Link>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
