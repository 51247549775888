import {Box, Link, useTheme} from '@mui/material';
import React from 'react';
import {formatUrl, PEOPLE_ENTRY_PAGE_URL_FORMAT} from '../../../cms/helpers';
import {useLanguage} from '../../../language/LanguageProvider';

type Props = {
    person: {
        id: string;
        title: string;
        slug: string;
        jobTitle: string;
        phoneNumber: string;
        emailAddress: string;
        profileImage: {
            id: string;
            url: string;
        }[];
    };
};

export const PersonCard: React.FC<Props> = (props) => {
    const theme = useTheme();
    const {getLangString} = useLanguage();
    const person = props.person;

    return (
        <Box key={person.id}>
            <img src={person.profileImage[0].url} style={{width: '100%', display: 'block'}} alt='' />
            <Box
                sx={{
                    fontSize: '1.125rem',
                    lineHeight: 1.44,
                    mt: 1.5,
                    [theme.breakpoints.up('md')]: {
                        mt: 2.5,
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: '1.3125rem',
                        lineHeight: 1.57,
                    },
                }}
            >
                <Box sx={{}}>{person.title},</Box>
                <Box>{person.jobTitle}</Box>
                <Box
                    sx={{
                        fontSize: '1rem',
                        mt: 1,
                    }}
                >
                    {person.phoneNumber !== null && person.phoneNumber !== '' && <Box>{person.phoneNumber}</Box>}
                    {person.emailAddress !== null && person.emailAddress !== '' && (
                        <Box>
                            <Link
                                href={`mailto:${person.emailAddress}`}
                                sx={{
                                    textDecoration: 'none',
                                    '&:hover': {textDecoration: 'underline'},
                                }}
                            >
                                <span
                                    style={{
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {person.emailAddress}
                                </span>
                            </Link>
                        </Box>
                    )}
                </Box>
            </Box>

            <Link
                href={formatUrl(PEOPLE_ENTRY_PAGE_URL_FORMAT, person.slug)}
                sx={{
                    display: 'inline-block',
                    textDecoration: 'none',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'text.primary',
                    mt: 2,
                    fontSize: '0.875rem',
                    lineHeight: '1.57',
                    [theme.breakpoints.up('md')]: {
                        mt: 3,
                        fontSize: '1.0625rem',
                        lineHeight: '1.52',
                    },
                }}
            >
                &rarr; {getLangString('READ_MORE')}
            </Link>
        </Box>
    );
};
