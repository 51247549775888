import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import * as React from 'react';
import {useLanguage} from '../../../language/LanguageProvider';
import {CordelCompanySimpleDto} from '../../../lib/dtos/Cordel';
import {colors} from '../../../theme';
import {ArrowDownward} from '../../icons/ArrowDownward';

const Company: React.FC<{selectCompany: (id: number) => void; companyName: string; companyId: number}> = (props) => {
    const {getLangString} = useLanguage();
    const handleClick = () => {
        props.selectCompany(props.companyId);
    };
    return (
        <Box
            onClick={handleClick}
            sx={{
                p: 3,
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'customBackground.fair',
                borderTop: 1,
                borderColor: 'formline.active',
                cursor: 'pointer',
            }}
        >
            <Typography>{props.companyName}</Typography>
            <Typography variant={'body2'}>{getLangString('ADD')}</Typography>
        </Box>
    );
};
export const CompanySelect: React.FC<{
    onCompanySelect: (companyId: number) => void;
    companies: CordelCompanySimpleDto[];
}> = (props) => {
    const {getLangString} = useLanguage();
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const handleChange = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <Accordion
            disableGutters={true}
            elevation={0}
            square={true}
            onChange={handleChange}
            sx={{
                my: 3,
                border: 1,
                borderColor: 'divider',
                '&.Mui-expanded:first-of-type': {
                    my: 3,
                },
                '&::before': {
                    background: 'none',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDownward color={colors.tanDark} size={16} />}
                aria-controls={'companies'}
                sx={{
                    p: 3,
                    pr: '20px',
                    '& .MuiAccordionSummary-content': {justifyContent: 'space-between'},
                    '& .MuiAccordionSummary-expandIconWrapper': {alignSelf: 'center'},
                }}
            >
                <Typography>{getLangString('SELECT_COMPANY_FROM_LIST')}</Typography>
                <Typography variant={'body2'} mr={0.5} color={'text.secondary'} alignSelf={'center'}>
                    {isExpanded ? getLangString('CLOSE') : getLangString('OPEN')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{p: 0}}>
                {props.companies.length > 0 ? (
                    props.companies.map((company) => (
                        <Company
                            key={company.cordelCompanyId}
                            companyId={company.cordelCompanyId}
                            companyName={company.name}
                            selectCompany={props.onCompanySelect}
                        />
                    ))
                ) : (
                    <Box
                        sx={{
                            p: 3,
                            borderTop: 1,
                            borderColor: 'formline.active',
                        }}
                    >
                        <Typography variant={'body2'}>{getLangString('SELECT_COMPANY_EMPTY_LIST')}</Typography>
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
