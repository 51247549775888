import {Box, Grid, Typography} from '@mui/material';
import {LoaderCenterBlur} from 'components/Loader';
import * as React from 'react';
import {ChangeEvent, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Prompt} from 'react-router-dom';
import {useLanguage} from '../../../../language/LanguageProvider';
import {CordelCompanySimpleDto} from '../../../../lib/dtos/Cordel';
import {FileDto} from '../../../../lib/dtos/File';
import {OfferDecisionStatus, OfferDto} from '../../../../lib/dtos/Offer';
import {UpdateProjectDto} from '../../../../lib/dtos/Project';
import {EmployeeDto} from '../../../../lib/dtos/User';
import {useM2Axios} from '../../../../lib/hooks/useM2Axios';
import {M2HttpClient} from '../../../../lib/remote/m2api/ApiHttpClient';
import {M2Response} from '../../../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {ProblemDetailsError} from '../../../../lib/remote/ProblemDetails';
import {OfferService} from '../../../../lib/services/OfferService';
import {FileDict} from '../../../../lib/types/Inquiry';
import {getNorwegianDateString} from '../../../../toolbox';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../../../Buttons';
import {InfoField} from '../../../InfoField';
import {CustomInputField} from '../../../InputField';
import {PDFUploader, UploadedFile} from '../../../PDFUploader';
import {getServiceTasksText} from '../../serviceText';
import {ContactInfo} from '../ContactInfo';
import {EmployeeSelect} from '../EmployeeSelect';
import {getAddress} from '../getAddress';
import {Header} from '../Header';
import {InspectionInformation} from '../orderPage/InfoBlock';
import {PriceInputFields, PriceSummary} from '../PriceSummary';
import {ProjectDescription} from '../ProjectDescription';
import {OfferContentProps} from './OfferDetailsPage';

type FileProps = {
    fileId: string;
    file: File;
};
const service = new OfferService(M2HttpClient);

const OfferUploader: React.FC<{
    companyId: number;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>, companyID: number) => void;
    handleRemoveFile: (companyID: number, fileId: string) => void;
    pdfFiles?: FileProps[];
}> = (props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.handleFileChange(event, props.companyId);
    };
    const handleRemove = (fileID: string) => {
        props.handleRemoveFile(props.companyId, fileID);
    };
    return (
        <>
            {props.pdfFiles &&
                props.pdfFiles.map((f) => (
                    <UploadedFile key={f.fileId} fileId={f.fileId} onRemoveFile={handleRemove} fileName={f.file.name} />
                ))}
            <PDFUploader
                handleFileChange={handleChange}
                allowMultiple={true}
                sx={{
                    backgroundColor: 'rgba(18, 18, 18, 0.2)',
                    border: 1,
                    px: 4,
                    borderRadius: '3px',
                    '&:hover': {backgroundColor: 'rgba(18, 18, 18,0.1)'},
                }}
            />
        </>
    );
};
export type InputFields = PriceInputFields & {description: string};
export const OfferEditableContent: React.FC<OfferContentProps> = (props) => {
    const {getLangString} = useLanguage();
    const [pdfFiles, setPdfFiles] = React.useState<{[companyID: number]: FileDict<FileProps>}>({});
    const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
    const [savingOffer, setSavingOffer] = React.useState<boolean>(false);
    const {control, formState, watch, handleSubmit, trigger, getValues} = useForm<InputFields>({
        defaultValues: {
            hourCost: props.offerDetails.workhourCost ? props.offerDetails.workhourCost.toString() : '',
            materialCost: props.offerDetails.materialCost ? props.offerDetails.materialCost.toString() : '',
            totalMvaCost: props.offerDetails.totalMvaCost ? props.offerDetails.totalMvaCost.toString() : '',
            description: props.offerDetails.description || '',
        },
    });
    const totalMva = watch('totalMvaCost');
    const hourCost = watch('hourCost');
    const materialCost = watch('materialCost');

    const [canEditOffer, setCanEditOffer] = React.useState(false);
    useEffect(() => {
        // We can edit if the status of the offer is not waiting, accepted or rejected.
        const canEdit = [OfferDecisionStatus.Waiting, OfferDecisionStatus.Accepted, OfferDecisionStatus.Rejected].every(
            (o) => o !== props.offerDetails.offerDecisionStatus,
        );
        setCanEditOffer(canEdit);
    }, [props.offerDetails]);

    const handleFieldBlur = (name: keyof InputFields) => {
        trigger(name);
        setUnsavedChanges(true);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, companyID: number) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            if (files) {
                setPdfFiles((prev) => ({
                    ...prev,
                    [companyID]: files.reduce((acc: FileDict<FileProps>, file, i) => {
                        const newID = i + '_' + Date.now();
                        return {...acc, [newID]: {fileId: newID, file: file}};
                    }, prev[companyID]),
                }));
            }
            setUnsavedChanges(true);
        }
    };
    const getNewFiles = (companyID: number) =>
        pdfFiles[companyID] && Object.keys(pdfFiles[companyID]).map((fileId) => pdfFiles[companyID][fileId]);
    const removeLocalFile = (companyId: number, fileId: string) => {
        if (pdfFiles) {
            delete pdfFiles[companyId][fileId];
            setPdfFiles({...pdfFiles});
        }
        setUnsavedChanges(true);
    };
    const deleteOfferFile = async (companyOfferId: number, fileId: string) => {
        try {
            await service.deleteCompanyOfferFile(props.offerDetails.offerId, companyOfferId, fileId);
            await props.reFetchOffer();
        } catch (e) {
            return;
        }
    };

    const sendOfferPdfToCompany = async (companyID: number) => {
        await service.addOfferToCompany(
            props.offerDetails.offerId,
            {
                offerId: props.offerDetails.offerId,
                companyId: companyID,
            },
            Object.keys(pdfFiles[companyID]).map((fileId) => pdfFiles[companyID][fileId].file),
        );
    };
    const parsePriceValue = (value: string | undefined) => {
        if (value) {
            return parseFloat(value.replace(' ', '').replace(',', '.'));
        }
        return undefined;
    };
    const saveOffer = async (form: InputFields) => {
        setSavingOffer(true);
        const data: Omit<OfferDto, 'descriptionCustomer, companiesWithInquiry'> = {
            ...props.offerDetails,
            description: form.description,
            workhourCost: parsePriceValue(form.hourCost),
            materialCost: parsePriceValue(form.materialCost),
            totalMvaCost: parsePriceValue(form.totalMvaCost),
        };
        try {
            for await (const company of props.inquiryCompanies) {
                if (pdfFiles[company.cordelCompanyId]) {
                    await sendOfferPdfToCompany(company.cordelCompanyId);
                }
            }
            await service.updateOffer(props.offerDetails.offerId, data);
            setPdfFiles({});
            setUnsavedChanges(false);
            await props.reFetchOffer();
        } catch (e) {
            return;
        }
        setSavingOffer(false);
    };
    const sendOfferToCustomer = async () => {
        setSavingOffer(true);
        try {
            await service.sendOfferToCustomer(props.offerDetails.offerId);
            await props.reFetchOffer();
        } catch (e) {
            return;
        }
        setSavingOffer(false);
    };

    const {project, offerDecisionStatus, descriptionCustomer} = props.offerDetails;
    const address = getAddress(project);

    const getCustomHeaderText = (): string => {
        if (offerDecisionStatus === OfferDecisionStatus.Accepted) {
            return getLangString('ACCEPTED');
        } else {
            return `${getLangString('OFFER')} ${getNorwegianDateString(new Date(project.createdAt))}`;
        }
    };
    const [{data: inspectionPhotos}] = useM2Axios<M2Response<FileDto[]>>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}/images/inspection`},
        {
            manual: false,
        },
    );
    const [{loading: loadingEmployees, data: employees}] = useM2Axios<
        M2Response<EmployeeDto[]>,
        unknown,
        ProblemDetailsError
    >(`${ApiEndpoints.user.base()}/employees?page=1&size=50`);
    const [{loading: updateLoading}, updateProject] = useM2Axios<unknown, UpdateProjectDto, ProblemDetailsError>(
        {url: `${ApiEndpoints.project.byId(props.offerDetails.project.projectId)}`, method: 'PUT'},
        {manual: true},
    );
    const handleEmployeeSelect = (id: number) => {
        const data: UpdateProjectDto = {projectId: props.offerDetails.project.projectId, projectLeaderEmployeeId: id};
        updateProject({data});
    };
    const serviceTaskText =
        props.availableServices && getServiceTasksText(props.offerDetails.project.services, props.availableServices);

    const disableSendButton = (): boolean => {
        const hasCompaniesWithoutFiles = props.inquiryCompanies.some((curr: CordelCompanySimpleDto) => {
            return props.uploadedFiles[curr.cordelCompanyId]
                ? Object.keys(props.uploadedFiles[curr.cordelCompanyId]).length === 0
                : true;
        });
        return (
            unsavedChanges ||
            hasCompaniesWithoutFiles ||
            props.offerDetails.materialCost === undefined ||
            props.offerDetails.workhourCost === undefined ||
            props.offerDetails.description === undefined
        );
    };

    return (
        <form onSubmit={handleSubmit(saveOffer)}>
            <Header
                id={project.projectId}
                customText={getCustomHeaderText()}
                project={project}
                services={props.availableServices}
            />
            <Grid container columnSpacing={0}>
                <Grid item xs={8} sm={4}>
                    <Typography variant={'h6'}>{getLangString('PROJECT_LEADER')}</Typography>
                    {employees && (
                        <EmployeeSelect
                            employees={employees.data}
                            onSelect={handleEmployeeSelect}
                            currentEmployee={props.offerDetails.project.projectLeader}
                        />
                    )}
                </Grid>
            </Grid>
            <Box my={6}>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('CONTACT_INFO')}
                </Typography>
                <ContactInfo {...project.customer} address={address} />
            </Box>
            <Box my={6}>
                <ProjectDescription
                    descriptionHeader={getLangString('INFO_TEXT_CUSTOMER')}
                    descriptionText={
                        descriptionCustomer && descriptionCustomer.text
                            ? descriptionCustomer.text
                            : getLangString('NO_DESCRIPTION')
                    }
                    serviceTaskText={serviceTaskText}
                    imageHeader={getLangString('ATTACHED_PHOTOS')}
                    images={(descriptionCustomer && descriptionCustomer.images) || []}
                />
            </Box>
            <Box my={6}>
                <InspectionInformation images={inspectionPhotos?.data || []} />
            </Box>
            <Typography variant={'h5'} mb={2} mt={8}>
                {getLangString('OFFER_INFO_HEADER')}
            </Typography>
            <Box>
                <Typography variant={'subtitle1'} mb={2}>
                    {getLangString('ADD_COMPANY_OFFERS_HEADER')}:
                </Typography>
                {props.inquiryCompanies.map((c) => (
                    <InfoField
                        key={c.cordelCompanyId}
                        sx={{
                            py: 2,
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'flex-start', sm: 'center'},
                            justifyContent: 'space-between',
                        }}
                    >
                        {c.name}
                        <Box sx={{textAlign: 'right'}}>
                            {props.uploadedFiles[c.cordelCompanyId] !== undefined &&
                                Object.keys(props.uploadedFiles[c.cordelCompanyId]).map((file) => {
                                    const uploadedFile = props.uploadedFiles[c.cordelCompanyId][file];
                                    const deleteFile = canEditOffer
                                        ? (fileId: string) => {
                                              deleteOfferFile(uploadedFile.cordelCompanyOfferId, fileId);
                                          }
                                        : undefined;
                                    return (
                                        <UploadedFile
                                            key={uploadedFile.id}
                                            fileId={uploadedFile.id}
                                            fileName={uploadedFile.fileName}
                                            onRemoveFile={deleteFile}
                                            file={uploadedFile}
                                        />
                                    );
                                })}
                            {canEditOffer && (
                                <OfferUploader
                                    companyId={c.cordelCompanyId}
                                    handleFileChange={handleFileChange}
                                    handleRemoveFile={removeLocalFile}
                                    pdfFiles={getNewFiles(c.cordelCompanyId)}
                                />
                            )}
                        </Box>
                    </InfoField>
                ))}
            </Box>
            <Box mt={6} mb={3}>
                <Typography variant={'subtitle1'} mb={2}>
                    {getLangString('PRICE')}:
                </Typography>
                <PriceSummary
                    editable={canEditOffer}
                    handleBlur={handleFieldBlur}
                    materialCost={materialCost}
                    hourCost={hourCost}
                    totalMvaCost={totalMva}
                    control={control}
                    errors={formState.errors}
                />
            </Box>
            <Box>
                <Typography variant={'subtitle1'} mb={2}>
                    {getLangString('OFFER_DESCRIPTION_HEADER')}:
                </Typography>
                <Controller
                    name='description'
                    control={control}
                    defaultValue={getValues('description')}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            multiline
                            disabled={!canEditOffer}
                            fullWidth={true}
                            minRows={5}
                            placeholder={'Skriv inn beskrivelse her...'}
                            name={field.name}
                            error={!!fieldState.error}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => handleFieldBlur(field.name)}
                            sx={{borderColor: 'primary.main'}}
                        />
                    )}
                />
            </Box>
            <Box my={7} sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'flex-end'}}>
                {(offerDecisionStatus === OfferDecisionStatus.Open ||
                    offerDecisionStatus === OfferDecisionStatus.Rejected) && (
                    <>
                        <ButtonSecondaryMedium
                            type={'submit'}
                            disabled={!unsavedChanges}
                            sx={{mr: {xs: 0, sm: 2}, mb: {xs: 1, sm: 0}}}
                        >
                            {getLangString('SAVE_CHANGES')}
                        </ButtonSecondaryMedium>
                        <ButtonPrimaryMedium onClick={sendOfferToCustomer} disabled={disableSendButton()}>
                            {getLangString('SEND_OFFER_TO_CUSTOMER')}
                        </ButtonPrimaryMedium>
                    </>
                )}
            </Box>
            <LoaderCenterBlur open={savingOffer} />
            <Prompt when={unsavedChanges} message={getLangString('UNSAVED_CHANGES_PROMPT')} />
        </form>
    );
};
