import {Link} from '@mui/material';

export const getTextWithLink = (text: string, url: string) => {
    const first = text.split('%href_start%');
    const second = first[1].split('%href_end%');
    return (
        <>
            {first[0]}
            <Link href={url} target={'_blank'} rel={'noreferrer'}>
                {second[0]}
            </Link>
            {second[1]}
        </>
    );
};
