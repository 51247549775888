import {Box, Switch, Theme, Typography, useMediaQuery} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {PrivacySettings} from '../../lib/dtos/Customer';
import {CssGrid} from '../Layout';

const Setting: React.FC<{
    label: string;
    checked: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    name: keyof PrivacySettings;
}> = ({name, label, checked, handleChange}) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(!isChecked);
        handleChange(e);
    };

    return (
        <Box display={'flex'} justifyContent={'space-between'} sx={{py: 1, '&:first-child': {pt: 0}}}>
            <Typography>{label}</Typography>
            <Switch checked={isChecked} onChange={onChange} name={name} />
        </Box>
    );
};
const SettingsGroup: React.FC<{header: string}> = ({header, children}) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return (
        <Box sx={{borderBottom: 1, py: 3}}>
            <CssGrid numberOfColumns={6}>
                <Box sx={{gridColumn: {xs: 'span 8', md: '1 / 3'}}}>
                    <Typography variant={isSmallScreen ? 'subtitle1' : 'body1'}>{header}</Typography>
                </Box>
                <Box sx={{gridColumn: {xs: 'span 8', md: '3 / 7'}}}>{children}</Box>
            </CssGrid>
        </Box>
    );
};
export const AccountSettings: React.FC<{
    settings: PrivacySettings;
    handleUpdate: (key: keyof PrivacySettings, isChecked: boolean) => void;
}> = ({settings, handleUpdate}) => {
    const {getLangString} = useLanguage();
    const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
        handleUpdate(e.target.name as keyof PrivacySettings, e.target.checked);
    };
    return (
        <>
            <SettingsGroup header={getLangString('SMS_SETTINGS')}>
                <Setting
                    name={'canReceiveGeneralSms'}
                    label={getLangString('GENERAL_UPDATES_SETTING')}
                    checked={settings.canReceiveGeneralSms}
                    handleChange={handleToggle}
                />
                <Setting
                    name={'canReceiveActionSms'}
                    label={getLangString('ACTION_REQUIRED_SETTING')}
                    checked={settings.canReceiveActionSms}
                    handleChange={handleToggle}
                />
            </SettingsGroup>

            <SettingsGroup header={getLangString('EMAIL_SETTINGS')}>
                <Setting
                    name={'canReceiveGeneralEmails'}
                    label={getLangString('GENERAL_UPDATES_SETTING')}
                    checked={settings.canReceiveGeneralEmails}
                    handleChange={handleToggle}
                />
                <Setting
                    name={'canReceiveActionEmails'}
                    label={getLangString('ACTION_REQUIRED_SETTING')}
                    checked={settings.canReceiveActionEmails}
                    handleChange={handleToggle}
                />
            </SettingsGroup>

            <SettingsGroup header={getLangString('PHONE_SETTINGS')}>
                <Setting
                    name={'canReceiveGeneralPhoneCalls'}
                    label={getLangString('GENERAL_UPDATES_SETTING')}
                    checked={settings.canReceiveGeneralPhoneCalls}
                    handleChange={handleToggle}
                />
                <Setting
                    name={'canReceiveActionPhoneCalls'}
                    label={getLangString('ACTION_REQUIRED_SETTING')}
                    checked={settings.canReceiveActionPhoneCalls}
                    handleChange={handleToggle}
                />
            </SettingsGroup>
        </>
    );
};
