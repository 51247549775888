import {Box, Collapse, IconButton, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../language/LanguageProvider';
import {ButtonPrimaryMedium} from './Buttons';
import {CloseIcon} from './icons/CloseIcon';
import {LoaderCentered} from './Loader';
import {getOfferRoutePath} from './loggedInPages/LoggedInRoutes';
import {StatusElement} from './StatusElement';

type NotificationType = 'offer' | 'payment' | 'statusUpdate';
type Props = {
    projectID: number;
    notificationType: NotificationType;
    linkID: number;
    overlineText: string;
    statusColor?: string;
    title: string;
    subTitleL1: string;
    subTitleL2: string;
    text?: string;
    buttonText: string;
    // Handler the button is clicked
    onButtonClick?: () => void;
    // when true, disables the button
    buttonDisabled?: boolean;
    // when true, an overlay is displayed above the notification
    notificationDisabled?: boolean;
    // when true, the notification is hidden
    hidden?: boolean;
    onClose?: () => void;
};
const styles: React.CSSProperties = {
    top: '0',
    left: '0',
    backgroundColor: '#E6D7BFCC',
    width: '100%',
    height: '100%',
    zIndex: 100,
    position: 'absolute',
};

export const NotificationTile: React.FC<Props> = (props) => {
    const {getLangString} = useLanguage();
    const theme = useTheme();
    const history = useHistory();
    const handleButtonClick: Record<NotificationType, () => void> = {
        offer: () => history.push(getOfferRoutePath(props.projectID, props.linkID)),
        payment: () => ({}), //TODO
        statusUpdate: () => {
            if (props.onButtonClick) {
                props.onButtonClick();
            }
        },
    };

    return (
        <Collapse in={!props.hidden} orientation='vertical'>
            <Box
                sx={{
                    border: 1,
                    borderColor: 'primary.main',
                    padding: 5,
                    marginY: 4,
                    [theme.breakpoints.down('sm')]: {
                        padding: '20px',
                    },
                }}
                position={'relative'}
            >
                {props.notificationDisabled && (
                    <div style={{...styles}}>
                        <LoaderCentered text={getLangString('SENDING')} />
                    </div>
                )}
                <StatusElement text={props.overlineText} iconColor={props.statusColor || theme.palette.accent.main} />
                {props.onClose && (
                    <Box sx={{position: 'absolute', top: 16, right: 16}}>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon size={12} />
                        </IconButton>
                    </Box>
                )}
                <Box marginTop={3} marginBottom={4}>
                    <Typography variant={'h4'}>{props.title}</Typography>
                    <Typography variant={'h4_light'} component={'div'}>
                        {props.subTitleL1 ? props.subTitleL1 + ',' : ''}
                    </Typography>
                    <Typography variant={'h4_light'}>{props.subTitleL2}</Typography>
                </Box>
                {props.text && (
                    <Box marginTop={4} marginBottom={5}>
                        <Typography variant={'body1'}>{props.text}</Typography>
                    </Box>
                )}

                <Box marginTop={3} marginBottom={4}>
                    {props.children}
                </Box>
                <ButtonPrimaryMedium
                    onClick={handleButtonClick[props.notificationType]}
                    disabled={props?.buttonDisabled}
                >
                    {props.buttonText}
                </ButtonPrimaryMedium>
            </Box>
        </Collapse>
    );
};
