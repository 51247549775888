import {DialogContent, DialogTitle, Typography} from '@mui/material';
import React from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../Buttons';
import {CustomDialog} from '../CustomDialog';

type DialogProps = {
    open: boolean;
    onClose: () => void;
    onSignIn: () => void;
};
export const DialogLoginExistingUser: React.FC<DialogProps> = ({open, onSignIn, onClose}) => {
    const {getLangString} = useLanguage();
    return (
        <CustomDialog open={open} onClose={onClose} sx={{'.MuiPaper-root': {minWidth: '0'}}}>
            <DialogTitle>
                <Typography textAlign={'center'} mb={3}>
                    {getLangString('EXISTING_USER_LOGIN_MODAL_TEXT')}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{textAlign: 'center'}}>
                <ButtonSecondaryMedium onClick={onClose} sx={{mx: 1}}>
                    {getLangString('CANCEL')}
                </ButtonSecondaryMedium>
                <ButtonPrimaryMedium onClick={onSignIn} sx={{my: {xs: 1, sm: 0}, mx: {xs: 0, sm: 1}}}>
                    {getLangString('SIGN_IN')}
                </ButtonPrimaryMedium>
            </DialogContent>
        </CustomDialog>
    );
};
