import {useQuery} from '@apollo/client';
import {Box, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ARTICLES_INDEX_PAGE_URL} from '../../cms/helpers';
import {CMS_SEO, GET_ARTICLES_INDEX_PAGE, GET_SEO_DATA} from '../../graphql';
import {CmsArticle, CmsArticleCategory} from '../../graphql/types';
import {useLanguage} from '../../language/LanguageProvider';
import {SEO} from '../../SEO';
import {PageContent} from '../cms/PageContent';
import {PageIntro} from '../cms/PageIntro';
import {PageOverline} from '../cms/PageOverline';
import {ArticleCard} from '../cms/partials/ArticleCard';
import {List, ListItem} from '../cms/partials/List';
import {Container, CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';

export const ArticlesIndexPage: React.FC = () => {
    const theme = useTheme();
    const {getLangString} = useLanguage();
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const relatedService = query.get('sid') === '' ? null : query.get('sid');
    const allFiltersAction = getLangString('ALL_ARTICLES_FILTER_ACTION');
    const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(null);

    const isCurrentCategory = (value: string | null) => {
        return currentCategoryId == value;
    };

    const {loading, error, data} = useQuery(GET_ARTICLES_INDEX_PAGE(relatedService));
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(ARTICLES_INDEX_PAGE_URL));
    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;
    const categories = data.categories;
    const articles = data.articles;
    const service = data.service ?? null;
    const overline = {
        text: service ? `Referanser for ${service.title}` : entry.title,
    };

    const filteredArticles =
        currentCategoryId === null
            ? articles
            : articles.filter((article: CmsArticle) => {
                  let foundMatch = false;
                  article.articleCategories.forEach((category: CmsArticleCategory) => {
                      if (category.id == currentCategoryId) {
                          foundMatch = true;
                      }
                  });
                  return foundMatch;
              });

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                sx={{
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 14,
                    },
                }}
            >
                <PageOverline {...overline} />
                <PageIntro {...{text: entry.introText}} />

                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                mt: 5,
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '3 / span 4',
                                    mt: 9,
                                },
                            }}
                        >
                            <List columns={{xs: 2}}>
                                <ListItem
                                    text={allFiltersAction}
                                    underline={isCurrentCategory(null)}
                                    mediumFont={isCurrentCategory(null)}
                                    underlineOnHover={true}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setCurrentCategoryId(null);
                                    }}
                                />
                                {categories.map((category: any) => {
                                    return (
                                        <ListItem
                                            key={category.id}
                                            text={category.title}
                                            underline={isCurrentCategory(category.id)}
                                            mediumFont={isCurrentCategory(category.id)}
                                            underlineOnHover={true}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentCategoryId(category.id);
                                            }}
                                        />
                                    );
                                })}
                            </List>
                        </Box>
                    </CssGrid>
                </Container>

                <Box
                    sx={{
                        mt: 5,
                        [theme.breakpoints.up('md')]: {
                            mt: 8,
                        },
                        [theme.breakpoints.up('lg')]: {
                            mt: 12,
                        },
                    }}
                >
                    {filteredArticles.map((article: any, index: number) => {
                        const isNarrow = article.mainImage.length > 1;

                        return (
                            <Box
                                key={article.id}
                                sx={{
                                    mt: index > 0 ? 8 : 0,
                                    [theme.breakpoints.up('md')]: {
                                        mt: index > 0 ? 12 : 0,
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        mt: index > 0 ? 16 : 0,
                                    },
                                }}
                            >
                                <Container>
                                    <CssGrid>
                                        <Box
                                            sx={{
                                                gridColumn: isNarrow ? 'span 8' : '2 / span 6',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ArticleCard article={article} />
                                        </Box>
                                    </CssGrid>
                                </Container>
                            </Box>
                        );
                    })}
                </Box>

                {entry.pageContent.length > 0 && (
                    <Box
                        sx={{
                            mt: 9,
                            [theme.breakpoints.up('md')]: {
                                mt: 24,
                            },
                            [theme.breakpoints.up('lg')]: {
                                mt: 32,
                            },
                        }}
                    >
                        <PageContent data={entry.pageContent} />
                    </Box>
                )}
            </Box>
        </>
    );
};
