import {ValidationRule} from 'react-hook-form';
import {
    addressRegex,
    alphaNumericSpaceRegex,
    alphaNumericSpaceSpecialRegex,
    emailRegex,
    noSpecialCharRegex,
    organizationNumberRegex,
    phoneRegex,
    phoneWithLandCodeRegex,
    priceRegex,
    webUrlRegex,
    zipRegex,
} from 'toolbox';

// GENERIC ERROR MESSAGE FOR FORM VALIDATION
export const isRequired = () => 'Dette feltet er påkrevd';
export const isRequiredNamed = (field: string) => `${field} mangler`;
export const minLenth = (min: number) => `Må være minst ${min} tegn`;
export const maxLength = (max: number) => `Kan være maks ${max} tegn`;
export const exactLength = (exact: number) => `Må være ${exact} tegn`;
export const betweenLength = (min: number, max: number) => `Må være mellom ${min} og ${max} tegn`;
export const illegalEntity = (entity: string) => `Ugyldig ${entity}`;
export const onlyAlphanumericSpace = () => `Kan bare ha A-Å, 0-9 og mellomrom`;
export const onlyAlphanumeric = () => `Kan bare ha A-Å og 0-9`;
export const containsIllegalCharacter = () => `Felt inneholder ugyldige tegn`;

// useForm validation fields
export const validateIsRequiredConditional = (condition: boolean): ValidationRule<boolean> => {
    return {value: condition, message: isRequired()};
};
export const validateIsRequired = (): ValidationRule<boolean> => {
    return {value: true, message: isRequired()};
};
export const validateIsEmail = (): ValidationRule<RegExp> => {
    return {value: emailRegex, message: illegalEntity('e-post')};
};
export const validatePhoneNumberWithLandCode = (): ValidationRule<RegExp> => {
    return {value: phoneWithLandCodeRegex, message: illegalEntity('telefonnummer. (Må inneholde landskode, eks +47)')};
};
export const validatePhoneNumber = (): ValidationRule<RegExp> => {
    return {value: phoneRegex, message: illegalEntity('telefonnummer')};
};
export const validateOrganizationNumber = (): ValidationRule<RegExp> => {
    return {value: organizationNumberRegex, message: exactLength(9)};
};
export const validateZip = (): ValidationRule<RegExp> => {
    return {value: zipRegex, message: exactLength(4)};
};
export const validateAlphaNumericSpaceSpecial = (): ValidationRule<RegExp> => {
    return {value: alphaNumericSpaceSpecialRegex, message: containsIllegalCharacter()};
};
export const validateNoSpecialChars = (): ValidationRule<RegExp> => {
    return {value: noSpecialCharRegex, message: containsIllegalCharacter()};
};
export const validateAlphaNumericSpace = (): ValidationRule<RegExp> => {
    return {value: alphaNumericSpaceRegex, message: onlyAlphanumericSpace()};
};
export const validateAddress = (): ValidationRule<RegExp> => {
    return {value: addressRegex, message: containsIllegalCharacter()};
};
export const validateWebUrl = (): ValidationRule<RegExp> => {
    return {value: webUrlRegex, message: illegalEntity('nettadresse')};
};
export const validatePrice = (): ValidationRule<RegExp> => {
    return {value: priceRegex, message: illegalEntity('verdi')};
};
