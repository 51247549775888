import {Button, Typography} from '@mui/material';
import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../language/LanguageProvider';
import {ArrowBackward} from './icons/ArrowBackward';

export const BackButton: React.FC<{onClickOverride?: () => void}> = ({onClickOverride}) => {
    const history = useHistory();
    const {getLangString} = useLanguage();
    const doGoBack = () => {
        onClickOverride ? onClickOverride() : history.goBack();
    };
    return (
        <Button onClick={doGoBack}>
            <ArrowBackward />
            <Typography ml={1}>{getLangString('BACK')}</Typography>
        </Button>
    );
};
