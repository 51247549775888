import {Typography} from '@mui/material';
import * as React from 'react';
import {ProjectBaseDto} from '../../../lib/dtos/Project';
import {ServiceDictionary} from '../../../lib/types/Inquiry';
import {getServiceText} from '../serviceText';
import {getAddress} from './getAddress';

export const Header: React.FC<{
    id: number;
    project: ProjectBaseDto;
    customText?: string;
    services?: ServiceDictionary;
}> = (props) => {
    return (
        <>
            <Typography variant={'h3_bold'} mt={6}>
                Prosjektid {props.id} {props.customText && `- ${props.customText}`}
            </Typography>
            <Typography variant={'h3'} lineHeight={'3.563rem'}>
                {props.services && `${getServiceText(props.project.services, props.services)},`}
            </Typography>
            <Typography variant={'h3'} lineHeight={'3.563rem'} mb={7}>
                {getAddress(props.project)}
            </Typography>
        </>
    );
};
