import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {CookieConsentProvider} from './components/CookieConsentProvider';
import {b2cPolicies, msalConfig} from './config/b2cConfig';
import './index.css';
import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
        const {payload} = message;
        const {account} = payload as AuthenticationResult;
        msalInstance.setActiveAccount(account);
    }

    if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error?.message?.includes('AADB2C90118')) {
            console.log('forgot password');
            msalInstance.loginRedirect({
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [],
            });
        }
    }
});
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <CookieConsentProvider>
                <App />
            </CookieConsentProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
