import {keyframes} from '@emotion/react';
import {Backdrop, Box, Typography} from '@mui/material';
import React, {VFC} from 'react';

const loader = keyframes(`
    0% {
        transform: rotate(0deg); 
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
`);

const loaderInner = keyframes(`
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
`);
export const Loader: React.FC<{size: number; color?: string}> = ({size, color}) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                border: 4,
                borderColor: color || 'primary.main',
                animation: `${loader} 2s infinite ease`,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: color || 'primary.main',
                    animation: `${loaderInner} 2s infinite ease-in`,
                }}
            />
        </Box>
    );
};

export const LoaderCentered: React.FC<{text?: string; background?: string}> = ({text, background}) => (
    <Box
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: background,
        }}
    >
        <Loader size={32} />
        {text && <Typography mt={2}>{text}</Typography>}
    </Box>
);

export const LoaderCenterBlur: VFC<{open: boolean; text?: string}> = ({open, text}) => (
    <Backdrop open={open} sx={{zIndex: 10000}}>
        <LoaderCentered text={text} />
    </Backdrop>
);
