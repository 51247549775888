import {CreateCompanyOfferDto, CreateOfferDto, OfferDecisionDto, OfferDto, OffersDto} from '../dtos/Offer';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {ApiEndpoints} from '../remote/m2api/M2Endpoints';

/**
 * Responsible for performing tasks related to inquiries.
 * It can create, update, delete and get inquiries.
 */
export class OfferService {
    private apiOfferPath = ApiEndpoints.offer;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    /**
     * Tries to get an inquiry by the provided id.
     * @param offerId the id of the offer
     * @returns the offer with the given id.
     */
    public async getOfferById(offerId: number): Promise<{data: OfferDto}> {
        return await this._httpClient.get<{data: OfferDto}>(this.apiOfferPath.byId(offerId));
    }

    /**
     * Tries to get all inquiries
     * @param page the page to be returned
     * @param size the number of entries per page
     * @returns all offers.
     */
    public async getOffers(page: number, size: number): Promise<OffersDto> {
        return await this._httpClient.get<OffersDto>(`${this.apiOfferPath.base()}?page=${page}&size=${size}`);
    }

    /**
     * Tries to create an offer
     * @param projectId
     * @param inquiryId
     */
    public async createOffer(projectId: number, inquiryId: number): Promise<{offerId: number}> {
        return await this._httpClient.post<{offerId: number}, CreateOfferDto>(this.apiOfferPath.base(), {
            projectId,
            inquiryId,
        });
    }
    /**
     * Tries to update an offer
     * @param offerId
     * @param data
     */
    public async updateOffer(offerId: number, data: OfferDto): Promise<unknown> {
        return await this._httpClient.put<OfferDto>(this.apiOfferPath.byId(offerId), data);
    }

    /**
     * Tries to send offer to company
     * @param offerId
     * @param data object with offer id and company id
     * @param pdfs offer files
     */
    public async addOfferToCompany(offerId: number, data: CreateCompanyOfferDto, pdfs: File[]): Promise<unknown> {
        const formData: FormData = new FormData();
        for (let index = 0; index < pdfs.length; index++) {
            formData.append('pdfs', pdfs[index]);
        }
        return await this._httpClient.submitForm<CreateCompanyOfferDto>(
            `${this.apiOfferPath.byId(offerId)}/companyOffers?offer=${JSON.stringify(data)}`,
            formData,
        );
    }
    public async deleteCompanyOfferFile(offerId: number, companyOfferId: number, fileID: string): Promise<unknown> {
        return await this._httpClient.delete<unknown, {id: string}>(
            `${this.apiOfferPath.byId(offerId)}/companyOffers/${companyOfferId}/files`,
            {id: fileID},
        );
    }

    /**
     * Tries to send email and sms about the created offer to customer
     * @param offerId
     */
    public async sendOfferToCustomer(offerId: number): Promise<unknown> {
        return await this._httpClient.post<unknown, unknown>(`${this.apiOfferPath.byId(offerId)}/sendOffer`);
    }

    /**
     * Tries to send the customer response
     * @param offerId
     * @param data - the customer response
     */
    public async replyOffer(offerId: number, data: OfferDecisionDto): Promise<unknown> {
        return await this._httpClient.post<unknown, OfferDecisionDto>(this.apiOfferPath.byId(offerId), data);
    }
}
