import {Box} from '@mui/material';
import React from 'react';

type Props = {
    id: number;
    image: {
        url: string;
    }[];
};

// TODO: Fix design
export const Image: React.FC<Props> = (props) => {
    return (
        <Box>
            <img style={{width: '100%'}} src={props.image[0].url} alt='' />
        </Box>
    );
};
