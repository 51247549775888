import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import React, {FC, useRef} from 'react';
import {FileDto} from '../../lib/dtos/File';
import {ImageViewer} from '../imageViewer/ImageViewer';
import {ItemSliderWithControls} from '../ItemSlider';
import {ImageDisplay} from './SecureImage';

export const Item: React.FC<{src: string; height: string}> = ({src, height}) => {
    return (
        <ImageViewer>
            <ImageDisplay
                src={src}
                alt={''}
                width={'100%'}
                height={height}
                style={{objectFit: 'cover', cursor: 'pointer'}}
            />
        </ImageViewer>
    );
};

export const ProjectPhotoSlider: FC<{photos: FileDto[]; headerText: string}> = ({photos, headerText}) => {
    const sliderContainerRef = useRef<HTMLDivElement>();
    const getMaxWidth = () => {
        if (sliderContainerRef.current) {
            return sliderContainerRef.current.clientWidth;
        }
    };
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    return (
        <>
            <Box // hack to help firefox set the correct width of slider element
                ref={sliderContainerRef}
                sx={{
                    gridColumn: {xs: 'span 8', md: '3 / span 6'},
                }}
            />
            <Box
                mb={10}
                sx={{
                    gridColumn: {xs: 'span 8', md: '3 / span 6'},
                }}
            >
                <ItemSliderWithControls
                    maxWidth={getMaxWidth()}
                    slidesPerViewOverride={{
                        '0': {slidesPerView: 2.1, spaceBetween: 12},
                        '600': {slidesPerView: 2.7, spaceBetween: 12},
                        '900': {slidesPerView: 2.1, spaceBetween: 12},
                        '1200': {slidesPerView: 2.7, spaceBetween: 12},
                    }}
                    items={photos.map((img) => (
                        <Item key={img.id} src={img.url} height={isSmallScreen ? '400px' : '600px'} />
                    ))}
                    headerTextElement={<Typography variant={'h6'}>{headerText}</Typography>}
                />
            </Box>
        </>
    );
};
