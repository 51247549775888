import {useQuery} from '@apollo/client';
import {Box} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {CMS_SEO, GET_CONTENT_PAGE, GET_SEO_DATA} from '../../graphql';
import {SEO} from '../../SEO';
import {PageContent} from '../cms/PageContent';
import {PageIntro} from '../cms/PageIntro';
import {PageOverline} from '../cms/PageOverline';
import {QuickLinks} from '../cms/QuickLinks';
import {NotFound} from '../errorPages/NotFound';

export const ContentPage: React.FC = () => {
    const {slug} = useParams<{slug: string}>();
    const {loading, error, data} = useQuery(GET_CONTENT_PAGE(slug));
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(`/${slug}`));

    if (loading) return <p>Loading</p>;
    if (error) return <p>Error</p>;

    const entry = data.entry;

    if (!entry) {
        return <NotFound />;
    }

    const quickLinks = entry.pageContent.map((block: any) => {
        return block.quickLink;
    });

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box>
                <PageOverline {...{text: entry.title}} />

                {entry.introText && <PageIntro {...{text: entry.introText}} />}

                <QuickLinks {...{quickLinks: quickLinks}} />

                {entry.pageContent.length === 0 && <Box sx={{pb: 8}} />}

                <PageContent data={entry.pageContent} />
            </Box>
        </>
    );
};
