import {Box, Link, useTheme} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../../graphql/helpers';

type Props = {
    columns: {
        text: string;
        icon: {
            url: string;
        };
        button?: string;
    }[];
};

export const ColumnGrid: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                [theme.breakpoints.up('md')]: {
                    mt: 9,
                },
            }}
        >
            {props.columns.map((column, i) => {
                const columnButton = column.button ? deconstructHTML(column.button) : null;
                return (
                    <Box
                        key={i}
                        sx={{
                            mt: 8,
                            width: '75%',
                            textAlign: 'center',
                            px: 2,
                            [theme.breakpoints.up('md')]: {
                                width: '33.33%',
                                mt: 0,
                                px: 4,
                                '&:first-child': {
                                    pl: 0,
                                },
                                '&:last-child': {
                                    pr: 0,
                                },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                mx: 'auto',
                            }}
                        >
                            <Box
                                sx={{
                                    height: '114px',
                                    mb: 5,
                                }}
                            >
                                <img
                                    style={{
                                        display: 'block',
                                        margin: '0 auto',
                                        width: 'auto',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    src={column.icon.url}
                                    alt=''
                                />
                            </Box>

                            <Box
                                sx={{
                                    mt: 3,
                                    fontSize: '1rem',
                                    lineHeight: '1.5',
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '1.0625rem',
                                    },
                                }}
                                dangerouslySetInnerHTML={{__html: column.text}}
                            />

                            {columnButton !== null && (
                                <Link
                                    sx={{
                                        mt: 3,
                                        textDecoration: 'none',
                                    }}
                                    href={columnButton.url}
                                >
                                    {columnButton.text}
                                </Link>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
