import {Box, Link, useTheme} from '@mui/material';
import React from 'react';
import {formatUrl, SERVICES_ENTRY_PAGE_URL_FORMAT} from '../../cms/helpers';
import {deconstructHTML} from '../../graphql/helpers';
import {CmsServiceGroup} from '../../graphql/types';
import {ButtonPrimaryMedium} from '../Buttons';
import {List, ListItem} from '../cms/partials/List';
import {Container, CssGrid} from '../Layout';

type ButtonProps = {
    primaryButton: string;
    secondaryButton: string;
};

const FeaturedServicesLinks: React.FC<ButtonProps> = (props) => {
    const theme = useTheme();

    const primaryButton = deconstructHTML(props.primaryButton);
    const secondaryButton = deconstructHTML(props.secondaryButton);
    return (
        <Box
            sx={{
                [theme.breakpoints.up('md')]: {
                    gridRow: '1',
                    gridColumn: 'span 1',
                },
            }}
        >
            {secondaryButton !== null && (
                <Box>
                    <Link
                        href={secondaryButton.url}
                        sx={{
                            display: 'inline-block',
                            textDecoration: 'underline',
                            color: 'text.primary',
                            whiteSpace: 'nowrap',
                            mt: 4,
                            [theme.breakpoints.up('sm')]: {
                                mt: 6,
                            },
                        }}
                    >
                        {secondaryButton.text}
                    </Link>
                </Box>
            )}
            {primaryButton !== null && (
                <ButtonPrimaryMedium
                    sx={{
                        mt: 3,
                        [theme.breakpoints.up('sm')]: {
                            mt: 5,
                        },
                    }}
                    href={primaryButton.url}
                >
                    {primaryButton.text}
                </ButtonPrimaryMedium>
            )}
        </Box>
    );
};

type Props = {
    featuredServices: {
        primaryButton: string;
        secondaryButton: string;
        service: CmsServiceGroup[];
    }[];
};

export const FeaturedServices: React.FC<Props> = (props) => {
    const theme = useTheme();
    return (
        <Container>
            <CssGrid>
                <Box
                    sx={{
                        gridColumn: '2 / span 6',
                        [theme.breakpoints.up('md')]: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            columnGap: 3,
                        },
                    }}
                >
                    {props.featuredServices.map((list, index: number) => {
                        const serviceGroup = list.service[0];

                        return (
                            <Box
                                key={index}
                                sx={{
                                    mt: index > 0 ? 8 : 0,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 0,
                                        gridRow: '1',
                                        gridColumn: 'span 1',
                                    },
                                }}
                            >
                                <List heading={serviceGroup.title}>
                                    {serviceGroup?.children?.map((service) => {
                                        return (
                                            <ListItem
                                                key={service.id}
                                                text={service.title || ''}
                                                url={formatUrl(SERVICES_ENTRY_PAGE_URL_FORMAT, service.slug)}
                                            />
                                        );
                                    })}
                                </List>

                                <Box
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <FeaturedServicesLinks
                                        primaryButton={list.primaryButton}
                                        secondaryButton={list.secondaryButton}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </CssGrid>

            <CssGrid>
                <Box
                    sx={{
                        display: 'none',
                        gridColumn: '2 / span 6',
                        [theme.breakpoints.up('md')]: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            columnGap: 3,
                        },
                    }}
                >
                    {props.featuredServices.map((list, i) => {
                        return (
                            <FeaturedServicesLinks
                                key={i}
                                primaryButton={list.primaryButton}
                                secondaryButton={list.secondaryButton}
                            />
                        );
                    })}
                </Box>
            </CssGrid>
        </Container>
    );
};
