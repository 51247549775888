import {Link, styled, SxProps, Theme, Typography} from '@mui/material';
import React from 'react';
import {FileDto} from '../lib/dtos/File';
import {useApiFileDownload} from '../lib/hooks/useApiFileDownload';
import {DownloadIcon} from './icons/DownloadIcon';

const DownloadElement = styled(Link)({
    cursor: 'pointer',
    display: 'flex',
    '& svg': {
        overflow: 'visible',
        verticalAlign: 'middle',
    },
    '&:hover #arrow': {
        transform: 'translateY(-8px)',
        transition: 'transform 0.3s',
    },
});

export const DownloadFile: React.FC<{file: FileDto; iconSize?: number}> = ({file, iconSize, children}) => {
    const {download} = useApiFileDownload();
    const handleDownload = () => {
        download({fileDescriptor: file});
    };
    return (
        <DownloadElement onClick={handleDownload}>
            <Typography>{children}</Typography>
            <DownloadIcon size={iconSize} />
        </DownloadElement>
    );
};

export const DownloadPDF: React.FC<{file: FileDto; sx?: SxProps<Theme>}> = ({file, sx}) => {
    const {download} = useApiFileDownload();
    const handleDownload = () => {
        download({fileDescriptor: file});
    };
    return (
        <Link
            onClick={handleDownload}
            sx={{
                border: 1.5,
                borderColor: 'formLine.active',
                py: 1,
                px: 1.5,
                textDecoration: 'none',
                cursor: 'pointer',
                ...sx,
            }}
        >
            <Typography variant={'subtitle1'} component={'span'}>
                {file.fileName}
            </Typography>
        </Link>
    );
};
