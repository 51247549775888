import {Skeleton} from '@mui/material';
import {useUrl} from 'components/imageViewer/UrlProvider';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import React, {CSSProperties, useEffect, useState} from 'react';

const baseUrl = process.env.REACT_APP_API_URL;
export type SecureImageProps = {
    src: string;
    alt: string;
    width?: string;
    height?: string;
    style?: CSSProperties;
    handleOnClick?: (imgSrc: string) => void;
};

/**
 * Secure image loader uses the M2 Axios instance with bearer token
 * to access our API to load images that are protected.
 */
const ImageViewerC: React.FC<SecureImageProps> = ({src, alt, width, height, style}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, getFile] = useM2Axios<ArrayBuffer>({}, {manual: true});
    const [imageUrl, setImageUrl] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>();
    const [hasStarted, setStarted] = useState(false);
    const urlContext = useUrl();
    useEffect(() => {
        const getIt = async () => {
            try {
                let url = src;

                if (urlContext?.url && urlContext.url.startsWith('blob')) {
                    url = urlContext.url;
                } else if (src.startsWith(baseUrl)) {
                    const file = await getFile({
                        url: src,
                        responseType: 'arraybuffer',
                    });
                    const blob = new Blob([file.request.response]);
                    url = URL.createObjectURL(blob);
                }
                urlContext?.setUrl(url);
                setImageUrl(url);
            } catch (error) {
                console.error('Error loading image: ' + src);
            }
        };
        if (!hasStarted) {
            setStarted(true);
            getIt();
        }
    }, [getFile, src, hasStarted, imageUrl, urlContext]);

    return (
        <>
            {!loaded && <Skeleton width={width || 98} height={height || 106} />}
            {imageUrl && (
                <img
                    onLoad={() => setLoaded(true)}
                    src={imageUrl}
                    alt={alt}
                    width={!loaded ? 0 : width || 98}
                    height={!loaded ? 0 : height || 106}
                    style={{...style, opacity: loaded ? 1 : 0, transition: 'opacity 300ms'}}
                />
            )}
        </>
    );
};

export const ImageDisplay = React.memo(ImageViewerC);
