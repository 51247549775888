import {Box, Container, Typography} from '@mui/material';
import {CenterContent} from 'components/AppLayout';
import {CssGrid, DefaultGridPageLayout} from 'components/Layout';
import {useLanguage} from 'language/LanguageProvider';
import React, {useContext, useEffect} from 'react';
import {AppContext} from 'store/appContext';
import {ProjectInvoiceDto} from '../../lib/dtos/Invoice';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {LoaderCenterBlur} from '../Loader';
import {Invoices} from './Invoices';
import {getServiceText} from './serviceText';

const InvoiceGroup: React.FC<ProjectInvoiceDto & {services?: ServiceDictionary}> = (props) => {
    const {services, zip, location, street} = props.project;
    const title = `${props.services && getServiceText(services, props.services) + ' -'} ${street}, ${zip} ${location}`;

    return (
        <Box sx={{my: 8, '&:first-child': {mt: 4}}}>
            <Typography variant={'h6'} mb={0}>
                {title}
            </Typography>
            <Invoices invoices={props.invoices} />
        </Box>
    );
};

const EmptyState: React.FC = () => {
    const {getLangString} = useLanguage();
    return (
        <CenterContent>
            <Typography variant={'h3'} color={'secondary'}>
                {getLangString('INVOICES_EMPTY_STATE')}
            </Typography>
        </CenterContent>
    );
};

export const InvoicesPage: React.FC = () => {
    const {state, bgColor} = useContext(AppContext);
    const [{data: invoices, loading}, getInvoices] = useM2Axios<{data: ProjectInvoiceDto[]}>('', {manual: true});

    useEffect(() => {
        if (state.user) {
            getInvoices({url: `${ApiEndpoints.customer.byId(state.user.id)}/invoices`});
        }
    }, [getInvoices, state.user]);

    return (
        <Container sx={{backgroundColor: bgColor, height: '100%', pt: 2, pb: 4}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    {invoices &&
                        (invoices.data.length === 0 ? (
                            <EmptyState />
                        ) : (
                            invoices.data.map((invoices) => (
                                <InvoiceGroup
                                    key={invoices.project.projectId}
                                    project={invoices.project}
                                    invoices={invoices.invoices}
                                    services={state.allServices}
                                />
                            ))
                        ))}
                    <LoaderCenterBlur open={loading} />
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
