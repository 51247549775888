import {CordelOrderDTO} from './CordelOrderDTO';
import {PhaseDto} from './PhaseDto';
import {ProjectBaseDto} from './Project';

export enum OrderStatus {
    New,
    Started,
    Closed,
}
export type OrderDto = {
    orderId: number;
    phases: PhaseDto[];
    project: ProjectBaseDto;
    cordelOrders: CordelOrderDTO[];
    createdAt: Date;
    updatedAt: Date;
};

export interface OrderSimpleDto {
    orderId: number;
    orderStatus: OrderStatus;
    project: ProjectBaseDto;
    createdAt: string;
    updatedAt: string;
}
export type OrderListDto = {
    orderId: number;
    phases: PhaseDto[];
    createdAt: Date;
    updatedAt: Date;
};
