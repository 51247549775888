import {Box, Dialog, DialogProps, IconButton, Paper} from '@mui/material';
import React from 'react';
import {CloseIcon} from './icons/CloseIcon';

export const CustomDialog: React.FC<DialogProps & {minWidth?: string}> = (props) => {
    const handleClose = () => {
        props.onClose && props.onClose({}, 'escapeKeyDown');
    };

    return (
        <Dialog
            {...props}
            PaperComponent={Paper}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: 0,
                    margin: '8px!important',
                    minWidth: props.minWidth,
                },
            }}
        >
            <Box sx={{pt: 2, px: 2, mb: -2, textAlign: 'right'}}>
                <IconButton onClick={handleClose}>
                    <CloseIcon size={12} />
                </IconButton>
            </Box>
            {props.children}
        </Dialog>
    );
};
