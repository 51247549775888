import {Checkbox, CheckboxProps, FormControlLabel, useTheme} from '@mui/material';
import React from 'react';
import {RadioActiveIcon, RadioInActiveIcon} from './icons/RadioIcon';

export const CustomCheckbox: React.FC<CheckboxProps & {iconColor?: string}> = (props) => {
    return (
        <Checkbox
            {...props}
            icon={<RadioInActiveIcon size={24} color={props.iconColor} />}
            checkedIcon={<RadioActiveIcon size={24} color={props.iconColor} />}
            disableRipple={true}
        />
    );
};
type Props = {
    labelText: string;
};
export const CheckboxButton: React.FC<CheckboxProps & Props> = (props) => {
    // const radioGroup = useRadioGroup();
    const {palette} = useTheme();
    const isChecked = props.checked;

    return (
        <FormControlLabel
            control={
                <CustomCheckbox
                    aria-label={props['aria-label']}
                    iconColor={isChecked ? palette.formLine.active : palette.formLine.inActive}
                    value={props.value}
                    checked={isChecked}
                    onChange={props.onChange}
                />
            }
            labelPlacement={'start'}
            label={props.labelText}
            sx={{
                border: `1px solid ${isChecked ? palette.formLine.active : palette.formLine.inActive}`,
                color: isChecked ? palette.text.primary : palette.text.secondary,
                padding: '8px 16px 8px 24px',
                justifyContent: 'space-between',
                margin: 0,
                marginTop: '-1px',
                zIndex: isChecked ? 2 : 1,
                width: '100%',
                '& span': {
                    color: 'inherit',
                },
            }}
        />
    );
};
