import {Box, Button, IconButton, Theme, Typography} from '@mui/material';
import {SxProps} from '@mui/system';
import {FileDto} from 'lib/dtos/File';
import {useApiFileDownload} from 'lib/hooks/useApiFileDownload';
import React, {ChangeEvent} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {trimString} from '../toolbox';
import {CloseIcon} from './icons/CloseIcon';

export const PDFUploader: React.FC<{
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    allowMultiple?: boolean;
    sx?: SxProps<Theme>;
}> = (props) => {
    const {getLangString} = useLanguage();
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };
    return (
        <>
            <input
                type={'file'}
                accept={'.pdf'}
                multiple={props.allowMultiple}
                onChange={props.handleFileChange}
                ref={hiddenFileInput}
                style={{display: 'none'}}
            />
            <Button onClick={handleClick} sx={props.sx}>
                {getLangString('SELECT_PDF')}
            </Button>
        </>
    );
};
export const UploadedFile: React.FC<{
    fileId: string;
    fileName: string;
    onRemoveFile?: (id: string) => void;
    file?: FileDto;
}> = ({fileId, fileName, onRemoveFile, file}) => {
    const {download} = useApiFileDownload();
    const handleClick = () => {
        onRemoveFile && onRemoveFile(fileId);
    };
    return (
        <Box sx={{border: 2, borderColor: 'primary', display: 'flex', alignItems: 'center', p: 1, pr: 1.5, mb: 1}}>
            {onRemoveFile && (
                <IconButton onClick={handleClick} sx={{p: 0.5}}>
                    <CloseIcon size={12} />
                </IconButton>
            )}
            <Box
                sx={{
                    cursor: file ? 'pointer' : 'default',
                }}
            >
                <Typography
                    onClick={() => (file ? download({fileDescriptor: file}) : undefined)}
                    variant={'body2'}
                    ml={1}
                >
                    {trimString(fileName, 25)}
                </Typography>
            </Box>
        </Box>
    );
};
