import {Link, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';

export type NameValue = {
    name: string;
    value: string;
    urlType?: 'email' | 'phone';
};

export const UnderlinedNameValue: React.VFC<NameValue> = (props) => {
    const getField = (prop: NameValue) => {
        if (prop.urlType) {
            if (prop.urlType === 'email') {
                return <Link href={`mailto:${props.value}`}>{props.value}</Link>;
            }
            if (prop.urlType === 'phone') {
                return <Link href={`tel:${props.value}`}>{props.value}</Link>;
            }
        }
        return <Typography>{props.value}</Typography>;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'inline',
                borderBottom: '1px solid black',
            }}
            paddingBottom={4}
        >
            <Typography minWidth={160}>{props.name}</Typography>
            {getField(props)}
        </Box>
    );
};
