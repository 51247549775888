import {Box, CircularProgress, Typography} from '@mui/material';
import {DataGrid, DataGridProps, GridColDef, GridOverlay, GridRenderCellParams} from '@mui/x-data-grid';
import {ArrowForward} from 'components/icons/ArrowForward';
import {format} from 'date-fns';
import {nb} from 'date-fns/locale';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {AdminAppContext} from 'store/adminAppContext';
import {colors} from 'theme';
import {useLanguage} from '../../../../language/LanguageProvider';
import {OrderStatus} from '../../../../lib/dtos/Order';
import {PaginationDto} from '../../../../lib/dtos/Pagination';
import {EmployeeBaseDto} from '../../../../lib/dtos/User';
import {OrderList} from '../../../../lib/types/Admin';
import {StatusElement} from '../../../StatusElement';

const gridColDefProps: Partial<GridColDef> = {
    hideable: false,
};
const columns: GridColDef[] = [
    {
        field: 'status',
        headerName: 'Status',
        renderCell: ({value}: GridRenderCellParams<OrderStatus>) => (
            <StatusElement text={orderStatus[value].text} iconColor={orderStatus[value].color} />
        ),
        ...gridColDefProps,
        minWidth: 120,
    },
    {
        field: 'projectId',
        headerName: 'ID',
        renderCell: ({value}) => <Typography>{value}</Typography>,
        ...gridColDefProps,
    },
    {
        field: 'dateCreated',
        headerName: 'Dato',
        type: 'date',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Typography>{format(params.value, 'P', {locale: nb})}</Typography>
        ),
        ...gridColDefProps,
    },
    {
        field: 'title',
        headerName: 'Tittel',
        flex: 1,
        renderCell: ({value}) => <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{value}</Typography>,
        ...gridColDefProps,
    },
    {
        field: 'projectLeader',
        headerName: 'Prosjektleder',
        renderCell: (params: GridRenderCellParams<EmployeeBaseDto>) => (
            <Typography>{params.value ? `${params.value.firstName} ${params.value.lastName}` : ''}</Typography>
        ),
        ...gridColDefProps,
        minWidth: 180,
    },
    {
        field: 'seeDetails',
        hideSortIcons: true,
        headerName: '',
        sortable: false,
        renderCell: () => (
            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} style={{cursor: 'pointer'}}>
                <ArrowForward size={20} />
            </Box>
        ),
        disableColumnMenu: true,
        ...gridColDefProps,
        minWidth: 20,
    },
];
export const OrdersDataGrid: React.VFC<
    {
        data: OrderList[];
        pagination: PaginationDto | undefined;
        rowsPerPage: number;
        minHeight: number;
    } & Pick<DataGridProps, 'onPageChange' | 'onPageSizeChange' | 'loading'>
> = ({data, pagination, rowsPerPage, minHeight, ...props}) => {
    const {bgColor} = useContext(AdminAppContext);
    const history = useHistory();
    const {getLangString} = useLanguage();

    return (
        <DataGrid
            {...props}
            rowsPerPageOptions={[5, 10, 25, 50]}
            columns={columns}
            pageSize={rowsPerPage}
            rows={data.map((order) => ({...order, id: order.orderId}))}
            rowCount={pagination?.total ?? 0}
            onRowClick={(params) => {
                history.push(`${history.location.pathname}/${params.row.orderId}`);
            }}
            components={{
                NoRowsOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <Typography>{getLangString('NO_DATA')}</Typography>
                    </GridOverlay>
                ),
                LoadingOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <CircularProgress />
                    </GridOverlay>
                ),
            }}
            componentsProps={{loadingOverlay: {background: bgColor}, noResultsOverlay: {background: bgColor}}}
            sx={{
                minHeight,
                '& .MuiDataGrid-overlay': {backgroundColor: 'transparent'},
            }}
        />
    );
};

const orderStatus: Record<OrderStatus, {text: string; color: string}> = {
    [OrderStatus.New]: {text: 'Ny', color: colors.fair},
    [OrderStatus.Started]: {text: 'Arbeid startet', color: colors.yellow},
    [OrderStatus.Closed]: {text: 'Ferdigstilt', color: colors.green},
};
