import {Box, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';
import {ArticleCard} from './partials/ArticleCard';

type Props = {
    id: number;
    article: {
        id: number;
        title: string;
        slug: string;
        mainImage: {
            id: number;
            url: string;
        }[];
        relatedServices: {
            id: number;
            title: string;
            slug: string;
        }[];
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const FeaturedArticle: React.FC<Props> = (props) => {
    const theme = useTheme();
    const article = props.article[0];

    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '2 / span 6',
                            },
                        }}
                    >
                        <ArticleCard article={article} />
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
