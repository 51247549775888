import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const ArrowBackward: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || '24'} height={size || '24'} viewBox='0 0 24 24'>
        <g transform='translate(-997.906 -149.069)'>
            <path
                d='M1009.832,166.434l-1.173,1.153-6.518-6.517,6.518-6.519,1.173,1.174-4.443,4.422h12.281v1.844h-12.281Z'
                fill={color || colors.black}
            />
        </g>
    </svg>
);
