import {Box, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';

type Props = {
    text: string;
};

export const PageOverline: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <Container>
            <CssGrid>
                <Box
                    sx={{
                        mt: 5,
                        mb: 3.5,
                        gridColumn: 'span 8',
                        fontSize: '0.875rem',
                        lineHeight: '1.57',
                        [theme.breakpoints.up('md')]: {
                            fontSize: '1.0625rem',
                            lineHeight: '1.52',
                            mt: 8,
                            mb: 5,
                        },
                        [theme.breakpoints.up('lg')]: {
                            mt: 12,
                            mb: 6,
                            gridColumn: '2 / span 6',
                        },
                    }}
                >
                    {props.text}
                </Box>
            </CssGrid>
        </Container>
    );
};
