import {Box, FormGroup, Grid, RadioGroup, styled, Typography} from '@mui/material';
import React, {ChangeEvent, useContext} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {Service, ServiceDictionary} from '../../lib/types/Inquiry';
import {addSpecificationText} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {CheckboxButton} from '../CheckboxButton';
import {CustomInputField} from '../InputField';
import {InquiryPhotoAttachments} from '../PhotoUploader';
import {RadioButton} from '../RadioButton';

type RadioProps = {
    handleChange: (service: Service | null) => void;
    availableOptions: ServiceDictionary | undefined;
    selectedOption: Service | null;
    columns?: number;
};
export const SelectServiceRadioOptions: React.FC<RadioProps> = ({
    availableOptions,
    selectedOption,
    handleChange,
    columns = 2,
}) => {
    const localHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedService = availableOptions ? availableOptions[event.target.value] : null;
        handleChange(selectedService);
    };
    if (availableOptions) {
        return (
            <RadioGroup onChange={localHandleChange} value={selectedOption ? selectedOption.id : null}>
                <Grid container>
                    {Object.keys(availableOptions).map((s) => {
                        const option = availableOptions[s];
                        if (!option.archived) {
                            return (
                                <Grid
                                    key={option.id}
                                    item
                                    xs={8}
                                    sm={columns == 1 ? 8 : 4}
                                    sx={{
                                        zIndex: selectedOption && selectedOption.id === availableOptions[s].id ? 10 : 1,
                                    }}
                                >
                                    <RadioButton labelText={availableOptions[s].title} value={availableOptions[s].id} />
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </RadioGroup>
        );
    } else {
        return <div>loading...</div>;
    }
};

type CheckboxProps = {
    handleChange: (services: Service[]) => void;
    availableOptions: ServiceDictionary | undefined;
    selectedOptions: Service[];
    columns?: number;
};
export const SelectServiceCheckboxOptions: React.FC<CheckboxProps> = ({
    availableOptions,
    selectedOptions,
    handleChange,
    columns = 2,
}) => {
    const localHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        const currentOption = selectedOptions.some((o) => o.id === value);
        if (currentOption) {
            handleChange(selectedOptions.filter((o) => o.id !== value));
        } else {
            const newOption = availableOptions ? availableOptions[value] : null;
            if (newOption) {
                handleChange([...selectedOptions, newOption]);
            }
        }
    };

    if (availableOptions) {
        return (
            <FormGroup>
                <Grid container>
                    {Object.keys(availableOptions).map((s) => {
                        const option = availableOptions[s];
                        const isSelected = selectedOptions.some((o) => o.id == option.id);
                        if (!option.archived) {
                            return (
                                <Grid
                                    item
                                    xs={8}
                                    sm={columns == 1 ? 8 : 4}
                                    sx={{
                                        zIndex: isSelected ? 10 : 1,
                                    }}
                                >
                                    <CheckboxButton
                                        key={option.id}
                                        labelText={option.title}
                                        value={option.id}
                                        checked={isSelected}
                                        onChange={localHandleChange}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </FormGroup>
        );
    } else {
        return <div>loading...</div>;
    }
};

export const Specification: React.FC = () => {
    const {state, dispatch} = useContext(AppContext);
    const {getLangString} = useLanguage();
    const [value, setValue] = React.useState<string>(state.inquiry.specification?.description || '');
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };
    const handleBlur = () => {
        dispatch(addSpecificationText(value));
    };
    return (
        <Grid container rowSpacing={5}>
            <Grid item xs={8} sm={4}>
                <Typography variant={'subtitle1'}>{getLangString('SPECIFICATION_HEADER')}</Typography>
                <CustomInputField
                    multiline
                    fullWidth={true}
                    minRows={5}
                    placeholder={'Eks. Murvegg? Åtte nye stikkontakter?'}
                    value={value}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <Typography variant={'subtitle1'}>{getLangString('PHOTO_ATTACHMENTS_HEADER')}</Typography>
                <InquiryPhotoAttachments />
            </Grid>
        </Grid>
    );
};

export const ButtonLayout = styled(Box)(({theme}) => ({
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
    },
}));
