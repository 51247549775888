import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {ItemSlider} from '../ItemSlider';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    heading: string;
    images: {
        id: string;
        url: string;
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const ImageSlider: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <ContentBlock quickLink={props.quickLink} size='md'>
            <Box sx={{overflow: 'hidden'}}>
                <Container>
                    {props.heading !== null && props.heading !== '' && (
                        <CssGrid>
                            <Box
                                sx={{
                                    gridRow: 1,
                                    gridColumn: 'span 8',
                                    position: 'relative',
                                    mb: 3,
                                    [theme.breakpoints.up('lg')]: {
                                        gridColumn: '2 / span 6',
                                        mb: 5,
                                    },
                                }}
                            >
                                <Typography variant='sectionHeading'>{props.heading}</Typography>
                            </Box>
                        </CssGrid>
                    )}
                </Container>
                <ItemSlider
                    items={props.images.map((image) => {
                        return (
                            <Box key={image.id}>
                                <img src={image.url} style={{width: '100%', display: 'block'}} alt='' />
                            </Box>
                        );
                    })}
                    slidesPerViewOverride={{
                        '0': {slidesPerView: 1, spaceBetween: 10},
                        '600': {slidesPerView: 2},
                        '900': {slidesPerView: 2, spaceBetween: 24},
                        '1200': {slidesPerView: 2, spaceBetween: 24},
                    }}
                />
            </Box>
        </ContentBlock>
    );
};
