import {Box, Grid, MenuItem, Select, Typography} from '@mui/material';
import {ButtonPrimaryMedium} from 'components/Buttons';
import {LoaderCenterBlur} from 'components/Loader';
import {useLanguage} from 'language/LanguageProvider';
import {CreateCordelOrderDto} from 'lib/dtos/CreateCordelOrderDto';
import {OrderDto} from 'lib/dtos/Order';
import {OrderableCompaniesDto} from 'lib/dtos/OrderableCompanies';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {MixedRequestError, useProblemDetailsError} from 'lib/hooks/useProblemDetailsError';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {ProblemDetailsError} from 'lib/remote/ProblemDetails';
import {useEffect, useState} from 'react';

export type CreateCordelOrderProps = {
    order: OrderDto;
    orderableCompany: OrderableCompaniesDto;
    onCreated: () => void;
};

export type SelectedTypes = {
    type?: string;
    method?: string;
};

export const CreateCordelOrder: React.FC<CreateCordelOrderProps> = ({order, orderableCompany, onCreated}) => {
    const [selected, setSelected] = useState<SelectedTypes>({type: '', method: ''});
    const {getLangString} = useLanguage();
    const {translatedError, translate, clearTranslatedError} = useProblemDetailsError(
        {},
        getLangString('FAILED_TO_SAVE'),
    );
    const [didCreate, setDidCreate] = useState<boolean>(false);
    const [{loading, response}, createCordelOrder] = useM2Axios<M2Response<unknown>, any, ProblemDetailsError>(
        ApiEndpoints.order.cordelOrders(order.orderId),
        {
            manual: true,
        },
    );
    useEffect(() => {
        if (!translatedError && response && response.status < 300) {
            setDidCreate(true);
        }
    }, [response, translatedError]);

    const doCreateCordelOrder = async (companyId: number, selectedTypes: SelectedTypes) => {
        setDidCreate(false);
        clearTranslatedError();
        try {
            if (!selectedTypes.method || !selectedTypes.type) {
                //
                console.log('Missing methods');
            } else {
                const cordelOrder: CreateCordelOrderDto = {
                    cordelCompanyId: companyId,
                    orderMethodId: selectedTypes.method,
                    orderType: selectedTypes.type,
                };
                const response = await createCordelOrder({method: 'POST', data: [cordelOrder]});
                setTimeout(() => {
                    setDidCreate(false);
                }, 3000);
                onCreated();
                setSelected({type: '', method: ''});
            }
        } catch (error) {
            translate(error as MixedRequestError);
        }
    };

    const hasSelected = (selected: SelectedTypes) => {
        const method = !!(selected?.method && selected.method.length > 1);
        const type = !!(selected?.type && selected.type.length > 1);
        return method && type;
    };

    return (
        <>
            <Grid container columnSpacing={2} direction={'row'}>
                <Grid item md={4}>
                    <Typography>Cordel ordremetode</Typography>
                    <Select
                        variant='outlined'
                        sx={{mb: 2, width: '100%'}}
                        value={selected.method}
                        displayEmpty={true}
                        renderValue={(selected?: string) => {
                            if (!selected) {
                                return <Typography mt={0.75}>Velg ordremetode</Typography>;
                            } else {
                                return (
                                    <Typography mt={0.75}>
                                        {orderableCompany?.orderMethods.find((o) => o.id == selected)?.description}
                                    </Typography>
                                );
                            }
                        }}
                        onChange={(v) => setSelected({...selected, method: v.target.value})}
                    >
                        {orderableCompany?.orderMethods?.map((od, index) => {
                            return (
                                <MenuItem key={index} value={od.id}>
                                    {od.description}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item md={4}>
                    <Typography>Cordel ordretype</Typography>
                    <Select
                        variant='outlined'
                        sx={{mb: 2, width: '100%'}}
                        value={selected.type}
                        displayEmpty={true}
                        renderValue={(selected?: string) => {
                            if (!selected) {
                                return <Typography mt={0.75}>Velg ordretype</Typography>;
                            } else {
                                return <Typography mt={0.75}>{selected}</Typography>;
                            }
                        }}
                        onChange={(v) => setSelected({...selected, type: v.target.value})}
                    >
                        {orderableCompany?.orderType?.map((orderType, index) => {
                            return (
                                <MenuItem key={index} value={orderType}>
                                    {orderType}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            </Grid>
            <LoaderCenterBlur open={loading} text={'Oppretter'}></LoaderCenterBlur>
            <Box>
                {translatedError && <Typography variant='error'>{translatedError}</Typography>}
                {didCreate && <Typography variant='success'>{getLangString('ORDER_CREATED')}</Typography>}
            </Box>
            <ButtonPrimaryMedium
                disabled={!hasSelected(selected) || loading}
                onClick={() => doCreateCordelOrder(orderableCompany.cordelCompanyId, selected)}
            >
                {getLangString('CREATE_ORDER_IN')} {orderableCompany.name}
            </ButtonPrimaryMedium>
        </>
    );
};
