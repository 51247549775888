import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {AppBar, Box, Container, styled, Theme, useMediaQuery, useTheme} from '@mui/material';
import {adminBasePath} from 'App';
import {M2Logo} from 'assets/M2Logo';
import {CssGrid} from 'components/Layout';
import {OrderDetailsPage} from 'components/loggedInPages/admin/orderPage/OrderDetailsPage';
import {OrderPage} from 'components/loggedInPages/admin/orderPage/OrderPage';
import {NavBar} from 'components/NavBar';
import {CustomNavLink, SubMenuMobile} from 'components/Navigation';
import {SignInPage} from 'components/openPages/SignInPage';
import {useInitializeUser} from 'initUser';
import {useLanguage} from 'language/LanguageProvider';
import React, {useContext} from 'react';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import {AdminAppContext, AdminStateProvider} from 'store/adminAppContext';
import {OrderContentDto} from '../../../lib/dtos/OrderContent';
import {EmployeeDto, UserDto} from '../../../lib/dtos/User';
import {useM2Axios} from '../../../lib/hooks/useM2Axios';
import {M2HttpClient} from '../../../lib/remote/m2api/ApiHttpClient';
import {M2Response} from '../../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../../lib/remote/m2api/M2Endpoints';
import {ContentService} from '../../../lib/services/ContentService';
import {SEO} from '../../../SEO';
import {loadEmployeeUserInfo, loadServiceTypes} from '../../../store/actions';
import {useIsSmallScreen} from '../../../toolbox';
import {LoaderCentered} from '../../Loader';
import {CompanyDetailsPage} from './companyPage/CompanyDetailsPage';
import {CompanyPage} from './companyPage/CompanyPage';
import {EmployeePage} from './employeePages/EmployeePage';
import {InquiriesPage} from './InquirePage/InquiriesPage';
import {InquiryDetailsPage} from './InquirePage/InquiryDetailsPage';
import {OfferDetailsPageEditable, OfferDetailsPageRejected} from './offerPage/OfferDetailsPage';
import {OffersPage} from './offerPage/OffersPage';

const Link = styled(CustomNavLink)({
    marginRight: 56,
});

export const getInquiryByIDPath = (projectID: number, inquiryID: number): string =>
    `${adminBasePath}/inquiries/${projectID}/${inquiryID}`;
export const getOfferByIDPath = (projectID: number, offerID?: number): string =>
    `${adminBasePath}/offers/${projectID}/${offerID || ''}`;
export const getRejectedOfferByIDPath = (projectID: number, offerID: number): string =>
    `${adminBasePath}/offers/${projectID}/${offerID}/rejected`;
export const getOffersListPath = (): string => `${adminBasePath}/offers`;

export const AdminSimpleMenuBar: React.FC<{bgColor?: string}> = ({bgColor}) => {
    const history = useHistory();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const goToMainPage = () => {
        history.push('/admin');
    };
    return (
        <AppBar sx={{backgroundColor: bgColor || theme.palette.customBackground.light, position: 'sticky'}}>
            <Container>
                <CssGrid sx={{alignItems: 'center', py: 1}}>
                    <Box
                        sx={{
                            gridColumn: '8',
                            cursor: 'pointer',
                            justifySelf: 'flex-end',
                        }}
                        onClick={goToMainPage}
                    >
                        <M2Logo size={isSmallScreen ? 50 : 72} color={theme.palette.text.primary} />
                    </Box>
                </CssGrid>
            </Container>
        </AppBar>
    );
};
const MenuContent: React.FC = () => {
    const {getLangString} = useLanguage();
    const {url} = useRouteMatch();
    const theme = useTheme();
    const activeStyle = {
        fontFamily: 'SuisseIntl-Medium, sans-serif',
        borderBottom: '2px solid ' + theme.palette.primary.main,
    };
    return (
        <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Box display={'flex'}>
                <Link activeStyle={activeStyle} exact={true} to={`${url}/inquiries`}>
                    {getLangString('INQUIRIES')}
                </Link>
                <Link activeStyle={activeStyle} exact={true} to={`${url}/offers`}>
                    {getLangString('OFFER')}
                </Link>
                <Link activeStyle={activeStyle} exact={true} to={`${url}/orders`}>
                    {getLangString('ORDER')}
                </Link>
            </Box>
            <Box display={'flex'}>
                <Link activeStyle={activeStyle} exact={true} to={`${url}/companies`}>
                    {getLangString('COMPANIES')}
                </Link>
                <Link activeStyle={activeStyle} exact={true} to={`${url}/employees`}>
                    {getLangString('EMPLOYEES')}
                </Link>
            </Box>
        </Box>
    );
};
export const AdminPagesWithMenuBar: React.FC = ({children}) => {
    const {bgColor} = useContext(AdminAppContext);
    const isSmallScreen = useIsSmallScreen();
    return (
        <>
            <NavBar bgColor={bgColor}>{!isSmallScreen && <MenuContent />}</NavBar>
            {isSmallScreen && <SubMenuMobile content={<MenuContent />} bgColor={bgColor} />}
            <Box sx={{backgroundColor: bgColor, flexGrow: 1, pt: 2}}>{children}</Box>
        </>
    );
};

export const AdminRoutes: React.FC = () => {
    const {path} = useRouteMatch();
    const {accessToken} = useInitializeUser();
    const {dispatch} = useContext(AdminAppContext);
    React.useEffect(() => {
        const fetchData = async () => {
            const service = new ContentService(M2HttpClient);
            try {
                const services: OrderContentDto = await service.getOrderContentDetails();
                dispatch(loadServiceTypes(services));
            } catch (e) {
                return;
            }
        };
        fetchData();
    }, []);
    const [{loading: isFetchingUser, data: user}, getUser] = useM2Axios<M2Response<UserDto>>(
        `${ApiEndpoints.user.base()}/me`,
        {manual: false},
    );
    const [{loading: isFetchingEmployee, data: employee}, getEmployee] = useM2Axios<M2Response<EmployeeDto>>('', {
        manual: true,
    });

    React.useEffect(() => {
        const getEmployeeData = async () => {
            try {
                if (user) {
                    const {data} = await getEmployee({url: ApiEndpoints.employee.byId(user.data.id), method: 'GET'});
                    dispatch(loadEmployeeUserInfo(data.data));
                }
            } catch (error) {
                /** */
            }
        };
        getEmployeeData();
    }, [user]);

    if (!accessToken) {
        return <LoaderCentered />;
    }

    return (
        <Box sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
            {/*TODO: Add image*/}
            <SEO documentTitle={'M2'} title={'M2'} url={window.location.href} />
            <Switch>
                <Route path={`${path}/inquiries/:projectID/:inquiryID`} component={InquiryDetailsPage} />
                <Route path={`${path}/offers/:projectID/:offerID/rejected`} component={OfferDetailsPageRejected} />
                <Route path={`${path}/offers/:projectID/:offerID`} component={OfferDetailsPageEditable} />
                <Route path={`${path}/orders/:orderID`} component={OrderDetailsPage} />

                <AdminPagesWithMenuBar>
                    <Switch>
                        <Route path={`${path}/inquiries`} component={InquiriesPage} />
                        <Route path={`${path}/offers`} component={OffersPage} />
                        <Route path={`${path}/orders`} component={OrderPage} />
                        <Route exact path={`${path}/companies`} component={CompanyPage} />
                        <Route exact path={`${path}/companies/:companyId`} component={CompanyDetailsPage} />
                        <Route exact path={`${path}/employees`} component={EmployeePage} />
                    </Switch>
                </AdminPagesWithMenuBar>
            </Switch>
        </Box>
    );
};
export const AdminPages: React.FC = () => {
    return (
        <AdminStateProvider>
            <Box width={'100%'}>
                <AuthenticatedTemplate>
                    <AdminRoutes />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <SignInPage />
                </UnauthenticatedTemplate>
            </Box>
        </AdminStateProvider>
    );
};
