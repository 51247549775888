import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {formatUrl, SERVICES_ENTRY_PAGE_URL_FORMAT} from '../../cms/helpers';
import {deconstructHTML} from '../../graphql/helpers';
import {CmsService} from '../../graphql/types';
import {ButtonPrimaryMedium} from '../Buttons';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';
import {List, ListItem} from './partials/List';

type Props = {
    id: number;
    heading: string;
    text: string;
    button: string;
    services: {
        id: number;
        listHeading: string;
        services: CmsService[];
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Services: React.FC<Props> = (props) => {
    const theme = useTheme();
    const button = deconstructHTML(props.button);

    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 5',
                            },
                        }}
                    >
                        <Typography variant='sectionHeading'>{props.heading}</Typography>
                        {props.text !== null && (
                            <Box
                                sx={{
                                    mt: 3,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 5,
                                    },
                                }}
                            >
                                <Typography variant='sectionIntro' dangerouslySetInnerHTML={{__html: props.text}} />
                            </Box>
                        )}
                    </Box>
                </CssGrid>

                <CssGrid>
                    {props.services.map((list, index: number) => {
                        const isPrimaryList = index === 0;
                        return (
                            <Box
                                sx={{
                                    mt: 5,
                                    display: isPrimaryList ? 'block' : 'none',
                                    gridColumn: '2 / span 6',
                                    gridRow: '1',
                                    [theme.breakpoints.up('sm')]: {
                                        display: 'block',
                                        gridColumn: isPrimaryList ? '2 / span 3' : '5 / span 3',
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        mt: 5,
                                        gridColumn: isPrimaryList ? '3 / span 2' : '6 / span 2',
                                    },
                                }}
                                key={list.id}
                            >
                                <List heading={list.listHeading}>
                                    {list.services.map((service: CmsService) => {
                                        return (
                                            <ListItem
                                                key={service.id}
                                                url={formatUrl(SERVICES_ENTRY_PAGE_URL_FORMAT, service.slug ?? '')}
                                                text={service.title ?? ''}
                                            />
                                        );
                                    })}
                                </List>
                            </Box>
                        );
                    })}
                </CssGrid>

                <CssGrid>
                    <Box
                        sx={{
                            mt: 8,
                            gridColumn: '2 / span 6',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 2',
                                mt: 10,
                            },
                        }}
                    >
                        {button !== null && <ButtonPrimaryMedium>{button.text}</ButtonPrimaryMedium>}
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
