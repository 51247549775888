import {useMsal} from '@azure/msal-react';
import {loginRequest} from 'config/b2cConfig';
import {useEffect, VFC} from 'react';
import {LoaderCenterBlur} from './Loader';

export const Login: VFC = () => {
    const {instance, inProgress} = useMsal();

    useEffect(() => {
        const redirectToLogin = () => {
            instance.loginRedirect(loginRequest);
        };
        inProgress === 'none' && redirectToLogin();
    }, [instance, inProgress]);

    return (
        <>
            <LoaderCenterBlur open={true} />
        </>
    );
};
