import {Typography} from '@mui/material';
import {OfferDecisionStatus, OffersDto} from 'lib/dtos/Offer';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {OfferList} from 'lib/types/Admin';
import {useEffect, useState, VFC} from 'react';
import {useSearch} from '../../../../lib/Search';
import {ServiceDictionary} from '../../../../lib/types/Inquiry';
import {SearchField} from '../../../SearchField';
import {getServiceText} from '../../serviceText';
import {getAddress} from '../getAddress';
import {OfferDataGrid} from './OfferDataGrid';

export const OfferTable: VFC<{
    onLoadingChange?: (isLoading: boolean) => void;
    title: string;
    services: ServiceDictionary | undefined;
    requestParams?: {employeeId?: number; status?: OfferDecisionStatus};
}> = ({requestParams, title, services}) => {
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const size = rowsPerPage * 2;
    const [offers, setOffers] = useState<OfferList[]>([]);
    const [page, setPage] = useState(1);
    const params = {status: requestParams?.status, employeeId: requestParams?.employeeId, size, page};
    const [{data, loading}] = useM2Axios<OffersDto>({url: 'api/offers', params});

    const {search, setSearchData} = useSearch<OfferList>();
    const [filteredOffers, setFilteredOffers] = useState<OfferList[]>([]);
    useEffect(() => {
        if (offers) {
            setSearchData(offers);
            setFilteredOffers(offers);
        }
    }, [offers, setSearchData]);
    useEffect(() => {
        if (data) {
            const newlyLoadedOffers = data.data.map(
                (offer): OfferList => ({
                    ...offer,
                    status: offer.offerDecisionStatus,
                    projectId: offer.project.projectId,
                    dateCreated: offer.createdAt as unknown as Date,
                    title: `${services && getServiceText(offer.project.services, services)}, ${getAddress(
                        offer.project,
                    )}`,
                    projectLeader: offer.project.projectLeader,
                }),
            );

            const newIds = newlyLoadedOffers.map((v) => v.offerId);

            setOffers((prev) =>
                [...prev.filter((old) => !newIds.includes(old.offerId)), ...newlyLoadedOffers].sort(
                    (a, b) => a.offerId - b.offerId,
                ),
            );
        }
    }, [data, services]);

    return (
        <>
            <Typography variant={'h5'} mt={7} mb={3}>
                {title}
            </Typography>
            <SearchField handleChange={(e) => setFilteredOffers(search(e.target.value))} />
            <OfferDataGrid
                data={filteredOffers}
                pagination={data?.pagination}
                rowsPerPage={rowsPerPage}
                onPageSizeChange={setRowsPerPage}
                onPageChange={(page) => setPage(page + 1)}
                loading={loading}
                minHeight={52 * Math.min(rowsPerPage, data ? data.data.length : 0) + 56 + 64}
            />
        </>
    );
};
