import {Box, Container, Typography} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {InvoiceDto, ProjectInvoiceDto} from '../../lib/dtos/Invoice';
import {ProjectDto, ProjectFilesDto} from '../../lib/dtos/Project';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {M2Response} from '../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {AppContext} from '../../store/appContext';
import {getNorwegianDateString} from '../../toolbox';
import {ProjectPageMenu} from '../AppLayout';
import {CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';
import {Documents} from './Documents';
import {GeneralProjectInfo} from './GeneralProjectInfo';
import {Invoices} from './Invoices';
import {ProjectPhotoSlider} from './ProjectPhotoSlider';
import {getServiceTasksText, getServiceText} from './serviceText';

export const EndedProjectPage: React.FC = () => {
    const {bgColor, state} = useContext(AppContext);
    const params = useParams<{projectID: string}>();
    const projectID = parseInt(params.projectID);

    const [{data: project}, getProject] = useM2Axios<M2Response<ProjectDto>>('', {
        manual: true,
    });
    const [{data: projectFiles, loading: loadingFiles}, getProjectFiles] = useM2Axios<M2Response<ProjectFilesDto>>('', {
        manual: true,
    });
    const [{data: productPhotos, loading: loadingPhotos}, getProjectPhotos] = useM2Axios<{data: FileDto[]}>('', {
        manual: true,
    });
    React.useEffect(() => {
        getProject({url: ApiEndpoints.project.byId(projectID)});
        getProjectFiles({url: `${ApiEndpoints.project.byId(projectID)}/files`});
        getProjectPhotos({url: `${ApiEndpoints.project.byId(projectID)}/images/product`});
    }, [projectID]);

    const [{data: projectInvoices, loading}, getInvoices] = useM2Axios<{data: ProjectInvoiceDto[]}>('', {manual: true});
    useEffect(() => {
        if (state.user) {
            getInvoices({url: `${ApiEndpoints.customer.byId(state.user.id)}/invoices`});
        }
    }, [getInvoices, state.user]);

    const getInvoicesForProject = (): InvoiceDto[] => {
        if (projectInvoices && project) {
            const invoicesForProject = projectInvoices.data.filter(
                (pInvoice) => pInvoice.project.projectId === project.data.projectId,
            );
            return invoicesForProject.reduce((acc: InvoiceDto[], curr) => [...acc, ...curr.invoices], []);
        }
        return [];
    };

    return (
        <Box sx={{overflow: 'hidden'}}>
            <Container sx={{backgroundColor: bgColor, pb: 7}}>
                <CssGrid>
                    <ProjectPageMenu />
                    {project && (
                        <Content
                            project={project.data}
                            projectFiles={projectFiles?.data}
                            loadingFiles={loadingFiles}
                            productPhotos={productPhotos?.data || []}
                            invoices={getInvoicesForProject()}
                            services={state.allServices}
                        />
                    )}
                    <LoaderCenterBlur open={!project} />
                </CssGrid>
            </Container>
        </Box>
    );
};

type ContentProps = {
    project: ProjectDto;
    loadingFiles: boolean;
    projectFiles?: ProjectFilesDto;
    productPhotos: FileDto[];
    invoices: InvoiceDto[];
    services: ServiceDictionary | undefined;
};
const Content: React.FC<ContentProps> = ({project, loadingFiles, projectFiles, services, invoices, productPhotos}) => {
    const {getLangString} = useLanguage();
    const {street, zip, location, projectLeader, toDate} = project;
    const address = `${street}, ${zip} ${location}`;
    const contact = projectLeader ? `${projectLeader.firstName} ${projectLeader.lastName}` : '';
    const endDate = toDate ? getNorwegianDateString(toDate) : '';

    return (
        <>
            <GeneralProjectInfo
                overlineTitle={getLangString('PROJECT_STATE_ENDED')}
                titleL1={services ? getServiceText(project.services, services) : ''}
                titleL2={address}
                details={{
                    project: address,
                    contact,
                    refNumber: '#' + project.projectId,
                    endDate,
                }}
                services={services ? getServiceTasksText(project.services, services) : ''}
            />
            {productPhotos.length > 0 && (
                <ProjectPhotoSlider photos={productPhotos} headerText={getLangString('PHOTOS_ENDED_PROJECT')} />
            )}
            <Box
                sx={{
                    gridColumn: {xs: 'span 8', md: '3 / span 5'},
                }}
            >
                <Box mb={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('CONTRACTS')}
                    </Typography>
                    <Documents files={projectFiles?.contracts || []} loadingFiles={loadingFiles} />
                </Box>
                <Box my={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('INVOICES')}
                    </Typography>
                    <Invoices invoices={invoices} />
                </Box>
                <Box my={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('DOCUMENTATION')}
                    </Typography>
                    <Documents files={projectFiles?.documentation || []} loadingFiles={loadingFiles} />
                </Box>
            </Box>
        </>
    );
};
