import {useContext, VFC} from 'react';
import {useLanguage} from '../../../../language/LanguageProvider';
import {OrderStatus} from '../../../../lib/dtos/Order';
import {AdminAppContext} from '../../../../store/adminAppContext';
import {AdminPageLayout} from '../AdminPageLayout';
import {OrderTable} from './OrderTable';

export const OrderPage: VFC = () => {
    const {state} = useContext(AdminAppContext);
    const {getLangString} = useLanguage();

    return (
        <AdminPageLayout>
            <OrderTable
                title={getLangString('NEW_ORDERS')}
                requestParams={{status: OrderStatus.New}}
                services={state.availableServices}
            />
            {state.user && (
                <OrderTable
                    title={getLangString('MY_ORDERS')}
                    requestParams={{employeeId: state.user.employeeId}}
                    services={state.availableServices}
                />
            )}
            <OrderTable title={getLangString('ALL_ORDERS')} services={state.availableServices} />
        </AdminPageLayout>
    );
};
