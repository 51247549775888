export const CONTENT_PAGE_URL_FORMAT = '/:slug';
export const SERVICES_INDEX_PAGE_URL = '/tjenester';
export const SERVICES_ENTRY_PAGE_URL_FORMAT = '/tjenester/:slug';
export const ARTICLES_INDEX_PAGE_URL = '/artikler';
export const ARTICLES_ENTRY_PAGE_URL_FORMAT = '/artikler/:slug';
export const PEOPLE_INDEX_PAGE_URL = '/menneskene';
export const PEOPLE_ENTRY_PAGE_URL_FORMAT = '/menneskene/:slug';

export const formatUrl = (format: string, slug?: string): string => {
    if (!slug) {
        return format;
    }
    return format.replace(':slug', slug);
};
