import {Accordion, AccordionDetails, AccordionSummary, Theme, useTheme} from '@mui/material';
import {SxProps} from '@mui/system';
import React from 'react';
import {MinusIcon} from './icons/MinusIcon';
import {PlusIcon} from './icons/Plus';

type Props = {
    id: string;
    ariaControls: string;
    headerElements: React.ReactNode;
    detailsElements: React.ReactNode;
    headerElementsStyleOverrides?: SxProps<Theme>;
    expanded?: boolean;
    defaultExpanded?: boolean;
    onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
    sxProps?: SxProps<Theme>;
};

export const CustomAccordion: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = React.useState<boolean | undefined>(props.expanded ?? false);
    const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
        setIsExpanded(expanded);
        if (props.onChange !== undefined) {
            props.onChange(event, expanded);
        }
    };
    return (
        <Accordion
            disableGutters={true}
            expanded={props.expanded}
            defaultExpanded={props.defaultExpanded}
            elevation={0}
            square={true}
            onChange={handleChange}
            sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderTop: 0,
                '&::before': {
                    background: 'none',
                },
                ...props.sxProps,
            }}
        >
            <AccordionSummary
                id={props.id}
                expandIcon={isExpanded ? <MinusIcon /> : <PlusIcon />}
                aria-controls={props.ariaControls}
                sx={{
                    py: 3,
                    ...props.headerElementsStyleOverrides,
                }}
            >
                {props.headerElements}
            </AccordionSummary>
            <AccordionDetails sx={{paddingLeft: 0, paddingRight: 0}}>{props.detailsElements}</AccordionDetails>
        </Accordion>
    );
};
