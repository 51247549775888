import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    heading: string;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Heading: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <ContentBlock quickLink={props.quickLink} size='sm'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 5',
                            },
                        }}
                    >
                        <Typography variant='sectionHeading' component='div'>
                            {props.heading}
                        </Typography>
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
