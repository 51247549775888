import {Box, Button, DialogContent, DialogTitle, Grid, MenuItem, Select, Typography} from '@mui/material';
import {CustomDialog} from 'components/CustomDialog';
import {LoaderCenterBlur} from 'components/Loader';
import {useLanguage} from 'language/LanguageProvider';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {CreateUserDto, EmployeeDto, UserRole} from '../../../../lib/dtos/User';
import {useM2Axios} from '../../../../lib/hooks/useM2Axios';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {
    validateAlphaNumericSpaceSpecial,
    validateIsEmail,
    validateIsRequired,
    validateNoSpecialChars,
    validatePhoneNumberWithLandCode,
} from '../../../../lib/validationMessages';
import {AreYouSureDialog} from '../../../AreYouSureDialog';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../../../Buttons';
import {CustomInputField} from '../../../InputField';
import {userRoleText} from './userRoleText';

type EmployeeCreated = {
    employeeId?: number;
};
export type EmployeeDialogForm = {
    isNew: boolean;
    data?: EmployeeDto;
};
type EmployeeDialogProps = {
    open: boolean;
    initialValues: EmployeeDialogForm;
    onClose: () => void;
    onSubmit: (reload: boolean) => void;
    onSaved: (createdId: number) => void;
};

type EmployeeFormFields = {
    [key: string]: unknown;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    role: UserRole;
    employeeId?: number;
};

export const EmployeeDialog: React.FC<EmployeeDialogProps> = (props) => {
    const {getLangString} = useLanguage();
    const {handleSubmit, trigger, reset, control} = useForm<EmployeeFormFields>();
    const [didSave, setDidSave] = useState<{error: string; success: boolean} | undefined>();
    const [shouldFinishProject, setShouldFinishProject] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };
    const onCancel = () => {
        setDidSave(undefined);
        handleClose();
    };
    useEffect(() => {
        const {data} = props.initialValues;
        const init: EmployeeFormFields = {
            firstName: data?.firstName || '',
            lastName: data?.lastName || '',
            phoneNumber: data?.phoneNumber || '',
            email: data?.email || '',
            role: data?.role || UserRole.Management,
            employeeId: data?.employeeId,
        };

        reset(init);
    }, [props.initialValues, reset]);

    const [{loading: isSendingEmployee}, sendEmployee] = useM2Axios<EmployeeCreated>('', {
        manual: true,
    });

    const [{loading: isDeletingEmployee}, deleteEmployee] = useM2Axios<any>('', {
        manual: true,
    });

    const doHandleSubmit = async (form: EmployeeFormFields, isNew: boolean) => {
        setDidSave(undefined);
        try {
            if (isNew) {
                const newEmployee: CreateUserDto = {
                    ...form,
                    role: form.role as unknown as UserRole,
                };

                const response = await sendEmployee({
                    url: ApiEndpoints.employee.base(),
                    method: 'POST',
                    data: newEmployee,
                });
                if (response.data.employeeId) {
                    props.onSaved(response.data.employeeId);
                } else {
                    onCancel();
                }
            } else {
                const employee: CreateUserDto = {
                    ...form,
                    role: form.role as unknown as UserRole,
                };
                if (!form.employeeId) {
                    throw new Error('Missing employee id');
                }
                await sendEmployee({
                    url: ApiEndpoints.employee.byId(form.employeeId),
                    method: 'PUT',
                    data: employee,
                });
            }
            props.onSubmit(true);
            setDidSave({error: '', success: true});
        } catch (error) {
            setDidSave({error: 'Klarte ikke å lagre ansatt', success: false});
        }
    };

    const [deleteEmployeeId, setDeleteEmployeeId] = useState<number | undefined>(undefined);
    const [promptForConfirmation, setPromptForConfirmation] = useState<boolean>(false);

    const doDelete = async (id: number | undefined) => {
        setDidSave(undefined);
        setPromptForConfirmation(false);
        setDeleteEmployeeId(undefined);
        if (!id) return;
        try {
            await deleteEmployee({
                url: ApiEndpoints.employee.byId(id),
                method: 'DELETE',
            });
            props.onSubmit(true);
            props.onClose();
        } catch (error) {
            setDidSave({error: 'Klarte ikke å slette ansatt', success: false});
        }
    };

    return (
        <>
            <CustomDialog open={props.open} onClose={handleClose}>
                <DialogTitle>
                    {props.initialValues.isNew
                        ? getLangString('ADD_EMPLOYEE')
                        : `${props.initialValues.data?.firstName} ${props.initialValues.data?.lastName}`}
                </DialogTitle>
                <DialogContent>
                    <form
                        onSubmit={handleSubmit((form) => {
                            doHandleSubmit(form, props.initialValues?.isNew || false);
                        })}
                        key={props.initialValues ? Math.random() : ''}
                    >
                        <Typography variant={'subtitle1'}>{getLangString('EMPLOYEE_INFO')}</Typography>
                        <Grid container columnSpacing={0} marginBottom={3}>
                            <Controller
                                name='employeeId'
                                control={control}
                                rules={{required: false}}
                                defaultValue={props.initialValues.data?.employeeId}
                                render={({field}) => <input type='hidden' value={field.value} />}
                            />

                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name='firstName'
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateNoSpecialChars(),
                                    }}
                                    control={control}
                                    defaultValue={props.initialValues.data?.firstName}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('FORNAME')}
                                            name={field.name}
                                            error={!!fieldState.error}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name='lastName'
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateNoSpecialChars(),
                                    }}
                                    control={control}
                                    defaultValue={props.initialValues.data?.lastName}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('SURNAME')}
                                            name={field.name}
                                            error={!!fieldState.error}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name='phoneNumber'
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validatePhoneNumberWithLandCode(),
                                    }}
                                    control={control}
                                    defaultValue={props.initialValues.data?.phoneNumber}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('PHONE')}
                                            name={field.name}
                                            error={!!fieldState.error}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name='email'
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateIsEmail(),
                                    }}
                                    control={control}
                                    defaultValue={props.initialValues.data?.email}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('EMAIL')}
                                            name={field.name}
                                            error={!!fieldState.error}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Box mb={3}>
                            <Controller
                                name='role'
                                rules={{
                                    required: validateIsRequired(),
                                    pattern: validateAlphaNumericSpaceSpecial(),
                                }}
                                control={control}
                                defaultValue={props.initialValues.data?.role}
                                render={({field, fieldState}) => (
                                    <Select
                                        name={field.name}
                                        displayEmpty={true}
                                        value={field.value}
                                        renderValue={(value: UserRole) => {
                                            if (!value) {
                                                return <Typography color={'text.secondary'}>Velg rolle</Typography>;
                                            } else {
                                                return <Typography>{userRoleText[value]}</Typography>;
                                            }
                                        }}
                                        onChange={field.onChange}
                                        error={!!fieldState.error?.message}
                                        onBlur={() => trigger('role')}
                                        sx={{width: {xs: '100%', sm: '50%'}, '& .MuiSelect-select': {p: 3, pr: 4}}}
                                    >
                                        <MenuItem value={UserRole.Management}>Ansatt</MenuItem>
                                        <MenuItem value={UserRole.Administrator}>Administrator</MenuItem>
                                    </Select>
                                )}
                            />
                        </Box>
                        {didSave?.error && (
                            <Box mb={2}>
                                <Typography color='red' variant={'caption'}>
                                    {didSave.error}
                                </Typography>
                            </Box>
                        )}
                        {didSave?.success && (
                            <Box mb={2}>
                                <Typography color='green' variant={'body1'}>
                                    Ansatt lagret
                                </Typography>
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: {xs: 'column', sm: 'row'},
                                mt: 5,
                            }}
                        >
                            {props.initialValues.data?.employeeId !== undefined ? (
                                <Button
                                    sx={{minWidth: 108, mb: 1, height: 44}}
                                    variant='outlined'
                                    color='error'
                                    size={'medium'}
                                    onClick={() => {
                                        setPromptForConfirmation(true);
                                        setDeleteEmployeeId(props.initialValues.data?.employeeId);
                                    }}
                                >
                                    {getLangString('DELETE')}
                                </Button>
                            ) : (
                                <Box />
                            )}
                            <Box sx={{alignSelf: 'flex-end', mt: {xs: 3, sm: 0}}}>
                                <ButtonPrimaryMedium
                                    type='submit'
                                    sx={{minWidth: 108, mr: 1, mb: 1}}
                                    disabled={isSendingEmployee}
                                >
                                    {props.initialValues.isNew ? getLangString('ADD') : getLangString('SAVE')}
                                </ButtonPrimaryMedium>
                                <ButtonSecondaryMedium onClick={onCancel} sx={{minWidth: 108, mr: 1, mb: 1}}>
                                    {props.initialValues.isNew ? getLangString('CANCEL') : getLangString('CLOSE')}
                                </ButtonSecondaryMedium>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </CustomDialog>
            <AreYouSureDialog
                title='Vil du slette ansatt?'
                onConfirm={() => doDelete(deleteEmployeeId)}
                onCancel={() => {
                    setPromptForConfirmation(false);
                    setDeleteEmployeeId(undefined);
                }}
                open={promptForConfirmation}
            >
                {'Denne handlingen kan ikke reverseres.'}
            </AreYouSureDialog>
            <LoaderCenterBlur open={isDeletingEmployee} text={`Sletter ${props.initialValues.data?.firstName}`} />
        </>
    );
};
