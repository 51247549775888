import React from 'react';
import {Container} from '../Layout';
import {ContentBlock} from './PageContent';
import {ColumnGrid} from './partials/ColumnGrid';

type Props = {
    id: number;
    columns: {
        text: string;
        icon: {
            url: string;
        };
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Columns: React.FC<Props> = (props) => {
    return (
        <ContentBlock quickLink={props.quickLink} size='md'>
            <Container>
                <ColumnGrid columns={props.columns} />
            </Container>
        </ContentBlock>
    );
};
