import {Box, Container, Typography, useTheme} from '@mui/material';
import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {Service} from '../../lib/types/Inquiry';
import {saveSelectedServiceType} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {ButtonPrimaryLarge} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {ButtonLayout, SelectServiceRadioOptions, Specification} from './InquiryDetailComponents';
import {getInquirySelectServiceTaskPath, getInquirySummaryPath, getInquiryTimeSelectionPath} from './InquiryPageRoutes';

export const InquirySelectServicePage: React.FC = () => {
    const theme = useTheme();
    const {getLangString} = useLanguage();
    const history = useHistory();
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const initialServiceId = query.get('sid');
    const {state, dispatch} = useContext(AppContext);
    const getInitialService = (id: string | null) => {
        if (!id) {
            return null;
        }
        let service = null;
        if (state.inquiry.availableServices) {
            Object.keys(state.inquiry.availableServices).forEach((key) => {
                const parent = state.inquiry.availableServices ? state.inquiry.availableServices[key] : undefined;
                if (parent && parent.children) {
                    Object.keys(parent.children).forEach((key) => {
                        const currentService = parent.children ? parent.children[key] : undefined;
                        if (currentService && currentService.id == parseInt(id)) {
                            service = currentService;
                        }
                    });
                }
            });
        }

        return service;
    };

    const [selectedService, setSelectedService] = React.useState<Service | null>(state.inquiry.selectedService || null);

    const initialService = getInitialService(initialServiceId);
    if (initialService && !selectedService) {
        setSelectedService(initialService);
    }

    const handleChange = (service: Service | null) => {
        setSelectedService(service);
    };

    const handleNextClick = () => {
        if (selectedService) {
            dispatch(saveSelectedServiceType(selectedService));

            if (selectedService.children && Object.keys(selectedService.children).length > 0) {
                history.push(getInquirySelectServiceTaskPath(selectedService.id));
            } else {
                state.inquiry.isEditing
                    ? history.push(getInquirySummaryPath())
                    : history.push(getInquiryTimeSelectionPath());
            }
        }
    };

    const hasChildren = (): boolean | undefined => {
        if (selectedService && selectedService.children) {
            return Object.keys(selectedService.children).length > 0;
        }
        return false;
    };

    return (
        <Container sx={{py: 7}}>
            <CssGrid>
                <Box
                    sx={{
                        display: 'none',
                        [theme.breakpoints.up('sm')]: {
                            gridColumn: '1 / span 8',
                            columnGap: 2.5,
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            mb: 4,
                        },
                        [theme.breakpoints.up('md')]: {
                            gridColumn: '2 / span 6',
                            columnGap: 3,
                        },
                    }}
                >
                    {state.inquiry.availableServices &&
                        Object.keys(state.inquiry.availableServices).map((key) => {
                            const service = state.inquiry.availableServices
                                ? state.inquiry.availableServices[key]
                                : null;
                            if (!service) {
                                return <Box />;
                            }
                            return (
                                <Box key={service.id} sx={{}}>
                                    <Typography variant={'h6'}>{service.title}</Typography>
                                    {service.description && (
                                        <Typography
                                            variant={'body1'}
                                            sx={{
                                                mt: 2,
                                                '& a': {
                                                    color: 'text.primary',
                                                },
                                                '& > :first-child': {
                                                    mt: 0,
                                                },
                                                '& > :last-child': {
                                                    mb: 0,
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: service.description,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })}
                </Box>
            </CssGrid>
            <CssGrid>
                <DefaultGridPageLayout>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            columnGap: 1.5,
                            rowGap: 5,
                            [theme.breakpoints.up('sm')]: {
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                columnGap: 2.5,
                            },
                            [theme.breakpoints.up('md')]: {
                                columnGap: 3,
                            },
                        }}
                    >
                        {state.inquiry.availableServices &&
                            Object.keys(state.inquiry.availableServices).map((key) => {
                                const service = state.inquiry.availableServices
                                    ? state.inquiry.availableServices[key]
                                    : null;
                                if (!service) {
                                    return <Box />;
                                }
                                return (
                                    <Box key={service.id} sx={{gridColumn: 'span 1'}}>
                                        <Box
                                            sx={{
                                                [theme.breakpoints.up('sm')]: {
                                                    display: 'none',
                                                },
                                            }}
                                        >
                                            <Typography variant={'subtitle1'}>{service.title}</Typography>
                                            {service.description && (
                                                <Typography
                                                    variant={'body1'}
                                                    sx={{
                                                        mt: 2,
                                                        '& a': {
                                                            color: 'text.primary',
                                                        },
                                                        '& > :first-child': {
                                                            mt: 0,
                                                        },
                                                        '& > :last-child': {
                                                            mb: 0,
                                                        },
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: service.description,
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <SelectServiceRadioOptions
                                            handleChange={handleChange}
                                            availableOptions={service.children}
                                            selectedOption={selectedService}
                                            columns={1}
                                        />
                                    </Box>
                                );
                            })}
                    </Box>
                    <Box marginTop={7}>{selectedService && hasChildren() === false ? <Specification /> : null}</Box>
                    <ButtonLayout>
                        <ButtonPrimaryLarge onClick={handleNextClick} disabled={!selectedService}>
                            {getLangString('NEXT')}
                        </ButtonPrimaryLarge>
                    </ButtonLayout>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
