import {ApolloClient, gql, InMemoryCache} from '@apollo/client';

export const apolloClient = new ApolloClient({
    uri: 'https://m2-cms.forge-dev02.racerdev.no/api',
    cache: new InMemoryCache(),
});

const PAGE_CONTENT_FIELDS = (entryType: string) => gql`
    fragment PageContentFields on ${entryType} {
        pageContent {
            ... on pageContent_bodyText_BlockType {
                id
                text
                fontSize
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_heading_BlockType {
                id
                heading
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_imageSlider_BlockType {
                id
                heading
                images {
                    id
                    url(width: 465, height: 621)
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_image_BlockType {
                id
                image {
                    id
                    url
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_articles_BlockType {
                id
                heading
                articles {
                    id
                    title
                    slug
                    ... on articles_default_Entry {
                        mainImage {
                            id
                            url(width: 465, height: 619)
                        }
                        relatedServices(level: 2) {
                            id
                            title
                            slug
                        }
                    }
                }
                narrowViewportArticleLimit
                readMoreLink
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_featuredArticle_BlockType {
                id
                article {
                    id
                    title
                    slug
                    ... on articles_default_Entry {
                        mainImage {
                            id
                            url(width: 465, height: 619)
                        }
                        relatedServices(level: 2) {
                            id
                            title
                            slug
                        }
                    }
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_people_BlockType {
                id
                heading
                people {
                    id
                    title
                    slug
                    ... on people_default_Entry {
                        jobTitle
                        phoneNumber
                        emailAddress
                        profileImage {
                            id
                            url(width: 302, height: 402)
                        }
                    }
                }
                readMoreLink
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_columns_BlockType {
                id
                columns {
                    ... on columns_BlockType {
                        id
                        icon {
                            id
                            url
                        }
                        text
                    }
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_columnBanner_BlockType {
                id
                heading
                text
                button
                columns {
                    ... on columns_BlockType {
                        id
                        icon {
                            id
                            url
                        }
                        text
                        button
                    }
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_questions_BlockType {
                id
                heading
                text
                questions {
                    id
                    title
                    ... on questions_default_Entry {
                        answer
                    }
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_partners_BlockType {
                id
                heading
                text
                listHeading
                partners {
                    id
                    title
                    ... on partners_default_Entry {
                        linkTo
                        logo {
                            id
                            url
                        }
                    }
                }
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_banner_BlockType {
                id
                backgroundImage {
                    id
                    url
                }
                heading
                overline
                primaryButton
                secondaryButton
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_services_BlockType {
                id
                heading
                text
                services {
                    ... on services_BlockType {
                        id
                        listHeading
                        services(level: 2) {
                            id
                            title
                            slug
                        }
                    }
                }
                button
                quickLink {
                    elementId
                    linkText
                }
            }
            ... on pageContent_contactForm_BlockType {
                id
                text
                confirmationText
                buttonText
                quickLink {
                    elementId
                    linkText
                }
            }
        }
    }
`;

export const GET_NAVIGATION = gql`
    query GetNavigation {
        globalSet(handle: "header") {
            ... on header_GlobalSet {
                id
                navigationLinks {
                    ... on navigationLinks_navigationLink_BlockType {
                        id
                        linkTo
                    }
                }
                headerLinks {
                    ... on headerLinks_headerLink_BlockType {
                        id
                        linkTo
                    }
                }
            }
        }
    }
`;

export const GET_FOOTER = gql`
    query GetFooter {
        globalSet(handle: "footer") {
            ... on footer_GlobalSet {
                id
                address
                emailAddress
                phoneNumber
                navigationLinks {
                    ... on navigationLinks_navigationLink_BlockType {
                        id
                        linkTo
                    }
                }
                socialLinks {
                    ... on socialLinks_socialLink_BlockType {
                        id
                        linkTo
                    }
                }
            }
        }
    }
`;

export const GET_HOMEPAGE = gql`
    ${PAGE_CONTENT_FIELDS('homepage_homepage_Entry')}
    query GetHomepage {
        entry(section: "homepage") {
            ... on homepage_homepage_Entry {
                id
                hero {
                    ... on hero_hero_BlockType {
                        id
                        backgroundImage {
                            id
                            url
                        }
                        heading
                    }
                }
                featuredServices {
                    ... on featuredServices_services_BlockType {
                        id
                        service(level: 1) {
                            id
                            title
                            slug
                            ... on services_serviceGroup_Entry {
                                introText
                                bodyText
                            }
                            children {
                                id
                                title
                                slug
                            }
                        }
                        primaryButton
                        secondaryButton
                    }
                }
                introText
                ...PageContentFields
            }
        }
    }
`;

export const GET_CONTENT_PAGE = (slug: string) => gql`
    ${PAGE_CONTENT_FIELDS('pages_default_Entry')}
    query GetContentPage {
        entry(section: "pages", slug: "${slug}") {
            ... on pages_default_Entry {
                id
                title
                slug
                introText
                ...PageContentFields
            }
        }
    }
`;

export const GET_ARTICLE_ENTRY_PAGE = (slug: string) => gql`
    ${PAGE_CONTENT_FIELDS('articles_default_Entry')}
    query GetArticleEntryPage {
        entry(section: "articles", slug: "${slug}") {
            ... on articles_default_Entry {
                id
                title
                slug
                introText
                mainImage {
                  id
                  url(width: 1280, height: 720)
                }
                articleCategories {
                    id
                    slug
                    title
                }
                relatedServices(level: 2) {
                    id
                    slug
                    title
                }
                relatedArticles {
                    id
                    title
                    slug
                    ... on articles_default_Entry {
                      mainImage {
                        id
                        url(width: 465, height: 619)
                      }
                        relatedServices(level: 2) {
                            id
                            slug
                            title
                        }
                    }
                }
                ...PageContentFields
            }
        }
    }
`;

export const GET_ARTICLES_INDEX_PAGE = (serviceId: string | null = null) => gql`
    ${PAGE_CONTENT_FIELDS('articlesIndex_articlesIndex_Entry')}
    query GetArticlesIndexPage {
        entry(section: "articlesIndex") {
            ... on articlesIndex_articlesIndex_Entry {
                id
                title
                slug
                introText
                ...PageContentFields
            }
        }
        categories: categories(group: "articleCategories") {
            id
            title
        }
        articles: entries(section: "articles" ${serviceId ? `, relatedServices: ${serviceId}` : ''}) {
            id
            title
            slug
            ... on articles_default_Entry {
                mainImage {
                    id
                    url(width: 465, height: 619)
                }
                relatedServices(level: 2) {
                    id
                    title
                    slug
                }
                articleCategories {
                    id
                    title
                    slug
                }
            }
        }
      ${
          serviceId
              ? `
        service: entry(id: ${serviceId}) {
          id
          title
        }
      `
              : ''
      }
    }
`;

export const GET_SERVICES_INDEX_PAGE = gql`
    query GetServicesIndexPage {
        entry: entry(section: "servicesIndex") {
            id
            title
            ... on servicesIndex_servicesIndex_Entry {
                introText
                button
            }
        }
        services: entries(section: "services", level: 1) {
            id
            title
            slug
            ... on services_serviceGroup_Entry {
                introText
                bodyText
            }
            children {
                id
                title
                slug
                ... on services_service_Entry {
                    introText
                    mainImage {
                        id
                        url(width: 628, height: 352)
                    }
                }
            }
        }
    }
`;

export const GET_SERVICE_ENTRY_PAGE = (slug: string) => gql`
    ${PAGE_CONTENT_FIELDS('services_service_Entry')}
    query GetServiceEntryPage {
        entry(section: "services", slug: "${slug}") {
            id
            title
            slug
            typeHandle
            ... on services_service_Entry {
                introText
                mainImage {
                    id
                    url(width: 1280, height: 720)
                }
                relatedServices {
                    id
                    slug
                    title
                }
                relatedArticles {
                    id
                    title
                    slug
                    ... on articles_default_Entry {
                      mainImage {
                        id
                        url(width: 465, height: 619)
                      }
                        relatedServices {
                            id
                            slug
                            title
                        }
                    }
                }
                ...PageContentFields
            }
        }
    }
`;

export const GET_PEOPLE_INDEX_PAGE = gql`
    query GetPeopleIndexPage {
        entry(section: "peopleIndex") {
            ... on peopleIndex_peopleIndex_Entry {
                id
                title
                slug
                introText
            }
        }
        categories: categories(group: "peopleCategories") {
            id
            title
        }
        people: entries(section: "people", orderBy: "title ASC") {
            id
            title
            slug
            ... on people_default_Entry {
                profileImage {
                    id
                    url(width: 302, height: 402)
                }
                jobTitle
                emailAddress
                phoneNumber
                peopleCategory {
                    id
                    title
                    slug
                }
            }
        }
    }
`;

export const GET_PEOPLE_ENTRY_PAGE = (slug: string) => gql`
    query GetPeopleEntryPage {
        entry(section: "people", slug: "${slug}") {
            ... on people_default_Entry {
                id
                title
                slug
                jobTitle
                yearsOfExperience
                emailAddress
                phoneNumber
                qualifications
                profileImage {
                  id
                  url(width: 952, height: 536)
                }
                experience {
                   ... on experience_experience_BlockType {
                      id
                      endYear
                      startYear
                      text
                  }
                }
            }
        }
    }
`;

export type CMS_SEO = {
    seomatic: {
        metaTitleContainer: string;
        metaTagContainer: string;
        metaLinkContainer: string;
        metaScriptContainer: string;
        metaJsonLdContainer: string;
        metaSiteVarsContainer: string;
    };
};
export const GET_SEO_DATA = (uri: string) => gql`
    {
        seomatic(uri: "${uri}") {
          metaTitleContainer
          metaTagContainer
          metaLinkContainer
          metaScriptContainer
          metaJsonLdContainer
          metaSiteVarsContainer
        }
    }
`;

type EmptyStateContent = {
    id: string;
    primaryButton: string;
    secondaryButton: string;
    heading: string;
    overline: string;
    backgroundImage: {
        url: string;
        id: string;
    }[];
};
export type CMS_EmptyProjectsState = {
    globalSet: {
        globalProjectsEmptyState: EmptyStateContent[];
    };
};

export const GET_EMPTY_PROJECTS_STATE = gql`
    query GetEmptyProjectsState {
        globalSet(handle: "emptyStates") {
            ... on emptyStates_GlobalSet {
                globalProjectsEmptyState {
                    ... on globalProjectsEmptyState_emptyState_BlockType {
                        id
                        primaryButton
                        secondaryButton
                        heading
                        overline
                        backgroundImage {
                            url
                            id
                        }
                    }
                }
            }
        }
    }
`;
