import {Box, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';
import {List, ListItem} from './partials/List';

type QuickLink = {
    linkText: string;
    elementId: string;
};

type Props = {
    quickLinks: {
        links: QuickLink[];
    }[];
};
export const QuickLinks: React.FC<Props> = (props) => {
    const theme = useTheme();

    const quickLinks = props.quickLinks.filter((quickLink) => {
        return (
            quickLink &&
            quickLink.links &&
            quickLink.links[0] &&
            quickLink.links[0].elementId !== '' &&
            quickLink.links[0].linkText !== ''
        );
    });

    if (quickLinks.length == 0) {
        return <Box />;
    }

    return (
        <Box
            sx={{
                mt: 5,
                mb: 5,
                [theme.breakpoints.up('md')]: {
                    mt: 9,
                    mb: 9,
                },
            }}
        >
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: '2 / span 6',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 6',
                            },
                        }}
                    >
                        <List columns={{xs: 2}}>
                            {quickLinks.map((quickLink, index: number) => {
                                const link = quickLink.links[0];

                                return (
                                    <ListItem
                                        key={index}
                                        text={link.linkText}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const element = document.getElementById(link.elementId);
                                            if (element) {
                                                element.scrollIntoView({behavior: 'smooth'});
                                            }
                                        }}
                                    />
                                );
                            })}
                        </List>
                    </Box>
                </CssGrid>
            </Container>
        </Box>
    );
};
