import {Box, Container, Typography} from '@mui/material';
import {default as React, Dispatch, useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {InquiryDto} from '../../lib/dtos/Inquiry';
import {OfferDecisionDto, OfferDecisionStatus, PaymentInterval} from '../../lib/dtos/Offer';
import {ProjectFilesDto} from '../../lib/dtos/Project';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {M2Response} from '../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {OfferService} from '../../lib/services/OfferService';
import {OfferDetailsCustomer} from '../../lib/types/Admin';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {ActionType, loadOfferDetails} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {getNorwegianDateString} from '../../toolbox';
import {ProjectPageMenu} from '../AppLayout';
import {ButtonPrimaryLarge, ButtonSecondaryLarge} from '../Buttons';
import {DialogWithTextField} from '../DialogWithTextField';
import {DownloadPDF} from '../DownloadFile';
import {CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';
import {Documents} from './Documents';
import {GeneralProjectInfo} from './GeneralProjectInfo';
import {PriceSummaryOffer} from './PriceSummaryOffer';
import {ProjectOfferStatusBanner} from './ProjectOfferStatusBanner';
import {ProjectPhotoSlider} from './ProjectPhotoSlider';
import {getServiceTasksText, getServiceText} from './serviceText';

const fetchOfferDetails = async (dispatch: Dispatch<ActionType>, offerID: number) => {
    const service = new OfferService(M2HttpClient);
    try {
        const response = await service.getOfferById(offerID);
        dispatch(loadOfferDetails(response.data));
    } catch (e) {
        return;
    }
};
export const ProjectOfferPage: React.FC = () => {
    const {bgColor, dispatch, state} = useContext(AppContext);
    const params = useParams<{projectID: string; offerID: string}>();
    const offerID = parseInt(params.offerID);

    React.useEffect(() => {
        fetchOfferDetails(dispatch, offerID);
    }, []);
    if (state.offerDetails && state.offerDetails[offerID]) {
        return (
            <OfferContent
                offerDetails={state.offerDetails[offerID]}
                bgColor={bgColor}
                dispatch={dispatch}
                services={state.allServices}
            />
        );
    } else {
        return <LoaderCenterBlur open={true} />;
    }
};

type Props = {
    offerDetails: OfferDetailsCustomer;
    bgColor?: string;
    dispatch: Dispatch<ActionType>;
    services: ServiceDictionary | undefined;
};

type SendingState = 'IDLE' | 'SENDING' | 'SUCCESS' | 'ERROR';
const OfferContent: React.FC<Props> = ({offerDetails, bgColor, dispatch, services}) => {
    const {project} = offerDetails;
    const address = `${project.street}, ${project.zip} ${project.location}`;
    const contact = project.projectLeader ? `${project.projectLeader.firstName} ${project.projectLeader.lastName}` : '';
    const startDate = offerDetails.fromDate ? getNorwegianDateString(offerDetails.fromDate) : '';
    const [displayDenyOfferDialog, setDisplayDenyOfferDialog] = React.useState<boolean>(false);
    const [isSendingOffer, setSendingOffer] = useState<SendingState>('IDLE');
    const {getLangString} = useLanguage();

    const [{data: inquiryInfo, loading: loadingInquiry}] = useM2Axios<M2Response<InquiryDto>>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}/inquiries`},
        {
            manual: false,
        },
    );
    const [{data: inspectionPhotos, loading: loadingPhotos}] = useM2Axios<M2Response<FileDto[]>>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}/images/inspection`},
        {
            manual: false,
        },
    );
    const [{data: projectFiles, loading: loadingFiles}] = useM2Axios<M2Response<ProjectFilesDto>>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}/files`},
        {
            manual: false,
        },
    );
    const getProjectFiles = (): FileDto[] => {
        const contracts = projectFiles?.data.contracts || [];
        const documentation = projectFiles?.data.documentation || [];
        return [...contracts, ...documentation];
    };

    const getOfferFiles = (): FileDto[] => {
        return offerDetails.cordelCompanyOffers.reduce((acc: FileDto[], curr) => {
            return [...acc, ...curr.files];
        }, []);
    };

    const doSendOffer = async (payload: OfferDecisionDto) => {
        try {
            const service = new OfferService(M2HttpClient);
            setSendingOffer('SENDING');
            await service.replyOffer(offerDetails.offerId, payload);
            setDisplayDenyOfferDialog(false);
            await fetchOfferDetails(dispatch, offerDetails.offerId);
            setTimeout(() => {
                window.scrollTo({top: 0});
                setSendingOffer('SUCCESS');
            }, 1000);
        } catch (e) {
            setSendingOffer('ERROR');
        }
    };
    const requestRevisedOffer = async (reason: string) => {
        const data: OfferDecisionDto = {
            decision: OfferDecisionStatus.Rejected,
            decisionReason: reason,
            paymentInterval: PaymentInterval.Complete, //TODO: Decide what should be default
        };
        await doSendOffer(data);
        setDisplayDenyOfferDialog(false);
    };
    const approveOffer = async () => {
        const data: OfferDecisionDto = {
            decision: OfferDecisionStatus.Accepted,
            paymentInterval: PaymentInterval.Complete, //TODO: Decide what should be default
        };
        void doSendOffer(data);
    };

    const getStatusString = (): string => {
        if (offerDetails.offerDecisionStatus === OfferDecisionStatus.Accepted) {
            return '- Godtatt';
        }
        return '';
    };

    return (
        <Box sx={{overflow: 'hidden'}}>
            <Container sx={{backgroundColor: bgColor, pb: 7}}>
                <CssGrid>
                    <ProjectPageMenu />
                    {offerDetails.offerDecisionStatus === OfferDecisionStatus.Rejected && (
                        <Box
                            sx={{
                                gridColumn: {xs: 'span 8', md: '2 / span 6'},
                                mb: '30px',
                            }}
                        >
                            <ProjectOfferStatusBanner
                                offerDecisionStatus={offerDetails.offerDecisionStatus}
                                text={getLangString('OFFER_REJECTED_BANNER_TEXT')}
                            />
                        </Box>
                    )}
                    <GeneralProjectInfo
                        titleL1={services ? getServiceText(project.services, services) : ''}
                        titleL2={address}
                        overlineTitle={`${getLangString('PROJECT_STATE_OFFER')} ${getNorwegianDateString(
                            offerDetails.dateCreated,
                        )} ${getStatusString()}`}
                        details={{
                            project: address,
                            contact,
                            refNumber: '#' + project.projectId,
                            startDate,
                        }}
                        services={services ? getServiceTasksText(project.services, services) : ''}
                    />
                    <Box
                        sx={{
                            gridColumn: {xs: 'span 8', md: '3 / span 5'},
                        }}
                    >
                        <Box mb={10}>
                            <Typography variant={'h6'} mb={2}>
                                {getLangString('SUMMARY')}
                            </Typography>
                            <Typography variant={'subtitle1'}>
                                {getLangString('PROJECT_DESCRIPTION_CUSTOMER')}
                            </Typography>
                            {inquiryInfo?.data.description ? (
                                <Typography variant={'body1'}>{inquiryInfo?.data.description}</Typography>
                            ) : (
                                <Typography>Ingen beskrivelse gitt</Typography>
                            )}
                            <Typography variant={'subtitle1'} mt={6}>
                                {getLangString('OFFER_DESCRIPTION')}
                            </Typography>
                            <Typography variant={'body1'}>{offerDetails.description}</Typography>
                        </Box>
                    </Box>
                    {inspectionPhotos?.data.length ? (
                        <ProjectPhotoSlider
                            photos={inspectionPhotos.data}
                            headerText={getLangString('PHOTOS_INSPECTION')}
                        />
                    ) : undefined}
                    <Box
                        sx={{
                            gridColumn: {xs: 'span 8', md: '3 / span 5'},
                        }}
                    >
                        <Box>
                            <Typography variant={'h6'} mb={2}>
                                {getLangString('DOCUMENTS')}
                            </Typography>
                            <Documents files={getProjectFiles()} loadingFiles={loadingFiles} />
                        </Box>
                    </Box>
                </CssGrid>
            </Container>
            <Box sx={{backgroundColor: 'customBackground.darker', py: 10}}>
                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: {xs: 'span 8', md: '3 / span 5'},
                                mb: 10,
                            }}
                        >
                            {offerDetails.materialCost !== undefined && offerDetails.workhourCost !== undefined && (
                                <PriceSummaryOffer
                                    materialCost={offerDetails.materialCost}
                                    workhourCost={offerDetails.workhourCost}
                                    totalMva={offerDetails.totalMvaCost}
                                />
                            )}
                            <CssGrid numberOfColumns={5}>
                                <Box
                                    mt={2}
                                    sx={{
                                        gridColumn: {xs: '1 / 6', md: '1 / 3'},
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant={'subtitle1'}>{getLangString('DOWNLOAD_PDF')}</Typography>
                                    {getOfferFiles().map((file) => (
                                        <DownloadPDF
                                            key={file.id}
                                            file={file}
                                            sx={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                            }}
                                        />
                                    ))}
                                </Box>
                            </CssGrid>
                        </Box>
                        {offerDetails.offerDecisionStatus === OfferDecisionStatus.Waiting && (
                            <>
                                <Box
                                    sx={{
                                        gridColumn: {xs: '2 / span 8', md: '3 / 7'},
                                        my: 1,
                                        minWidth: 'auto',
                                    }}
                                >
                                    {isSendingOffer == 'ERROR' && (
                                        <Typography color='error'>{getLangString('FAILED_TO_SAVE')}</Typography>
                                    )}
                                </Box>
                                <ButtonPrimaryLarge
                                    sx={{
                                        gridColumn: {xs: '2 / span 6', md: '3 / 5'},
                                        my: 1,
                                        minWidth: 'auto',
                                    }}
                                    onClick={approveOffer}
                                >
                                    {getLangString('APPROVE_OFFER_BTN')}
                                </ButtonPrimaryLarge>
                                <ButtonSecondaryLarge
                                    onClick={() => setDisplayDenyOfferDialog(true)}
                                    sx={{
                                        gridColumn: {xs: '2/ span 6', md: '5 /7'},
                                        my: 1,
                                        minWidth: 'auto',
                                    }}
                                >
                                    {getLangString('DENY_OFFER_BTN')}
                                </ButtonSecondaryLarge>
                            </>
                        )}
                        <DialogWithTextField
                            title={getLangString('DENY_OFFER_BTN')}
                            subTitle={getLangString('OFFER_DECISION_SUBTITLE')}
                            handleClose={() => setDisplayDenyOfferDialog(false)}
                            isOpen={displayDenyOfferDialog}
                            handleConfirmClick={requestRevisedOffer}
                        />
                    </CssGrid>
                </Container>
            </Box>
            <LoaderCenterBlur open={isSendingOffer == 'SENDING'} text={getLangString('SENDING_ANSWER')} />
        </Box>
    );
};
