import {Box, Typography} from '@mui/material';
import {CustomAccordion} from 'components/Accordion';
import {format} from 'date-fns';
import {StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {FC, useState} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {PhotoAttachment} from '../PhotoAttachment';

export type StatusUpdateListItemProps = {
    statusUpdate: StatusUpdateDto;
    startExpanded: boolean;
};

export const StatusUpdate: FC<StatusUpdateListItemProps> = ({statusUpdate, startExpanded}) => {
    const {getLangString} = useLanguage();
    const [isExpanded, setIsExpanded] = useState(startExpanded);
    const getCustomerComment = () => {
        const firstAnswer = statusUpdate.answers[0];
        return {
            text: firstAnswer.answer || '',
            images: (firstAnswer.images || []).map((i) => i.url),
        };
    };

    return (
        <CustomAccordion
            id={`${statusUpdate.statusUpdateId}`}
            ariaControls={''}
            expanded={isExpanded}
            onChange={(e, expanded) => setIsExpanded(expanded)}
            headerElements={
                <Box key='data'>
                    <Typography key={'created'} variant={'body2'}>
                        {format(new Date(statusUpdate.createdAt), 'dd.MM.yyyy')}
                    </Typography>
                </Box>
            }
            sxProps={{pt: 1, '&:first-child': {borderTop: 1, borderColor: 'divider'}}}
            headerElementsStyleOverrides={{pb: 0, pt: 1}}
            detailsElements={
                <>
                    <Box mb={2}>
                        <Typography variant={'subtitle1'} sx={{mb: 0}}>
                            M2:
                        </Typography>
                        <Typography>{statusUpdate.statusDescription}</Typography>
                    </Box>
                    {statusUpdate.answered && (
                        <Box>
                            <Typography variant={'subtitle1'} sx={{mb: 0}}>
                                {getLangString('ME')}:
                            </Typography>
                            <Typography>{getCustomerComment().text}</Typography>
                            <Box mt={2}>
                                {getCustomerComment().images.map((img, i) => (
                                    <PhotoAttachment key={img} id={`photo_${i}`} photoUrl={img} text={''} />
                                ))}
                            </Box>
                        </Box>
                    )}
                </>
            }
        />
    );
};
