import {Box, Typography, useTheme} from '@mui/material';
import React, {useState} from 'react';
import {M2Logo} from '../../assets/M2Logo';
import {Container, CssGrid} from '../Layout';

// TODO: Make reusable image component wrapped in box
type Props = {
    id: number;
    backgroundImage: {
        url: string;
    }[];
    heading: string;
};

export const Hero: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [vh, setVh] = useState<number>(window.innerHeight * 0.01);
    window.addEventListener('resize', () => {
        setVh(window.innerHeight * 0.01);
    });

    return (
        <Box
            sx={{
                minHeight: `${vh * 100}px`,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.up('md')]: {
                    minHeight: `${vh * 100}px`,
                },
            }}
        >
            <img
                src={props.backgroundImage[0].url}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />
            <Box
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0',
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    width: '100%;',
                    pb: 12,
                    pt: 10,
                    [theme.breakpoints.up('md')]: {
                        pt: 14,
                        pb: 18,
                    },
                }}
            >
                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('sm')]: {
                                    gridColumn: '1 / span 6',
                                },
                                [theme.breakpoints.up('lg')]: {
                                    gridColumn: '2 / span 5',
                                },
                            }}
                        >
                            <Typography
                                variant='homepageHero'
                                sx={{
                                    wordBreak: 'break-word',
                                }}
                                dangerouslySetInnerHTML={{__html: props.heading}}
                            />
                        </Box>
                    </CssGrid>
                </Container>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 32,
                    right: 12,
                    height: '160px',
                    width: '160px',
                    '& svg': {
                        display: 'block',
                    },
                    [theme.breakpoints.up('md')]: {
                        height: '260px',
                        width: '260px',
                        bottom: 'calc(3vh + 40px)',
                        right: 40,
                    },
                }}
            >
                <M2Logo size='100%' color={theme.palette.accent.main} />
            </Box>
        </Box>
    );
};
