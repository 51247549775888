interface HtmlButton {
    url: string;
    target: string;
    text: string;
}

export const deconstructHTML = (linkitField: string): HtmlButton | null => {
    if (linkitField === undefined || linkitField === null || linkitField === '') {
        return null;
    }

    const div = document.createElement('div');
    div.innerHTML = linkitField.trim();
    const htmlElement = div.children[0];

    if (htmlElement === undefined || htmlElement === null) {
        return null;
    }

    const url = htmlElement.getAttribute('href') ?? '';
    const target = htmlElement.getAttribute('target') ?? '';
    const text = htmlElement.textContent ?? '';

    return {
        url,
        target,
        text,
    };
};
