import {Grid, Typography} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {CustomDatePicker} from '../../../CustomDatePicker';

export const ProjectTimeFrame: FC<{
    fromDate: Date;
    toDate: Date;
    updateProject: (fromDate: Date | undefined, toDate: Date | undefined) => void;
}> = ({fromDate, toDate, updateProject}) => {
    const [fromValue, setFromValue] = useState<Date | null>(null);
    const [toValue, setToValue] = useState<Date | null>(null);

    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        !fromValue && setFromValue(fromDate);
        !toValue && setToValue(toDate);
    }, [fromDate, fromValue, toDate, toValue]);

    const handleFromChange = (newValue: Date | null) => {
        setError(false);
        newValue && setFromValue(newValue);
    };
    const handleToChange = (newValue: Date | null) => {
        setError(false);
        newValue && setToValue(newValue);
    };
    const handleTextBlur = () => {
        if (fromValue && toValue && !error) {
            updateProject(fromValue, toValue);
        }
    };
    const doSaveFromDates = (fromDate: Date | null) => {
        if (fromDate && toValue) {
            updateProject(fromDate, toValue);
        }
    };
    const doSaveToDates = (toDate: Date | null) => {
        if (fromValue && toDate) {
            updateProject(fromValue, toDate);
        }
    };
    const handleMinMaxError = () => {
        setError(true);
    };
    return (
        <Grid container>
            <Grid item xs={8} sm={4}>
                <CustomDatePicker
                    label={'Oppstartsdato'}
                    value={fromValue}
                    maxDate={toValue || undefined}
                    handleChange={handleFromChange}
                    handleDateSelect={doSaveFromDates}
                    handleTextBlur={handleTextBlur}
                    handleMinMaxDateError={handleMinMaxError}
                    error={error}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <CustomDatePicker
                    label={'Sluttdato'}
                    value={toValue}
                    minDate={fromValue || undefined}
                    handleChange={handleToChange}
                    handleDateSelect={doSaveToDates}
                    handleTextBlur={handleTextBlur}
                    handleMinMaxDateError={handleMinMaxError}
                    error={error}
                />
            </Grid>
            <Grid item xs={8}>
                <Typography variant={'error'} component={'p'}>
                    {error && 'Sluttdato må være etter oppstartsdato'}
                </Typography>
            </Grid>
        </Grid>
    );
};
