import {Accordion, AccordionDetails, AccordionSummary, Box, styled} from '@mui/material';
import React, {ReactNode} from 'react';
import {NavLink, NavLinkProps, useLocation} from 'react-router-dom';
import {useLanguage} from '../language/LanguageProvider';
import {ChevronExpandMoreIcon} from './icons/ChevronIcons';
import {DefaultGridPageLayout} from './Layout';

export const CustomNavLink = styled((props: NavLinkProps) => (
    <NavLink {...props} activeStyle={{borderBottom: '1px solid #121212', ...props.activeStyle}} />
))(({theme}) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
        marginRight: 40,
        marginTop: 8,
        marginBottom: 8,
    },
}));

export const BurgerMenuLink = styled(NavLink)(({theme}) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    paddingBottom: theme.spacing(0.5),
    fontFamily: 'SuisseWorks-Regular, serif',
    fontSize: '2.625rem',

    [theme.breakpoints.down('sm')]: {
        fontSize: '2.125rem',
    },
}));

export const SubMenuMobile: React.FC<{content: ReactNode; bgColor: string | undefined}> = ({content, bgColor}) => {
    const {getLangString} = useLanguage();
    const [isOpen, setIsOpen] = React.useState(false);
    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };
    const location = useLocation();
    React.useEffect(() => {
        setIsOpen(false);
    }, [location]);
    return (
        <Box
            sx={{
                position: 'sticky',
                top: 72,
                background: bgColor,
                boxShadow: '0px 6px 6px #0000001a',
                zIndex: 10,
            }}
        >
            <Accordion expanded={isOpen} elevation={0} sx={{background: 'inherit'}} onChange={handleButtonClick}>
                <AccordionSummary
                    expandIcon={<ChevronExpandMoreIcon />}
                    sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        px: 2,
                        '& > div:first-child': {
                            flexGrow: 0,
                        },
                    }}
                >
                    {getLangString('SUBMENU')}
                </AccordionSummary>
                <AccordionDetails sx={{px: 2, pt: 0}}>{content}</AccordionDetails>
            </Accordion>
        </Box>
    );
};

export const SubMenuLargerScreens: React.FC<{content: ReactNode}> = ({content}) => (
    <DefaultGridPageLayout>{content}</DefaultGridPageLayout>
);
