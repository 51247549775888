import {Box, CircularProgress, Typography} from '@mui/material';
import {DataGrid, DataGridProps, GridColDef, GridOverlay, GridRenderCellParams} from '@mui/x-data-grid';
import {ArrowForward} from 'components/icons/ArrowForward';
import {format} from 'date-fns';
import {nb} from 'date-fns/locale';
import {OfferDecisionStatus} from 'lib/dtos/Offer';
import {PaginationDto} from 'lib/dtos/Pagination';
import {OfferList} from 'lib/types/Admin';
import {useContext, VFC} from 'react';
import {useHistory} from 'react-router-dom';
import {AdminAppContext} from 'store/adminAppContext';
import {colors} from 'theme';
import {EmployeeBaseDto} from '../../../../lib/dtos/User';
import {StatusElement} from '../../../StatusElement';
import {getOfferByIDPath, getRejectedOfferByIDPath} from '../AdminPages';
import {gridColDefProps} from '../InquirePage/InquirePageDataGrid';

const columns: GridColDef[] = [
    {
        ...gridColDefProps,
        field: 'status',
        headerName: 'Status',
        renderCell: ({value}: GridRenderCellParams<OfferDecisionStatus>) => (
            <StatusElement text={offerStatus[value].text} iconColor={offerStatus[value].color} />
        ),
        minWidth: 120,
    },
    {
        ...gridColDefProps,
        field: 'projectId',
        headerName: 'ID',
        renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
        ...gridColDefProps,
        field: 'dateCreated',
        headerName: 'Dato',
        type: 'date',
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Typography>{format(params.value, 'P', {locale: nb})}</Typography>
        ),
    },
    {
        ...gridColDefProps,
        // minWidth: 200,
        field: 'title',
        headerName: 'Tittel',
        flex: 1,
        renderCell: ({value}) => <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{value}</Typography>,
    },
    {
        ...gridColDefProps,
        field: 'projectLeader',
        headerName: 'Prosjektleder',
        renderCell: (params: GridRenderCellParams<EmployeeBaseDto>) => (
            <Typography>{params.value ? `${params.value.firstName} ${params.value.lastName}` : ''}</Typography>
        ),
        minWidth: 180,
    },
    {
        ...gridColDefProps,
        hideSortIcons: true,
        disableColumnMenu: true,
        field: 'seeDetails',
        headerName: '',
        sortable: false,
        renderCell: () => (
            <Box display={'flex'} justifyContent={'flex-end'} width={20} style={{cursor: 'pointer'}}>
                <ArrowForward size={20} />
            </Box>
        ),
        minWidth: 20,
        width: 36,
    },
];

export const OfferDataGrid: VFC<
    {
        data: OfferList[];
        pagination: PaginationDto | undefined;
        rowsPerPage: number;
        minHeight: number;
    } & Pick<DataGridProps, 'onPageChange' | 'onPageSizeChange' | 'loading'>
> = ({data, pagination, rowsPerPage, minHeight, ...props}) => {
    const history = useHistory();
    const {bgColor} = useContext(AdminAppContext);

    return (
        <DataGrid
            {...props}
            sx={{
                backgroundColor: bgColor,
                minHeight,
                '& .MuiDataGrid-overlay': {backgroundColor: 'transparent'},
            }}
            columns={columns}
            pageSize={rowsPerPage}
            components={{
                NoRowsOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <Typography>Ingen data</Typography>
                    </GridOverlay>
                ),
                LoadingOverlay: () => (
                    <GridOverlay style={{backgroundColor: bgColor}}>
                        <CircularProgress />
                    </GridOverlay>
                ),
            }}
            componentsProps={{loadingOverlay: {background: bgColor}, noResultsOverlay: {background: bgColor}}}
            rows={data.map((d) => ({...d, id: d.offerId}))}
            rowCount={pagination?.total ?? 0}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRowClick={(params) => {
                const {status, projectId, offerId} = params.row;
                if (status === OfferDecisionStatus.Rejected) {
                    history.push(getRejectedOfferByIDPath(projectId, offerId));
                } else {
                    history.push(getOfferByIDPath(projectId, offerId));
                }
            }}
        />
    );
};

export const offerStatus: Record<OfferDecisionStatus, {text: string; color: string}> = {
    [OfferDecisionStatus.Open]: {text: 'Åpen', color: colors.fair},
    [OfferDecisionStatus.Waiting]: {text: 'Tilbud sendt', color: colors.yellow},
    [OfferDecisionStatus.Accepted]: {text: 'Godtatt', color: colors.green},
    [OfferDecisionStatus.Rejected]: {text: 'Avslått', color: colors.red},
    [OfferDecisionStatus.Canceled]: {text: 'Avbrutt', color: colors.red},
};
