import React from 'react';
import {IconProps} from './IconProps';

export const NotificationIcon: React.FC<IconProps & {bgColor?: string}> = (props) => {
    const size = props.size || 16;
    return (
        <svg viewBox={'0 0 16 16'} width={size} height={size}>
            {props.bgColor && <circle cx={size / 2} cy={size / 2} r={8} fill={props.bgColor} />}
            <circle cx={size / 2} cy={size / 2} r={8} fill={props.color} opacity={0.3} />
            <circle cx={size / 2} cy={size / 2} r={5} fill={props.color} />
        </svg>
    );
};
