import {Box, Grid, Typography, useTheme} from '@mui/material';
import {CustomInputField} from 'components/InputField';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {MixedRequestError, useProblemDetailsError} from 'lib/hooks/useProblemDetailsError';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {isRequired, validateIsEmail, validateNoSpecialChars, validatePhoneNumber} from 'lib/validationMessages';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLanguage} from '../../language/LanguageProvider';
import {ButtonPrimaryLarge} from '../Buttons';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    text: string;
    confirmationText: string;
    buttonText: string;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

type ContactFormFields = {
    [key: string]: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    message: string;
};

export const ContactForm: React.FC<Props> = (props) => {
    const theme = useTheme();
    const [formSent, setFormSent] = useState(false);
    const {getLangString} = useLanguage();
    const {clearTranslatedError, translate, translatedError} = useProblemDetailsError(
        {},
        'Klarte ikke å sende skjema, prøv igjen senere.',
    );

    const {control, formState, handleSubmit, trigger, reset} = useForm<ContactFormFields>({
        defaultValues: {
            firstName: '',
            lastName: '',
            phone: '',
            message: '',
            email: '',
        },
    });

    const [{loading: isSending}, doSendContactForm] = useM2Axios(
        {
            url: ApiEndpoints.contact.question(),
            method: 'POST',
        },
        {
            manual: true,
        },
    );

    const doHandleForm = async (form: ContactFormFields) => {
        setFormSent(false);
        clearTranslatedError();
        try {
            await doSendContactForm({data: form});
            setFormSent(true);
            reset();
        } catch (error) {
            translate(error as MixedRequestError);
        }
    };

    return (
        <ContentBlock quickLink={props.quickLink} size='sm'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '2 / span 5',
                            },
                        }}
                    >
                        <Typography
                            component='div'
                            sx={{
                                '& a': {
                                    color: 'text.primary',
                                },
                                '& > :first-child': {
                                    mt: 0,
                                },
                                '& > :last-child': {
                                    mb: 0,
                                },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: props.text,
                            }}
                        />
                    </Box>
                </CssGrid>

                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '2 / span 6',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                mt: 6,
                                fontSize: '1rem',
                                fontFamily: 'SuisseIntl-Medium',
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '1.0625rem',
                                    mt: 9,
                                },
                            }}
                        >
                            {getLangString('CONTACT_INFO')}
                        </Box>
                        <form onSubmit={handleSubmit((v) => doHandleForm(v))}>
                            <Grid container columnSpacing={0} marginBottom={3}>
                                <Grid item xs={8} sm={4}>
                                    <Controller
                                        name={'firstName'}
                                        rules={{required: isRequired(), pattern: validateNoSpecialChars()}}
                                        control={control}
                                        defaultValue=''
                                        render={({field, fieldState}) => (
                                            <CustomInputField
                                                label={getLangString('FORNAME')}
                                                name={field.name}
                                                error={!!fieldState.error}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={() => trigger(field.name)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Controller
                                        name={'lastName'}
                                        rules={{required: isRequired(), pattern: validateNoSpecialChars()}}
                                        control={control}
                                        defaultValue=''
                                        render={({field, fieldState}) => (
                                            <CustomInputField
                                                label={getLangString('SURNAME')}
                                                name={field.name}
                                                error={!!fieldState.error}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={() => trigger(field.name)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Controller
                                        name={'email'}
                                        rules={{pattern: validateIsEmail(), required: isRequired()}}
                                        control={control}
                                        defaultValue=''
                                        render={({field, fieldState}) => (
                                            <CustomInputField
                                                label={getLangString('EMAIL')}
                                                name={field.name}
                                                error={!!fieldState.error}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={() => trigger(field.name)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Controller
                                        name={'phone'}
                                        rules={{required: isRequired(), pattern: validatePhoneNumber()}}
                                        control={control}
                                        defaultValue=''
                                        render={({field, fieldState}) => (
                                            <CustomInputField
                                                label={getLangString('PHONE')}
                                                name={field.name}
                                                error={!!fieldState.error}
                                                value={field.value}
                                                onChange={field.onChange}
                                                onBlur={() => trigger(field.name)}
                                                helperText={fieldState.error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    mt: 6,
                                    fontSize: '1rem',
                                    fontFamily: 'SuisseIntl-Medium',
                                    [theme.breakpoints.up('md')]: {
                                        fontSize: '1.0625rem',
                                        mt: 9,
                                    },
                                }}
                            >
                                Hva kan vi hjelpe deg med?
                            </Box>
                            <Box
                                sx={{
                                    mt: 3.5,
                                }}
                            >
                                <Controller
                                    name={'message'}
                                    rules={{required: isRequired()}}
                                    control={control}
                                    defaultValue=''
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            placeholder={'Fritekst...'}
                                            multiline={true}
                                            fullWidth={true}
                                            minRows={5}
                                            name={field.name}
                                            error={!!fieldState.error}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Box>
                            <Box
                                sx={{
                                    mt: 6,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 9,
                                    },
                                }}
                            >
                                <p>
                                    {translatedError && <Typography variant='error'>{translatedError}</Typography>}
                                    {formSent && <Typography>{getLangString('CONTACT_US_CONFIRMATION')}.</Typography>}
                                </p>
                                <ButtonPrimaryLarge disabled={isSending || !formState.isValid} type='submit'>
                                    {isSending ? 'Sender..' : props.buttonText}
                                </ButtonPrimaryLarge>
                            </Box>
                        </form>
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
