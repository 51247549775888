import {Box, Link, Typography, useTheme} from '@mui/material';
import React, {SyntheticEvent} from 'react';

type ListItemProps = {
    text: string;
    url?: string;
    underline?: boolean;
    underlineOnHover?: boolean;
    mediumFont?: boolean;
    onClick?: (e: SyntheticEvent) => void;
};

export const ListItem: React.FC<ListItemProps> = (props) => {
    const underline = props.underline ?? true;
    const mediumFont = props.mediumFont ?? false;
    const underlineOnHover = props.underlineOnHover ?? false;
    const theme = useTheme();
    const clickHandler = (e: SyntheticEvent) => {
        if (props.onClick !== undefined) {
            props.onClick(e);
        }
    };

    return (
        <Box>
            <Link
                sx={{
                    fontFamily: mediumFont ? 'SuisseIntl-Medium' : 'SuisseIntl-Regular',
                    fontSize: '1.125rem',
                    lineHeight: '2.22',
                    textDecoration: 'none',
                    borderBottomWidth: underline ? '1px' : '0',
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'text.primary',
                    [theme.breakpoints.up('md')]: {
                        fontSize: '1.3125rem',
                        lineHeight: '2.38',
                    },
                    '&:hover': {
                        borderBottomWidth: underlineOnHover || underline ? '1px' : '',
                    },
                }}
                href={props.url ?? '#'}
                onClick={clickHandler}
            >
                {props.text}
            </Link>
        </Box>
    );
};

type ListProps = {
    heading?: string;
    columns?: {
        xs?: number;
        sm?: number;
        md?: number;
        lg?: number;
    };
};

export const List: React.FC<ListProps> = (props) => {
    const theme = useTheme();
    const columns = props.columns ?? {};

    return (
        <Box>
            {props.heading !== undefined && (
                <Typography
                    component='div'
                    sx={{
                        fontSize: '0.875rem',
                        lineHeight: '1.57',
                        color: 'text.secondary',
                        mb: 1.5,
                        [theme.breakpoints.up('md')]: {
                            mb: 2.5,
                            fontSize: '0.9375rem',
                            lineHeight: '1.53',
                        },
                    }}
                >
                    {props.heading}
                </Typography>
            )}

            <Box
                sx={{
                    columns: columns.xs ?? 1,
                    columnGap: '10px',
                    [theme.breakpoints.up('sm')]: {
                        columns: columns.sm ?? columns.xs ?? 1,
                    },
                    [theme.breakpoints.up('md')]: {
                        columns: columns.md ?? columns.sm ?? columns.xs ?? 1,
                        columnGap: '24px',
                    },
                    [theme.breakpoints.up('lg')]: {
                        columns: columns.lg ?? columns.md ?? columns.sm ?? columns.xs ?? 1,
                    },
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};
