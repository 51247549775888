import {Box, Grid, Typography} from '@mui/material';
import {CustomAccordion} from 'components/Accordion';
import {InfoField} from 'components/InfoField';
import {format} from 'date-fns';
import {useLanguage} from 'language/LanguageProvider';
import {StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {useState} from 'react';
import {CustomerAnswer} from './orderPage/InfoBlock';

export type StatusUpdateListItemProps = {
    statusUpdate: StatusUpdateDto;
    startExpanded: boolean;
};

export const StatusUpdateListItem: React.FC<StatusUpdateListItemProps> = ({statusUpdate, startExpanded}) => {
    const {getLangString} = useLanguage();
    const [isExpanded, setIsExpanded] = useState(startExpanded);
    const getAnswer = () => {
        const firstAnswer = statusUpdate.answers[0];
        const images = firstAnswer?.images ? firstAnswer.images : [];

        const textAnswer = firstAnswer?.answer ? firstAnswer.answer : getLangString('NO_COMMENT');
        return {
            descriptionText: textAnswer,
            images: images.map((i) => i.url),
        };
    };

    const hasAnswered = () => {
        return statusUpdate.answered;
    };

    return (
        <Box mb={2}>
            <CustomAccordion
                id={`${statusUpdate.statusUpdateId}`}
                ariaControls={''}
                expanded={isExpanded}
                onChange={(e, expanded) => setIsExpanded(expanded)}
                headerElements={
                    <Grid container key='data'>
                        <Grid item md={3} sm={3} xs={12}>
                            <Typography key={'description'} variant={'subtitle1'}>
                                {getLangString('STATUS_UPDATE')}
                            </Typography>
                        </Grid>
                        <Grid item md xs>
                            <Typography key={'created'} variant={'subtitle1'}>
                                {format(new Date(statusUpdate.createdAt), 'dd.MM.yyyy')}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                detailsElements={
                    <>
                        <InfoField>{statusUpdate.statusDescription}</InfoField>
                        <Box mb={2} mt={2}>
                            {(hasAnswered() && <CustomerAnswer {...getAnswer()} />) || (
                                <Typography variant={'body1'}>{getLangString('NO_ANSWER_GIVEN')}</Typography>
                            )}
                        </Box>
                    </>
                }
            />
        </Box>
    );
};
