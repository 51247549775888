/* eslint-disable @typescript-eslint/no-explicit-any */
import {useQuery} from '@apollo/client';
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {PEOPLE_INDEX_PAGE_URL} from '../../cms/helpers';
import {CMS_SEO, GET_PEOPLE_ENTRY_PAGE, GET_SEO_DATA} from '../../graphql';
import {SEO} from '../../SEO';
import {PageOverline} from '../cms/PageOverline';
import {NotFound} from '../errorPages/NotFound';
import {Container, CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';

type DataProps = {
    label: string;
    value: string;
    url?: string;
};

const PersonDataRow: React.FC<DataProps> = (props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                py: 2.5,
                display: 'flex',
                fontSize: '0.875rem',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'text.primary',
                [theme.breakpoints.up('sm')]: {
                    fontSize: '1rem',
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '1.0625rem',
                },
            }}
        >
            <Box
                sx={{
                    width: `${(1 / 4) * 100}%`,
                    [theme.breakpoints.up('sm')]: {
                        width: `${(1 / 3) * 100}%`,
                    },
                }}
            >
                {props.label}
            </Box>
            <Box sx={{flex: '1 1 0%', ml: 1.25}}>
                {props.url ? (
                    <a style={{color: theme.palette.text.primary}} href={props.url}>
                        {props.value}
                    </a>
                ) : (
                    props.value
                )}
            </Box>
        </Box>
    );
};

export const PeopleEntryPage: React.FC = () => {
    const theme = useTheme();
    const {slug} = useParams<{slug: string}>();
    const {loading, error, data} = useQuery(GET_PEOPLE_ENTRY_PAGE(slug));
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(`${PEOPLE_INDEX_PAGE_URL}/${slug}`));

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;

    if (!entry) {
        return <NotFound />;
    }

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                sx={{
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 10,
                    },
                }}
            >
                {/* TODO: Translate text */}
                <PageOverline text='Menneskene' />

                <Container>
                    <CssGrid>
                        <Typography
                            sx={{
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '2 / span 5',
                                },
                            }}
                            component='h1'
                            variant='pageIntro'
                        >
                            {entry.title}
                        </Typography>
                    </CssGrid>

                    <CssGrid>
                        <Box
                            sx={{
                                mt: 5,
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('sm')]: {
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    columnGap: 3,
                                },
                                [theme.breakpoints.up('md')]: {
                                    mt: 8,
                                    gridColumn: '2 / span 6',
                                },
                            }}
                        >
                            <PersonDataRow label='Stilling' value={entry.jobTitle} />
                            <PersonDataRow label='Års erfaring' value={entry.yearsOfExperience} />
                            <PersonDataRow label='E-post' value={entry.emailAddress} url='#' />
                            <PersonDataRow label='Telefon' value={entry.phoneNumber} url='#' />
                        </Box>
                    </CssGrid>

                    {entry.qualifications !== null && entry.qualifications !== '' && (
                        <CssGrid>
                            <Box
                                sx={{
                                    mt: 6,
                                    gridColumn: 'span 8',
                                    [theme.breakpoints.up('md')]: {
                                        mt: 12,
                                        gridColumn: '3 / span 4',
                                    },
                                }}
                            >
                                <Typography
                                    component='h2'
                                    sx={{
                                        fontFamily: 'SuisseIntl-Medium',
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '1.0625rem',
                                        },
                                    }}
                                >
                                    Kvalifikasjoner
                                </Typography>
                                <Typography
                                    component='div'
                                    sx={{
                                        mt: 4,
                                        '& > :first-child': {
                                            mt: 0,
                                        },
                                        '& > :last-child': {
                                            mb: 0,
                                        },
                                        [theme.breakpoints.up('md')]: {
                                            mt: 6,
                                            fontSize: '1.0625rem',
                                        },
                                    }}
                                    dangerouslySetInnerHTML={{__html: entry.qualifications}}
                                />
                            </Box>
                        </CssGrid>
                    )}

                    {entry.experience.length > 0 && (
                        <CssGrid>
                            <Box
                                sx={{
                                    mt: 6,
                                    gridColumn: 'span 8',
                                    [theme.breakpoints.up('md')]: {
                                        mt: 12,
                                        gridColumn: '3 / span 4',
                                    },
                                }}
                            >
                                <Typography component='h2' sx={{fontFamily: 'SuisseIntl-Medium'}}>
                                    Erfaring
                                </Typography>
                                <Box
                                    sx={{
                                        mt: 4,
                                        [theme.breakpoints.up('md')]: {
                                            mt: 6,
                                        },
                                    }}
                                >
                                    {entry.experience.map((item: any, index: number) => {
                                        return (
                                            <Box
                                                key={item.id}
                                                sx={{
                                                    pb: 2.5,
                                                    pt: index == 0 ? 0 : 2.5,
                                                    display: 'grid',
                                                    gridTemplateColumns: '3fr 5fr',
                                                    columnGap: 1.25,
                                                    borderBottomWidth: 1,
                                                    borderBottomStyle: 'solid',
                                                    borderBottomColor: 'text.primary',
                                                    [theme.breakpoints.up('md')]: {
                                                        gridTemplateColumns: '1fr 3fr',
                                                        columnGap: 3,
                                                        fontSize: '1.0625rem',
                                                    },
                                                }}
                                            >
                                                <Box>{`${item.startYear}-${item.endYear}`}</Box>
                                                <Box
                                                    sx={{
                                                        '& > :first-child': {
                                                            mt: 0,
                                                        },
                                                        '& > :last-child': {
                                                            mb: 0,
                                                        },
                                                    }}
                                                    dangerouslySetInnerHTML={{__html: item.text}}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </CssGrid>
                    )}

                    {entry.profileImage.length > 0 && (
                        <CssGrid>
                            <Box
                                sx={{
                                    gridColumn: 'span 8',
                                    mt: 6,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 12,
                                        gridColumn: '2 / span 6',
                                    },
                                }}
                            >
                                <img style={{width: '100%', display: 'block'}} src={entry.profileImage[0].url} alt='' />
                            </Box>
                        </CssGrid>
                    )}
                </Container>
            </Box>
        </>
    );
};
