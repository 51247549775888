import {Box, styled} from '@mui/material';
import React from 'react';
import {ImageViewer} from './imageViewer/ImageViewer';
import {ImageDisplay} from './loggedInPages/SecureImage';

export const PhotoContainer = styled(Box)(({theme}) => ({
    width: 'min-content',
    minWidth: '116px',
    minHeight: '130px',
    border: `1px solid ${theme.palette.formLine.active}`,
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: '0.75rem',

    '& img': {
        objectFit: 'cover',
    },
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

// export type PictureElement = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const PhotoAttachment: React.FC<{
    id: string;
    photoUrl: string;
    text: string;
    // if this flag is true, the image can be opened to fullscreen
    enableLargeView?: boolean;
    //Determines if we should use the secure image loader (from our api)
    secure?: boolean;
    handleOnClick?: (id: string) => void;
}> = ({id, photoUrl, text, enableLargeView: enableEnlarger, handleOnClick}) => {
    const onClick = () => {
        handleOnClick?.(id);
    };
    return (
        <PhotoContainer onClick={onClick}>
            <ImageViewer enableFullView={enableEnlarger}>
                <ImageDisplay src={photoUrl} alt={'opplastet bilde'} />
            </ImageViewer>
            <span>{text}</span>
        </PhotoContainer>
    );
};
