import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const RadioActiveIcon: React.FC<IconProps> = ({color, size}) => (
    <svg
        width={size || '24'}
        height={size || '24'}
        viewBox='0 0 24 24'
        fill={color || colors.black}
        stroke={color || colors.black}
    >
        <rect x='0.5' y='0.5' width='21' height='21' transform='translate(1 1)' fill='none' strokeWidth='1' />
        <rect width='12' height='12' transform='translate(6 6)' stroke='none' />
    </svg>
);
export const RadioInActiveIcon: React.FC<IconProps> = ({color, size}) => (
    <svg width={size || '24'} height={size || '24'} viewBox='0 0 24 24'>
        <g fill='none' transform='translate(1 1)'>
            <path
                d='M 1 1 L 1 21 L 21 21 L 21 1 L 1 1 M 0 0 L 22 0 L 22 22 L 0 22 L 0 0 Z'
                stroke='none'
                fill={color || colors.black}
            />
        </g>
    </svg>
);
