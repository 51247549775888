import {Box, Grid, Typography} from '@mui/material';
import {InfoField} from 'components/InfoField';
import {PhotoAttachment} from 'components/PhotoAttachment';
import {useLanguage} from 'language/LanguageProvider';
import {VFC} from 'react';

export type ProjectDescriptionProps = {
    descriptionHeader: string;
    descriptionText: string;
    serviceTaskText?: string;
    images: string[];
    imageHeader?: string;
    secure?: boolean;
    infoBoxBackground?: string;
};
export const Photos: VFC<Pick<ProjectDescriptionProps, 'imageHeader' | 'images' | 'secure'>> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <>
            {props.imageHeader && (
                <Typography variant={'h6'} mb={2}>
                    {props.imageHeader}
                </Typography>
            )}
            <Box display={'flex'} flexWrap={'wrap'}>
                {props.images.length > 0 ? (
                    props.images.map((img, i) => (
                        <PhotoAttachment key={img} id={`photo_${i}`} photoUrl={img} text={''} />
                    ))
                ) : (
                    <Typography>{getLangString('NO_PHOTOS')}</Typography>
                )}
            </Box>
        </>
    );
};
export const ProjectDescription: React.FC<ProjectDescriptionProps> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <Grid container>
            <Grid item xs={8} sm={4}>
                <Typography variant={'h6'} mb={2}>
                    {props.descriptionHeader}
                </Typography>
                <InfoField style={{background: props.infoBoxBackground}}>
                    {props.serviceTaskText && <Typography mb={1}>{props.serviceTaskText}</Typography>}
                    <Typography>{props.descriptionText}</Typography>
                    {!props.serviceTaskText && !props.descriptionText && getLangString('NO_DESCRIPTION')}
                </InfoField>
            </Grid>
            <Grid item xs={8} sm={4} sx={{mt: {xs: 4, sm: 0}}}>
                <Photos imageHeader={props.imageHeader} images={props.images} />
            </Grid>
        </Grid>
    );
};
