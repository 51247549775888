import {Box, Container, Grid, Typography, useTheme} from '@mui/material';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {
    validateIsEmail,
    validateIsRequired,
    validateNoSpecialChars,
    validatePhoneNumberWithLandCode,
    validateZip,
} from '../../lib/validationMessages';
import {saveInquiryContactInfo} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {AddressFields} from '../AddressForm';
import {ButtonPrimaryLarge} from '../Buttons';
import {getTextWithLink} from '../getTextWithLink';
import {CustomInputField} from '../InputField';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {CustomRadio} from '../RadioButton';
import {ButtonLayout} from './InquiryDetailComponents';
import {getInquirySummaryPath} from './InquiryPageRoutes';

type FormFields = AddressFields & {
    givenName: string;
    familyName: string;
    phone: string;
    email: string;
};

export const InquiryContactInfo: React.FC = () => {
    const {state, dispatch} = React.useContext(AppContext);
    const {getLangString} = useLanguage();
    const {handleSubmit, formState, trigger, control} = useForm<FormFields>();
    const {palette} = useTheme();

    const {contactInformation} = state.inquiry;

    const [isCheckboxChecked, setIsCheckboxChecked] = React.useState<boolean>(false);
    const toggleCheckbox = () => {
        setIsCheckboxChecked((checked) => !checked);
        trigger();
    };

    const history = useHistory();
    const doSubmit = (form: FormFields) => {
        dispatch(
            saveInquiryContactInfo({
                personDetails: {
                    firstName: form.givenName,
                    lastName: form.familyName,
                    phoneNumber: form.phone,
                    email: form.email,
                },
                address: {
                    street: form.address,
                    zip: form.postalCode,
                    location: form.city,
                },
            }),
        );
        history.push(getInquirySummaryPath());
    };

    return (
        <Container sx={{py: 7}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    <form onSubmit={handleSubmit(doSubmit)}>
                        <Typography variant={'subtitle1'}>{getLangString('CONTACT_INFO')}</Typography>
                        <Grid container columnSpacing={0} marginBottom={3}>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name={'givenName'}
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateNoSpecialChars(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.personDetails.firstName : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            required={true}
                                            label={getLangString('FORNAME')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name={'familyName'}
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateNoSpecialChars(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.personDetails.lastName : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            required={true}
                                            label={getLangString('SURNAME')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name={'phone'}
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validatePhoneNumberWithLandCode(),
                                    }}
                                    control={control}
                                    defaultValue={
                                        contactInformation ? contactInformation.personDetails.phoneNumber : '+47 '
                                    }
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            required={true}
                                            label={getLangString('PHONE')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name={'email'}
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateIsEmail(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.personDetails.email : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            required={true}
                                            label={getLangString('EMAIL')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant={'subtitle1'}>{getLangString('INSPECTION_ADDRESS')}</Typography>
                        <Grid container columnSpacing={0} marginBottom={3}>
                            <Grid item xs={8} sm={4}>
                                <Controller
                                    name={'address'}
                                    rules={{
                                        required: validateIsRequired(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.address.street : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('ADDRESS')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            required={true}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Controller
                                    name={'postalCode'}
                                    rules={{
                                        required: validateIsRequired(),
                                        pattern: validateZip(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.address.zip : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('POSTAL_CODE')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            required={true}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Controller
                                    name={'city'}
                                    rules={{
                                        required: validateIsRequired(),
                                    }}
                                    control={control}
                                    defaultValue={contactInformation ? contactInformation.address.location : ''}
                                    render={({field, fieldState}) => (
                                        <CustomInputField
                                            label={getLangString('CITY')}
                                            name={field.name}
                                            value={field.value}
                                            onChange={field.onChange}
                                            required={true}
                                            error={!!fieldState.error}
                                            onBlur={() => trigger(field.name)}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant={'subtitle1'}>{getLangString('CONSENT')}</Typography>
                        <Box
                            border={'1px solid'}
                            borderColor={isCheckboxChecked ? palette.formLine.active : palette.formLine.inActive}
                            padding={2}
                            marginBottom={8}
                            display={'flex'}
                            alignItems={'center'}
                        >
                            <CustomRadio onClick={toggleCheckbox} checked={isCheckboxChecked} />
                            <div>
                                {getTextWithLink(
                                    getLangString('GDPR_TEXT__FORMAT'),
                                    `${window.location.origin}/personvernerklæring`,
                                )}
                            </div>
                        </Box>
                        <ButtonLayout>
                            <ButtonPrimaryLarge type={'submit'} disabled={!formState.isValid || !isCheckboxChecked}>
                                {getLangString('NEXT')}
                            </ButtonPrimaryLarge>
                        </ButtonLayout>
                    </form>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
