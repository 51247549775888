import {Box, Container} from '@mui/material';
import {CssGrid, DefaultGridPageLayout} from 'components/Layout';
import React, {FC, useContext} from 'react';
import {AdminAppContext} from 'store/adminAppContext';
import {AdminSimpleMenuBar} from './AdminPages';

export const AdminPageLayoutWithSimpleMenu: FC = ({children}) => {
    const {bgColor} = useContext(AdminAppContext);

    return (
        <Box sx={{backgroundColor: bgColor, minHeight: '100vh'}}>
            <AdminSimpleMenuBar bgColor={bgColor} />
            <Container sx={{mb: 12}}>
                <CssGrid>
                    <DefaultGridPageLayout>{children}</DefaultGridPageLayout>
                </CssGrid>
            </Container>
        </Box>
    );
};
export const AdminPageLayout: FC = ({children}) => {
    const {bgColor} = useContext(AdminAppContext);

    return (
        <Box sx={{backgroundColor: bgColor, minHeight: '100vh'}}>
            <Container sx={{mb: 12}}>
                <CssGrid>
                    <DefaultGridPageLayout>{children}</DefaultGridPageLayout>
                </CssGrid>
            </Container>
        </Box>
    );
};
