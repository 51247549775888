import {useIsAuthenticated} from '@azure/msal-react';
import React from 'react';
import {InquiryContactInfo} from './InquiryContactInfo';
import {InquiryContactInfoPrefilled} from './InquiryContactInfoPrefilled';
import {useRedirectIfNoActiveInquiry} from './InquiryPageRoutes';

export const InquiryContactInfoPage: React.FC = () => {
    //Redirect to initial inquiry page if there is no active inquiry
    useRedirectIfNoActiveInquiry();

    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        return <InquiryContactInfoPrefilled />;
    }
    return <InquiryContactInfo />;
};
