import {Box, Container, Skeleton, Typography} from '@mui/material';
import {ImageViewer} from 'components/imageViewer/ImageViewer';
import React, {Dispatch, FC, useContext, useEffect} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {ProjectFilesDto, ProjectSimpleDto} from '../../lib/dtos/Project';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {M2Response} from '../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {ActionType} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {CenterContent} from '../AppLayout';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {LoaderCenterBlur} from '../Loader';
import {Documents} from './Documents';
import {ImageDisplay} from './SecureImage';
import {getServiceText} from './serviceText';

const Thumb: FC<{img: FileDto}> = ({img}) => (
    <Box
        sx={{
            '& img': {
                objectFit: 'cover',
            },
            mr: 1,
            mb: 1,
            width: '140px',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
        }}
    >
        <ImageViewer>
            <ImageDisplay src={img.url} alt={'uploaded photo'} width={'140px'} height={'140px'} />
            <Typography variant={'body2'}>{img.description || img.fileName}</Typography>
        </ImageViewer>
    </Box>
);

const DocumentGroup: FC<{groupTitle: string; project: ProjectSimpleDto; dispatch: Dispatch<ActionType>}> = (props) => {
    const [{data: files, loading}, getFiles] = useM2Axios<{data: ProjectFilesDto}>('', {manual: true});
    const [{data: inspectionImages, loading: loadingInspectionImages}, getInspectionImages] = useM2Axios<{
        data: FileDto[];
    }>('', {manual: true});
    const [{data: productImages, loading: loadingProductImages}, getProductImages] = useM2Axios<{data: FileDto[]}>('', {
        manual: true,
    });
    useEffect(() => {
        getFiles({url: `${ApiEndpoints.project.byId(props.project.projectId)}/files`});
    }, [getFiles, props.project]);

    useEffect(() => {
        getInspectionImages({url: `${ApiEndpoints.project.byId(props.project.projectId)}/images/inspection`});
        getProductImages({url: `${ApiEndpoints.project.byId(props.project.projectId)}/images/product`});
    }, [getInspectionImages, getProductImages, props.project]);

    const getDocuments = (): FileDto[] => {
        const documentation = files?.data.documentation || [];
        const contracts = files?.data.contracts || [];
        const archive = files?.data.archive || [];
        return [...documentation, ...contracts, ...archive];
    };
    const getPhotos = () => {
        if (loadingInspectionImages || loadingProductImages) {
            return <Skeleton width={'140px'} height={'140px'} sx={{display: 'block'}} />;
        } else if (productImages || inspectionImages) {
            return (
                <Box display={'flex'} flexWrap={'wrap'} my={4}>
                    {inspectionImages && inspectionImages.data.map((img) => <Thumb key={img.id} img={img} />)}
                    {productImages && productImages.data.map((img) => <Thumb key={img.id} img={img} />)}
                </Box>
            );
        }
    };
    return (
        <Box sx={{my: 8, '&:first-child': {mt: 4}}}>
            <Typography variant={'h6'}>{props.groupTitle}</Typography>
            <Documents files={getDocuments()} loadingFiles={loading} />
            {getPhotos()}
        </Box>
    );
};
const EmptyState: React.FC = () => {
    const {getLangString} = useLanguage();
    return (
        <CenterContent>
            <Typography variant={'h3'} color={'secondary'}>
                {getLangString('DOCUMENTATION_EMPTY_STATE')}
            </Typography>
        </CenterContent>
    );
};
export const DocumentationPage: React.FC = () => {
    const {bgColor, state, dispatch} = useContext(AppContext);
    const [{data: projects, loading: loadingProjects}] = useM2Axios<M2Response<ProjectSimpleDto[]>>({
        url: ApiEndpoints.project.base(),
    });
    const getProjectTitle = (p: ProjectSimpleDto) =>
        `${state.allServices && getServiceText(p.services, state.allServices) + ' -'} ${p.street}, ${p.zip} ${
            p.location
        }`;

    return (
        <Container sx={{backgroundColor: bgColor, height: '100%', pb: 7}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    {projects &&
                        (projects.data.length === 0 ? (
                            <EmptyState />
                        ) : (
                            projects.data.map((p) => (
                                <DocumentGroup
                                    key={p.projectId}
                                    project={p}
                                    groupTitle={getProjectTitle(p)}
                                    dispatch={dispatch}
                                />
                            ))
                        ))}
                    <LoaderCenterBlur open={loadingProjects} />
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
