import {useQuery} from '@apollo/client';
import {Box, Container, Typography, useTheme} from '@mui/material';
import {StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getNorwegianDateString} from 'toolbox';
import {inquiryBasePath} from '../../App';
import {CMS_EmptyProjectsState, GET_EMPTY_PROJECTS_STATE} from '../../graphql';
import {deconstructHTML} from '../../graphql/helpers';
import {useLanguage} from '../../language/LanguageProvider';
import {OfferDecisionStatus} from '../../lib/dtos/Offer';
import {ProjectSimpleDto, ProjectStatus} from '../../lib/dtos/Project';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {OfferService} from '../../lib/services/OfferService';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {loadOfferDetails, loadOffers} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {CenterContent} from '../AppLayout';
import {ButtonTertiaryLarge, ButtonUnderlined} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {LoaderCenterBlur} from '../Loader';
import {NotificationTile} from '../NotificationTile';
import {ProjectTile} from '../ProjectTile';
import {ArchivedProjects} from './ArchivedProjects';
import {offerStatus} from './ProjectOfferStatusBanner';
import {getServiceText} from './serviceText';
import {StatusUpdateFeedback} from './StatusUpdateFeedback';

const EmptyState: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const {loading, data} = useQuery<CMS_EmptyProjectsState>(GET_EMPTY_PROJECTS_STATE);

    const handleInquiryBtnClick = () => {
        history.push(inquiryBasePath);
    };
    if (data) {
        const content = data.globalSet.globalProjectsEmptyState[0];
        const primaryButton = deconstructHTML(content.primaryButton);
        const secondaryButton = deconstructHTML(content.secondaryButton);

        return (
            <CenterContent>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        backgroundImage: `url(${content.backgroundImage[0].url})`,
                        backgroundBlendMode: 'overlay',
                        backgroundSize: 'cover',
                        textAlign: 'center',
                        padding: 14,
                        pb: 10,
                        marginBottom: 6,
                        [theme.breakpoints.down('sm')]: {
                            px: 4,
                            marginTop: 6,
                        },
                    }}
                >
                    <Typography variant={'body1'} sx={{color: theme.palette.tertiary.main, mb: 3}}>
                        {content.overline}
                    </Typography>
                    <Typography variant={'h1_hero'} textAlign={'center'}>
                        {content.heading}
                    </Typography>
                    <Box
                        marginTop={10}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        sx={{
                            marginTop: 10,
                            [theme.breakpoints.down('sm')]: {
                                marginTop: 6,
                            },
                        }}
                    >
                        <ButtonTertiaryLarge onClick={handleInquiryBtnClick}>{primaryButton?.text}</ButtonTertiaryLarge>
                        {/*TODO: add link to projects page*/}
                        <ButtonUnderlined sx={{margin: 3, color: theme.palette.tertiary.main}}>
                            {secondaryButton?.text}
                        </ButtonUnderlined>
                    </Box>
                </Box>
            </CenterContent>
        );
    }
    return <CenterContent>Her kommer dine prosjekter</CenterContent>;
};

const ProjectsContent: React.FC<{
    projects: ProjectSimpleDto[];
    availableServices: ServiceDictionary | undefined;
    offerNodes: ReactNode[];
    statusNodes: ReactNode[];
}> = ({projects, availableServices, offerNodes, statusNodes}) => {
    const projectsWithOrder = projects.filter((project) => project.order);
    const {getLangString} = useLanguage();

    return (
        <DefaultGridPageLayout sx={{overflow: 'hidden'}}>
            <Box marginBottom={10}>
                <Typography variant={'h6'}>{getLangString('NOTIFICATIONS')}</Typography>
                {offerNodes.length || statusNodes.length ? (
                    [...offerNodes, ...statusNodes]
                ) : (
                    <Typography>{getLangString('NO_NEW_MESSAGES')}</Typography>
                )}
            </Box>
            <Box marginBottom={10}>
                <Typography variant={'h6'}>{getLangString('ACTIVE_PROJECTS')}</Typography>
                {projectsWithOrder.length > 0 ? (
                    projectsWithOrder.map((project) => (
                        <ProjectTile key={project.projectId} project={project} availableServices={availableServices} />
                    ))
                ) : (
                    <Typography>{getLangString('NO_ACTIVE_PROJECTS')}</Typography>
                )}
            </Box>
        </DefaultGridPageLayout>
    );
};
export const MyProjectsPage: React.FC = () => {
    const [{data: projects, loading: loadingProjects}, getProjects] = useM2Axios<M2Response<ProjectSimpleDto[]>>('', {
        manual: true,
    });

    const {dispatch, state, bgColor} = useContext(AppContext);
    const [statusNodes, setStatusMessageNodes] = useState<ReactNode[]>([]);
    const [offerNodes, setOfferNodes] = useState<ReactNode[]>([]);
    const [{data: statuses}, getStatuses] = useM2Axios<M2Response<StatusUpdateDto[]>>(
        {url: ApiEndpoints.statusUpdate.base(), method: 'GET'},
        {manual: false},
    );
    const [loadingOffers, setLoadingOffers] = useState<boolean>();

    React.useEffect(() => {
        const service = new OfferService(M2HttpClient);
        const fetchOffers = async () => {
            try {
                setLoadingOffers(true);
                const response = await service.getOffers(1, 50);
                dispatch(loadOffers(response.data, response.pagination));
                response.data.forEach(async (offer) => {
                    const response = await service.getOfferById(offer.offerId);
                    dispatch(loadOfferDetails(response.data));
                });
                setLoadingOffers(false);
            } catch (e) {
                setLoadingOffers(false);
                return;
            }
        };
        fetchOffers();
    }, [dispatch]);

    const handleStatusSent = async (statusId: number) => {
        await getStatuses();
    };

    useEffect(() => {
        const nodes: ReactNode[] = [];
        if (statuses?.data?.length) {
            nodes.push(
                statuses.data
                    ?.sort((a, b) => b.statusUpdateId - a.statusUpdateId)
                    .map((s) => {
                        return (
                            <StatusUpdateFeedback key={s.statusUpdateId} status={s} onStatusSent={handleStatusSent} />
                        );
                    }),
            );
        }
        setStatusMessageNodes(nodes);
    }, [statuses]);

    useEffect(() => {
        let nodes: ReactNode[] = [];
        if (state.offers?.length) {
            const filteredOffers = state.offers.filter(
                (o) => o.status !== OfferDecisionStatus.Open && o.status !== OfferDecisionStatus.Accepted,
            );
            nodes = filteredOffers.reverse().map((o) => {
                const offerData = state.offerDetails && state.offerDetails[o.offerId];
                const serviceText =
                    offerData && state.allServices && getServiceText(offerData.project.services, state.allServices);
                return (
                    <NotificationTile
                        key={o.offerId}
                        projectID={o.projectId}
                        linkID={o.offerId}
                        overlineText={offerStatus[o.status].text}
                        statusColor={offerStatus[o.status].color}
                        notificationType={'offer'}
                        title={'Tilbud - ' + getNorwegianDateString(o.dateCreated)}
                        subTitleL1={serviceText || ''}
                        subTitleL2={o.title}
                        buttonText={'Se over tilbud'}
                    />
                );
            });
        }
        setOfferNodes(nodes);
    }, [state.offers, state.allServices, state.offerDetails]);

    React.useEffect(() => {
        getProjects({url: ApiEndpoints.project.base()});
    }, []);
    const activeProjects = projects?.data.filter(
        (p) => p.projectStatus !== ProjectStatus.Finished && p.projectStatus !== ProjectStatus.Canceled,
    );
    const archivedProjects = projects?.data.filter((p) => p.projectStatus === ProjectStatus.Finished);

    return (
        <Box mt={2}>
            <Container sx={{backgroundColor: bgColor, height: '100%', pb: 8}}>
                <CssGrid>
                    {activeProjects ? (
                        <ProjectsContent
                            projects={activeProjects}
                            availableServices={state.allServices}
                            offerNodes={offerNodes}
                            statusNodes={statusNodes}
                        />
                    ) : (
                        !loadingOffers &&
                        !loadingProjects && (
                            <Box sx={{gridColumn: {xs: 'span 8', md: '2 / span 6'}}}>
                                <EmptyState />
                            </Box>
                        )
                    )}
                    <LoaderCenterBlur open={loadingOffers || loadingProjects} />
                </CssGrid>
            </Container>
            {archivedProjects && archivedProjects.length > 0 && (
                <ArchivedProjects projects={archivedProjects} services={state.allServices} />
            )}
        </Box>
    );
};
