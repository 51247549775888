import {ArrowForward} from '@mui/icons-material';
import {CircularProgress, TextField, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridOverlay, GridRenderCellParams} from '@mui/x-data-grid';
import {ButtonSquareBordered} from 'components/Buttons';
import {LoaderCenterBlur} from 'components/Loader';
import {useLanguage} from 'language/LanguageProvider';
import {CordelCompanyContactDto, CordelCompanyDto} from 'lib/dtos/Cordel';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {useSearch} from 'lib/Search';
import {nbNO} from 'lib/services/nbNO';
import {Reducer, useContext, useEffect, useReducer, useState, VFC} from 'react';
import {useHistory} from 'react-router-dom';
import {AdminAppContext} from 'store/adminAppContext';
import {AdminPageLayout} from '../AdminPageLayout';
import {CompanyDialog, CompanyDialogFormProps} from './CompanyDialog';
import {GridActionType, GridPaginationReducer, GridState} from './GridPaginationReducer';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Bedriftsnavn',
        flex: 1,
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
    },
    {
        field: 'contacts',
        headerName: 'Kontaktperson',
        flex: 1,
        renderCell: (params: GridRenderCellParams<CordelCompanyContactDto[]>) => {
            if (params?.value?.length) {
                const c = params?.value[0];
                return <div>{`${c.firstName} ${c.lastName}`}</div>;
            } else {
                return '';
            }
        },
    },
    {
        field: 'go',
        disableColumnMenu: true,
        sortable: false,
        type: 'actions',
        renderCell: () => <ArrowForward style={{cursor: 'pointer'}} />,
    },
    {
        field: ' ',
        disableColumnMenu: true,
        sortable: false,
        type: 'action',
        renderCell: () => <span style={{cursor: 'pointer'}}>Endre / Slett</span>,
    },
];

export const gridDefaultState = {
    currentPage: 1,
    maxVisitedPage: 1,
    rowsPerPage: 10,
    loadOffset: 50,
    cachedData: 0,
    totalData: 0,
    shouldFetch: true,
    gridData: new Array<CordelCompanyDto>(),
};

export const CompanyPage: VFC = () => {
    const {getLangString} = useLanguage();
    const [state, dispatch] = useReducer<Reducer<GridState<CordelCompanyDto>, GridActionType<CordelCompanyDto>>>(
        GridPaginationReducer,
        gridDefaultState,
    );

    const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
    const [formInitialData, setFormInitialData] = useState<CompanyDialogFormProps | undefined>(undefined);
    const [filteredCompanies, setFilteredCompanies] = useState<CordelCompanyDto[]>([]);
    const history = useHistory();
    const {search, setSearchData} = useSearch<CordelCompanyDto>([
        'name',
        'type',
        'contacts.?.lastName',
        'contacts.?.firstName',
    ]);
    const {bgColor} = useContext(AdminAppContext);

    const [{loading: isFetchingCompanies}, getCompanies] = useM2Axios<M2Response<CordelCompanyDto[]>>(
        {url: ApiEndpoints.company.base()},
        {manual: true},
    );

    useEffect(() => {
        const params = {size: state.loadOffset, page: state.currentPage};
        if (!state.shouldFetch) return;
        async function name() {
            try {
                const response = await getCompanies({params});
                dispatch({type: 'ADD_DATA', payload: {data: response.data.data, pagination: response.data.pagination}});
            } catch (error) {
                //
            }
        }
        name();
    }, [getCompanies, state.shouldFetch, state.currentPage, state.loadOffset, state.rowsPerPage]);

    const [{loading: isFetchingCompany}, getCompany] = useM2Axios<M2Response<CordelCompanyDto>>('', {
        manual: true,
    });

    const onCompanyDialogClose = async () => {
        setCompanyDialogOpen(false);
        setFormInitialData(undefined);
    };

    const onCompanyDialogSubmit = async (reloadData: boolean) => {
        if (reloadData) {
            dispatch({type: 'RESET'});
        }
    };

    const loadCompany = async (companyId: number, reload: boolean) => {
        try {
            const {data} = await getCompany({url: ApiEndpoints.company.byId(companyId), method: 'GET'});
            setFormInitialData({isNew: false, data: data.data});
            if (reload) dispatch({type: 'RESET'});
        } catch (error) {
            /** */
        }
    };

    const goToCompany = (companyId: number) => {
        history.push(`/admin/companies/${companyId}`);
    };

    const createNewCompany = () => {
        setFormInitialData({isNew: true, data: undefined});
        setCompanyDialogOpen(true);
    };

    useEffect(() => {
        if (state.gridData) {
            setSearchData(state.gridData);
            setFilteredCompanies(state.gridData);
        }
    }, [state.gridData, setSearchData]);

    const onPageChange = (page: number) => {
        dispatch({type: 'SET_PAGE', payload: {data: page}});
    };
    const onPageSizeChange = (size: number) => {
        dispatch({type: 'SET_ROWS', payload: {data: size}});
    };

    return (
        <AdminPageLayout>
            <LoaderCenterBlur open={false} />
            <Typography variant={'h6'} mt={7} mb={3}>
                {getLangString('COMPANIES')}
            </Typography>
            <ButtonSquareBordered onClick={createNewCompany} sx={{minWidth: 108, mb: 1, display: 'block'}}>
                + {getLangString('ADD_COMPANY')}
            </ButtonSquareBordered>
            <TextField
                sx={{mb: 4}}
                size='small'
                placeholder='Søk her...'
                onChange={(e) => setFilteredCompanies(search(e.target.value))}
            />
            <DataGrid
                localeText={nbNO}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                pageSize={state.rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                onPageChange={(page) => onPageChange(page + 1)}
                onPageSizeChange={(size) => onPageSizeChange(size)}
                rowCount={state.totalData}
                loading={isFetchingCompanies}
                page={state.currentPage - 1}
                components={{
                    NoRowsOverlay: () => (
                        <GridOverlay style={{backgroundColor: bgColor}}>
                            <Typography>{getLangString('NO_DATA')}</Typography>
                        </GridOverlay>
                    ),
                    LoadingOverlay: () => (
                        <GridOverlay style={{backgroundColor: bgColor}}>
                            <CircularProgress />
                        </GridOverlay>
                    ),
                }}
                onCellClick={async (c) => {
                    if (c.field !== ' ') {
                        goToCompany(c.row.cordelCompanyId);
                    } else {
                        await loadCompany(c.row.cordelCompanyId, false);
                        setCompanyDialogOpen(true);
                    }
                }}
                rows={filteredCompanies.map((c) => {
                    return {...c, id: c.cordelCompanyId};
                })}
            />
            <LoaderCenterBlur open={isFetchingCompany} />
            {companyDialogOpen && (
                <CompanyDialog
                    open={companyDialogOpen}
                    onClose={onCompanyDialogClose}
                    onSubmit={onCompanyDialogSubmit}
                    onSaved={(id) => loadCompany(id, true)}
                    initialValues={formInitialData}
                />
            )}
        </AdminPageLayout>
    );
};
