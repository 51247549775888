import {Box, Switch, Typography, useMediaQuery, useTheme} from '@mui/material';
import {ChangeEvent, FC, useContext, useEffect, useState} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {ConsentGroupDto, CookieConsentDto, CookieGroup, CreateCookieConsentDto} from '../lib/dtos/Cookies';
import {useM2Axios} from '../lib/hooks/useM2Axios';
import {ApiEndpoints} from '../lib/remote/m2api/M2Endpoints';
import {setCookie} from '../toolbox';
import {ButtonPrimaryMedium, ButtonSecondaryMedium, ButtonUnderlined} from './Buttons';
import {CookieContext} from './CookieConsentProvider';
import {LoaderCentered} from './Loader';

const CookieOption: FC<{
    text: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    disabled?: boolean;
}> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.checked);
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(!isChecked);
        props.handleChange(e);
    };

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={1}>
            <Typography sx={{mr: 2}}>{props.text}</Typography>
            <Switch onChange={onChange} checked={isChecked} disabled={props.disabled} />
        </Box>
    );
};

export const CookieConsent: FC = () => {
    const {getLangString} = useLanguage();

    const {consentId, consentValues, availableGroups, updateConsentValues, showConsentBox, setShowConsentBox} =
        useContext(CookieContext);

    const cookieGroupName: Record<CookieGroup, string> = {
        necessary: getLangString('COOKIE_OPTION_NECESSARY'),
        statistics: getLangString('COOKIE_OPTION_ANALYTICS'),
        marketing: getLangString('COOKIE_OPTION_MARKETING'),
        functional: getLangString('COOKIE_OPTION_FUNCTIONAL'),
    };
    const [newConsentValues, setNewConsentValues] = useState<{[key: string]: boolean} | undefined>();
    const onlyNecessarySelected = () =>
        newConsentValues && Object.keys(newConsentValues).filter((group) => newConsentValues[group]).length === 1;

    useEffect(() => {
        if (consentValues) {
            setNewConsentValues(consentValues.reduce((acc, curr) => ({...acc, [curr.groupId]: curr.consent}), {}));
        }
    }, [consentValues]);

    const [{loading: postingConsent}, postConsent] = useM2Axios<{data: CookieConsentDto}, CreateCookieConsentDto>(
        {url: ApiEndpoints.cookies.base(), method: 'POST'},
        {manual: true},
    );
    const updateConsent = async (consents: ConsentGroupDto[]) => {
        const payload = consentId
            ? {consentId, cookieName: 'm2_cookie', consents}
            : {cookieName: 'm2_cookie', consents};
        const response = await postConsent({
            data: payload,
        });
        if (!consentId) {
            setCookie('m2_cookie', response.data.data.consentId);
        }
        updateConsentValues && updateConsentValues(consents);
    };

    const handleAcceptSelection = async () => {
        const selectedConsents = newConsentValues
            ? Object.keys(newConsentValues).map((groupId) => ({groupId, consent: newConsentValues[groupId]}))
            : [];
        await updateConsent(selectedConsents);
        setShowConsentBox(false);
    };
    const handleAcceptAll = async () => {
        const selectedConsents = newConsentValues
            ? Object.keys(newConsentValues).map((groupId) => ({groupId, consent: true}))
            : [];
        await updateConsent(selectedConsents);
        setShowConsentBox(false);
    };

    if (showConsentBox) {
        return (
            <Box
                sx={{
                    backgroundColor: 'customBackground.fair',
                    maxWidth: 500,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                    mx: {xs: 2, sm: 3, md: 6},
                    mb: {xs: 4, md: 8},
                    px: {xs: 3, md: 4},
                    py: {xs: 4, md: 5},
                }}
            >
                <Typography mb={3}>{getLangString('COOKIE_TEXT')}</Typography>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} columnGap={5} my={3}>
                    {newConsentValues &&
                        availableGroups
                            ?.sort((ga, gb) => (ga.order > gb.order ? 1 : -1))
                            .map((g) => (
                                <CookieOption
                                    key={g.cookieGroupId}
                                    text={cookieGroupName[g.handle]}
                                    handleChange={(e) => {
                                        setNewConsentValues({...newConsentValues, [g.cookieGroupId]: e.target.checked});
                                    }}
                                    checked={newConsentValues[g.cookieGroupId]}
                                    disabled={g.checkedByDefault}
                                />
                            ))}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <ButtonPrimaryMedium onClick={handleAcceptAll} sx={{minWidth: 'auto', flex: 1}}>
                        {getLangString('COOKIE_ACCEPT_BUTTON_TEXT')}
                    </ButtonPrimaryMedium>
                    <ButtonSecondaryMedium sx={{minWidth: 'auto', flex: 1, ml: 2}} onClick={handleAcceptSelection}>
                        {onlyNecessarySelected()
                            ? getLangString('COOKIE_REJECT_BUTTON_TEXT')
                            : getLangString('COOKIE_ACCEPT_SELECTION_BUTTON_TEXT')}
                    </ButtonSecondaryMedium>
                </Box>
                {postingConsent && <LoaderCentered background={'rgba(255,255,255,0.6)'} />}
            </Box>
        );
    } else {
        return null;
    }
};

// remove this when CookieConsent is put into use
export const CookieConsentOLD: FC = () => {
    const {getLangString} = useLanguage();
    const [showConsent, setShowConsent] = useState<boolean>(localStorage.getItem('M2_cc') !== '1');
    const handleClick = () => {
        setShowConsent(false);
        localStorage.setItem('M2_cc', '1');
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    if (showConsent) {
        return (
            <Box
                sx={{
                    backgroundColor: 'customBackground.fair',
                    maxWidth: 500,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                    mx: {xs: 2, sm: 3, md: 6},
                    mb: {xs: 4, md: 8},
                    px: {xs: 3, md: 4},
                    py: {xs: 4, md: 5},
                }}
            >
                <Typography mb={3}>{getLangString('COOKIE_TEXT__OLD')}</Typography>
                <Box display={'flex'} alignItems={'center'}>
                    <ButtonPrimaryMedium
                        onClick={handleClick}
                        sx={{[theme.breakpoints.down('sm')]: {minWidth: 'auto', flex: 1}}}
                    >
                        {getLangString('COOKIE_ACCEPT_BUTTON_TEXT__OLD')}
                    </ButtonPrimaryMedium>
                    {isSmallScreen ? (
                        <ButtonSecondaryMedium sx={{minWidth: 'auto', ml: 2}}>
                            {getLangString('READ_MORE')}
                        </ButtonSecondaryMedium>
                    ) : (
                        <ButtonUnderlined sx={{ml: 6, fontSize: '0.875rem'}} href='/personvernerklæring'>
                            {getLangString('READ_MORE')}
                        </ButtonUnderlined>
                    )}
                </Box>
            </Box>
        );
    }
    return null;
};
