import {FileDto} from './File';
import {InquiryNoRelationDto, InquirySimpleDto} from './Inquiry';
import {OfferSimpleDto} from './Offer';
import {OrderDto, OrderListDto} from './Order';
import {PaginationDto} from './Pagination';
import {StatusUpdateDto} from './StatusUpdates';
import {EmployeeBaseDto, UserDto, UserSimpleDto} from './User';

export enum ProjectStatus {
    New,
    InProgress,
    Started,
    Evaluation,
    Canceled,
    Finished,
}
export type Services = {
    service: number;
    tasks: number[];
};
export type ProjectBaseDto = {
    projectId: number;
    customer: UserSimpleDto;
    projectLeader: EmployeeBaseDto | undefined;
    fromDate: Date;
    toDate: Date;
    street: string;
    zip: string;
    location: string;
    services: Services[];
    projectStatus: ProjectStatus;
    createdAt: string;
    updatedAt: string;
};
export type ProjectSimpleDto = {
    projectId: number;
    customer: UserSimpleDto;
    street: string;
    zip: string;
    location: string;
    services: Services[];
    projectStatus: ProjectStatus;
    inquiry: InquiryNoRelationDto;
    offers: OfferSimpleDto[];
    order: OrderListDto;
    createdAt: string;
    updatedAt: string;
};
export type ProjectDto = {
    projectId: number;
    customer: UserDto;
    projectLeader: EmployeeBaseDto;
    street: string;
    zip: string;
    location: string;
    fromDate: Date;
    toDate: Date;
    services: Services[];
    projectStatus: ProjectStatus;
    inquiry: InquiryNoRelationDto;
    offers: OfferSimpleDto[];
    order: OrderDto;
    statusUpdates: StatusUpdateDto;
    createdAt: string;
    updatedAt: string;
};

export type ProjectExtendedDto = {
    projectId: number;
    customer: UserDto;
    street: string;
    zip: string;
    location: string;
    createdAt: string;
    updatedAt: string;
    inquiry: Omit<InquirySimpleDto, 'project'>;
    offers: OfferSimpleDto[];
};
export type ProjectsDto = {
    data: ProjectExtendedDto[];
    pagination: PaginationDto;
};

export type ProjectFilesDto = {
    contracts: FileDto[];
    archive: FileDto[];
    invoices: FileDto[];
    documentation: FileDto[];
};

export type UpdateProjectDto = {
    projectId: number;
    projectLeaderEmployeeId?: number;
    fromDate?: Date;
    toDate?: Date;
};
