import {Box, Link, Typography, useTheme} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../graphql/helpers';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';
import {ListItem} from './partials/List';

type Props = {
    id: number;
    heading: string;
    text: string;
    listHeading: string;
    partners: {
        id: string;
        title: string;
        linkTo: string;
        logo: {
            id: string;
            url: string;
        }[];
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Partners: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '2 / span 5',
                            },
                        }}
                    >
                        <Typography variant='sectionHeading'>{props.heading}</Typography>

                        <Box
                            sx={{
                                mt: 3,
                                [theme.breakpoints.up('md')]: {
                                    mt: 5,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    '& a': {
                                        color: 'text.primary',
                                    },
                                }}
                                variant='sectionIntro'
                                dangerouslySetInnerHTML={{__html: props.text}}
                            />
                        </Box>
                    </Box>
                </CssGrid>

                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            mt: 3.5,
                            [theme.breakpoints.up('sm')]: {
                                gridColumn: '2 / span 6',
                            },
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 4',
                                mt: 8,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                columnGap: '2rem',
                                rowGap: '1rem',
                            }}
                        >
                            {props.partners.map((partner) => {
                                const link = deconstructHTML(partner.linkTo);
                                const url = link ? link.url : '';
                                const content = partner.logo.length ? (
                                    <Link
                                        href={url}
                                        target='_blank'
                                        sx={{
                                            px: 4,
                                            py: 1.5,
                                            '& img': {
                                                display: 'block',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                objectPosition: 'center',
                                                filter: 'grayscale(1)',
                                                transition: 'all',
                                                transitionDuration: '250ms',
                                            },
                                            '&:hover img': {
                                                filter: 'grayscale(0)',
                                            },
                                        }}
                                    >
                                        <img src={partner.logo[0].url} alt='' />
                                    </Link>
                                ) : (
                                    <ListItem url={url} text={partner.title} />
                                );

                                return (
                                    <Box
                                        key={partner.id}
                                        sx={{
                                            width: '100%',
                                            aspectRatio: '4 / 3',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {content}
                                    </Box>
                                );
                            })}
                        </Box>
                        {/* <List heading={props.listHeading} columns={{sm: 2}}> */}
                        {/*     {props.partners.map((partner) => { */}
                        {/*         const link = deconstructHTML(partner.linkTo); */}
                        {/*         const url = link ? link.url : ''; */}
                        {/*         return <ListItem key={partner.id} url={url} text={partner.title} />; */}
                        {/*     })} */}
                        {/* </List> */}
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
