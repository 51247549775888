import {
    CordelCompanyContactDto,
    CordelCompanyDto,
    CordelsDto,
    CreateCordelCompanyContactDto,
    CreateCordelCompanyDto,
    UpdateCordelCompanyContactDto,
    UpdateCordelCompanyDto,
} from '../dtos/Cordel';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {ApiEndpoints} from '../remote/m2api/M2Endpoints';

/**
 * Responsible for performing tasks related to inquiries.
 * It can create, update, delete and get inquiries.
 */
export class CordelService {
    private apiCordelsPath = ApiEndpoints.cordel;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    /**
     * Tries to add a new company
     * @param company new company
     */
    public async createCompany(company: CreateCordelCompanyDto): Promise<unknown> {
        return await this._httpClient.post<unknown, CreateCordelCompanyDto>(this.apiCordelsPath.base(), company);
    }

    /**
     * @returns all cordel companies
     */
    public async getCordelCompanies(page: number, size: number): Promise<CordelsDto> {
        return await this._httpClient.get<CordelsDto>(`${this.apiCordelsPath.base()}?page=${page}&size=${size}`);
    }

    /**
     * Tries to get the company by the provided id.
     * @param companyId
     * @returns the company
     */
    public async getCordelCompanyById(companyId: number): Promise<{data: CordelCompanyDto}> {
        return await this._httpClient.get<{data: CordelCompanyDto}>(this.apiCordelsPath.byId(companyId));
    }

    /**
     * Tries to update the company with the provided id.
     * @param companyId
     * @param data
     */
    public async updateCordelCompany(companyId: number, data: UpdateCordelCompanyDto): Promise<unknown> {
        return await this._httpClient.put(this.apiCordelsPath.byId(companyId), data);
    }

    /**
     * Tries to add a new contact
     * @param companyId the id of the contact's company
     * @param contact the new contact
     */
    public async createCompanyContact(companyId: number, contact: CreateCordelCompanyContactDto): Promise<unknown> {
        return await this._httpClient.post<unknown, CreateCordelCompanyContactDto>(
            this.apiCordelsPath.contacts(companyId),
            contact,
        );
    }

    /**
     * Tries to get the company contact
     * @param companyId
     * @param contactId
     */
    public async getCompanyContactById(companyId: number, contactId: number): Promise<{data: CordelCompanyContactDto}> {
        return await this._httpClient.get<{data: CordelCompanyContactDto}>(
            this.apiCordelsPath.contactById(companyId, contactId),
        );
    }

    /**
     * Tries to update the company contact
     * @param companyId
     * @param contactId
     * @param data
     */
    public async updateCompanyContactById(
        companyId: number,
        contactId: number,
        data: UpdateCordelCompanyContactDto,
    ): Promise<unknown> {
        return await this._httpClient.put(this.apiCordelsPath.contactById(companyId, contactId), data);
    }

    /**
     * Tries to delete the company contact
     * @param companyId
     * @param contactId
     */
    public async deleteCompanyContactById(companyId: number, contactId: number): Promise<unknown> {
        return await this._httpClient.delete(this.apiCordelsPath.contactById(companyId, contactId));
    }
}
