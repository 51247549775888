import {Box, Container, Theme, Typography, useMediaQuery} from '@mui/material';
import React, {Dispatch, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {InvoiceDto, ProjectInvoiceDto} from '../../lib/dtos/Invoice';
import {ProjectDto, ProjectFilesDto} from '../../lib/dtos/Project';
import {StatusUpdateDto} from '../../lib/dtos/StatusUpdates';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {M2Response} from '../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {ActionType} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {getNorwegianDateString} from '../../toolbox';
import {ProjectPageMenu} from '../AppLayout';
import {CssGrid} from '../Layout';
import {LoaderCentered} from '../Loader';
import {PhasesWithSlideButtons, ProjectPhaseMobile} from '../ProjectPhase';
import {Documents} from './Documents';
import {GeneralProjectInfo} from './GeneralProjectInfo';
import {Invoices} from './Invoices';
import {getCurrentPhase} from './phases';
import {ProjectPhotoSlider} from './ProjectPhotoSlider';
import {getServiceText} from './serviceText';
import {StatusUpdate} from './StatusUpdate';
import {StatusUpdateFeedback} from './StatusUpdateFeedback';

export const ProjectPage: React.FC = () => {
    const {bgColor, state, dispatch} = useContext(AppContext);
    const params = useParams<{projectID: string}>();
    const projectID = parseInt(params.projectID);

    const [{data: project}, getProject] = useM2Axios<M2Response<ProjectDto>>('', {
        manual: true,
    });
    const [{data: projectFiles, loading: loadingFiles}, getProjectFiles] = useM2Axios<M2Response<ProjectFilesDto>>('', {
        manual: true,
    });
    React.useEffect(() => {
        getProject({url: ApiEndpoints.project.byId(projectID)});
        getProjectFiles({url: `${ApiEndpoints.project.byId(projectID)}/files`});
    }, [projectID]);

    const [{data: projectInvoices, loading}, getInvoices] = useM2Axios<{data: ProjectInvoiceDto[]}>('', {manual: true});

    useEffect(() => {
        if (state.user) {
            getInvoices({url: `${ApiEndpoints.customer.byId(state.user.id)}/invoices`});
        }
    }, [getInvoices, state.user]);
    const getInvoicesForProject = (): InvoiceDto[] => {
        if (projectInvoices && project) {
            const invoicesForProject = projectInvoices.data.filter(
                (pInvoice) => pInvoice.project.projectId === project.data.projectId,
            );
            return invoicesForProject.reduce((acc: InvoiceDto[], curr) => [...acc, ...curr.invoices], []);
        }
        return [];
    };
    return (
        <Box sx={{overflow: 'hidden'}}>
            <Container sx={{backgroundColor: bgColor, pb: 7}}>
                <CssGrid>
                    <ProjectPageMenu />
                    {!project ? (
                        <LoaderCentered />
                    ) : (
                        <Content
                            project={project.data}
                            projectFiles={projectFiles?.data}
                            loadingFiles={loadingFiles}
                            services={state.allServices}
                            invoices={getInvoicesForProject()}
                            dispatch={dispatch}
                        />
                    )}
                </CssGrid>
            </Container>
        </Box>
    );
};

type ContentProps = {
    project: ProjectDto;
    loadingFiles: boolean;
    projectFiles?: ProjectFilesDto;
    services?: ServiceDictionary;
    invoices: InvoiceDto[];
    dispatch: Dispatch<ActionType>;
};
const Content: React.FC<ContentProps> = ({project, loadingFiles, projectFiles, services, invoices, dispatch}) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const {street, zip, location} = project;
    const address = `${street}, ${zip} ${location}`;
    const currentPhase = getCurrentPhase(project.order.phases);
    const {getLangString} = useLanguage();

    const contact = project.projectLeader ? `${project.projectLeader.firstName} ${project.projectLeader.lastName}` : '';
    const endDate = project.toDate ? getNorwegianDateString(project.toDate) : '';

    const [{data: inspectionPhotos, loading: loadingPhotos}] = useM2Axios<M2Response<FileDto[]>>({
        url: `${ApiEndpoints.project.byId(project.projectId)}/images/inspection`,
    });

    const [{data: statuses}, getStatuses] = useM2Axios<M2Response<StatusUpdateDto[]>>({
        url: ApiEndpoints.project.statuses(project.projectId),
        params: {includeAnswered: 'true', includeRead: 'true'},
    });
    const handleStatusSent = async () => {
        await getStatuses();
    };
    return (
        <>
            <GeneralProjectInfo
                overlineTitle={getLangString('PROJECT_STATE_ONGOING')}
                titleL1={services ? getServiceText(project.services, services) : ''}
                titleL2={address}
                details={{
                    project: address,
                    contact,
                    refNumber: '#' + project.projectId,
                    endDate,
                }}
            />
            <Box sx={{gridColumn: {xs: 'span 8', md: '2 / span 6'}}}>
                {statuses &&
                    statuses.data
                        ?.filter((s) => !s.read)
                        .sort((a, b) => b.statusUpdateId - a.statusUpdateId)
                        .map((s) => {
                            return (
                                <StatusUpdateFeedback
                                    key={s.statusUpdateId}
                                    status={s}
                                    onStatusSent={handleStatusSent}
                                />
                            );
                        })}
            </Box>
            <Box
                sx={{
                    gridColumn: {xs: 'span 8', md: '3 / span 5'},
                    mt: 5,
                }}
            >
                <Box mb={10}>
                    {isSmallScreen ? (
                        <Box>
                            <Typography variant={'h6'} mb={2}>
                                {getLangString('PROJECT_PHASES_STATE')}
                            </Typography>
                            {project.order.phases.map((phase, i) => (
                                <ProjectPhaseMobile
                                    key={i}
                                    title={phase.title}
                                    description={phase.description}
                                    isCurrent={phase.phaseId === currentPhase}
                                />
                            ))}
                        </Box>
                    ) : (
                        <PhasesWithSlideButtons
                            phases={project.order.phases}
                            current={currentPhase}
                            headerText={getLangString('PROJECT_PHASES_STATE')}
                        />
                    )}
                </Box>
                <Box mb={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('CONTRACTS')}
                    </Typography>
                    <Documents files={projectFiles?.contracts || []} loadingFiles={loadingFiles} />
                </Box>
                <Box mb={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('INVOICES')}
                    </Typography>
                    <Invoices invoices={invoices} />
                </Box>
            </Box>
            {inspectionPhotos?.data.length ? (
                <ProjectPhotoSlider photos={inspectionPhotos.data} headerText={getLangString('PHOTOS_INSPECTION')} />
            ) : undefined}
            <Box sx={{gridColumn: {xs: 'span 8', md: '3 / span 5'}}}>
                <Box mb={10}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('DOCUMENTATION')}
                    </Typography>
                    <Documents files={projectFiles?.documentation || []} loadingFiles={loadingFiles} />
                </Box>
                <Box>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('STATUS_UPDATES')}
                    </Typography>
                    <Box>
                        {statuses ? (
                            statuses.data
                                ?.sort((a, b) => b.statusUpdateId - a.statusUpdateId)
                                .map((s, i) => (
                                    <StatusUpdate key={s.statusUpdateId} statusUpdate={s} startExpanded={i === 0} />
                                ))
                        ) : (
                            <Typography>{getLangString('NO_STATUS_UPDATES')}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
