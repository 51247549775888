import {Box, Grid, TextField, Typography} from '@mui/material';
import {NotificationTile} from 'components/NotificationTile';
import {PhotoAttachment} from 'components/PhotoAttachment';
import {PhotoUploader} from 'components/PhotoUploader';
import {useLanguage} from 'language/LanguageProvider';
import {StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {ProblemDetailsError} from 'lib/remote/ProblemDetails';
import {FileDict} from 'lib/types/Inquiry';
import React, {ChangeEvent, useState} from 'react';
import {getNorwegianDateString} from 'toolbox';

export type StatusUpdateFeedbackProps = {
    status: StatusUpdateDto;
    onStatusSent: (statusId: number) => void;
};

/**
 * Component for displaying status updates for a customer, and be able to answer them.
 */
export const StatusUpdateFeedback: React.FC<StatusUpdateFeedbackProps> = ({status, onStatusSent}) => {
    const {getLangString} = useLanguage();
    const [answered, setAnswered] = useState(false);
    const [description, setDescription] = useState('');
    const [selectedFiles, setSelectedFiles] = useState<FileDict<File> | undefined>();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const [{error: sendError, loading: sending}, sendStatus] = useM2Axios<M2Response<any>, any, ProblemDetailsError>(
        {url: ApiEndpoints.statusUpdate.byId(status.statusUpdateId), method: 'POST'},
        {manual: true},
    );

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.files &&
            Array.from(e.target.files).forEach((file, i) => {
                const timeStamp = Date.now();
                setSelectedFiles((prev) => ({
                    ...prev,
                    [i + '_' + timeStamp]: file,
                }));
            });
    };
    const removeFile = (id: string) => {
        if (selectedFiles) {
            delete selectedFiles[id];
            setSelectedFiles({...selectedFiles});
        }
    };

    const doSendStatusResponse = async (files?: FileDict<File>, description?: string) => {
        const formData: FormData = new FormData();
        formData.append('answer', JSON.stringify({answer: description}));
        if (files) {
            const filesArray = Object.values(files);
            for (const file of filesArray) {
                formData.append('images', file);
            }
        }
        try {
            await sendStatus({
                data: formData,
            });
            setAnswered(true);
            // Wait some time before notification is sent that we are done
            setTimeout(() => {
                onStatusSent(status.statusUpdateId);
            }, 1000);
        } catch (error) {
            // errors are handled with useM2Axios
        }
    };

    return (
        <NotificationTile
            key={status.statusUpdateId}
            projectID={status.project.projectId}
            linkID={status.statusUpdateId}
            notificationType={'statusUpdate'}
            overlineText={getLangString('NOTIFICATION_HEADER_STATUSUPDATE')}
            title={'Status - ' + getNorwegianDateString(new Date(status.createdAt))}
            subTitleL1=''
            subTitleL2=''
            text={status.statusDescription}
            buttonText={getLangString('SEND_STATUS_RESPONSE')}
            onButtonClick={() => doSendStatusResponse(selectedFiles, description)}
            buttonDisabled={sending}
            notificationDisabled={sending}
            hidden={answered}
            onClose={doSendStatusResponse}
        >
            <Grid container rowSpacing={5} position={'relative'}>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant={'subtitle1'}>{getLangString('STATUS_UPDATE_FEEDBACK')}</Typography>
                    <TextField
                        multiline
                        fullWidth={true}
                        minRows={5}
                        placeholder={
                            'Eks. legg til bestilling, oppdaget feil på montering, eller andre kommentarer til prosjektet så langt.'
                        }
                        value={description}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant={'subtitle1'}>{getLangString('PHOTO_ATTACHMENTS_HEADER')}</Typography>
                    <Box display={'flex'} flexWrap={'wrap'}>
                        {selectedFiles &&
                            Object.keys(selectedFiles).map((f, i) => (
                                <PhotoAttachment
                                    key={i}
                                    id={f}
                                    handleOnClick={removeFile}
                                    photoUrl={URL.createObjectURL(selectedFiles[f])}
                                    enableLargeView={false}
                                    text={'Fjern bilde'}
                                />
                            ))}
                        <PhotoUploader handleFileChange={handleFileChange} />
                    </Box>
                </Grid>
            </Grid>
            {sendError && (
                <Typography color='red' variant={'caption'}>
                    Klarte ikke å sende tilbakemelding, prøv igjen.
                </Typography>
            )}
        </NotificationTile>
    );
};
