import {Box, Container, IconButton, styled, Theme, Typography, useMediaQuery} from '@mui/material';
import React, {useContext} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {myPagesBasePath} from '../App';
import {useLanguage} from '../language/LanguageProvider';
import {toggleDisplayLargePhoto} from '../store/actions';
import {AppContext} from '../store/appContext';
import {useIsSmallScreen} from '../toolbox';
import {Footer} from './Footer';
import {ArrowBackward} from './icons/ArrowBackward';
import {LargePhotoViewer} from './LargePhotoViewer';
import {CssGrid} from './Layout';
import {NavBar} from './NavBar';
import {CustomNavLink, SubMenuLargerScreens, SubMenuMobile} from './Navigation';

export const AppLayout: React.FC = ({children}) => {
    const {bgColor, state, dispatch} = useContext(AppContext);
    return (
        <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} sx={{backgroundColor: bgColor}}>
            <NavBar bgColor={bgColor} />
            <Box sx={{flex: 1, display: 'flex', alignItems: 'stretch'}}>{children}</Box>
            <Footer />
            {state.displayLargePhoto && (
                <LargePhotoViewer
                    photo={state.displayLargePhoto}
                    onClose={() => dispatch(toggleDisplayLargePhoto(undefined))}
                />
            )}
        </Box>
    );
};

export const CenterContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
});

const SubMenuContent: React.FC<{url: string}> = ({url}) => {
    const {getLangString} = useLanguage();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: {
                    sm: 'space-between',
                    xs: 'flex-start',
                },
                py: {sm: '30px', xs: 0},
                flexWrap: 'wrap',
            }}
        >
            <CustomNavLink exact={true} to={`${url}/prosjekter`}>
                {getLangString('MY_PROJECTS')}
            </CustomNavLink>
            <CustomNavLink exact={true} to={`${url}/faktura`}>
                {getLangString('INVOICES')}
            </CustomNavLink>
            <CustomNavLink exact={true} to={`${url}/dokumentasjon`}>
                {getLangString('DOCUMENTATION')}
            </CustomNavLink>
            <CustomNavLink exact={true} to={`${url}/kontoinnstillinger`}>
                {getLangString('ACCOUNT_SETTINGS')}
            </CustomNavLink>
            <CustomNavLink exact={true} to={`${url}/fordeler`}>
                {getLangString('MY_DISCOUNTS')}
            </CustomNavLink>
        </Box>
    );
};

export const PageWithSubmenu: React.FC = ({children}) => {
    const smallScreen = useIsSmallScreen();
    const {bgColor} = useContext(AppContext);
    const {url} = useRouteMatch();
    return (
        <>
            {smallScreen ? (
                <SubMenuMobile content={<SubMenuContent url={url} />} bgColor={bgColor} />
            ) : (
                <Container>
                    <CssGrid>
                        <SubMenuLargerScreens content={<SubMenuContent url={url} />} />
                    </CssGrid>
                </Container>
            )}
            {children}
        </>
    );
};

export const ProjectPageMenu: React.FC = () => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory();
    const {getLangString} = useLanguage();

    if (isSmallScreen) {
        return (
            <Box sx={{gridColumn: 'span 8', mt: 2, mb: 4}}>
                <IconButton onClick={history.goBack} sx={{px: 0}}>
                    <ArrowBackward size={16} />
                    <Typography ml={1}>{getLangString('MY_PROJECTS')}</Typography>
                </IconButton>
            </Box>
        );
    }
    return <SubMenuLargerScreens content={<SubMenuContent url={myPagesBasePath} />} />;
};
