import {Typography} from '@mui/material';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {useEffect, useState, VFC} from 'react';
import {OrderSimpleDto, OrderStatus} from '../../../../lib/dtos/Order';
import {useSearch} from '../../../../lib/Search';
import {OrderList} from '../../../../lib/types/Admin';
import {ServiceDictionary} from '../../../../lib/types/Inquiry';
import {SearchField} from '../../../SearchField';
import {getServiceText} from '../../serviceText';
import {getAddress} from '../getAddress';
import {OrdersDataGrid} from './OrdersDataGrid';

export const OrderTable: VFC<{
    onLoadingChange?: (isLoading: boolean) => void;
    title: string;
    services: ServiceDictionary | undefined;
    requestParams?: {employeeId?: number; status?: OrderStatus};
}> = ({onLoadingChange, requestParams, title, services}) => {
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const size = rowsPerPage * 2;
    const [orders, setOrders] = useState<OrderList[]>([]);
    const [page, setPage] = useState(1);
    const params = {status: requestParams?.status, employeeId: requestParams?.employeeId, size, page};
    const [{data: ordersResponse, loading}] = useM2Axios<M2Response<OrderSimpleDto[]>>({
        url: 'api/orders',
        params,
    });
    const {search, setSearchData} = useSearch<OrderList>();
    const [filteredOrders, setFilteredOrders] = useState<OrderList[]>([]);
    useEffect(() => {
        if (orders) {
            setSearchData(orders);
            setFilteredOrders(orders);
        }
    }, [orders, setSearchData]);
    useEffect(() => {
        if (ordersResponse) {
            const newlyLoadedOrders = ordersResponse.data.map(
                (order): OrderList => ({
                    orderId: order.orderId,
                    status: order.orderStatus,
                    projectId: order.project.projectId,
                    dateCreated: order.createdAt as unknown as Date,
                    title: `${services && getServiceText(order.project.services, services)}, ${getAddress(
                        order.project,
                    )}`,
                    projectLeader: order.project.projectLeader,
                }),
            );
            //
            const newIds = newlyLoadedOrders.map((v) => v.orderId);

            setOrders((prev) =>
                [...prev.filter((old) => !newIds.includes(old.orderId)), ...newlyLoadedOrders].sort(
                    (a, b) => a.orderId - b.orderId,
                ),
            );
        }
    }, [ordersResponse]);

    useEffect(() => {
        onLoadingChange?.(loading);
    }, [loading, onLoadingChange]);

    return (
        <>
            <Typography variant={'h5'} mt={7} mb={3}>
                {title}
            </Typography>
            <SearchField handleChange={(e) => setFilteredOrders(search(e.target.value))} />
            <OrdersDataGrid
                data={filteredOrders}
                pagination={ordersResponse?.pagination}
                rowsPerPage={rowsPerPage}
                onPageSizeChange={setRowsPerPage}
                onPageChange={(page) => setPage(page + 1)}
                loading={loading}
                minHeight={52 * Math.min(rowsPerPage, orders.length) + 56 + 64}
            />
        </>
    );
};
