import {Box, Skeleton, styled, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {getNorwegianDateString} from '../../toolbox';
import {DownloadFile} from '../DownloadFile';

const DocumentRow = styled(Box)(({theme}) => ({
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(4)} 0px`,
}));
export const Document: React.FC<{file: FileDto}> = ({file}) => {
    const theme = useTheme();
    const date = new Date(file.createdAt);
    return (
        <DocumentRow>
            {file.createdAt && (
                <Typography color={'text.secondary'} sx={{mr: 3, [theme.breakpoints.up('sm')]: {mr: 7}}}>
                    {getNorwegianDateString(date)}
                </Typography>
            )}
            <Box flex={1} sx={{mr: 2}}>
                {file.fileName}
            </Box>
            <Box alignSelf={'flex-end'}>
                <DownloadFile file={file} />
            </Box>
        </DocumentRow>
    );
};

export const Documents: React.FC<{files: FileDto[]; loadingFiles: boolean}> = ({files, loadingFiles}) => {
    const {getLangString} = useLanguage();
    if (loadingFiles) {
        return <Skeleton height={'56px'} />;
    } else if (files.length) {
        return (
            <>
                {files.map((doc) => (
                    <Document key={doc.id} file={doc} />
                ))}
            </>
        );
    }
    return <Typography>{getLangString('NO_DOCUMENTS')}</Typography>;
};
