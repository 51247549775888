import {ApolloProvider} from '@apollo/client';
import {Box} from '@mui/material';
import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {
    ARTICLES_ENTRY_PAGE_URL_FORMAT,
    ARTICLES_INDEX_PAGE_URL,
    CONTENT_PAGE_URL_FORMAT,
    PEOPLE_ENTRY_PAGE_URL_FORMAT,
    PEOPLE_INDEX_PAGE_URL,
    SERVICES_ENTRY_PAGE_URL_FORMAT,
    SERVICES_INDEX_PAGE_URL,
} from '../../cms/helpers';
import {apolloClient} from '../../graphql';
import {ArticleEntryPage} from './ArticleEntryPage';
import {ArticlesIndexPage} from './ArticlesIndexPage';
import {ContentPage} from './ContentPage';
import {Homepage} from './Homepage';
import {PeopleEntryPage} from './PeopleEntryPage';
import {PeopleIndexPage} from './PeopleIndexPage';
import {ServiceEntryPage} from './ServiceEntryPage';
import {ServicesIndexPage} from './ServicesIndexPage';

export const ContentPages: React.FC = () => {
    const {path} = useRouteMatch();

    return (
        <ApolloProvider client={apolloClient}>
            <Box width={'100%'}>
                <Switch>
                    <Route path={`${path}${PEOPLE_ENTRY_PAGE_URL_FORMAT}`} component={PeopleEntryPage} />
                    <Route exact path={`${path}${PEOPLE_INDEX_PAGE_URL}`} component={PeopleIndexPage} />
                    <Route path={`${path}${ARTICLES_ENTRY_PAGE_URL_FORMAT}`} component={ArticleEntryPage} />
                    <Route exact path={`${path}${ARTICLES_INDEX_PAGE_URL}`} component={ArticlesIndexPage} />
                    <Route path={`${path}${SERVICES_ENTRY_PAGE_URL_FORMAT}`} component={ServiceEntryPage} />
                    <Route exact path={`${path}${SERVICES_INDEX_PAGE_URL}`} component={ServicesIndexPage} />
                    <Route path={`${path}${CONTENT_PAGE_URL_FORMAT}`} component={ContentPage} />
                    <Route exact path={`${path}`} component={Homepage} />
                </Switch>
            </Box>
        </ApolloProvider>
    );
};
