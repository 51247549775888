import {useQuery} from '@apollo/client';
import {Box, Link, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {ARTICLES_INDEX_PAGE_URL, formatUrl, SERVICES_ENTRY_PAGE_URL_FORMAT} from '../../cms/helpers';
import {CMS_SEO, GET_ARTICLE_ENTRY_PAGE, GET_SEO_DATA} from '../../graphql';
import {useLanguage} from '../../language/LanguageProvider';
import {SEO} from '../../SEO';
import {PageContent} from '../cms/PageContent';
import {PageOverline} from '../cms/PageOverline';
import {ArticleSlider} from '../cms/partials/ArticleSlider';
import {NotFound} from '../errorPages/NotFound';
import {FacebookIcon} from '../icons/FacebookIcon';
import {PinterestIcon} from '../icons/PinterestIcon';
import {Container, CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';

export const ArticleEntryPage: React.FC = () => {
    const {getLangString} = useLanguage();
    const relatedArticlesHeading = getLangString('RELATED_ARTICLES');

    const theme = useTheme();
    const {slug} = useParams<{slug: string}>();
    const {loading, error, data} = useQuery(GET_ARTICLE_ENTRY_PAGE(slug));
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(`${ARTICLES_INDEX_PAGE_URL}/${slug}`));

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;

    if (!entry) {
        return <NotFound />;
    }

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                component='article'
                sx={{
                    pb: 12,
                    [theme.breakpoints.up('md')]: {
                        pb: 16,
                    },
                }}
            >
                <PageOverline {...{text: entry.articleCategories[0].title}} />

                <Container>
                    <CssGrid>
                        <Typography
                            sx={{
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '2 / span 5',
                                },
                            }}
                            component='h1'
                            variant='h1'
                        >
                            {entry.title}
                        </Typography>
                    </CssGrid>
                </Container>

                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                mt: 3.5,
                                gridColumn: 'span 8',
                                display: 'flex',
                                flexWrap: 'wrap',
                                [theme.breakpoints.up('md')]: {
                                    mt: 7,
                                    gridColumn: '2 / span 5',
                                },
                            }}
                        >
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {entry.relatedServices.map((service: any, index: number) => {
                                const isLast = index == entry.relatedServices.length - 1;

                                return (
                                    <Box key={service.id} sx={{[theme.breakpoints.up('md')]: {fontSize: '1.0625rem'}}}>
                                        <Link href={formatUrl(SERVICES_ENTRY_PAGE_URL_FORMAT, service.slug)}>
                                            {service.title}
                                        </Link>
                                        {!isLast && (
                                            <Box component='span' dangerouslySetInnerHTML={{__html: ',&nbsp;'}} />
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    </CssGrid>

                    <CssGrid>
                        <Box
                            sx={{
                                mt: 1.5,
                                gridColumn: 'span 8',
                                display: 'flex',
                                flexWrap: 'wrap',
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '1.0625rem',
                                    mt: 3,
                                    gridColumn: '2 / span 5',
                                },
                            }}
                        >
                            <Box sx={{mr: 2.5}}>Del:</Box>
                            <Box sx={{mr: 2.5}}>
                                <a
                                    href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <FacebookIcon />
                                </a>
                            </Box>
                            <Box>
                                <a
                                    href={`https://www.pinterest.com/pin/create/button/?url=${window.location.href}`}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <PinterestIcon />
                                </a>
                            </Box>
                        </Box>
                    </CssGrid>

                    <Box
                        sx={{
                            mt: 6,
                            [theme.breakpoints.up('md')]: {
                                mt: 8,
                            },
                        }}
                    >
                        <img style={{width: '100%', display: 'block'}} src={entry.mainImage[0].url} alt='' />
                    </Box>

                    <CssGrid>
                        <Box
                            sx={{
                                mt: 3.5,
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '3 / span 5',
                                    mt: 12,
                                },
                            }}
                        >
                            <Typography
                                component='div'
                                variant='articleIntro'
                                dangerouslySetInnerHTML={{__html: entry.introText}}
                            />
                        </Box>
                    </CssGrid>
                </Container>

                <Box>
                    <PageContent data={entry.pageContent} />
                </Box>

                {entry.relatedArticles.length > 0 && (
                    <Box
                        sx={{
                            mt: 12,
                            [theme.breakpoints.up('md')]: {
                                mt: 16,
                            },
                        }}
                    >
                        <ArticleSlider heading={relatedArticlesHeading} articles={entry.relatedArticles} />
                    </Box>
                )}
            </Box>
        </>
    );
};
