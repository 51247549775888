import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React, {ReactNode} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from './Buttons';
import {LoaderCentered} from './Loader';

export type AreYouSureDialogProps = {
    onConfirm: () => void;
    onCancel?: () => void;
    open: boolean;
    title: string;
    children?: ReactNode;
    progress?: boolean;
};

export const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({
    onConfirm,
    onCancel,
    open,
    children,
    title,
    progress,
}) => {
    const {getLangString} = useLanguage();
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            sx={{minWidth: 340}}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>{children}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{p: 3, pt: 0, button: {minWidth: 'auto'}}}>
                <ButtonSecondaryMedium onClick={onCancel}>{getLangString('CANCEL')}</ButtonSecondaryMedium>
                <ButtonPrimaryMedium onClick={onConfirm}>{getLangString('CONFIRM')}</ButtonPrimaryMedium>
            </DialogActions>
            {progress && <LoaderCentered background={'rgba(255,255,255,0.5)'} />}
        </Dialog>
    );
};
