import {TextField, TextFieldProps} from '@mui/material';
import React from 'react';

export const CustomInputField: React.FC<TextFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            sx={{
                p: 0,
                display: 'flex',
                mr: '-1px',
                mb: '-1px',
                'input, textarea': {
                    px: {xs: 2, sm: 3},
                    py: 3,
                },
                '&:hover': {
                    zIndex: 10,
                },
                '& .MuiFilledInput-root': {
                    p: 0,
                },
                '& .MuiFilledInput-root.Mui-disabled': {
                    backgroundColor: 'inherit',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                    color: 'rgba(18, 18, 18, 0.4)',
                    WebkitTextFillColor: 'rgba(18, 18, 18, 0.4)',
                },
                '& .Mui-error': {
                    borderColor: 'error.main',
                },
            }}
            InputProps={{disableUnderline: true}}
            variant={'filled'}
        />
    );
};
