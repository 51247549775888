import {Box, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {useLanguage} from '../../../../language/LanguageProvider';
import {FileDto} from '../../../../lib/dtos/File';
import {useM2Axios} from '../../../../lib/hooks/useM2Axios';
import {M2Response} from '../../../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {FileDict} from '../../../../lib/types/Inquiry';
import {ButtonPrimaryMedium} from '../../../Buttons';
import {InfoField} from '../../../InfoField';
import {UploadedFile} from '../../../PDFUploader';
import {getServiceTasksText} from '../../serviceText';
import {ContactInfo} from '../ContactInfo';
import {getAddress} from '../getAddress';
import {Header} from '../Header';
import {InspectionInformation} from '../orderPage/InfoBlock';
import {ProjectDescription} from '../ProjectDescription';
import {OfferContentProps} from './OfferDetailsPage';

export const OfferRejectedContent: React.FC<OfferContentProps> = (props) => {
    const {getLangString} = useLanguage();
    const {project, materialCost, workhourCost, totalMvaCost} = props.offerDetails;
    const address = getAddress(project);

    const uploadedFiles = (): {[id: number]: FileDict<FileDto & {cordelCompanyOfferId: number}>} => {
        if (props.offerDetails.cordelCompanyOffers) {
            return props.offerDetails.cordelCompanyOffers.reduce(
                (x, companyOffer) => ({
                    ...x,
                    [companyOffer.companyId]: companyOffer.files.reduce(
                        (acc, file) => ({
                            ...acc,
                            [file.id]: {...file, cordelCompanyOfferId: companyOffer.cordelCompanyOfferId},
                        }),
                        {},
                    ),
                }),
                {},
            );
        } else {
            return {};
        }
    };
    const [{data: inspectionPhotos}] = useM2Axios<M2Response<FileDto[]>>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}/images/inspection`},
        {
            manual: false,
        },
    );
    const serviceTaskText =
        props.availableServices && getServiceTasksText(props.offerDetails.project.services, props.availableServices);
    return (
        <>
            <Header
                id={project.projectId}
                customText={getLangString('NOT_ACCEPTED')}
                project={project}
                services={props.availableServices}
            />
            <Grid container columnSpacing={0}>
                <Grid item xs={8} sm={4}>
                    <Typography variant={'h6'} mb={2}>
                        {getLangString('PROJECT_LEADER')}
                    </Typography>
                    <InfoField>
                        {project.projectLeader?.firstName} {project.projectLeader?.lastName}
                    </InfoField>
                </Grid>
            </Grid>
            <Box my={4}>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('CONTACT_INFO')}
                </Typography>
                <ContactInfo
                    firstName={project.customer.firstName}
                    lastName={project.customer.lastName}
                    email={project.customer.email}
                    phoneNumber={project.customer.phoneNumber}
                    address={address}
                />
            </Box>
            <Box my={4}>
                <ProjectDescription
                    descriptionHeader={getLangString('INFO_TEXT_CUSTOMER')}
                    descriptionText={
                        props.offerDetails.descriptionCustomer && props.offerDetails.descriptionCustomer.text
                            ? props.offerDetails.descriptionCustomer.text
                            : getLangString('NO_DESCRIPTION')
                    }
                    serviceTaskText={serviceTaskText}
                    imageHeader={getLangString('ATTACHED_PHOTOS')}
                    images={
                        (props.offerDetails.descriptionCustomer && props.offerDetails.descriptionCustomer.images) || []
                    }
                />
            </Box>
            <Box my={4}>
                <InspectionInformation images={inspectionPhotos?.data || []} />
            </Box>
            <Box>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('COMPANY_OFFERS_HEADER')}
                </Typography>
                {props.inquiryCompanies.map((c) => (
                    <InfoField
                        key={c.cordelCompanyId}
                        sx={{
                            py: 2,
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'flex-start', sm: 'center'},
                            justifyContent: 'space-between',
                        }}
                    >
                        {c.name}
                        <Box sx={{textAlign: 'right'}}>
                            {uploadedFiles()[c.cordelCompanyId] !== undefined &&
                                Object.keys(uploadedFiles()[c.cordelCompanyId]).map((file) => {
                                    const uploadedFile = uploadedFiles()[c.cordelCompanyId][file];
                                    return (
                                        <UploadedFile
                                            key={uploadedFile.id}
                                            fileId={uploadedFile.id}
                                            fileName={uploadedFile.fileName}
                                        />
                                    );
                                })}
                        </Box>
                    </InfoField>
                ))}
            </Box>
            <Box my={4}>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('PRICE')}
                </Typography>
                <Grid container columnSpacing={0}>
                    <Grid item xs={8} sm={4}>
                        <InfoField>{(materialCost || 0).toLocaleString()} kr</InfoField>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <InfoField>{(workhourCost || 0).toLocaleString()} kr</InfoField>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <InfoField>{(totalMvaCost || 0).toLocaleString()} kr</InfoField>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <InfoField sx={{backgroundColor: 'rgba(18, 18, 18, 0.1)'}}>
                            <Typography sx={{fontWeight: 'bold'}}>
                                {getLangString('TOTAL')}{' '}
                                {(materialCost || 0) + (workhourCost || 0) + (totalMvaCost || 0)} kr
                            </Typography>
                        </InfoField>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('OFFER_DESCRIPTION_HEADER')}
                </Typography>
                <InfoField>{props.offerDetails.description}</InfoField>
            </Box>
            <Box mt={7} mb={4}>
                <Typography variant={'h6'} fontSize={'1.625rem'}>
                    {getLangString('OFFER_NOT_ACCEPTED')}
                </Typography>
                <Typography variant={'h6'} my={3}>
                    {getLangString('CUSTOMER_COMMENT')}
                </Typography>
                {props.offerDetails.offerDecisionReason ? (
                    <InfoField>{props.offerDetails.offerDecisionReason}</InfoField>
                ) : (
                    <Typography>{getLangString('NO_COMMENT')}</Typography>
                )}
            </Box>
            <Box my={7} sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
                <ButtonPrimaryMedium onClick={props.goToEditPage}>{getLangString('UPDATE_OFFER')}</ButtonPrimaryMedium>
            </Box>
        </>
    );
};
