import {Box, Grid, Typography} from '@mui/material';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from 'components/Buttons';
import {CustomInputField} from 'components/InputField';
import {useLanguage} from 'language/LanguageProvider';
import {CordelCompanyDto, CreateCordelCompanyContactDto, CreateCordelCompanyDto} from 'lib/dtos/Cordel';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {MixedRequestError, useProblemDetailsError} from 'lib/hooks/useProblemDetailsError';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {Address} from 'lib/types/Address';
import {
    maxLength,
    minLenth,
    validateAddress,
    validateIsEmail,
    validateIsRequired,
    validateIsRequiredConditional,
    validateNoSpecialChars,
    validateOrganizationNumber,
    validatePhoneNumberWithLandCode,
    validateWebUrl,
    validateZip,
} from 'lib/validationMessages';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

export type CompanyDialogProps = {
    initialValues: CompanyFormFields;
    isNew: boolean;
    onSubmit: (reload: boolean) => void;
    onSaved: (createdId: number) => void;
    onCancel: () => void;
    submitDisabled?: boolean;
    hasUnsavedChanges: (unsavedChanges: boolean) => void;
};

type EditContact = CreateCordelCompanyContactDto & {
    [key: string]: any;
    cordelCompanyContactId?: number;
};

export type CompanyFormFields = Address & {
    [key: string]: any;
    cordelCompanyId?: number;
    name: string;
    contact: EditContact;
    apiKey?: string;
    apiUrl: string;
    description: string;
    datawarehouseUrl: string;
    datawarehouseUsername?: string;
    datawarehousePassword?: string;
    organizationNumber: string;
    type: string;
};

type CordelCompanyCreated = {
    cordelCompanyId?: number;
};

export const CompanyDialogForm: React.VFC<CompanyDialogProps> = (props) => {
    const {getLangString} = useLanguage();
    const {translatedError, translate, clearTranslatedError} = useProblemDetailsError(
        {
            CORDEL_WAREHOUSE_BAD_CREDENTIALS: getLangString('CORDEL_WAREHOUSE_CREDENTIALS_ERROR'),
            CORDEL_INSTANCE_BAD_CREDENTIALS: getLangString('CORDEL_API_CREDENTIALS_ERROR'),
        },
        getLangString('FAILED_TO_SAVE'),
    );
    const {control, handleSubmit, trigger, reset, formState} = useForm<CompanyFormFields>({
        defaultValues: props.initialValues,
    });
    useEffect(() => {
        props.hasUnsavedChanges(formState.isDirty);
    }, [formState]);

    const [didSave, setDidSave] = useState<{error: string; success: boolean} | undefined>();

    const onCancel = () => {
        setDidSave(undefined);
        props.onCancel();
    };

    // HTTP client for posting/putting a company
    const [{loading: isSendingCompany}, sendCompany] = useM2Axios<CordelCompanyCreated>('', {
        manual: true,
    });

    useEffect(() => {
        reset({...props.initialValues});
    }, [reset, props.initialValues]);

    const doHandleSubmit = async (form: CompanyFormFields, isNew: boolean) => {
        setDidSave(undefined);
        clearTranslatedError();
        try {
            if (isNew) {
                /** */
                const newCompany: CreateCordelCompanyDto = {
                    ...form,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    apiKey: form.apiKey!,
                    organizationNumber: Number.parseInt(form.organizationNumber),
                    contacts: [
                        {
                            ...form.contact,
                            position: 'Kontakt',
                        },
                    ],
                };

                const response = await sendCompany({
                    url: ApiEndpoints.company.base(),
                    method: 'POST',
                    data: newCompany,
                });
                if (response.data.cordelCompanyId) {
                    props.onSaved(response.data.cordelCompanyId);
                } else {
                    onCancel();
                }
            } else {
                const newCompany: CordelCompanyDto = {
                    ...form,
                    organizationNumber: Number.parseInt(form.organizationNumber),
                    contacts: [
                        {
                            ...form.contact,
                            position: 'Kontakt',
                        } as any,
                    ],
                } as any;
                if (!form.cordelCompanyId) {
                    throw new Error('Missing company id');
                }
                await sendCompany({
                    url: ApiEndpoints.company.byId(form.cordelCompanyId),
                    method: 'PUT',
                    data: newCompany,
                });
            }
            props.onSubmit(true);
            if (form.cordelCompanyId) props.onSaved(form.cordelCompanyId);
            setDidSave({error: '', success: true});
        } catch (error) {
            translate(error as MixedRequestError);
            setDidSave({error: getLangString('FAILED_TO_SAVE'), success: false});
        }
    };

    return (
        <form onSubmit={handleSubmit((form) => doHandleSubmit(form, props.isNew))}>
            <Typography variant={'subtitle1'}>{getLangString('COMPANY_INFORMATION')}</Typography>
            <Grid container columnSpacing={0} marginBottom={3}>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name={'cordelCompanyId'}
                        control={control}
                        defaultValue={props.initialValues.cordelCompanyId}
                        render={({field}) => <input type={'hidden'} name={field.name} value={field.value} />}
                    />
                    <Controller
                        name={'name'}
                        rules={{
                            required: validateIsRequired(),
                            pattern: validateNoSpecialChars(),
                        }}
                        control={control}
                        defaultValue={props.initialValues.name}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('COMPANY_NAME') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name={'organizationNumber'}
                        rules={{required: validateIsRequired(), pattern: validateOrganizationNumber()}}
                        control={control}
                        defaultValue={props.initialValues.organizationNumber}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                type='number'
                                label={getLangString('ORGANIZATION_NUMBER') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='type'
                        rules={{required: validateIsRequired()}}
                        control={control}
                        defaultValue={props.initialValues.type}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('COMPANY_TYPE') + '*'}
                                placeholder={'Eks: Rørlegger'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='street'
                        rules={{
                            required: validateIsRequired(),
                            minLength: {
                                value: 2,
                                message: minLenth(2),
                            },
                            pattern: validateAddress(),
                        }}
                        control={control}
                        defaultValue={props.initialValues.street}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('ADDRESS') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='zip'
                        rules={{required: validateIsRequired(), pattern: validateZip()}}
                        control={control}
                        defaultValue={props.initialValues.zip}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('POSTAL_CODE')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='location'
                        rules={{required: validateIsRequired(), pattern: validateNoSpecialChars()}}
                        control={control}
                        defaultValue={props.initialValues.location}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('CITY')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Typography variant={'subtitle1'}>{getLangString('COMPANY_INFORMATION')}</Typography>
            <Grid container columnSpacing={0} marginBottom={3}>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name='description'
                        rules={{required: maxLength(4000)}}
                        control={control}
                        defaultValue={props.initialValues.description}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                multiline
                                fullWidth={true}
                                minRows={5}
                                label={getLangString('COMPANY_DESCRIPTION')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Typography variant={'subtitle1'}>{getLangString('CONTACT_INFO')}</Typography>
            <Grid container columnSpacing={0} marginBottom={3}>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name={'contact.cordelCompanyContactId'}
                        control={control}
                        rules={{required: false}}
                        defaultValue={props.initialValues.contact.cordelCompanyContactId}
                        render={({field}) => (
                            <input type={'hidden'} name={field.name} value={field.value as number | undefined} />
                        )}
                    />
                    <Controller
                        name='contact.firstName'
                        rules={{required: validateIsRequired(), pattern: validateNoSpecialChars()}}
                        control={control}
                        defaultValue={props.initialValues.contact.firstName}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('FORNAME')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='contact.lastName'
                        rules={{
                            required: validateIsRequired(),
                            pattern: validateNoSpecialChars(),
                        }}
                        control={control}
                        defaultValue={props.initialValues.contact.lastName}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('SURNAME') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='contact.phoneNumber'
                        rules={{required: validateIsRequired(), pattern: validatePhoneNumberWithLandCode()}}
                        control={control}
                        defaultValue={props.initialValues.contact.phoneNumber}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('PHONE') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='contact.email'
                        rules={{required: validateIsRequired(), pattern: validateIsEmail()}}
                        control={control}
                        defaultValue={props.initialValues.contact.email}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('EMAIL') + '*'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Typography variant={'subtitle1'}>{getLangString('CORDEL_INFORMATION')}</Typography>
            <Grid container columnSpacing={0} marginBottom={3}>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='apiUrl'
                        rules={{required: validateIsRequired(), pattern: validateWebUrl()}}
                        control={control}
                        defaultValue={props.initialValues.apiUrl}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('CORDEL_URL') + '*'}
                                placeholder={'Eks: https://wi.cordel2.no/DemoVVS/api'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='apiKey'
                        rules={{required: validateIsRequiredConditional(props.isNew)}}
                        control={control}
                        defaultValue=''
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('API_KEY') + (props.isNew ? '*' : '')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                        name='datawarehouseUrl'
                        rules={{required: validateIsRequired(), pattern: validateWebUrl()}}
                        control={control}
                        defaultValue={props.initialValues.datawarehouseUrl}
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('CORDEL_WAREHOUSE_URL') + '*'}
                                placeholder={'Eks: https://apicobra.cordel.no'}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='datawarehouseUsername'
                        rules={{required: validateIsRequiredConditional(props.isNew)}}
                        control={control}
                        defaultValue=''
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('CORDEL_WAREHOUSE_USERNAME') + (props.isNew ? '*' : '')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Controller
                        name='datawarehousePassword'
                        rules={{required: validateIsRequiredConditional(props.isNew)}}
                        control={control}
                        defaultValue=''
                        render={({field, fieldState}) => (
                            <CustomInputField
                                label={getLangString('CORDEL_WAREHOUSE_PASSWORD') + (props.isNew ? '*' : '')}
                                name={field.name}
                                error={!!fieldState.error}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={() => trigger(field.name)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            {didSave?.error && (
                <Box mb={2}>
                    <Typography color='red' variant={'caption'}>
                        {(translatedError && translatedError) || didSave.error}
                        {translatedError}
                    </Typography>
                </Box>
            )}
            {didSave?.success && (
                <Box mb={2}>
                    <Typography color='green' variant={'body1'}>
                        Selskap lagret
                    </Typography>
                </Box>
            )}
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <ButtonSecondaryMedium onClick={onCancel} sx={{minWidth: 108, mr: 1, mb: 1}}>
                    {getLangString('CANCEL')}
                </ButtonSecondaryMedium>
                <ButtonPrimaryMedium
                    type='submit'
                    sx={{minWidth: 108, mr: 1, mb: 1}}
                    disabled={isSendingCompany || props.submitDisabled}
                >
                    {props.isNew ? getLangString('ADD') : getLangString('SAVE')}
                </ButtonPrimaryMedium>
            </Box>
        </form>
    );
};
