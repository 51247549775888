import {ServiceDto} from '../lib/dtos/OrderContent';
import {PaginationDto} from '../lib/dtos/Pagination';
import {ProjectDto} from '../lib/dtos/Project';
import {EmployeeDto} from '../lib/dtos/User';
import {Inquiry, InquiryDetails, OfferDetailsAdmin, OfferList} from '../lib/types/Admin';
import {ServiceDictionary} from '../lib/types/Inquiry';
import {ActionType} from './actions';

const createDict = (array: ServiceDto[]): ServiceDictionary => {
    return array.reduce(
        (x, s) => ({
            ...x,
            [s.id]: {id: s.id, title: s.title, children: s.children ? createDict(s.children) : undefined},
        }),
        {},
    );
};
export const getServices = (services: ServiceDto[]) => {
    let allServices = {};
    services.forEach((service) => {
        const services = createDict(service.children);
        allServices = {...allServices, ...services};
    });
    return allServices;
};

export type AdminState = {
    user?: EmployeeDto;
    availableServices?: ServiceDictionary;
    inquiries?: {data: Inquiry[]; pagination: PaginationDto};
    inquiryDetails?: InquiryDetails;
    offers?: {data: OfferList[]; pagination: PaginationDto};
    offerDetails?: OfferDetailsAdmin;
    project?: ProjectDto;
};
export const initialAdminState: AdminState = {};
export const adminAppReducer = (state: AdminState, action: ActionType): AdminState => {
    switch (action.type) {
        case 'LOAD_EMPLOYEE_USER_INFO': {
            return {
                ...state,
                user: {
                    ...action.payload.user,
                },
            };
        }
        case 'UPDATE_USER_INFO_PHONE':
            return {
                ...state,
                user: {
                    ...state.user!,
                    phoneNumber: action.payload.phoneNumber,
                },
            };
        case 'UPDATE_USER_INFO_EMAIL':
            return {
                ...state,
                user: {
                    ...state.user!,
                    email: action.payload.email,
                },
            };
        case 'LOAD_SERVICE_TYPES': {
            return {
                ...state,
                availableServices: getServices(action.payload.details.services),
            };
        }
        case 'ADMIN_LOAD_INQUIRIES':
            return {
                ...state,
                inquiries: {
                    data: action.payload.inquiries.map((data) => ({
                        status: data.status,
                        inquiryId: data.inquiryId,
                        projectId: data.project.projectId,
                        date: new Date(data.createdAt),
                        title: 'Prosjekt ' + data.project.projectId, //TODO: set correct title and status when available from backend
                        projectLeader: data.project.projectLeader,
                    })),
                    pagination: action.payload.pagination,
                },
            };
        case 'ADMIN_LOAD_INQUIRY_DETAILS': {
            const {inquiry} = action.payload;
            return {
                ...state,
                inquiryDetails: {
                    inquiryId: inquiry.inquiryId,
                    contactInfo: inquiry.project.customer,
                    consultationFrom: new Date(inquiry.consultationFrom),
                    consultationTo: new Date(inquiry.consultationTo),
                    consultationType: inquiry.consultationType as 'email' | 'call',
                    project: inquiry.project,
                    description: inquiry.description,
                    images: inquiry.images,
                    created: new Date(inquiry.createdAt),
                    cordelInquiries: inquiry.cordelInquiries,
                },
            };
        }
        case 'ADMIN_LOAD_PROJECT_DETAILS':
            return {
                ...state,
                project: action.payload.project,
            };
        case 'LOAD_OFFERS':
            return {
                ...state,
                offers: {
                    data: action.payload.offers.map((data) => ({
                        status: data.offerDecisionStatus,
                        offerId: data.offerId,
                        projectId: data.project.projectId,
                        dateCreated: new Date(data.createdAt),
                        title: `${data.project.street}, ${data.project.zip} ${data.project.location}`,
                        projectLeader: data.project.projectLeader,
                    })),
                    pagination: action.payload.pagination,
                },
            };

        case 'LOAD_OFFER_DETAILS': {
            return {
                ...state,
                offerDetails: action.payload.offerDetails,
            };
        }
        case 'ADMIN_LOAD_PROJECT_DESCRIPTION_CUSTOMER': {
            if (state.offerDetails) {
                return {
                    ...state,
                    offerDetails: {
                        ...state.offerDetails,
                        descriptionCustomer: {...action.payload},
                    },
                };
            }
            return state;
        }
        default:
            return state;
    }
};
