import {Backdrop, Box, Theme, useMediaQuery} from '@mui/material';
import React, {Children, FC, ReactElement, ReactNode, useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {CloseIcon} from '../icons/CloseIcon';
import {UrlProvider} from './UrlProvider';

type ImageViewerProps = {
    enableFullView?: boolean;
};

export const ImageViewer: FC<ImageViewerProps> = ({enableFullView = true, children}) => {
    const [showFull, setShowFull] = useState(false);
    const trapEscape = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.cancelBubble = true;
            e.preventDefault();
            handleEnlarge(false);
        }
    }, []);

    const handleEnlarge = (shouldShowFull: boolean) => {
        if (!enableFullView) return;
        // disable scrolling when viewing popover
        document.body.style.overflow = shouldShowFull ? 'hidden' : '';
        setShowFull(shouldShowFull);
    };

    useEffect(() => {
        if (!enableFullView) return;
        if (showFull) {
            window.addEventListener('keydown', trapEscape);
        } else {
            window.removeEventListener('keydown', trapEscape);
        }
        return () => {
            window.removeEventListener('keydown', trapEscape);
        };
    }, [trapEscape, showFull, enableFullView]);
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <>
            <UrlProvider>
                <Box onClick={() => handleEnlarge(!showFull)} sx={{cursor: 'pointer'}}>
                    {children}
                </Box>
                {showFull &&
                    ReactDOM.createPortal(
                        <Backdrop
                            open={true}
                            onClick={() => handleEnlarge(!showFull)}
                            sx={{backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 10000, color: '#fff', textAlign: 'center'}}
                        >
                            <Box sx={{position: 'fixed', top: 12, right: 12, cursor: 'pointer'}}>
                                <CloseIcon color={'#fff'} size={16} />
                            </Box>
                            <Box
                                sx={{
                                    '& p': {mx: 1, mt: 1.5},
                                    '& img': {
                                        objectFit: 'contain',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        maxWidth: isSmallScreen ? '100vw' : '85vw',
                                        maxHeight: '85vh',
                                    },
                                }}
                            >
                                {Children.map<ReactNode, ReactNode>(children, (child) => {
                                    if (React.isValidElement(child)) {
                                        if (child.props['src']) {
                                            return React.cloneElement(child as ReactElement);
                                        }
                                        return child;
                                    }
                                })}
                            </Box>
                        </Backdrop>,
                        document.body,
                    )}
            </UrlProvider>
        </>
    );
};
