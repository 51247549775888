import {Services} from 'lib/dtos/Project';
import {ServiceDictionary} from '../../lib/types/Inquiry';

export const getServiceText = (services: Services[], serviceDict: ServiceDictionary) => {
    const service = services[0]; // Default to first for now
    const selectedService = serviceDict[service.service];
    return selectedService?.title;
};

export const getServiceTasksText = (services: Services[], serviceDict: ServiceDictionary) => {
    const service = services[0]; // Default to first for now
    const selectedService = serviceDict[service.service];
    if (selectedService.children && service.tasks.length) {
        const s = service.tasks.reduce((acc, v) => {
            if (selectedService.children) {
                const s = selectedService?.children[`${v}`];
                if (s) acc += s.title + ', ';
            }
            return acc;
        }, '');
        return s.slice(0, s.length - 2);
    }
};
