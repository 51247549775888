import {TimeOption} from '../components/inquiryPages/InquirySelectDatePage';
import {InquiryDto, InquirySimpleDto} from '../lib/dtos/Inquiry';
import {OfferDto, OfferSimpleDto} from '../lib/dtos/Offer';
import {OrderContentDto} from '../lib/dtos/OrderContent';
import {PaginationDto} from '../lib/dtos/Pagination';
import {ProjectDto} from '../lib/dtos/Project';
import {EmployeeDto, UserDto} from '../lib/dtos/User';
import {ConsultationType, ContactInformation, FileDict, Service} from '../lib/types/Inquiry';

export type ActionType =
    | {type: 'TOGGLE_DISPLAY_LARGE_PHOTO'; payload: {imgUrl: string | undefined}}
    | {type: 'LOAD_USER_INFO'; payload: {user: UserDto}}
    | {type: 'UPDATE_USER_INFO_PHONE'; payload: {phoneNumber: string}}
    | {type: 'UPDATE_USER_INFO_EMAIL'; payload: {email: string}}
    | {type: 'SAVE_INQUIRY_CONTACT_INFO'; payload: {contactInformation: ContactInformation}}
    | {type: 'LOAD_SERVICE_TYPES'; payload: {details: OrderContentDto}}
    | {type: 'SELECT_SERVICE_TYPE'; payload: {service: Service}}
    | {type: 'SELECT_SERVICE_TASKS'; payload: {tasks: Service[]}}
    | {type: 'ADD_SPECIFICATION_TEXT'; payload: {description: string}}
    | {type: 'UPDATE_SPECIFICATION_PHOTOS'; payload: {photos: FileDict<File> | undefined}}
    | {type: 'CLEAR_INQUIRY_DATA'}
    | {type: 'EDITING_INQUIRY'}
    | {type: 'EDITING_INQUIRY_DONE'}
    | {type: 'ADD_CONSULTATION_TYPE'; payload: {consultationType: ConsultationType; timeOption?: TimeOption}}
    | {type: 'LOAD_OFFERS'; payload: {offers: OfferSimpleDto[]; pagination: PaginationDto}}
    | {type: 'LOAD_OFFER_DETAILS'; payload: {offerDetails: OfferDto}}
    //-- ADMIN --
    | {type: 'LOAD_EMPLOYEE_USER_INFO'; payload: {user: EmployeeDto}}
    | {type: 'ADMIN_LOAD_PROJECT_DETAILS'; payload: {project: ProjectDto}}
    | {type: 'ADMIN_LOAD_INQUIRIES'; payload: {inquiries: InquirySimpleDto[]; pagination: PaginationDto}}
    | {type: 'ADMIN_LOAD_INQUIRY_DETAILS'; payload: {inquiry: InquiryDto}}
    | {type: 'ADMIN_LOAD_PROJECT_DESCRIPTION_CUSTOMER'; payload: {text?: string; images?: string[]}};

export const toggleDisplayLargePhoto = (imgUrl: string | undefined): ActionType => ({
    type: 'TOGGLE_DISPLAY_LARGE_PHOTO',
    payload: {imgUrl},
});
export const saveInquiryContactInfo = (data: ContactInformation): ActionType => ({
    type: 'SAVE_INQUIRY_CONTACT_INFO',
    payload: {contactInformation: data},
});
export const loadServiceTypes = (details: OrderContentDto): ActionType => ({
    type: 'LOAD_SERVICE_TYPES',
    payload: {details},
});
export const saveSelectedServiceType = (service: Service): ActionType => ({
    type: 'SELECT_SERVICE_TYPE',
    payload: {service},
});
export const saveSelectedServiceTasks = (tasks: Service[]): ActionType => ({
    type: 'SELECT_SERVICE_TASKS',
    payload: {tasks: [...tasks]},
});
export const addSpecificationText = (text: string): ActionType => ({
    type: 'ADD_SPECIFICATION_TEXT',
    payload: {description: text},
});
export const updateSpecificationPhotos = (photos: FileDict<File> | undefined): ActionType => ({
    type: 'UPDATE_SPECIFICATION_PHOTOS',
    payload: {photos},
});

export const addConsultationType = (consultationType: ConsultationType, timeOption?: TimeOption): ActionType => ({
    type: 'ADD_CONSULTATION_TYPE',
    payload: {consultationType, timeOption},
});
export const clearInquiryData = (): ActionType => ({
    type: 'CLEAR_INQUIRY_DATA',
});

export const loadUserInfo = (user: UserDto): ActionType => ({
    type: 'LOAD_USER_INFO',
    payload: {user},
});
export const loadEmployeeUserInfo = (user: EmployeeDto): ActionType => ({
    type: 'LOAD_EMPLOYEE_USER_INFO',
    payload: {user},
});
export const updateUserInfoPhone = (phoneNumber: string): ActionType => ({
    type: 'UPDATE_USER_INFO_PHONE',
    payload: {phoneNumber},
});
export const updateUserInfoEmail = (email: string): ActionType => ({
    type: 'UPDATE_USER_INFO_EMAIL',
    payload: {email},
});
export const editingInquiry = (): ActionType => ({
    type: 'EDITING_INQUIRY',
});
export const editingInquiryDone = (): ActionType => ({
    type: 'EDITING_INQUIRY_DONE',
});

// -- ADMIN --
export const loadInquiries = (inquiries: InquirySimpleDto[], pagination: PaginationDto): ActionType => ({
    type: 'ADMIN_LOAD_INQUIRIES',
    payload: {inquiries, pagination},
});
export const loadInquiryDetails = (inquiry: InquiryDto): ActionType => ({
    type: 'ADMIN_LOAD_INQUIRY_DETAILS',
    payload: {inquiry},
});
export const loadOfferDetails = (offerDetails: OfferDto): ActionType => ({
    type: 'LOAD_OFFER_DETAILS',
    payload: {offerDetails},
});
export const loadProjectDescriptionCustomer = (data: {text?: string; images?: string[]}): ActionType => ({
    type: 'ADMIN_LOAD_PROJECT_DESCRIPTION_CUSTOMER',
    payload: data,
});
export const loadOffers = (offers: OfferSimpleDto[], pagination: PaginationDto): ActionType => ({
    type: 'LOAD_OFFERS',
    payload: {offers, pagination},
});
export const loadProjectDetails = (project: ProjectDto): ActionType => ({
    type: 'ADMIN_LOAD_PROJECT_DETAILS',
    payload: {project},
});
