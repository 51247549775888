import {Box, ButtonProps} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {adminBasePath, myPagesBasePath} from '../App';
import {useInitializeUser} from '../initUser';
import {UserRole} from '../lib/dtos/User';
import {BurgerMenuLink} from './Navigation';

export const MyPageButton: React.FC<{ButtonComponent: React.FC<ButtonProps>}> = ({ButtonComponent}) => {
    const {userRole} = useInitializeUser();
    const history = useHistory();
    const doNavigate = () => {
        userRole === UserRole.Customer ? history.push(myPagesBasePath) : history.push(`${adminBasePath}/inquiries`);
    };

    return (
        <ButtonComponent color='info' onClick={doNavigate}>
            Min side
        </ButtonComponent>
    );
};
export const MyPageLink: React.FC = () => {
    const {userRole} = useInitializeUser();
    return (
        <Box key={'myPage'}>
            <BurgerMenuLink to={userRole === UserRole.Customer ? myPagesBasePath : `${adminBasePath}/inquiries`}>
                Min side
            </BurgerMenuLink>
        </Box>
    );
};
