import {Box, Container, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {ProjectSimpleDto} from '../../lib/dtos/Project';
import {ServiceDictionary} from '../../lib/types/Inquiry';
import {getNorwegianDateString} from '../../toolbox';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {getEndedProjectRoutePath} from './LoggedInRoutes';
import {getServiceText} from './serviceText';

const ProjectEntry: React.FC<{projectID: number; date: string; title: string}> = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const goToProject = () => {
        history.push(getEndedProjectRoutePath(props.projectID));
    };
    const date = new Date(props.date);
    return (
        <Box
            onClick={goToProject}
            sx={{
                display: 'flex',
                borderBottom: 1,
                borderColor: 'divider',
                paddingY: 4,
                paddingX: 0,
                cursor: 'pointer',
            }}
        >
            <Typography color={'secondary'}>{getNorwegianDateString(date)}</Typography>
            <Box flex={1} sx={{ml: 7, [theme.breakpoints.down('sm')]: {ml: 3}}}>
                <Typography>{props.title}</Typography>
            </Box>
        </Box>
    );
};
export const ArchivedProjects: React.FC<{projects: ProjectSimpleDto[]; services: ServiceDictionary | undefined}> = ({
    projects,
    services,
}) => {
    const {getLangString} = useLanguage();
    return (
        <Box
            sx={{
                backgroundColor: 'customBackground.dark',
                py: {sm: 13, xs: 7},
                px: {xs: 0},
            }}
        >
            <Container>
                <CssGrid>
                    <DefaultGridPageLayout>
                        <Typography variant={'h6'}>{getLangString('ARCHIVED_PROJECTS')}</Typography>
                        {projects.map((p) => (
                            <ProjectEntry
                                key={p.projectId}
                                date={p.createdAt}
                                title={services ? getServiceText(p.services, services) : ''}
                                projectID={p.projectId}
                            />
                        ))}
                    </DefaultGridPageLayout>
                </CssGrid>
            </Container>
        </Box>
    );
};
