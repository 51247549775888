import {useQuery} from '@apollo/client';
import {Box, useTheme} from '@mui/material';
import React from 'react';
import {GET_HOMEPAGE} from '../../graphql';
import {FeaturedServices} from '../cms/FeaturedServices';
import {Hero} from '../cms/Hero';
import {PageContent} from '../cms/PageContent';
import {PageIntro} from '../cms/PageIntro';
import {LoaderCenterBlur} from '../Loader';

export const Homepage: React.FC = () => {
    const theme = useTheme();
    const {loading, error, data} = useQuery(GET_HOMEPAGE);

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;

    return (
        <Box>
            <Hero {...entry.hero[0]} />

            <Box
                sx={{
                    mt: 6,
                    [theme.breakpoints.up('md')]: {
                        mt: 12,
                    },
                }}
            >
                <PageIntro {...{text: entry.introText}} />
            </Box>

            <Box
                sx={{
                    mt: 4,
                    [theme.breakpoints.up('md')]: {
                        mt: 8,
                    },
                }}
            >
                <FeaturedServices featuredServices={entry.featuredServices} />
            </Box>

            <Box>
                <PageContent data={entry.pageContent} />
            </Box>
        </Box>
    );
};
