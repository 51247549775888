import {DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {DateValidationError} from '@mui/lab/internal/pickers/date-utils';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {TextField, TextFieldProps} from '@mui/material';
import nbLocale from 'date-fns/locale/nb';
import {FC, useState} from 'react';

type Props = {
    value: Date | null;
    handleChange: (newValue: Date | null) => void;
    handleDateSelect?: (newValue: Date | null) => void;
    handleTextBlur?: () => void;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    handleMinMaxDateError?: () => void;
};
export const CustomDatePicker: FC<Props & TextFieldProps> = (props) => {
    const [errorText, setErrorText] = useState<string | null>();
    const handleError = (reason: DateValidationError) => {
        if (reason === 'minDate' || reason === 'maxDate') {
            props.handleMinMaxDateError && props.handleMinMaxDateError();
        } else if (reason === 'invalidDate') {
            setErrorText('Ugylding dato');
        }
    };
    const handleDateChange = (newValue: Date | null) => {
        setErrorText(null);
        props.handleChange(newValue);
    };
    const handleDateAccept = (newValue: Date | null) => {
        if (props.handleDateSelect && !errorText) {
            props.handleDateSelect(newValue);
        }
    };
    const handleDateBlur = () => {
        if (props.handleTextBlur && !errorText) {
            props.handleTextBlur();
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
            <DatePicker
                value={props.value}
                label={props.label}
                maxDate={props.maxDate}
                minDate={props.minDate}
                onChange={handleDateChange}
                onAccept={handleDateAccept}
                onError={handleError}
                renderInput={(params) => (
                    <TextField
                        variant={'filled'}
                        fullWidth={true}
                        sx={{
                            mr: '-1px',
                            mb: '-1px',
                            '& input': {
                                padding: 3,
                            },
                            '& .Mui-error': {
                                borderColor: 'error.main',
                            },
                        }}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: 'dd.mm.yyyy',
                        }}
                        {...params}
                        onBlur={handleDateBlur}
                        error={!!errorText || props.error}
                        helperText={errorText || props.helperText}
                        required={props.required}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
