import {Box, Grid, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {FileDto} from '../../lib/dtos/File';
import {InvoiceDto} from '../../lib/dtos/Invoice';
import {getNorwegianDateString} from '../../toolbox';
import {CustomAccordion} from '../Accordion';
import {DownloadFile} from '../DownloadFile';

const HeaderElement: React.FC<{date: string; title: string}> = ({date, title}) => {
    const theme = useTheme();
    return (
        <Box display={'flex'}>
            <Typography color={'text.secondary'}>{date}</Typography>
            <Box flex={1} sx={{ml: 3, [theme.breakpoints.up('sm')]: {ml: 7}}}>
                <Typography>{title}</Typography>
            </Box>
        </Box>
    );
};
type InvoiceProps = {
    id: string;
    invoiceDate: Date;
    title: string;
    isPayed: boolean;
    amount: number;
    amountIncVat: number;
    vat: number;
    file?: FileDto;
};
const InvoiceContent: React.FC<Omit<InvoiceProps, 'title'>> = (props) => {
    const theme = useTheme();
    const {getLangString} = useLanguage();
    return (
        <Grid container paddingBottom={2}>
            <Grid item xs={8} sm={4}>
                <Box sx={{'& > div': {display: 'flex', justifyContent: 'space-between'}}}>
                    <Box>
                        <Typography>{getLangString('INVOICE_DATE')}</Typography>
                        <Typography>{getNorwegianDateString(props.invoiceDate)}</Typography>
                    </Box>
                    <Box>
                        <Typography>{getLangString('INVOICE_NUMBER')}</Typography>
                        <Typography>{props.id}</Typography>
                    </Box>
                    <Box>
                        <Typography>{getLangString('INVOICE_STATUS')}</Typography>
                        <Typography>{props.isPayed ? 'Betalt' : 'Ubetalt'}</Typography>
                    </Box>
                    <Box>
                        <Typography>{getLangString('INVOICE_AMOUNT')}</Typography>
                        <Typography>
                            {props.amountIncVat.toLocaleString('nb-NO', {
                                style: 'currency',
                                currency: 'NOK',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={8} sm={4}>
                <Box
                    sx={{
                        height: '100%',
                        pt: 2,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        display: 'flex',
                        [theme.breakpoints.down('sm')]: {
                            justifyContent: 'flex-start',
                        },
                    }}
                >
                    {props.file ? (
                        <DownloadFile file={props.file} iconSize={20}>
                            {getLangString('DOWNLOAD_PDF')}
                        </DownloadFile>
                    ) : (
                        <Typography>{getLangString('NO_PDF')}</Typography>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export const Invoice: React.FC<InvoiceProps> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <CustomAccordion
            id={props.id}
            ariaControls={'invoice_' + props.id}
            headerElements={
                <HeaderElement
                    date={getNorwegianDateString(props.invoiceDate)}
                    title={`${props.title} - ${getLangString('INVOICE_NUMBER')} ${props.id}`}
                />
            }
            detailsElements={
                <InvoiceContent
                    invoiceDate={props.invoiceDate}
                    id={props.id}
                    isPayed={props.isPayed}
                    amount={props.amount}
                    amountIncVat={props.amountIncVat}
                    vat={props.vat}
                    file={props.file}
                />
            }
        />
    );
};

export const Invoices: React.FC<{invoices: InvoiceDto[]}> = ({invoices}) => {
    const {getLangString} = useLanguage();
    if (invoices.length > 0) {
        return (
            <>
                {invoices.map((invoice) => (
                    <Invoice
                        key={invoice.invoiceNumber}
                        id={`${invoice.invoiceNumber}`}
                        title={invoice.company.name}
                        invoiceDate={new Date(invoice.invoiceDate)}
                        isPayed={invoice.paymentStatus}
                        amount={invoice.amount}
                        amountIncVat={invoice.amountIncVat}
                        vat={invoice.vatAmount}
                        file={invoice.invoiceFile}
                    />
                ))}
            </>
        );
    }
    return <Typography>{getLangString('NO_INVOICES')}</Typography>;
};
