import React from 'react';
import {ContentBlock} from './PageContent';
import {ArticleSlider} from './partials/ArticleSlider';

type Props = {
    id: number;
    heading: string;
    articles: {
        id: number;
        title: string;
        slug: string;
        mainImage: {
            id: number;
            url: string;
        }[];
        relatedServices: {
            id: number;
            title: string;
            slug: string;
        }[];
    }[];
    readMoreLink: string;
    narrowViewportArticleLimit: number;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Articles: React.FC<Props> = (props) => {
    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <ArticleSlider
                heading={props.heading}
                link={props.readMoreLink}
                articles={props.articles}
                narrowViewportLimit={props.narrowViewportArticleLimit}
            />
        </ContentBlock>
    );
};
