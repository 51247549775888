import {ProjectStatus} from 'lib/dtos/Project';

const company = {
    base: (): string => 'api/cordels',
    byId: (id: number): string => `api/cordels/${id}`,
};
export const ApiEndpoints = {
    cookies: {
        base: (): string => 'api/cookies',
        byId: (consentId: string) => `api/cookies/${consentId}`,
        groups: (): string => 'api/cookies/groups',
    },
    order: {
        base: (): string => 'api/orders',
        byId: (orderId: number): string => `api/orders/${orderId}`,
        cordelOrders: (orderId: number): string => `api/orders/${orderId}/cordelOrders`,
    },
    inquiry: {
        base: (): string => 'api/inquiries',
        byId: (id: number): string => `api/inquiries/${id}`,
    },
    offer: {
        base: (): string => 'api/offers',
        byId: (id: number): string => `api/offers/${id}`,
    },
    project: {
        base: (): string => 'api/projects',
        byId: (projectId: number): string => `api/projects/${projectId}`,
        orderableCompanies: (projectId: number): string => `api/projects/${projectId}/orderableCompanies`,
        inspectionImages: (projectId: number): string => `api/projects/${projectId}/images/inspection`,
        productImages: (projectId: number): string => `api/projects/${projectId}/images/product`,
        inquiries: (projectId: number): string => `api/projects/${projectId}/inquiries`,
        offers: (projectId: number): string => `api/projects/${projectId}/offers`,
        orders: (projectId: number): string => `api/projects/${projectId}/orders`,
        statuses: (projectId: number): string => `api/projects/${projectId}/statuses`,
        state: (projectId: number, status: ProjectStatus): string =>
            `api/projects/${projectId}/setstate?status=${status}`,
    },
    statusUpdate: {
        base: (includeAnswered?: boolean): string =>
            `api/statusUpdates${includeAnswered ? '?includeAnswered=true' : ''}`,
        byId: (id: number) => `api/statusUpdates/${id}`,
    },
    user: {
        base: (): string => 'api/user',
        byId: (id: string): string => `api/user/${id}`,
    },
    employee: {
        base: (): string => 'api/user/employees',
        byId: (id: number): string => `api/user/employees/${id}`,
    },
    customer: {
        base: (): string => 'api/customer',
        byId: (id: number): string => `api/customer/${id}`,
    },
    content: {
        orderDetails: (): string => 'api/content/order/details',
    },
    cordel: {
        base: (): string => 'api/cordels',
        byId: (id: number): string => `api/cordels/${id}`,
        contacts: (cordelID: number): string => `api/cordels/${cordelID}/contacts`,
        contactById: (cordelID: number, contactID: number): string => `api/cordels/${cordelID}/contacts/${contactID}`,
    },
    company,
    contact: {
        question: (): string => 'api/contact/question',
    },
};
