import {Button, capitalize, Grid, List, ListItem, Typography} from '@mui/material';
import {CustomAccordion} from 'components/Accordion';
import {useLanguage} from 'language/LanguageProvider';
import {useState, VFC} from 'react';
import {AreYouSureDialog} from '../../../AreYouSureDialog';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../../../Buttons';
import {Phase} from './OrderDetails';

export type PhaseHandler = (phase: Phase) => void;

export type PhaseEvents = {onMarkFinish: PhaseHandler; onDeletePhase: PhaseHandler; onEdit: PhaseHandler};

type PhaseAccordionProps = {
    phase: Phase;
} & PhaseEvents;

export const PhaseAccordion: VFC<PhaseAccordionProps> = ({phase, onMarkFinish, onDeletePhase, onEdit}) => {
    const {getLangString} = useLanguage();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [progress, setProgress] = useState<boolean>(false);
    const handleConfirm = async () => {
        setProgress(true);
        await onDeletePhase(phase);
        setProgress(false);
        setShowAlert(false);
    };
    return (
        <>
            <CustomAccordion
                id={`${phase.phaseId}`}
                ariaControls={''}
                defaultExpanded={phase.status === 'active'}
                headerElements={
                    <Grid container>
                        <Grid item md={3} sm={3} xs={12}>
                            <Typography key={'description'} sx={{fontFamily: 'SuisseIntl-Medium'}}>
                                0{phase.displayOrder}. {phase.title}
                            </Typography>
                        </Grid>
                        <Grid item md xs>
                            <Typography key={'status'} sx={{fontFamily: 'SuisseIntl-Medium'}}>{`${capitalize(
                                getLangString(phase.status),
                            )}`}</Typography>
                        </Grid>
                    </Grid>
                }
                headerElementsStyleOverrides={{pt: 5, pb: 1}}
                detailsElements={
                    <>
                        <List>
                            {phase.description && <Typography>{phase.description}</Typography>}
                            {phase.status !== 'done' && (
                                <ListItem sx={{justifyContent: 'flex-start', mt: 2, px: 0, gap: 2}}>
                                    <ButtonPrimaryMedium
                                        sx={{
                                            minWidth: 'auto',
                                        }}
                                        disabled={phase.status !== 'active'}
                                        onClick={() => {
                                            onMarkFinish(phase);
                                        }}
                                    >
                                        {getLangString('MAKE_DONE')}
                                    </ButtonPrimaryMedium>
                                    <ButtonSecondaryMedium
                                        sx={{
                                            minWidth: 'auto',
                                        }}
                                        onClick={() => onEdit(phase)}
                                    >
                                        {getLangString('EDIT')}
                                    </ButtonSecondaryMedium>
                                    {
                                        <Button
                                            sx={{
                                                ml: 'auto',
                                                minWidth: 'auto',
                                            }}
                                            variant='contained'
                                            color='error'
                                            size={'medium'}
                                            onClick={() => setShowAlert(true)}
                                        >
                                            {getLangString('DELETE')}
                                        </Button>
                                    }
                                </ListItem>
                            )}
                        </List>
                    </>
                }
            />
            <AreYouSureDialog
                open={showAlert}
                title={getLangString('DELETE_PHASE_TEXT')}
                onCancel={() => setShowAlert(false)}
                onConfirm={handleConfirm}
                progress={progress}
            />
        </>
    );
};
