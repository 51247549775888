import {Container, Grid, RadioGroup, Typography} from '@mui/material';
import React, {ChangeEvent, useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {ConsultationType} from '../../lib/types/Inquiry';
import {addConsultationType} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {setHour} from '../../toolbox';
import {ButtonPrimaryLarge} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {RadioButton} from '../RadioButton';
import {ButtonLayout} from './InquiryDetailComponents';
import {getInquiryContactInfoPath, getInquirySummaryPath, useRedirectIfNoActiveInquiry} from './InquiryPageRoutes';

export const InquirySelectDatePage: React.FC = () => {
    //Redirect to initial inquiry page if there is no active inquiry
    useRedirectIfNoActiveInquiry();

    const {getLangString} = useLanguage();
    const {state, dispatch} = useContext(AppContext);
    const [consultationType, setConsultationType] = React.useState<ConsultationType>(
        state.inquiry.timeSchedule?.consultationType || '',
    );
    const [selectedTime, setSelectedTime] = useState<TimeOption>(state.inquiry.timeSchedule?.timeOption || 'morning');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setConsultationType(event.target.value as ConsultationType);
    };
    const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedTime(event.target.value as TimeOption);
    };
    const history = useHistory();
    const handleNextClick = () => {
        if (consultationType === 'email') {
            dispatch(addConsultationType(consultationType));
        } else {
            dispatch(addConsultationType(consultationType, selectedTime));
        }
        state.inquiry.isEditing ? history.push(getInquirySummaryPath()) : history.push(getInquiryContactInfoPath());
    };
    return (
        <Container sx={{py: 7}}>
            <CssGrid>
                <DefaultGridPageLayout sx={{minHeight: '400px'}}>
                    <Typography variant={'subtitle1'}>{getLangString('CONSULTATION_TYPE_HEADER')}</Typography>
                    <RadioGroup onChange={handleChange} value={consultationType}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={8} sm={4}>
                                <RadioButton labelText={getLangString('CONSULTATION_TYPE_PHONE')} value={'call'} />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <RadioButton labelText={getLangString('CONSULTATION_TYPE_EMAIL')} value={'email'} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    {consultationType === 'call' && (
                        <TimeSelector onTimeChange={handleTimeChange} value={selectedTime} />
                    )}
                    <ButtonLayout>
                        <ButtonPrimaryLarge onClick={handleNextClick} disabled={!consultationType}>
                            {getLangString('NEXT')}
                        </ButtonPrimaryLarge>
                    </ButtonLayout>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
export type TimeOption = 'morning' | 'afternoon' | 'evening';

export const timeInterval: Record<TimeOption, {from: Date; to: Date}> = {
    morning: {from: setHour(8), to: setHour(12)},
    afternoon: {from: setHour(12), to: setHour(16)},
    evening: {from: setHour(16), to: setHour(18)},
};

const TimeSelector: React.FC<{onTimeChange: (event: ChangeEvent<HTMLInputElement>) => void; value: TimeOption}> = ({
    onTimeChange,
    value,
}) => {
    return (
        <RadioGroup onChange={onTimeChange} value={value} sx={{mt: 5}}>
            <Grid container columnSpacing={3}>
                <Grid item xs={8} sm={4}>
                    <RadioButton labelText={'Mellom klokken 08:00 - 12:00'} value={'morning'} />
                    <RadioButton labelText={'Mellom klokken 12:00 - 16:00'} value={'afternoon'} />
                    <RadioButton labelText={'Mellom klokken 16:00 - 18:00'} value={'evening'} />
                </Grid>
            </Grid>
        </RadioGroup>
    );
};
