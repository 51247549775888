import {Typography} from '@mui/material';
import {InquiriesDto, InquiryState} from 'lib/dtos/Inquiry';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {Inquiry} from 'lib/types/Admin';
import {useEffect, useState, VFC} from 'react';
import {useSearch} from '../../../../lib/Search';
import {ServiceDictionary} from '../../../../lib/types/Inquiry';
import {SearchField} from '../../../SearchField';
import {getServiceText} from '../../serviceText';
import {getAddress} from '../getAddress';
import {InquirePageDataGrid} from './InquirePageDataGrid';

export const InquiresTable: VFC<{
    onLoadingChange?: (isLoading: boolean) => void;
    title: string;
    services: ServiceDictionary | undefined;
    requestParams?: {employeeId?: number; status?: InquiryState};
}> = ({onLoadingChange, requestParams, title, services}) => {
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState(1);
    const size = rowsPerPage * 2; // double fetch size as it avoid page flickering
    const params = {size, status: requestParams?.status, employeeId: requestParams?.employeeId, page};
    const [inquiries, setInquiries] = useState<Inquiry[]>([]);
    const [{data: inquiriesDto, loading}] = useM2Axios<InquiriesDto>({
        url: 'api/Inquiries',
        params,
    });
    const {search, setSearchData} = useSearch<Inquiry>();
    const [filteredInquiries, setFilteredInquiries] = useState<Inquiry[]>([]);
    useEffect(() => {
        if (inquiries) {
            setSearchData(inquiries);
            setFilteredInquiries(inquiries);
        }
    }, [inquiries, setSearchData]);
    // todo only fetch if data is not cached. Must wait for api to return right pagination data
    useEffect(() => {
        if (inquiriesDto && inquiriesDto.data.length > 0) {
            const newInquiries = inquiriesDto.data.map(
                (inquiry): Inquiry => ({
                    ...inquiry,
                    projectId: inquiry.project.projectId,
                    date: inquiry.createdAt as unknown as Date,
                    title: `${services && getServiceText(inquiry.project.services, services)}, ${getAddress(
                        inquiry.project,
                    )}`,
                    projectLeader: inquiry.project.projectLeader,
                }),
            );
            const newInquiresIds = newInquiries.map((i) => i.inquiryId);
            setInquiries((prev) =>
                [...prev.filter((p) => !newInquiresIds.includes(p.inquiryId)), ...newInquiries].sort(
                    (a, b) => a.inquiryId - b.inquiryId,
                ),
            );
        }
    }, [inquiriesDto]);

    useEffect(() => {
        onLoadingChange?.(loading);
    }, [loading, onLoadingChange]);

    return (
        <>
            <Typography variant={'h5'} mt={7} mb={3}>
                {title}
            </Typography>
            <SearchField handleChange={(e) => setFilteredInquiries(search(e.target.value))} />
            <InquirePageDataGrid
                data={filteredInquiries}
                pagination={inquiriesDto?.pagination}
                onPageSizeChange={setRowsPerPage}
                onPageChange={(page) => {
                    setPage(page + 1); // page is 0 index
                }}
                loading={loading}
                rowsPerPage={rowsPerPage}
                minHeight={52 * Math.min(rowsPerPage, inquiries.length) + 56 + 64}
            />
        </>
    );
};
