import React from 'react';
import {IconProps} from './IconProps';

export const MinusIcon: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || '24'} height={size || '24'} viewBox='0 0 24 24'>
        <g fill={color || '#121212'}>
            <path d='M-.042-5.312h9.5v1.2h-9.5Z' transform='translate(7.292 16.712)' />
        </g>
    </svg>
);
