import {AuthError, BrowserAuthError, InteractionRequiredAuthError} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import React from 'react';
import {b2cPolicies, protectedResources} from './config/b2cConfig';
import {UserRole} from './lib/dtos/User';
import {M2HttpClient} from './lib/remote/m2api/ApiHttpClient';

export const useInitializeUser = (): {accessToken: string | undefined; userRole: UserRole | undefined} => {
    const [accessToken, setAccessToken] = React.useState<string>();
    const [userRole, setUserRole] = React.useState<UserRole>();
    const {instance, accounts, inProgress} = useMsal();

    React.useEffect(() => {
        const initializeUser = async () => {
            await instance.handleRedirectPromise();

            if (inProgress === 'none' && accounts[0]) {
                const userAccount = accounts[0];
                const request = {
                    scopes: protectedResources.backend.scopes,
                    account: userAccount,
                };
                try {
                    const tokenResponse = await instance.acquireTokenSilent(request);
                    M2HttpClient.setAccessToken(tokenResponse.accessToken);
                    setAccessToken(tokenResponse.accessToken);
                    const idTokenClaims = tokenResponse.account?.idTokenClaims as {extension_role: string};
                    setUserRole(parseInt(idTokenClaims.extension_role));
                } catch (error) {
                    if (
                        (error instanceof AuthError && error.errorMessage.includes('AADB2C90077')) ||
                        error instanceof BrowserAuthError ||
                        error instanceof InteractionRequiredAuthError
                    ) {
                        return instance.acquireTokenRedirect({
                            ...request,
                            authority: b2cPolicies.authorities.signIn.authority,
                        });
                    }
                }
            } else {
                // do something if there are errors
            }
        };
        initializeUser();
    }, [instance, inProgress, accounts]);

    return {accessToken, userRole};
};
