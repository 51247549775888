import {Box, IconButton, styled, Typography} from '@mui/material';
import React from 'react';
import {PhaseDto} from '../lib/dtos/PhaseDto';
import {useIsSmallScreen} from '../toolbox';
import {ArrowBackward} from './icons/ArrowBackward';
import {ArrowForward} from './icons/ArrowForward';

const Square = styled(Box)(({theme}) => ({
    width: 12,
    height: 12,
    backgroundColor: theme.palette.primary.main,
}));
const HorizontalLine = styled(Box)(({theme}) => ({
    height: 2,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    marginRight: 0,
    flexGrow: 1,
}));
const VerticalLine = styled(Box)(({theme}) => ({
    flexGrow: 1,
    width: 2,
    minHeight: 16,
    backgroundColor: theme.palette.primary.main,
    margin: '8px 5px 3px 5px',
}));
export const ProjectPhaseDesktop: React.FC<{
    title: string;
    description?: string;
    isCurrent?: boolean;
    getRefWidth: (width: number) => void;
}> = (props) => {
    const phaseRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        phaseRef.current && props.getRefWidth(phaseRef.current.offsetWidth);
    }, [phaseRef, props]);

    return (
        <Box
            ref={phaseRef}
            sx={{
                opacity: props.isCurrent ? 1 : 0.4,
                marginRight: 2,
                minWidth: 400,
                width: '50%',
                '&:last-child': {marginRight: 0},
            }}
        >
            <Box display={'inline-flex'} alignItems={'center'} width={'100%'} marginLeft={'1px'}>
                <Square />
                <HorizontalLine />
            </Box>
            <Typography variant={'subtitle1'}>{props.title}</Typography>
            <Typography>{props.description}</Typography>
        </Box>
    );
};
export const ProjectPhaseMobile: React.FC<{title: string; description?: string; isCurrent?: boolean}> = ({
    title,
    description,
    isCurrent,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'stretch',
                opacity: isCurrent ? 1 : 0.4,
                //do not display vertical line on last element
                '&:last-child > div > :last-child': {
                    display: 'none',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Square marginRight={3} marginTop={'5px'} />
                <VerticalLine />
            </Box>
            <Box mt={'1px'}>
                <Typography marginBottom={1}>{title}</Typography>
                <Typography marginBottom={1}>{description}</Typography>
            </Box>
        </Box>
    );
};

export const ProjectPhases: React.FC<{
    phases: PhaseDto[];
    current: number | undefined;
    phasePositionOffset?: number;
}> = ({phases, current, phasePositionOffset}) => {
    const isSmallScreen = useIsSmallScreen();
    const [indexOfCurrent, setIndexOfCurrent] = React.useState<number>(0);
    const [elementWidth, setElementWidth] = React.useState<number>();
    const getElementWidth = (width: number) => setElementWidth(width);

    const containerRef = React.useRef<HTMLDivElement>(null);

    if (isSmallScreen) {
        return (
            <Box>
                {phases.map((phase, i) => (
                    <ProjectPhaseMobile
                        key={i}
                        title={`Fase 0${phase.displayOrder} - ${phase.title}`}
                        description={phase.description}
                        isCurrent={phase.phaseId === current}
                    />
                ))}
            </Box>
        );
    }

    const translateValue = elementWidth
        ? (phasePositionOffset !== undefined ? phasePositionOffset : indexOfCurrent) * (elementWidth + 16)
        : 0;
    return (
        <Box
            ref={containerRef}
            display={'flex'}
            width={'fit-content'}
            sx={{transform: `translateX(-${translateValue}px)`, transition: 'transform ease-in-out 0.3s'}}
        >
            {phases.map((phase, i) => {
                if (phase.phaseId === current && indexOfCurrent !== i) {
                    setIndexOfCurrent(i);
                }
                return (
                    <ProjectPhaseDesktop
                        key={i}
                        getRefWidth={getElementWidth}
                        title={`Fase 0${phase.displayOrder} - ${phase.title}`}
                        description={phase.description}
                        isCurrent={phase.phaseId === current}
                    />
                );
            })}
        </Box>
    );
};

export const PhasesWithSlideButtons: React.FC<{
    phases: PhaseDto[];
    current: number | undefined;
    headerText?: string;
}> = (props) => {
    const [phaseOffset, setPhaseOffset] = React.useState<number>(0);

    React.useEffect(() => {
        const index = props.phases.findIndex((phase) => phase.phaseId === props.current);
        setPhaseOffset(index);
    }, [props]);
    const handleRightClick = () => {
        setPhaseOffset(phaseOffset + 1);
    };
    const handleLeftClick = () => {
        setPhaseOffset(phaseOffset - 1);
    };

    return (
        <>
            <Box display={'flex'} alignItems={'center'} mb={2}>
                {props.headerText && (
                    <Typography variant={'h6'} mr={5}>
                        {props.headerText}
                    </Typography>
                )}
                <IconButton
                    onClick={handleLeftClick}
                    disabled={phaseOffset === 0}
                    sx={{opacity: phaseOffset === 0 ? 0.4 : 1}}
                >
                    <ArrowBackward />
                </IconButton>
                <IconButton
                    onClick={handleRightClick}
                    disabled={phaseOffset === props.phases.length - 1}
                    sx={{opacity: phaseOffset === props.phases.length - 1 ? 0.4 : 1}}
                >
                    <ArrowForward />
                </IconButton>
            </Box>
            <ProjectPhases phases={props.phases} current={props.current} phasePositionOffset={phaseOffset} />
        </>
    );
};
