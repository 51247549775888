import {Box, Link, Typography, useTheme} from '@mui/material';
import {ARTICLES_INDEX_PAGE_URL, formatUrl, SERVICES_ENTRY_PAGE_URL_FORMAT} from 'cms/helpers';
import {CustomAccordion} from 'components/Accordion';
import {ButtonPrimaryMedium} from 'components/Buttons';
import {Container, CssGrid} from 'components/Layout';
import {useLanguage} from 'language/LanguageProvider';
import React, {useState} from 'react';
import {CmsService, CmsServiceGroup} from '../../graphql/types';

type Props = {
    services: CmsServiceGroup[];
};

export const ServicesIndex: React.FC<Props> = (props) => {
    const theme = useTheme();
    const {getLangString} = useLanguage();

    const getInitialService = (groups: CmsServiceGroup[]) => {
        let initialService: null | string = null;
        groups.forEach((group: CmsServiceGroup) => {
            if (initialService == null && group.children && group.children.length) {
                initialService = group.children[0].id;
            }
        });

        return initialService;
    };

    const [currentPanel, setCurrentPanel] = useState<string | null>();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setCurrentPanel(isExpanded ? panel : null);
    };

    return (
        <Box>
            {props.services.map((serviceGroup: CmsServiceGroup) => {
                if (!serviceGroup.children || !serviceGroup.children.length) {
                    return;
                }

                return (
                    <Box
                        key={serviceGroup.id}
                        id={serviceGroup.slug ?? ''}
                        sx={{
                            mt: 8,
                            scrollMarginTop: '100px',
                            [theme.breakpoints.up('sm')]: {
                                scrollMarginTop: '140px',
                            },
                            [theme.breakpoints.up('md')]: {
                                mt: 10,
                            },
                            [theme.breakpoints.up('lg')]: {
                                mt: 14,
                            },
                        }}
                    >
                        <Container>
                            <CssGrid>
                                <Box
                                    sx={{
                                        gridColumn: 'span 8',
                                        [theme.breakpoints.up('md')]: {
                                            gridColumn: '3 / span 5',
                                        },
                                    }}
                                >
                                    <Box
                                        component='h2'
                                        sx={{
                                            fontWeight: 'normal',
                                            fontSize: '1.75rem',
                                            lineHeight: '1.32',
                                            [theme.breakpoints.up('sm')]: {
                                                fontSize: '2.25rem',
                                                lineHeight: '1.35',
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                fontSize: '2.625rem',
                                                lineHeight: '1.35',
                                            },
                                        }}
                                    >
                                        {serviceGroup.title}
                                    </Box>

                                    <Typography
                                        component='div'
                                        variant='sectionIntro'
                                        sx={{
                                            mt: 3,
                                            '& > :first-child': {
                                                mt: 0,
                                            },
                                            '& > :last-child': {
                                                mb: 0,
                                            },
                                            '& a': {
                                                color: 'text.primary',
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                mt: 5,
                                            },
                                        }}
                                        dangerouslySetInnerHTML={{__html: serviceGroup.introText ?? ''}}
                                    />

                                    {serviceGroup.bodyText !== '' && (
                                        <Typography
                                            component='div'
                                            sx={{
                                                mt: 3,
                                                '& > :first-child': {
                                                    mt: 0,
                                                },
                                                '& > :last-child': {
                                                    mb: 0,
                                                },
                                                '& a': {
                                                    color: 'text.primary',
                                                },
                                                [theme.breakpoints.up('md')]: {
                                                    mt: 5,
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{__html: serviceGroup.bodyText ?? ''}}
                                        />
                                    )}
                                </Box>
                            </CssGrid>

                            <Box
                                sx={{
                                    mt: 5,

                                    [theme.breakpoints.up('md')]: {
                                        mt: 9,
                                    },
                                }}
                            >
                                <CssGrid>
                                    <Box
                                        sx={{
                                            gridColumn: 'span 8',
                                            [theme.breakpoints.up('md')]: {
                                                gridColumn: '2 / span 6',
                                            },
                                        }}
                                    >
                                        {serviceGroup.children.map((service: CmsService) => {
                                            return (
                                                <Box
                                                    key={service.id}
                                                    id={service.slug}
                                                    sx={{
                                                        scrollMarginTop: '100px',
                                                        [theme.breakpoints.up('sm')]: {
                                                            scrollMarginTop: '140px',
                                                        },
                                                    }}
                                                >
                                                    <CustomAccordion
                                                        onChange={handleChange(service.id)}
                                                        expanded={currentPanel === service.id}
                                                        id={`categories-${service.id}`}
                                                        ariaControls=''
                                                        headerElements={
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    fontFamily: 'SuisseIntl-Medium',
                                                                    fontWeight: 'medium',
                                                                    fontSize: '1rem',
                                                                    [theme.breakpoints.up('md')]: {
                                                                        fontSize: '1.0625rem',
                                                                    },
                                                                }}
                                                            >
                                                                {service.title}
                                                            </Box>
                                                        }
                                                        detailsElements={
                                                            <Box
                                                                sx={{
                                                                    pb: 3,
                                                                    display: 'grid',
                                                                    gridTemplateColumns: 'repeat(6, 1fr)',
                                                                    columnGap: 1.5,
                                                                    [theme.breakpoints.up('sm')]: {
                                                                        columnGap: 2.5,
                                                                    },
                                                                    [theme.breakpoints.up('md')]: {
                                                                        pr: 8,
                                                                        pb: 5,
                                                                        columnGap: 3,
                                                                    },
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        gridRow: '1',
                                                                        gridColumn: 'span 6',
                                                                        [theme.breakpoints.up('md')]: {
                                                                            gridColumn: '3 / span 4',
                                                                        },
                                                                    }}
                                                                >
                                                                    <Box
                                                                        component='div'
                                                                        sx={{
                                                                            '> :first-child': {
                                                                                mt: 0,
                                                                            },
                                                                            '> :last-child': {
                                                                                mb: 0,
                                                                            },
                                                                            '& a': {
                                                                                color: 'text.primary',
                                                                            },
                                                                            fontSize: '1rem',
                                                                            [theme.breakpoints.up('md')]: {
                                                                                fontSize: '1.0625rem',
                                                                            },
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: service.introText ?? '',
                                                                        }}
                                                                    ></Box>

                                                                    <Box
                                                                        sx={{
                                                                            mt: 3,
                                                                            [theme.breakpoints.up('md')]: {
                                                                                mt: 4,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            href={formatUrl(
                                                                                SERVICES_ENTRY_PAGE_URL_FORMAT,
                                                                                service.slug,
                                                                            )}
                                                                            sx={{
                                                                                fontFamily: 'SuisseIntl-Medium',
                                                                                fontSize: '1rem',
                                                                                [theme.breakpoints.up('md')]: {
                                                                                    fontSize: '1.0625rem',
                                                                                },
                                                                            }}
                                                                        >
                                                                            Les mer om denne tjenesten
                                                                        </Link>
                                                                    </Box>

                                                                    <Box
                                                                        sx={{
                                                                            mt: 3,
                                                                            [theme.breakpoints.up('md')]: {
                                                                                mt: 4,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {service.mainImage && (
                                                                            <img
                                                                                style={{
                                                                                    width: '100%',
                                                                                    display: 'block',
                                                                                }}
                                                                                src={service.mainImage[0].url}
                                                                                alt=''
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        mt: 3,
                                                                        gridRow: '2',
                                                                        gridColumn: 'span 6',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-start',
                                                                        [theme.breakpoints.up('md')]: {
                                                                            gridRow: '1',
                                                                            gridColumn: '1 / span 2',
                                                                            alignSelf: 'end',
                                                                        },
                                                                    }}
                                                                >
                                                                    <ButtonPrimaryMedium
                                                                        href={`/bestilling?sid=${service.id}`}
                                                                        sx={{
                                                                            maxWidth: '100%',
                                                                            minWidth: '0',
                                                                            px: 4,
                                                                        }}
                                                                    >
                                                                        {getLangString('SERVICE_ORDER_ACTION')}
                                                                    </ButtonPrimaryMedium>

                                                                    <Link
                                                                        href={`${ARTICLES_INDEX_PAGE_URL}?sid=${service.id}`}
                                                                        sx={{
                                                                            mt: 2,
                                                                            display: 'flex',
                                                                            alignItems: 'flex-start',
                                                                            textDecoration: 'none',
                                                                            [theme.breakpoints.up('md')]: {
                                                                                order: '-1',
                                                                                mt: 0,
                                                                                mb: 2.5,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: '&nearr;',
                                                                            }}
                                                                        ></span>
                                                                        <span
                                                                            style={{
                                                                                marginLeft: theme.spacing(1.5),
                                                                            }}
                                                                        >
                                                                            {getLangString(
                                                                                'SERVICE_REFERENCES_ACTION__FORMAT',
                                                                            ).replace(
                                                                                '%service_name%',
                                                                                service.title
                                                                                    ? service.title.toLowerCase()
                                                                                    : '',
                                                                            )}
                                                                        </span>
                                                                    </Link>
                                                                </Box>
                                                            </Box>
                                                        }
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </CssGrid>
                            </Box>
                        </Container>
                    </Box>
                );
            })}
        </Box>
    );
};
