import {useQuery} from '@apollo/client';
import {Box, useTheme} from '@mui/material';
import React from 'react';
import {SERVICES_INDEX_PAGE_URL} from '../../cms/helpers';
import {CMS_SEO, GET_SEO_DATA, GET_SERVICES_INDEX_PAGE} from '../../graphql';
import {SEO} from '../../SEO';
import {PageIntro} from '../cms/PageIntro';
import {PageOverline} from '../cms/PageOverline';
import {ServicesIndex} from '../cms/ServicesIndex';
import {LoaderCenterBlur} from '../Loader';

export const ServicesIndexPage: React.FC = () => {
    const theme = useTheme();
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(SERVICES_INDEX_PAGE_URL));
    const {loading, error, data} = useQuery(GET_SERVICES_INDEX_PAGE);

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const {entry, services} = data;

    const scrollToHash = async () => {
        await new Promise((resolve) => resolve(true));
        const hash = decodeURIComponent(window.location.hash);
        const hashElement = hash !== '' ? document.querySelector(hash) : null;
        if (hashElement) {
            hashElement.scrollIntoView();
        }
    };

    if (entry) {
        scrollToHash();
    }

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                sx={{
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 14,
                    },
                }}
            >
                <PageOverline {...{text: entry.title}} />

                <PageIntro {...{text: entry.introText}} />

                {/* <ServiceQuickLinks button={entry.button} services={services} /> */}

                <ServicesIndex services={services} />
            </Box>
        </>
    );
};
