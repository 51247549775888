import {ArrowForward} from '@mui/icons-material';
import {TextField, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {ButtonSquareBordered} from 'components/Buttons';
import {LoaderCenterBlur} from 'components/Loader';
import {useLanguage} from 'language/LanguageProvider';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import {nbNO} from 'lib/services/nbNO';
import {useContext, useEffect, useState, VFC} from 'react';
import {EmployeeDto, UserRole} from '../../../../lib/dtos/User';
import {useSearch} from '../../../../lib/Search';
import {AdminAppContext} from '../../../../store/adminAppContext';
import {AdminPageLayout} from '../AdminPageLayout';
import {EmployeeDialog, EmployeeDialogForm} from './EmployeeDialog';
import {userRoleText} from './userRoleText';

const columns: GridColDef[] = [
    {
        field: 'firstName',
        headerName: 'Fornavn',
        flex: 1,
    },
    {
        field: 'lastName',
        headerName: 'Etternavn',
        flex: 1,
    },
    {
        field: 'role',
        headerName: 'Rolle',
        flex: 1,
        renderCell: (params: GridRenderCellParams<UserRole>) => <>{userRoleText[params.value]}</>,
    },
    {
        field: 'go',
        disableColumnMenu: true,
        sortable: false,
        type: 'actions',
        renderCell: (params: GridRenderCellParams<void>) => <ArrowForward style={{cursor: 'pointer'}} />,
    },
];

export const EmployeePage: VFC = () => {
    const {state} = useContext(AdminAppContext);
    const {getLangString} = useLanguage();
    const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
    const [formInitialData, setFormInitialData] = useState<EmployeeDialogForm | undefined>(undefined);
    const {search, setSearchData} = useSearch<EmployeeDto>();
    const [{loading: isFetchingCompanies, data: employees}, getEmployees] = useM2Axios<M2Response<EmployeeDto[]>>(
        ApiEndpoints.employee.base(),
        {
            manual: false,
        },
    );

    const [{loading: isFetchingEmployee}, getEmployee] = useM2Axios<M2Response<EmployeeDto>>('', {
        manual: true,
    });

    const onEmployeeDialogClose = async () => {
        setEmployeeDialogOpen(false);
        setFormInitialData(undefined);
    };
    const onEmployeeDialogOpen = async (employeeId: number) => {
        await loadEmployee(employeeId);
        setEmployeeDialogOpen(true);
    };

    const onCompanyDialogSubmit = async (reloadData: boolean) => {
        if (reloadData) await getEmployees();
    };

    const loadEmployee = async (employeeId: number) => {
        try {
            setEmployeeDialogOpen(true);
            const {data} = await getEmployee({url: ApiEndpoints.employee.byId(employeeId), method: 'GET'});
            setFormInitialData({isNew: false, data: data.data});
        } catch (error) {
            /** */
        }
    };

    const createNewEmployee = () => {
        setFormInitialData({isNew: true, data: undefined});
        setEmployeeDialogOpen(true);
    };
    const [filteredEmployees, setFilteredEmployees] = useState<EmployeeDto[]>([]);

    useEffect(() => {
        if (employees?.data) {
            setSearchData(employees.data);
            setFilteredEmployees(employees.data);
        }
    }, [employees]);
    const canAddEmployee = () => {
        return state.user && (state.user.role === UserRole.Administrator || state.user.role === UserRole.Developer);
    };
    return (
        <AdminPageLayout>
            <LoaderCenterBlur open={false} />
            <Typography variant={'h6'} mt={7} mb={3}>
                {getLangString('EMPLOYEES')}
            </Typography>
            {canAddEmployee() && (
                <ButtonSquareBordered onClick={createNewEmployee} sx={{minWidth: 108, mb: 1, display: 'block'}}>
                    + {getLangString('ADD_EMPLOYEE')}
                </ButtonSquareBordered>
            )}
            <TextField
                sx={{mb: 4}}
                size='small'
                placeholder='Søk her...'
                onChange={(e) => setFilteredEmployees(search(e.target.value))}
            />
            <DataGrid
                localeText={nbNO}
                columns={columns}
                pageSize={25}
                onRowClick={(c) => {
                    onEmployeeDialogOpen(c.row.employeeId);
                }}
                rows={filteredEmployees.map((c, i) => {
                    return {...c, id: i};
                })}
            />
            <LoaderCenterBlur open={isFetchingEmployee} />
            {formInitialData && (
                <EmployeeDialog
                    open={employeeDialogOpen}
                    onClose={onEmployeeDialogClose}
                    onSubmit={onCompanyDialogSubmit}
                    onSaved={loadEmployee}
                    initialValues={formInitialData}
                />
            )}
        </AdminPageLayout>
    );
};
