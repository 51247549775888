import {Box, Typography} from '@mui/material';
import React from 'react';
import {OfferDecisionStatus} from '../../lib/dtos/Offer';
import {colors} from '../../theme';
import {StatusElement} from '../StatusElement';

export const offerStatus: Record<OfferDecisionStatus, {text: string; color: string}> = {
    [OfferDecisionStatus.Open]: {text: 'Venter på tilbud', color: colors.orange},
    [OfferDecisionStatus.Waiting]: {text: 'Tilbud til godkjenning', color: colors.orange},
    [OfferDecisionStatus.Accepted]: {text: 'Tilbud godtatt', color: colors.green},
    [OfferDecisionStatus.Rejected]: {text: 'Tilbud avslått', color: colors.red},
    [OfferDecisionStatus.Canceled]: {text: 'Avbrutt', color: colors.red},
};
export const ProjectOfferStatusBanner: React.FC<{offerDecisionStatus: OfferDecisionStatus; text: string}> = ({
    offerDecisionStatus,
    text,
}) => {
    return (
        <Box
            sx={{
                border: 1,
                borderColor: 'formLine.active',
                p: 2.5,
                backgroundColor: `${offerStatus[offerDecisionStatus].color}4D`, //hex with opacity 30%
            }}
        >
            <StatusElement
                iconColor={offerStatus[offerDecisionStatus].color}
                text={offerStatus[offerDecisionStatus].text}
                bgColor={colors.fair}
            />
            <Typography variant={'h6'} mt={1}>
                {text}
            </Typography>
        </Box>
    );
};
