import {useCallback, useState} from 'react';

function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
type SearchType<T> = Array<T>;
export const useSearch = <T>(keys?: Array<keyof T | string>) => {
    const [data, setData] = useState<SearchType<T>>([]);

    function traverse(path: string[], target: any, tester: RegExp, index: number): boolean {
        if (path.length) {
            const searchKey = path[0];
            for (const key in target) {
                if (Object.prototype.hasOwnProperty.call(target, key)) {
                    const v = (target as any)[key];
                    if (path.length == 1 && searchKey == key && (typeof v == 'string' || typeof v == 'number')) {
                        if (tester.test(`${v}`)) return true;
                    } else if (typeof v == 'object') {
                        const res = traverse(path.slice(1), v, tester, index++);
                        if (res) return true;
                    }
                }
            }
        }
        return false;
    }

    const search = (searchValue: string) => {
        if (!searchValue || searchValue == '') return data;
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row) => {
            if (!keys?.length) {
                return Object.values(row).some((v) => searchRegex.test(`${v}`));
            } else {
                for (let index = 0; index < keys.length; index++) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const indexer = keys[index] as string;
                    const path = indexer.split('.');
                    const res = traverse(path, row, searchRegex, 0);
                    if (res) return true;
                }
                return false;
            }
        });
        return filteredRows;
    };

    const setSearchData = useCallback((values: SearchType<T>) => {
        if (!values || !values?.length) return;
        setData(values);
    }, []);
    return {search, setSearchData};
};
