import {useLanguage} from 'language/LanguageProvider';
import {OfferDecisionStatus} from 'lib/dtos/Offer';
import {useContext, VFC} from 'react';
import {AdminAppContext} from 'store/adminAppContext';
import {AdminPageLayout} from '../AdminPageLayout';
import {OfferTable} from './OfferTable';

export const OffersPage: VFC = () => {
    const {state} = useContext(AdminAppContext);
    const {getLangString} = useLanguage();

    return (
        <AdminPageLayout>
            <OfferTable
                title={getLangString('OPEN_OFFERS')}
                requestParams={{status: OfferDecisionStatus.Open}}
                services={state.availableServices}
            />
            {state.user && (
                <OfferTable
                    title={getLangString('MY_OFFERS')}
                    requestParams={{employeeId: state.user.employeeId}}
                    services={state.availableServices}
                />
            )}
            <OfferTable title={getLangString('ALL_OFFERS')} services={state.availableServices} />
        </AdminPageLayout>
    );
};
