import {useQuery} from '@apollo/client';
import parse from 'html-react-parser';
import {FC, useContext} from 'react';
import {Helmet} from 'react-helmet';
import {CMS_SEO, GET_SEO_DATA} from './graphql';
import {AppContext} from './store/appContext';

export const SEO: FC<{
    cmsSEO?: CMS_SEO;
    documentTitle?: string;
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    themeColor?: string;
}> = (props) => {
    const {bgColor} = useContext(AppContext);
    if (props.cmsSEO) {
        return (
            <Helmet>
                {parse(props.cmsSEO.seomatic.metaTagContainer)}
                {parse(props.cmsSEO.seomatic.metaTitleContainer)}
            </Helmet>
        );
    }
    return (
        <Helmet
            title={props.documentTitle}
            meta={[
                {name: 'theme-color', content: bgColor},
                props.description ? {name: 'description', content: props.description} : {},
                {property: 'og:url', content: props.url},
                {property: 'og:title', content: props.title},
                props.image ? {property: 'og:image', content: props.image} : {},
                {property: 'og:type', content: 'article'},
                props.description ? {property: 'og:description', content: props.description} : {},
            ]}
        />
    );
};

export const AppSEO: FC = () => {
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA('/'));
    return <SEO cmsSEO={SEOData} />;
};
