import {AddressEntity} from '../types/Address';

export type UserSimpleDto = {
    customerId: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
};

export type UserDto = {
    id: number;
    type: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    addresses: AddressEntity[];
    createdAt: Date;
    updatedAt: Date;
};

export type EmployeeBaseDto = {
    employeeId: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
};
export type EmployeeDto = EmployeeBaseDto & {
    role: UserRole;
};

export enum UserRole {
    Developer = 1,
    Administrator = 2,
    Management = 3,
    Craftman = 9,
    Customer = 10,
}

export type CreateUserDto = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    role: UserRole;
};
