import React from 'react';
import {dictionaries, SupportedLanguage, TranslationKeys} from './translations';

type ContextProps = {
    userLanguage: SupportedLanguage;
    dictionary: {[key: string]: string};
    userLanguageChange: (selected: SupportedLanguage) => void;
};
// create the language context with default selected language
export const LanguageContext = React.createContext<ContextProps>({
    userLanguage: 'NO',
    dictionary: dictionaries.NO,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userLanguageChange: (selected: SupportedLanguage) => {
        /*method is defined in the provider*/
    },
});

export const LanguageProvider: React.FC = ({children}) => {
    const [userLanguage, setUserLanguage] = React.useState<SupportedLanguage>('NO');

    const provider = {
        userLanguage,
        dictionary: dictionaries[userLanguage],
        userLanguageChange: (selected: SupportedLanguage) => {
            setUserLanguage(selected);
        },
    };

    return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
};

/**
 * Hook to get access to language provider for translations
 */
export const useLanguage = () => {
    const languageContext = React.useContext(LanguageContext);
    const getLangString = (tID: TranslationKeys) => languageContext.dictionary[tID] || tID;
    return {getLangString};
};
