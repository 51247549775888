import {useIsAuthenticated} from '@azure/msal-react';
import {Box, Container, Grid, styled, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import illustration from '../../assets/illustrations/Desk.svg';
import {useLanguage} from '../../language/LanguageProvider';
import {ButtonPrimaryLarge} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {getProjectsRoutePath} from '../loggedInPages/LoggedInRoutes';

const Text = styled(Typography)(({theme}) => ({
    marginLeft: '8%',
    fontSize: '1.3125rem',
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        textAlign: 'center',
        fontSize: '1rem',
    },
}));
const CustomContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& img': {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
}));
export const InquiryConfirmation: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const {getLangString} = useLanguage();

    const handleButtonClick = () => {
        history.push(getProjectsRoutePath());
    };
    const isLoggedIn = useIsAuthenticated();
    return (
        <CustomContainer maxWidth={'xl'}>
            <CssGrid>
                <DefaultGridPageLayout>
                    <Grid container paddingTop={5}>
                        <Grid item xs={8} order={{xs: 2, sm: 1}}>
                            <Typography
                                variant={'h3'}
                                fontFamily={'SuisseWorks-Regular, serif'}
                                textAlign={'center'}
                                marginBottom={5}
                            >
                                {getLangString('INQUIRY_CONFIRMATION_HEADER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={2} order={{xs: 1, sm: 2}} textAlign={'center'}>
                            <img width={'100%'} height={188} src={illustration} alt={'decorative illustration'} />
                        </Grid>
                        <Grid item xs={8} sm={6} order={{xs: 3}} alignSelf={'center'}>
                            <Text
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        width: '100%',
                                        wordBreak: 'keep-all',
                                    },
                                }}
                            >
                                {getLangString('INQUIRY_CONFIRMATION_TEXT')}
                            </Text>
                            <br />
                            <Text>{!isLoggedIn && getLangString('INQUIRY_CONFIRMATION_SUBTEXT')}</Text>
                        </Grid>
                    </Grid>
                    <Box textAlign={'center'} marginTop={11} marginBottom={10}>
                        <ButtonPrimaryLarge onClick={handleButtonClick}>
                            {getLangString('GO_TO_PROJECT')}
                        </ButtonPrimaryLarge>
                    </Box>
                </DefaultGridPageLayout>
            </CssGrid>
        </CustomContainer>
    );
};
