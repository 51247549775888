import {Box} from '@mui/material';
import {AxiosPromise} from 'axios';
import {BackButton} from 'components/BackButton';
import {LoaderCentered} from 'components/Loader';
import {CordelCompanySimpleDto} from 'lib/dtos/Cordel';
import {FileDto} from 'lib/dtos/File';
import {M2HttpClient} from 'lib/remote/m2api/ApiHttpClient';
import {InquiryService} from 'lib/services/InquiryService';
import {OfferDetailsAdmin} from 'lib/types/Admin';
import {FileDict, ServiceDictionary} from 'lib/types/Inquiry';
import React, {Dispatch, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ActionType, loadOfferDetails, loadProjectDescriptionCustomer} from 'store/actions';
import {AdminAppContext} from 'store/adminAppContext';
import {OfferDto} from '../../../../lib/dtos/Offer';
import {useM2Axios} from '../../../../lib/hooks/useM2Axios';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {AdminPageLayoutWithSimpleMenu} from '../AdminPageLayout';
import {getOfferByIDPath, getOffersListPath} from '../AdminPages';
import {OfferEditableContent} from './OfferEditableContent';
import {OfferRejectedContent} from './OfferRejectedContent';

export type OfferContentProps = {
    offerDetails: OfferDetailsAdmin;
    dispatch: Dispatch<ActionType>;
    inquiryCompanies: CordelCompanySimpleDto[];
    uploadedFiles: {[id: number]: FileDict<FileDto & {cordelCompanyOfferId: number}>};
    goToEditPage: () => void;
    availableServices?: ServiceDictionary;
    reFetchOffer: () => AxiosPromise<{data: OfferDto}>;
};
const OfferDetailsPage: React.FC<{Content: React.FC<OfferContentProps>}> = ({Content}) => {
    const {state, dispatch} = React.useContext(AdminAppContext);
    const params = useParams<{projectID: string; offerID: string}>();
    const history = useHistory();
    const [initialLoading, setInitialLoading] = useState<boolean>();

    const [{data: offerDetails}, getOffer] = useM2Axios<{data: OfferDto}>(
        {url: `${ApiEndpoints.offer.byId(parseInt(params.offerID))}`},
        {
            manual: true,
        },
    );
    React.useEffect(() => {
        const fetchOfferData = async () => {
            setInitialLoading(true);
            await getOffer();
            setInitialLoading(false);
        };
        fetchOfferData();
    }, [getOffer, params.offerID]);

    const [inquiryCompanies, setInquiryCompanies] = React.useState<CordelCompanySimpleDto[]>([]);

    React.useEffect(() => {
        const load = async (offer: OfferDto) => {
            dispatch(loadOfferDetails(offer));
            const service = new InquiryService(M2HttpClient);
            const inquiryCompanies = await service.getInquiryCompanies(offer.inquiryId);
            const response = await service.getInquiryById(offer.inquiryId);
            setInquiryCompanies(inquiryCompanies.data);
            dispatch(loadProjectDescriptionCustomer({text: response.data.description, images: response.data.images}));
        };
        if (offerDetails && offerDetails.data.inquiryId) {
            load(offerDetails.data);
        }
    }, [dispatch, offerDetails]);

    const getUploadedFiles = (): {[id: number]: FileDict<FileDto & {cordelCompanyOfferId: number}>} => {
        if (offerDetails && offerDetails.data.cordelCompanyOffers) {
            return offerDetails.data.cordelCompanyOffers.reduce(
                (x, companyOffer) => ({
                    ...x,
                    [companyOffer.companyId]: companyOffer.files.reduce(
                        (acc, file) => ({
                            ...acc,
                            [file.id]: {...file, cordelCompanyOfferId: companyOffer.cordelCompanyOfferId},
                        }),
                        {},
                    ),
                }),
                {},
            );
        } else {
            return {};
        }
    };
    const goBackToList = () => {
        history.replace(getOffersListPath());
    };
    const goToEditableOfferPage = () => {
        offerDetails?.data &&
            history.push(getOfferByIDPath(offerDetails.data.project.projectId, offerDetails.data.offerId));
    };

    return (
        <AdminPageLayoutWithSimpleMenu>
            <Box mt={2}>
                <BackButton onClickOverride={goBackToList} />
            </Box>
            {initialLoading ? (
                <LoaderCentered />
            ) : (
                state.offerDetails && (
                    <Content
                        offerDetails={state.offerDetails}
                        dispatch={dispatch}
                        inquiryCompanies={inquiryCompanies}
                        goToEditPage={goToEditableOfferPage}
                        uploadedFiles={getUploadedFiles()}
                        availableServices={state.availableServices}
                        reFetchOffer={getOffer}
                    />
                )
            )}
        </AdminPageLayoutWithSimpleMenu>
    );
};

export const OfferDetailsPageRejected: React.FC = () => OfferDetailsPage({Content: OfferRejectedContent});
export const OfferDetailsPageEditable: React.FC = () => OfferDetailsPage({Content: OfferEditableContent});
