import {nbNO} from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import {SimplePaletteColorOptions} from '@mui/material/styles/createPalette';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import type {} from '@mui/x-data-grid/themeAugmentation';
import React from 'react';

declare module '@mui/material/Button' {
    //Define which palette props should be available as Button color
    interface ButtonPropsColorOverrides {
        tertiary: true;
        tan: true;
        accent: true;
        quaternary: true;
    }
    //Define which variants should be available
    interface ButtonPropsVariantOverrides {
        underlined: true;
    }
}
//Define available typography variants
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        h1_hero: true;
        homepageHero: true;
        h3_bold: true;
        h4_light: true;
        pageIntro: true;
        sectionHeading: true;
        sectionIntro: true;
        articleCardHeading: true;
        articleIntro: true;
        error: true;
        success: true;
    }
}
declare module '@mui/material/styles' {
    interface Theme {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customThemeProp: any; //example value
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customThemeProp?: any; //example value
    }
    interface TypographyVariants {
        h1_hero: React.CSSProperties;
        homepageHero: React.CSSProperties;
        h4_light: React.CSSProperties;
        pageIntro: React.CSSProperties;
        sectionHeading: React.CSSProperties;
        sectionIntro: React.CSSProperties;
        articleCardHeading: React.CSSProperties;
        articleIntro: React.CSSProperties;
        error: React.CSSProperties;
        success: React.CSSProperties;
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        h1_hero?: React.CSSProperties;
        homepageHero?: React.CSSProperties;
        h3_bold?: React.CSSProperties;
        h4_light?: React.CSSProperties;
        pageIntro?: React.CSSProperties;
        sectionHeading?: React.CSSProperties;
        sectionIntro?: React.CSSProperties;
        articleCardHeading?: React.CSSProperties;
        articleIntro?: React.CSSProperties;
        error?: React.CSSProperties;
        success?: React.CSSProperties;
    }
    interface Palette {
        accent: SimplePaletteColorOptions;
        tertiary: SimplePaletteColorOptions;
        quaternary: SimplePaletteColorOptions;
        tan?: SimplePaletteColorOptions;
        myCustomColorVariant: SimplePaletteColorOptions; //example value
        formLine: {active: string; inActive: string};
        customBackground: {
            fair: string;
            light: string;
            medium: string;
            darker: string;
            dark: string;
        };
    }
    interface PaletteOptions {
        accent?: SimplePaletteColorOptions;
        quaternary?: SimplePaletteColorOptions;
        tertiary?: SimplePaletteColorOptions;
        tan?: SimplePaletteColorOptions;
        myCustomColorVariant?: SimplePaletteColorOptions; //example value
        formLine?: {active: string; inActive: string};
        customBackground?: {
            fair: string;
            light: string;
            medium: string;
            darker: string;
            dark: string;
        };
    }
}
const breakpoints = createBreakpoints({});

type ColorProps =
    | 'black'
    | 'grayDark'
    | 'orange'
    | 'red'
    | 'green'
    | 'yellow'
    | 'fair'
    | 'tanDarkest'
    | 'tanDarker'
    | 'tanDark'
    | 'tanMedium'
    | 'tan'
    | 'tanLight'
    | 'brownLight'
    | 'brown'
    | 'brownDark';
export const colors: Record<ColorProps, string> = {
    black: '#121212',

    grayDark: '#242424',

    orange: '#D36800',

    red: '#D33900',

    green: '#1C7B32',

    yellow: '#CC9F07',

    fair: '#F4ECE1',

    tanDarkest: '#504431',
    tanDarker: '#5D554A',
    tanDark: '#91887A',
    tanMedium: '#888174',
    tan: '#BBAF9D',
    tanLight: '#E6D7BF',

    brownLight: '#D6B28F',
    brown: '#C3905E',
    brownDark: '#9D7A58',
};

export const customTheme = createTheme(
    {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1366,
            },
        },
        palette: {
            primary: {
                main: colors.grayDark,
                contrastText: colors.tanLight,
            },
            secondary: {
                main: colors.tanDarkest,
                contrastText: colors.tanLight,
            },
            tertiary: {
                main: colors.brownLight,
                contrastText: colors.black,
            },
            quaternary: {
                main: colors.brown,
                contrastText: colors.black,
            },
            tan: {
                main: colors.tan,
                contrastText: colors.black,
            },
            error: {
                main: colors.red,
            },
            success: {
                main: colors.green,
            },
            info: {
                main: colors.orange,
            },
            accent: {
                main: colors.orange,
                contrastText: colors.black,
            },
            text: {
                primary: colors.black,
                secondary: colors.tanDarker,
                disabled: 'rgba(18, 18, 18, 0.2)',
            },
            background: {
                default: colors.tanLight,
            },
            divider: 'rgba(18, 18, 18, 0.7)',
            action: {
                disabledBackground: colors.tan,
                disabled: 'rgba(18, 18, 18, 0.6)',
            },
            //Example custom color variant
            myCustomColorVariant: {
                main: '#0091d7',
            },
            formLine: {
                inActive: colors.tanDark,
                active: colors.black,
            },
            customBackground: {
                fair: colors.fair,
                light: colors.tanLight,
                medium: colors.brownLight,
                darker: colors.brown,
                dark: colors.brownDark,
            },
        },
        typography: {
            fontFamily: 'SuisseIntl-Regular',
            body1: {
                fontSize: '1rem',
                color: '#121212',
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            body2: {
                fontSize: '0.875rem',
            },
            error: {
                fontSize: '1rem',
                color: colors.red,
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            success: {
                fontSize: '1rem',
                color: colors.green,
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            h1: {
                fontFamily: 'SuisseWorks-Regular, serif',
                fontSize: '3.625rem',
                lineHeight: '1.34',
                [breakpoints.down('sm')]: {
                    fontSize: '2.6875rem',
                    lineHeight: '1.22',
                },
            },
            h2: {
                fontFamily: 'SuisseWorks-Regular, serif',
                fontSize: '3.375rem',
            },
            h3: {
                fontSize: '2.625rem',
                [breakpoints.down('sm')]: {
                    fontSize: '1.75rem',
                },
            },
            h3_bold: {
                fontSize: '2.625rem',
                fontFamily: 'SuisseIntl-Medium',
                fontWeight: 'normal',
                [breakpoints.down('sm')]: {
                    fontSize: '1.75rem',
                },
            },
            h4: {
                fontSize: '2rem',
                fontFamily: 'SuisseIntl-Medium',
                [breakpoints.down('sm')]: {
                    fontSize: '1.5rem',
                },
            },
            h4_light: {
                fontSize: '2rem',
                fontFamily: 'SuisseIntl-Regular, san-serif',
                [breakpoints.down('sm')]: {
                    fontSize: '1.5rem',
                },
            },
            h5: {
                fontSize: '1.625rem',
                fontFamily: 'SuisseIntl-Medium, san-serif',
                [breakpoints.down('sm')]: {
                    fontSize: '1.3125rem',
                },
            },
            h6: {
                fontSize: '1.3125rem',
                fontFamily: 'SuisseIntl-Medium',
            },
            subtitle1: {
                fontSize: '1rem',
                fontFamily: 'SuisseIntl-Medium',
                marginBottom: '1rem',
            },
            //Example of custom variant
            h1_hero: {
                fontFamily: 'SuisseWorks-Regular, serif',
                fontWeight: 'normal',
                color: colors.brownLight,
                fontSize: '3.625rem',
                [breakpoints.down('sm')]: {
                    fontSize: '1.75rem',
                },
            },
            homepageHero: {
                fontFamily: 'SuisseWorks-Regular, serif',
                fontWeight: 'normal',
                color: colors.orange,
                fontSize: '2rem',
                lineHeight: '1.25',
                [breakpoints.up('sm')]: {
                    fontSize: '2.6875rem',
                },
                [breakpoints.up('md')]: {
                    fontSize: '3.125rem',
                    lineHeight: '1.34',
                },
                [breakpoints.up('lg')]: {
                    fontSize: '3.875rem',
                    lineHeight: '1.34',
                },
            },
            pageIntro: {
                fontFamily: 'SuisseIntl-Regular',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                lineHeight: '1.37',
                [breakpoints.up('md')]: {
                    fontSize: '2.625rem',
                    lineHeight: '1.36',
                },
            },
            sectionHeading: {
                fontSize: '2.125rem',
                color: colors.black,
                lineHeight: '1.24',
                [breakpoints.up('md')]: {
                    // fontSize: '3.375rem', // This is the value on the homepage
                    fontSize: '2.625rem',
                    lineHeight: '1.2',
                },
            },
            sectionIntro: {
                fontSize: '1.125rem',
                color: colors.black,
                lineHeight: '1.44',
                [breakpoints.up('md')]: {
                    fontSize: '1.3125rem',
                    lineHeight: '1.57',
                },
            },
            articleCardHeading: {
                fontFamily: 'SuisseWorks-Regular, serif',
                fontSize: '1.75rem',
                lineHeight: '1.35',
                [breakpoints.up('md')]: {
                    fontSize: '2rem',
                    lineHeight: '1.35',
                },
            },
            articleIntro: {
                fontSize: '1.3125rem',
                lineHeight: '1.43',
                [breakpoints.up('md')]: {
                    fontSize: '1.625rem',
                },
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        fontFamily: 'SuisseIntl-Regular',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        h1_hero: 'h1',
                        homepageHero: 'h1',
                        h3_bold: 'h3',
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        textTransform: 'initial',
                        borderRadius: '6px',
                    },
                    containedSizeLarge: {
                        height: 56,
                        minWidth: 257,
                        [breakpoints.down('sm')]: {
                            minWidth: 240,
                        },
                    },
                    sizeMedium: {
                        height: 44,
                        minWidth: 236,
                        borderRadius: '3px',
                    },
                    containedSizeSmall: {
                        height: 32,
                        borderRadius: '3px',
                    },
                    textSizeMedium: {
                        minWidth: 'auto',
                    },
                },
                variants: [
                    {
                        props: {
                            variant: 'underlined',
                        },
                        style: {
                            padding: '0 1px',
                            minWidth: 'fit-content',
                            borderRadius: 0,
                            borderBottom: '1px solid',
                            fontSize: 'inherit',
                            fontFamily: 'SuisseIntl-Medium, sans-serif',
                        },
                    },
                    {
                        props: {
                            variant: 'underlined',
                            color: 'primary',
                        },
                        style: {
                            color: colors.black,
                        },
                    },
                    {
                        props: {
                            variant: 'underlined',
                            color: 'accent',
                        },
                        style: {
                            color: colors.orange,
                        },
                    },
                    {
                        props: {
                            variant: 'outlined',
                            color: 'quaternary',
                        },
                        style: {
                            color: colors.black,
                            background: colors.brown,
                            borderRadius: 0,
                            border: '1px solid black',
                            ':hover': {
                                background: colors.brown,
                                border: '1px solid black',
                            },
                        },
                    },
                    {
                        props: {
                            variant: 'outlined',
                            color: 'error',
                        },
                        style: {
                            color: colors.red,
                            borderRadius: 3,
                            border: '1px solid ' + colors.red,
                        },
                    },
                ],
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        borderColor: colors.black,
                        '& .MuiOutlinedInput-notchedOutline ': {
                            borderColor: colors.black,
                        },
                        '& .MuiAutocomplete-input': {
                            padding: 0,
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        border: '1px solid',
                        borderColor: colors.black,
                        backgroundColor: 'transparent',
                        '&:after, &:before': {
                            border: 0,
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-focused': {
                            backgroundColor: 'transparent',
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 0,
                        },
                        '&:hover:after': {
                            borderBottom: 0,
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        left: '16px',
                        top: '8px',
                        [breakpoints.down('sm')]: {
                            left: '6px',
                        },
                        color: colors.tanDarker,
                    },
                    shrink: {
                        transform: 'translate(9px, 0px) scale(0.75)',
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        marginTop: 0,
                        marginRight: '12px',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: '27px',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            paddingLeft: 24,
                            paddingTop: 24,
                            paddingBottom: 24,
                            '& .MuiAutocomplete-input': {
                                padding: 0,
                            },
                        },
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 3px 6px #0000001A',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px',
                        [breakpoints.up('sm')]: {
                            padding: '8px 40px',
                        },
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    maxWidth: 'xl',
                },
                styleOverrides: {
                    root: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        [breakpoints.up('sm')]: {
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        },
                        [breakpoints.up('md')]: {
                            paddingLeft: '48px',
                            paddingRight: '48px',
                        },
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        paddingLeft: 0,
                        paddingRight: 0,
                        alignItems: 'flex-start',
                    },
                    content: {
                        margin: 0,
                    },
                },
            },
            MuiSwitch: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        width: 35,
                        height: 18,
                        padding: 0,
                        '& .MuiSwitch-switchBase': {
                            padding: 4,
                            opacity: 0.5,

                            '&.Mui-checked': {
                                opacity: 1,
                                transform: 'translateX(17px)',
                                '& + .MuiSwitch-track': {
                                    opacity: 1,
                                    backgroundColor: 'transparent',
                                    border: '1px solid ' + colors.black,
                                },
                            },
                        },

                        '& .MuiSwitch-thumb': {
                            width: 10,
                            height: 10,
                            borderRadius: 0,
                            color: colors.black,
                            boxShadow: 'none',
                        },
                        '& .MuiSwitch-track': {
                            borderRadius: 0,
                            backgroundColor: 'transparent',
                            border: '1px solid' + colors.black,
                        },
                    },
                },
            },
            MuiGrid: {
                defaultProps: {
                    columns: 8,
                    columnSpacing: 3,
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        fontFamily: 'SuisseIntl-Medium, sans-serif',
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid ' + colors.black,
                        '&:last-child': {
                            borderBottom: 'none',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontFamily: 'inherit',
                        paddingLeft: 0,
                        paddingTop: 16,
                        paddingBottom: 16,
                        border: 'none',
                        verticalAlign: 'top',
                        minWidth: 56,
                    },
                },
            },
            MuiTablePagination: {
                defaultProps: {
                    rowsPerPageOptions: [10, 25, 50],
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backdropFilter: 'blur(15px)',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: {
                        '& .MuiBackdrop-root': {
                            backdropFilter: 'none',
                            backgroundColor: 'rgba(0,0,0,0)',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        backgroundColor: colors.fair,
                    },
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        backgroundColor: 'rgba(18, 18, 18, 0.02)',
                    },
                },
            },
            MuiDataGrid: {
                defaultProps: {
                    disableSelectionOnClick: true,
                    autoHeight: true,
                    localeTex: nbNO,
                },
                styleOverrides: {
                    root: {
                        border: 'none',
                        '& .MuiDataGrid-columnHeader': {
                            fontFamily: 'SuisseIntl-Medium, sans-serif',
                            fontSize: '1.1875rem',
                            padding: '8px',
                            '&:first-child': {
                                paddingLeft: 0,
                            },
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                padding: 0,
                            },
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            color: 'transparent',
                        },
                        '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
                            {
                                outline: 'none',
                            },
                        '& .MuiDataGrid-cell': {
                            minWidth: '200px',
                            maxWidth: '200px',
                            borderBottom: '1px solid',
                            borderColor: colors.black,
                            padding: '8px',
                            '&:first-child': {
                                paddingLeft: 0,
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                    },
                },
            },
        },
    },
    nbNO,
);
