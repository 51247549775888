import React from 'react';
import {IconProps} from './IconProps';

export const ChevronExpandMoreIcon: React.FC<IconProps> = ({size}) => (
    <svg viewBox='0 0 24 24' width={size || 24} height={size || 24}>
        <path d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z' />
    </svg>
);
export const ChevronExpandLessIcon: React.FC<IconProps> = ({size}) => (
    <svg viewBox='0 0 24 24' width={size || 24} height={size || 24}>
        <path d='m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
    </svg>
);
