import {Box, BoxProps, GridProps, useTheme} from '@mui/material';
import React from 'react';

// TODO: Should these be named LayoutContainer and LayoutGrid to avoid confusion with MUI?
type ContainerProps = {
    fullWidth?: boolean;
};

export const Container: React.FC<ContainerProps> = (props) => {
    const theme = useTheme();
    const fullWidth = props.fullWidth ?? false;

    return (
        <Box
            sx={{
                maxWidth: fullWidth ? 'none' : '1366px',
                margin: '0 auto',
                px: 2,
                [theme.breakpoints.up('sm')]: {
                    px: 3,
                },
                [theme.breakpoints.up('md')]: {
                    px: 6,
                },
            }}
        >
            {props.children}
        </Box>
    );
};

export const CssGrid: React.FC<GridProps & {numberOfColumns?: number}> = (props) => {
    const theme = useTheme();

    return (
        <Box
            {...props}
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${props.numberOfColumns || 8}, 1fr)`,
                columnGap: 1.5,
                [theme.breakpoints.up('sm')]: {
                    columnGap: 2.5,
                },
                [theme.breakpoints.up('md')]: {
                    columnGap: 3,
                },
                ...props.sx,
            }}
        >
            {props.children}
        </Box>
    );
};
export const DefaultGridPageLayout: React.FC<BoxProps> = (props) => (
    <Box
        {...props}
        sx={{
            gridColumn: {xs: 'span 8', md: '2 / span 6'},
            ...props.sx,
        }}
    >
        {props.children}
    </Box>
);
