import {Box} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../graphql/helpers';
import {ContentBlock} from './PageContent';
import {PersonCard} from './partials/PersonCard';
import {SliderWithControls} from './partials/Slider';

type Props = {
    id: number;
    heading: string;
    people: {
        id: string;
        title: string;
        slug: string;
        jobTitle: string;
        phoneNumber: string;
        emailAddress: string;
        profileImage: {
            id: string;
            url: string;
        }[];
    }[];
    readMoreLink: string;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const People: React.FC<Props> = (props) => {
    const readMoreLink = props.readMoreLink ? deconstructHTML(props.readMoreLink) : null;
    return (
        <ContentBlock quickLink={props.quickLink} size='lg'>
            <Box sx={{overflow: 'hidden'}}>
                <SliderWithControls
                    heading={props.heading}
                    link={
                        readMoreLink
                            ? {
                                  text: readMoreLink.text,
                                  url: readMoreLink.url,
                              }
                            : undefined
                    }
                    items={props.people.map((person: any) => {
                        return (
                            <Box key={person.id}>
                                <PersonCard person={person} />
                            </Box>
                        );
                    })}
                    breakpoints={{
                        '0': {spaceBetween: 10, slidesPerView: 1.8},
                        '600': {spaceBetween: 24, slidesPerView: 2.2},
                        '1200': {spaceBetween: 24, slidesPerView: 4.1},
                    }}
                />
            </Box>
        </ContentBlock>
    );
};
