import {Box, DialogContent, DialogTitle, TextField, Typography} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from './Buttons';
import {CustomDialog} from './CustomDialog';

export const DialogWithTextField: React.FC<{
    title: string;
    subTitle: string;
    handleClose: () => void;
    handleConfirmClick: (textFieldValue: string) => void;
    isOpen?: boolean;
}> = (props) => {
    const {getLangString} = useLanguage();
    const [value, setValue] = React.useState<string>();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    const handleConfirm = () => {
        props.handleConfirmClick(value || '');
    };
    return (
        <CustomDialog
            open={props.isOpen !== undefined ? props.isOpen : true}
            onClose={props.handleClose}
            aria-labelledby={props.title}
            keepMounted={true}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Typography>{props.subTitle}</Typography>
                <TextField
                    multiline
                    fullWidth={true}
                    minRows={5}
                    placeholder={getLangString('TEXT_AREA_PLACEHOLDER_DESCRIBE')}
                    value={value}
                    onChange={handleChange}
                    color={'secondary'}
                    sx={{
                        mt: 4,
                        mb: 5,
                        '& .MuiOutlinedInput-notchedOutline ': {
                            borderColor: 'formLine.inActive',
                        },
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonSecondaryMedium onClick={props.handleClose} sx={{minWidth: 108, mr: 1}}>
                        {getLangString('CANCEL')}
                    </ButtonSecondaryMedium>
                    <ButtonPrimaryMedium onClick={handleConfirm} sx={{minWidth: 108}}>
                        {getLangString('SUBMIT')}
                    </ButtonPrimaryMedium>
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};
