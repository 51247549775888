import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const PlusIcon: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || 24} height={size || 24} viewBox='0 0 24 24'>
        <path
            d='M.764-3.636V-5.03H4.383V-8.674H5.8V-5.03H9.421v1.395H5.8V0H4.383V-3.636Z'
            transform='translate(6.886 16.324)'
            fill={color || colors.black}
        />
    </svg>
);
