import {ApolloProvider, useQuery} from '@apollo/client';
import {Box, Link, useTheme} from '@mui/material';
import {SxProps} from '@mui/system';
import React, {useContext} from 'react';
import {apolloClient, GET_FOOTER} from '../graphql';
import {deconstructHTML} from '../graphql/helpers';
import {useLanguage} from '../language/LanguageProvider';
import {CookieContext} from './CookieConsentProvider';
import {Container, CssGrid} from './Layout';

export const Footer: React.FC = () => {
    const theme = useTheme();
    const {getLangString} = useLanguage();
    const cookieContext = useContext(CookieContext);

    const {loading, error, data} = useQuery(GET_FOOTER);

    if (loading || error) {
        return <Box></Box>;
    }

    const {address, phoneNumber, emailAddress, navigationLinks, socialLinks} = data.globalSet;

    const headingStyle = {
        fontSize: '0.75rem',
        letterSpacing: '0.02em',
        opacity: '0.6',
        mb: 1.5,
        [theme.breakpoints.up('md')]: {
            fontSize: '0.9375rem',
        },
    };

    const textStyle = {
        fontSize: '1.125rem',
        lineHeight: '2',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.0625rem',
        },
    };
    const linkStyle: SxProps = {
        textDecoration: 'none',
        color: 'text.primary',
        display: 'inline-block',
        '&:hover': {
            textDecoration: 'underline',
        },
    };

    return (
        <ApolloProvider client={apolloClient}>
            <Box
                sx={{
                    backgroundColor: 'accent.main',
                    pt: 4,
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 12,
                        pt: 9,
                    },
                }}
            >
                <Container>
                    <CssGrid sx={{rowGap: 8}}>
                        <Box
                            sx={{
                                gridColumn: '1 / span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridRow: '1',
                                    gridColumn: '4 / span 3',
                                },
                            }}
                        >
                            <Box sx={headingStyle}>M2</Box>
                            <Box sx={textStyle}>
                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                {navigationLinks.map((navigationLink: any) => {
                                    const link = deconstructHTML(navigationLink.linkTo);
                                    if (link) {
                                        return (
                                            <Box key={navigationLink.id}>
                                                <Link sx={linkStyle} href={link.url}>
                                                    {link.text}
                                                </Link>
                                            </Box>
                                        );
                                    }
                                })}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                gridColumn: '1 / span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridRow: '1',
                                    gridColumn: '1 / span 3',
                                },
                            }}
                        >
                            <Box sx={headingStyle}>{getLangString('FOOTER_HEADER_CONTACT_INFO')}</Box>
                            <Box sx={textStyle}>
                                {address !== '' && (
                                    <Box dangerouslySetInnerHTML={{__html: address.replace('\n', '<br/>')}} />
                                )}
                                {phoneNumber !== '' && (
                                    <Box>
                                        <Link sx={linkStyle} href={`tel:${phoneNumber}`}>
                                            {phoneNumber}
                                        </Link>
                                    </Box>
                                )}
                                {emailAddress !== '' && (
                                    <Box>
                                        <Link sx={linkStyle} href={`mailto:${emailAddress}`}>
                                            {emailAddress}
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                gridColumn: '1 / span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridRow: '1',
                                    gridColumn: '7 / span 2',
                                },
                            }}
                        >
                            <Box sx={headingStyle}>{getLangString('FOOTER_HEADER_SOCIAL_MEDIA')}</Box>
                            <Box sx={textStyle}>
                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                {socialLinks.map((socialLink: any) => {
                                    const link = deconstructHTML(socialLink.linkTo);
                                    if (link) {
                                        return (
                                            <Box key={socialLink.id}>
                                                <Link sx={linkStyle} href={link.url}>
                                                    {link.text}
                                                </Link>
                                            </Box>
                                        );
                                    }
                                })}
                            </Box>
                        </Box>
                    </CssGrid>

                    <Box
                        sx={{
                            mt: 8,
                            fontSize: '0.875rem',
                            [theme.breakpoints.up('md')]: {
                                fontSize: '0.9375rem',
                                mt: 7,
                            },
                        }}
                    >
                        <Link href='/personvernerklæring' sx={linkStyle}>
                            {getLangString('PRIVACY_TERMS_COOKIE_BTN')}
                        </Link>
                        <Link
                            onClick={() => cookieContext.setShowConsentBox && cookieContext.setShowConsentBox(true)}
                            sx={{...linkStyle, cursor: 'pointer', ml: {xs: 0, sm: 5}, my: {xs: 2, sm: 0}}}
                        >
                            {getLangString('MANAGE_COOKIES_BTN')}
                        </Link>
                    </Box>
                </Container>
            </Box>
        </ApolloProvider>
    );
};
