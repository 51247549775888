import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {Box} from '@mui/material';
import React, {useContext} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {adminBasePath, myPagesBasePath} from '../../App';
import {useInitializeUser} from '../../initUser';
import {OrderContentDto} from '../../lib/dtos/OrderContent';
import {UserRole} from '../../lib/dtos/User';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {ContentService} from '../../lib/services/ContentService';
import {UserService} from '../../lib/services/UserService';
import {SEO} from '../../SEO';
import {loadServiceTypes, loadUserInfo} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {PageWithSubmenu} from '../AppLayout';
import {getInquirySummaryPath} from '../inquiryPages/InquiryPageRoutes';
import {LoaderCentered} from '../Loader';
import {SignInPage} from '../openPages/SignInPage';
import {AccountInfoPage} from './AccountInfoPage';
import {DiscountsPage} from './DiscountsPage';
import {DocumentationPage} from './DocumentationPage';
import {EndedProjectPage} from './EndedProjectPage';
import {InvoicesPage} from './InvoicesPage';
import {MyProjectsPage} from './MyProjectsPage';
import {ProjectOfferPage} from './ProjectOfferPage';
import {ProjectPage} from './ProjectPage';
import {ProjectPaymentPage} from './ProjectPaymentPage';

export const getOfferRoutePath = (projectID: number, offerID: number): string =>
    `${myPagesBasePath}/prosjekter/${projectID}/tilbud/${offerID}`;
export const getPaymentRoutePath = (projectID: string): string => `${myPagesBasePath}/prosjekter/${projectID}/betaling`;
export const getEndedProjectRoutePath = (projectID: number): string =>
    `${myPagesBasePath}/prosjekter/${projectID}/avsluttet`;
export const getProjectRoutePath = (projectID: number): string => `${myPagesBasePath}/prosjekter/${projectID}/aktiv`;
export const getProjectsRoutePath = (): string => `${myPagesBasePath}/prosjekter`;
export const getInvoicesRoutePath = (): string => `${myPagesBasePath}/faktura`;
export const getDocumentationsRoutePath = (): string => `${myPagesBasePath}/dokumentasjon`;
export const getAccountSettingsRoutePath = (): string => `${myPagesBasePath}/kontoinnstillinger`;
export const getDiscountsRoutePath = (): string => `${myPagesBasePath}/fordeler`;

export const AccessContext = React.createContext<{accessToken: string}>({accessToken: ''});

const LoggedInRoutes: React.FC = () => {
    const {path} = useRouteMatch();
    const {dispatch, state} = useContext(AppContext);
    React.useEffect(() => {
        const service = new ContentService(M2HttpClient);
        const fetchData = async () => {
            try {
                const services: OrderContentDto = await service.getOrderContentDetails();
                dispatch(loadServiceTypes(services));
            } catch (e) {
                return;
            }
        };
        fetchData();
    }, [dispatch]);

    const {accessToken, userRole} = useInitializeUser();
    React.useEffect(() => {
        const getUserDetails = async () => {
            if (!state.user && accessToken) {
                const service = new UserService(M2HttpClient);
                try {
                    const response = await service.getUserDetails();
                    dispatch(loadUserInfo(response.data));
                } catch (e) {
                    return;
                }
            }
        };
        getUserDetails();
    }, [dispatch, state.user, accessToken]);
    if (!accessToken || userRole === undefined) {
        return <LoaderCentered />;
    }

    const inquiryFromLocalStorage = localStorage.getItem('new_inquiry_data');

    if (userRole === UserRole.Customer) {
        if (inquiryFromLocalStorage) {
            return <Redirect to={getInquirySummaryPath()} />;
        }
        return (
            <>
                <SEO documentTitle={'Mine Sider'} title={'Mine Sider'} />
                <Switch>
                    <Route path={`${path}/prosjekter/:projectID/tilbud/:offerID`} component={ProjectOfferPage} />
                    <Route path={`${path}/prosjekter/:projectID/betaling`} component={ProjectPaymentPage} />
                    <Route path={`${path}/prosjekter/:projectID/avsluttet`} component={EndedProjectPage} />
                    <Route path={`${path}/prosjekter/:projectID/aktiv`} component={ProjectPage} />
                    <PageWithSubmenu>
                        <Switch>
                            <Route path={`${path}/prosjekter`} component={MyProjectsPage} />
                            <Route path={`${path}/faktura`} component={InvoicesPage} />
                            <Route path={`${path}/dokumentasjon`} component={DocumentationPage} />
                            <Route path={`${path}/kontoinnstillinger`} component={AccountInfoPage} />
                            <Route path={`${path}/fordeler`} component={DiscountsPage} />
                            <Route exact path={path}>
                                <Redirect to={`${path}/prosjekter`} />
                            </Route>
                        </Switch>
                    </PageWithSubmenu>
                </Switch>
            </>
        );
    } else {
        return <Redirect to={`${adminBasePath}/inquiries`} />;
    }
};

export const Protected: React.FC = () => {
    return (
        <Box width={'100%'}>
            <AuthenticatedTemplate>
                <LoggedInRoutes />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignInPage />
            </UnauthenticatedTemplate>
        </Box>
    );
};
