import {Grid} from '@mui/material';
import {InfoField} from 'components/InfoField';
import * as React from 'react';

export type ContactInfoProps = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
};

export const ContactInfo: React.FC<ContactInfoProps> = (props) => (
    <Grid container columnSpacing={0}>
        <Grid item xs={8} sm={4}>
            <InfoField>
                {props.firstName} {props.lastName}
            </InfoField>
        </Grid>
        <Grid item xs={8} sm={4}>
            <InfoField>{props.phoneNumber}</InfoField>
        </Grid>
        <Grid item xs={8} sm={4}>
            <InfoField>{props.email}</InfoField>
        </Grid>
        <Grid item xs={8} sm={4}>
            <InfoField>{props.address}</InfoField>
        </Grid>
    </Grid>
);
