import {useQuery} from '@apollo/client';
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useParams} from 'react-router-dom';
import {SERVICES_INDEX_PAGE_URL} from '../../cms/helpers';
import {CMS_SEO, GET_SEO_DATA, GET_SERVICE_ENTRY_PAGE} from '../../graphql';
import {useLanguage} from '../../language/LanguageProvider';
import {SEO} from '../../SEO';
import {ButtonPrimaryLarge} from '../Buttons';
import {PageContent} from '../cms/PageContent';
import {PageOverline} from '../cms/PageOverline';
import {ArticleSlider} from '../cms/partials/ArticleSlider';
import {NotFound} from '../errorPages/NotFound';
import {Container, CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';

export const ServiceEntryPage: React.FC = () => {
    const {getLangString} = useLanguage();
    const relatedArticlesHeading = getLangString('RELATED_ARTICLES');
    const theme = useTheme();
    const {slug} = useParams<{slug: string}>();
    const {loading, error, data} = useQuery(GET_SERVICE_ENTRY_PAGE(slug));
    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(`${SERVICES_INDEX_PAGE_URL}/${slug}`));

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;

    if (!entry || entry.typeHandle !== 'service') {
        return <NotFound />;
    }

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                component='article'
                sx={{
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 10,
                    },
                }}
            >
                <PageOverline text='Våre tjenester' />

                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '1 / span 5',
                                },
                                [theme.breakpoints.up('lg')]: {
                                    gridColumn: '2 / span 5',
                                },
                            }}
                        >
                            <Typography component='h1' variant='pageIntro'>
                                {entry.title}
                            </Typography>

                            <Typography
                                sx={{
                                    '& a': {color: 'text.primary'},
                                }}
                                component='div'
                                variant='articleIntro'
                                dangerouslySetInnerHTML={{__html: entry.introText}}
                            />

                            <Box
                                sx={{
                                    mt: 4,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 6,
                                    },
                                }}
                            >
                                <ButtonPrimaryLarge href={`/bestilling?sid=${entry.id}`}>
                                    {getLangString('MAKE_ORDER_BTN_TEXT')}
                                </ButtonPrimaryLarge>
                            </Box>
                        </Box>
                    </CssGrid>

                    <Box
                        sx={{
                            mt: 8,
                        }}
                    >
                        <img style={{width: '100%', display: 'block'}} src={entry.mainImage[0].url} alt='' />
                    </Box>
                </Container>

                <Box>
                    <PageContent data={entry.pageContent} />
                </Box>

                {entry.relatedArticles.length > 0 && (
                    <Box
                        sx={{
                            mt: 12,
                            [theme.breakpoints.up('md')]: {
                                mt: 16,
                            },
                        }}
                    >
                        <ArticleSlider heading={relatedArticlesHeading} articles={entry.relatedArticles} />
                    </Box>
                )}
            </Box>
        </>
    );
};
