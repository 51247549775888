import {Box, SxProps, useTheme} from '@mui/material';
import React from 'react';
import {Articles} from './Articles';
import {Banner} from './Banner';
import {BodyText} from './BodyText';
import {ColumnBanner} from './ColumnBanner';
import {Columns} from './Columns';
import {ContactForm} from './ContactForm';
import {FeaturedArticle} from './FeaturedArticle';
import {Heading} from './Heading';
import {Image} from './Image';
import {ImageSlider} from './ImageSlider';
import {Partners} from './Partners';
import {People} from './People';
import {Questions} from './Questions';
import {Services} from './Services';

type ContentBlockProps = {
    size: string;
    className?: string;
    quickLink?: {
        linkText: string;
        elementId: string;
    }[];
};
export const ContentBlock: React.FC<ContentBlockProps> = (props) => {
    const theme = useTheme();
    const spacing: Record<string, SxProps> = {
        sm: {
            my: 3,
            [theme.breakpoints.up('md')]: {my: 4},
            [theme.breakpoints.up('lg')]: {my: 5},
        },
        md: {
            my: 6,
            [theme.breakpoints.up('md')]: {my: 8},
            [theme.breakpoints.up('lg')]: {my: 12},
        },
        lg: {
            my: 12,
            [theme.breakpoints.up('md')]: {my: 14},
            [theme.breakpoints.up('lg')]: {my: 16},
        },
    };

    const quickLinkId =
        props.quickLink !== undefined && props.quickLink !== null && props.quickLink.length > 0
            ? props.quickLink[0].elementId
            : undefined;

    return (
        <Box
            className={props.className}
            sx={{
                scrollMarginTop: '100px',
                [theme.breakpoints.up('sm')]: {
                    scrollMarginTop: '140px',
                },
                ...spacing[props.size],
            }}
            id={quickLinkId}
        >
            {props.children}
        </Box>
    );
};

const blocks: Record<string, any> = {
    pageContent_banner_BlockType: Banner,
    pageContent_bodyText_BlockType: BodyText,
    pageContent_heading_BlockType: Heading,
    pageContent_columnBanner_BlockType: ColumnBanner,
    pageContent_columns_BlockType: Columns,
    pageContent_featuredArticle_BlockType: FeaturedArticle,
    pageContent_image_BlockType: Image,
    pageContent_partners_BlockType: Partners,
    pageContent_services_BlockType: Services,
    pageContent_questions_BlockType: Questions,
    pageContent_people_BlockType: People,
    pageContent_articles_BlockType: Articles,
    pageContent_imageSlider_BlockType: ImageSlider,
    pageContent_contactForm_BlockType: ContactForm,
};

export const PageContent: React.FC<{data: any}> = ({data}) => {
    return (
        <Box
            sx={{
                '& > :last-child.remove-margin-if-last': {
                    mb: 0,
                },
            }}
        >
            {data.map((block: any) => {
                if (blocks[block.__typename]) {
                    return React.createElement(blocks[block.__typename], {key: block.id, ...block});
                } else {
                    return <Box>No component named {block.__typename}</Box>;
                }
            })}
        </Box>
    );
};
