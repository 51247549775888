import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const ArrowForward: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || '24'} height={size || '24'} viewBox='0 0 24 24'>
        <path
            d='M1009.979,166.434l1.173,1.153,6.518-6.517-6.518-6.519-1.173,1.174,4.442,4.422H1002.14v1.844h12.281Z'
            transform='translate(-997.905 -149.069)'
            fill={color || colors.black}
        />
    </svg>
);
