import {Grid, InputAdornment, Typography} from '@mui/material';
import * as React from 'react';
import {Control, Controller, FieldErrors} from 'react-hook-form';
import {useLanguage} from '../../../language/LanguageProvider';
import {validatePrice} from '../../../lib/validationMessages';
import {InfoField} from '../../InfoField';
import {CustomInputField} from '../../InputField';
import {InputFields} from './offerPage/OfferEditableContent';

export type PriceInputFields = {
    hourCost: string;
    materialCost: string;
    totalMvaCost: string;
    editable: boolean;
};
export const PriceSummary: React.FC<
    {
        control: Control<InputFields>;
        handleBlur: (name: keyof PriceInputFields) => void;
        errors?: FieldErrors<PriceInputFields>;
    } & PriceInputFields
> = (props) => {
    const {getLangString} = useLanguage();
    const calculateTotalPrice = (): string => {
        const total: number =
            parseFloat(props.hourCost || '0') +
            parseFloat(props.materialCost || '0') +
            parseFloat(props.totalMvaCost || '0');
        return total > 0 ? total.toLocaleString() + ' kr' : '';
    };
    const handleBlur = (name: keyof PriceInputFields) => {
        props.handleBlur && props.handleBlur(name);
    };

    return (
        <Grid container columnSpacing={0}>
            <Grid item xs={8} sm={4}>
                <Controller
                    name='materialCost'
                    rules={{
                        pattern: validatePrice(),
                    }}
                    control={props.control}
                    defaultValue={props.materialCost}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            disabled={!props.editable}
                            label={getLangString('PRICE_MATERIAL')}
                            autoComplete={'off'}
                            name={field.name}
                            error={!!fieldState.error}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => handleBlur(field.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>kr</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <Controller
                    name='hourCost'
                    rules={{
                        pattern: validatePrice(),
                    }}
                    control={props.control}
                    defaultValue={props.hourCost}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            disabled={!props.editable}
                            label={getLangString('PRICE_WORK_HOURS')}
                            autoComplete={'off'}
                            name={field.name}
                            error={!!fieldState.error}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => handleBlur(field.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>kr</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <Controller
                    name='totalMvaCost'
                    rules={{
                        pattern: validatePrice(),
                    }}
                    control={props.control}
                    defaultValue={props.totalMvaCost}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            disabled={!props.editable}
                            label={getLangString('TOTAL_VAT')}
                            autoComplete={'off'}
                            name={field.name}
                            error={!!fieldState.error}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() => handleBlur(field.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>kr</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <InfoField sx={{backgroundColor: 'rgba(18, 18, 18, 0.1)', height: '73px'}}>
                    <Typography sx={{fontWeight: 'bold'}}>
                        {getLangString('TOTAL')} {calculateTotalPrice()}
                    </Typography>
                </InfoField>
            </Grid>
            <Grid item xs={8} mt={1}>
                <Typography color={'error'}>
                    {(props.errors &&
                        (props.errors.totalMvaCost?.message ||
                            props.errors.hourCost?.message ||
                            props.errors.materialCost?.message)) || <br />}
                </Typography>
            </Grid>
        </Grid>
    );
};
