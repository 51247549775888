import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {CssGrid} from '../Layout';

const ProjectInfo: React.FC<{columns: number}> = ({columns, children}) => (
    <CssGrid
        numberOfColumns={columns}
        sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
            gridColumn: {xs: 'span 8', md: 'span 3'},
        }}
    >
        {children}
    </CssGrid>
);
type ProjectDetails = {project: string; contact: string; refNumber?: string; endDate?: string; startDate?: string};

type InfoProps = {
    titleL1?: string;
    titleL2: string;
    overlineTitle: string;
    details: ProjectDetails;
    services?: string;
};
export const GeneralProjectInfo: React.FC<InfoProps> = (props) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const {getLangString} = useLanguage();

    const detailsText: Record<keyof ProjectDetails, string> = {
        project: getLangString('PROJECT'),
        contact: getLangString('CONTACT_PERSON'),
        refNumber: getLangString('REF_NUMBER'),
        endDate: getLangString('END_DATE'),
        startDate: getLangString('START_DATE'),
    };
    return (
        <Box
            sx={{
                gridColumn: {xs: 'span 8', md: '2 / span 6'},
                mt: '30px',
            }}
        >
            <Typography variant={'h3_bold'}>{props.overlineTitle}</Typography>
            <Typography variant={'h3'} lineHeight={'3.563rem'}>
                {props.titleL1}
            </Typography>
            <Typography variant={'h3'} lineHeight={'3.563rem'}>
                {props.titleL2}
            </Typography>
            <CssGrid numberOfColumns={isSmallScreen ? 8 : 6} sx={{my: {xs: 5, md: 7}}}>
                <Typography variant={'h6'} sx={{gridColumn: {xs: 'span 8', md: 'span 6'}}}>
                    {getLangString('ABOUT_PROJECT')}
                </Typography>
                {Object.keys(props.details).map((detail) => (
                    <ProjectInfo key={detail} columns={isSmallScreen ? 4 : 3}>
                        <Typography sx={{gridColumn: '1'}}>{detailsText[detail as keyof ProjectDetails]}</Typography>
                        <Typography sx={{gridColumn: '2/ -1'}}>
                            {props.details[detail as keyof ProjectDetails]}
                        </Typography>
                    </ProjectInfo>
                ))}
                {props.services && (
                    <CssGrid
                        numberOfColumns={isSmallScreen ? 8 : 6}
                        sx={{
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                            gridColumn: {xs: 'span 8', md: 'span 6'},
                        }}
                    >
                        <Typography sx={{gridColumn: '1'}}>{getLangString('SERVICES')}</Typography>
                        <Typography sx={{gridColumn: '2/ -1'}}>{props.services}</Typography>
                    </CssGrid>
                )}
            </CssGrid>
        </Box>
    );
};
