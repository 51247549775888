import {Box, BoxProps, SxProps, Theme, Typography} from '@mui/material';
import React from 'react';
import {useLanguage} from '../../language/LanguageProvider';

const Row: React.FC<BoxProps & {first: string; second: string; styleOverride?: SxProps<Theme>}> = (props) => {
    const commonStyling: SxProps<Theme> = {
        py: 3,
        borderBottom: 0.5,
        borderColor: 'divider',
        ...props.styleOverride,
    };
    return (
        <>
            <Box sx={{gridColumn: '1/7', ...commonStyling}}>{props.first}</Box>
            <Box sx={{textAlign: 'right', ...commonStyling}}>kr</Box>
            <Box sx={{textAlign: 'right', pl: 1, ...commonStyling}}>{props.second}</Box>
        </>
    );
};
type Props = {
    materialCost: number;
    workhourCost: number;
    totalMva?: number;
};
const localizeCurrencyString = (value: number) => {
    return value.toLocaleString('nb-NO', {minimumFractionDigits: 2, maximumFractionDigits: 2});
};
export const PriceSummaryOffer: React.FC<Props> = ({materialCost, workhourCost, totalMva}) => {
    const {getLangString} = useLanguage();
    const mva = totalMva || (materialCost + workhourCost) * 0.25;
    const totalPrice = materialCost + workhourCost + mva;
    return (
        <>
            <Typography variant={'h6'} mb={2}>
                {getLangString('OFFER_SUMMARY')}
            </Typography>
            <Box sx={{py: 4}}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(8, 1fr)`,
                        columnGap: 0,
                    }}
                >
                    <Row first={getLangString('MATERIAL')} second={localizeCurrencyString(materialCost)} />
                    <Row first={getLangString('HOURS')} second={localizeCurrencyString(workhourCost)} />
                    <Row first={getLangString('VAT')} second={localizeCurrencyString(mva)} />
                    <Row
                        first={getLangString('TOTAL_PRICE')}
                        second={localizeCurrencyString(totalPrice)}
                        styleOverride={{fontFamily: 'SuisseIntl-Medium', borderBottom: 2}}
                    />
                </Box>
            </Box>
        </>
    );
};
