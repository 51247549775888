import {Grid, Typography, useMediaQuery} from '@mui/material';
import {Box} from '@mui/system';
import {BackButton} from 'components/BackButton';
import {LoaderCentered} from 'components/Loader';
import {NameValue, UnderlinedNameValue} from 'components/UnderlinedNameValues';
import {useLanguage} from 'language/LanguageProvider';
import {CordelCompanyDto} from 'lib/dtos/Cordel';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {AdminPageLayout} from '../AdminPageLayout';

interface CompanyDetails {
    description: string;
    details: Array<NameValue>;
}

export const CompanyDetailsPage: React.FC = () => {
    const params = useParams<{companyId: string}>();
    const history = useHistory();
    const {getLangString} = useLanguage();
    const mq = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [companyDetails, setDetails] = useState<CompanyDetails>();
    // Fetch company
    const [{error: gotError, loading: isFetchingCompany, data: company}, getCompany] = useM2Axios<
        M2Response<CordelCompanyDto>
    >(ApiEndpoints.company.byId(Number.parseInt(params.companyId)), {
        manual: false,
    });

    const handleGoBack = () => {
        history.push(`/admin/companies`);
    };

    useEffect(() => {
        const c = company?.data;
        if (c) {
            const tmpCompanyDetails: CompanyDetails = {description: c.description, details: []};
            const companyData: Array<{name: string; value: string; urlType?: 'email' | 'phone'}> = [];

            companyData.push({name: 'Bedriftsnavn', value: c.name});
            companyData.push({name: 'Håndverksgruppe', value: c.type});

            companyData.push({name: 'Adresse', value: `${c.street}, ${c.zip} ${c.location}`});
            companyData.push({name: 'Org.nummer', value: '' + c.organizationNumber});

            const contact = c.contacts[0];
            let contactName = '';
            const contactEmail = contact?.email ? contact.email : '';
            const contactPhone = contact?.phoneNumber ? contact.phoneNumber : '';
            if (contact.firstName && contact.lastName) {
                contactName = `${contact.firstName} ${contact.lastName}`;
            }
            companyData.push({name: 'Kontaktperson', value: contactName});
            companyData.push({name: 'Telefon', value: contactPhone, urlType: 'phone'});
            companyData.push({name: 'E-post', value: contactEmail, urlType: 'email'});
            tmpCompanyDetails.details = companyData;

            setDetails(tmpCompanyDetails);
        }
        //
    }, [company]);
    // Display form
    return (
        <AdminPageLayout>
            <Box mt={2}>
                <BackButton onClickOverride={handleGoBack} />
            </Box>
            {(companyDetails?.details?.length && (
                <>
                    <Typography variant={'h3_bold'} mt={4} mb={4}>
                        {company?.data?.name}
                    </Typography>
                    <Grid container columnSpacing={4} marginBottom={3} pb={2}>
                        {companyDetails?.details?.map((d, key) => {
                            let md = 4;
                            if (companyDetails.details.length % 2 == 1 && key == companyDetails.details.length - 1) {
                                md = 12;
                            }
                            return (
                                <Grid key={key} item xs={12} md={md} mb={3}>
                                    <UnderlinedNameValue {...d} />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box display='flex' justifyContent={'center'} width={'100%'}>
                        <Grid container rowGap={2} maxWidth={mq ? 630 : '100%'}>
                            {/* <Box> */}
                            <Grid item xs={12} mb={4}>
                                <Typography fontWeight={'bold'}>{getLangString('DESCRIPTION')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{companyDetails.description}</Typography>
                            </Grid>
                            {/* </Box> */}
                        </Grid>
                    </Box>
                </>
            )) || <Typography>Fant ikke selskap</Typography>}
            {isFetchingCompany && !gotError ? <LoaderCentered /> : <div></div>}
        </AdminPageLayout>
    );
};
