import {parseISO} from 'date-fns';

/**
 * Checks if the body contains any date string and formats them to a Date object
 * Will traverse objects and arrays looking for date strings to convert
 * Solution found at @see https://stackoverflow.com/a/66238542
 * @param body
 * @returns A new input with all date strings as date object
 */
export function dateMapper<T extends unknown[]>(body: T): T;
export function dateMapper<T extends Record<string, unknown>>(body: T): T;
export function dateMapper(body: string): Date | string;
export function dateMapper<T>(body: T) {
    if (body == null) return body;
    if (isIsoDateString(body)) return transformToDate(body);

    if (Array.isArray(body)) {
        return body.map((el) => dateMapper(el));
    }

    if (typeof body === 'object') {
        const result: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(body)) {
            result[key] = dateMapper(value);
        }
        return result;
    }

    return body;
}
const isoDateFormat = new RegExp(
    '^(\\d{4})-(\\d{2})-(\\d{2})T(\\d{2}):(\\d{2}):(\\d{2}(?:\\.\\d*)?)((-(\\d{2}):(\\d{2})|Z)?)$',
);
function transformToDate(value: string) {
    return parseISO(value);
}

function isIsoDateString(value: unknown): value is string {
    if (value && typeof value === 'string') {
        const val = isoDateFormat.test(value);
        return val;
    }
    return false;
}
