import {Box, Link, Typography, useTheme} from '@mui/material';
import React from 'react';
import {ARTICLES_ENTRY_PAGE_URL_FORMAT, formatUrl, SERVICES_ENTRY_PAGE_URL_FORMAT} from '../../../cms/helpers';

type Props = {
    article: {
        id: number;
        title: string;
        slug: string;
        mainImage: {
            id: number;
            url: string;
        }[];
        relatedServices: {
            id: number;
            title: string;
            slug: string;
        }[];
    };
    centerText?: boolean;
    isSliderItem?: boolean;
};

export const ArticleCard: React.FC<Props> = (props) => {
    const theme = useTheme();
    const article = props.article;
    const centerText = props.centerText !== undefined ? props.centerText : true;
    const isSliderItem = props.isSliderItem !== undefined ? props.isSliderItem : false;
    const images = isSliderItem ? [article.mainImage[0]] : article.mainImage;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                transition: 'opacity 250ms ease',
                opacity: '1',
                '&:hover': {
                    opacity: '0.85',
                },
            }}
        >
            <Box
                sx={{
                    maxWidth: isSliderItem || images.length > 1 ? 'none' : '465px',
                }}
            >
                <Link
                    href={formatUrl(ARTICLES_ENTRY_PAGE_URL_FORMAT, article.slug)}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: images.length > 1 ? 'repeat(2, 1fr)' : '1fr',
                        columnGap: 1.5,
                        [theme.breakpoints.up('sm')]: {
                            columnGap: 2.5,
                        },
                        [theme.breakpoints.up('md')]: {
                            columnGap: 3,
                        },
                    }}
                >
                    {images.map((image) => {
                        return (
                            <Box key={image.id}>
                                <img
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                    }}
                                    src={image.url}
                                    alt=''
                                />
                            </Box>
                        );
                    })}
                </Link>

                <Box
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            padding: isSliderItem ? '0' : `0 ${theme.spacing(5)}`,
                        },
                    }}
                >
                    <Box
                        sx={{
                            mt: theme.spacing(1.25),
                            textAlign: centerText ? 'center' : 'left',
                            [theme.breakpoints.up('md')]: {
                                mt: theme.spacing(2),
                            },
                        }}
                    >
                        <Typography variant='articleCardHeading'>
                            <Link
                                href={formatUrl(ARTICLES_ENTRY_PAGE_URL_FORMAT, article.slug)}
                                sx={{
                                    textDecoration: 'none',
                                    color: 'text.primary',
                                }}
                            >
                                {article.title}
                            </Link>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            textAlign: 'center',
                            mt: 2,
                        }}
                    >
                        <Link
                            href={formatUrl(ARTICLES_ENTRY_PAGE_URL_FORMAT, article.slug)}
                            sx={{
                                fontFamily: 'SuisseIntl-Medium',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {'Les mer'}
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: centerText ? 'center' : 'flex-start',
                            mt: 1.5,
                            [theme.breakpoints.up('md')]: {
                                mt: 3,
                            },
                        }}
                    >
                        {article.relatedServices.map((service, index: number) => {
                            return (
                                <Box key={service.id}>
                                    <Link
                                        href={formatUrl(SERVICES_ENTRY_PAGE_URL_FORMAT, service.slug)}
                                        sx={{
                                            textDecoration: 'none',
                                            borderBottomStyle: 'solid',
                                            borderBottomWidth: '1px',
                                            borderBottomColor: 'text.primary',
                                            fontSize: '0.875rem',
                                            lineHeight: '1.57',
                                            letterSpacing: '0.2px',
                                            [theme.breakpoints.up('md')]: {
                                                fontSize: '1.0625rem',
                                                lineHeight: '1.53',
                                            },
                                        }}
                                    >
                                        {service.title}
                                    </Link>
                                    {index < article.relatedServices.length - 1 && <span>,&nbsp;</span>}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
