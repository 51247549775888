import React, {useEffect, useState} from 'react';
import {ConsentGroupDto, CookieConsentDto, CookieGroup, CookieGroupDto} from '../lib/dtos/Cookies';
import {useM2Axios} from '../lib/hooks/useM2Axios';
import {ApiEndpoints} from '../lib/remote/m2api/M2Endpoints';
import {getCookie} from '../toolbox';

type ContextProps = {
    availableGroups?: CookieGroupDto[];
    consentId?: string;
    consentValues?: ConsentGroupDto[];
    updateConsentValues?: (values: ConsentGroupDto[]) => void;
    setShowConsentBox: (open: boolean) => void;
    showConsentBox: boolean;
};

//udpate this list when adding new cookies
const relevantCookieGroups: Partial<CookieGroup>[] = ['necessary'];

export const CookieContext = React.createContext<ContextProps>({setShowConsentBox: () => false, showConsentBox: false});

export const CookieConsentProvider: React.FC = ({children}) => {
    const consentId = getCookie('m2_cookie');
    const [openModal, setOpenModal] = useState<boolean>(!consentId);
    const [{data: cookieGroups}] = useM2Axios<{data: CookieGroupDto[]}>({
        url: ApiEndpoints.cookies.groups(),
    });
    const [{data: userConsent}, getConsents] = useM2Axios<{data: CookieConsentDto}>(
        {url: ApiEndpoints.cookies.byId(consentId)},
        {manual: true},
    );
    useEffect(() => {
        if (consentId) {
            getConsents();
        }
    }, [consentId, getConsents]);

    const [consents, setConsents] = useState<ConsentGroupDto[]>();

    useEffect(() => {
        if (consentId) {
            if (userConsent && userConsent.data) {
                setConsents(userConsent.data.groupConsents);
            }
        } else {
            const defaultConsents =
                cookieGroups && cookieGroups.data.map((g) => ({groupId: g.cookieGroupId, consent: g.checkedByDefault}));
            setConsents(defaultConsents);
        }
    }, [consentId, cookieGroups?.data, userConsent]);

    const provider = {
        availableGroups: cookieGroups
            ? cookieGroups.data //.filter((g) => relevantCookieGroups.some((rg) => rg === g.handle)) TODO: apply filter
            : undefined,
        consentValues: consents,
        consentId,
        updateConsentValues: setConsents,
        setShowConsentBox: setOpenModal,
        showConsentBox: openModal,
    };

    return <CookieContext.Provider value={provider}>{children}</CookieContext.Provider>;
};
