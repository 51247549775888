import {CordelCompanySimpleDto} from '../dtos/Cordel';
import {CreateCordelInquiryDto, CreateInquiryDto, InquiriesDto, InquiryDto} from '../dtos/Inquiry';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {ApiEndpoints} from '../remote/m2api/M2Endpoints';

/**
 * Responsible for performing tasks related to inquiries.
 * It can create, update, delete and get inquiries.
 */
export class InquiryService {
    private apiInquiryPath = ApiEndpoints.inquiry;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    /**
     * Tries to create a new inquiry
     * @param inquiry new inquiry
     * @param images images to upload for this inquiry
     */
    public async createInquiry(inquiry: CreateInquiryDto, images?: File[] | undefined | null): Promise<unknown> {
        const formData: FormData = new FormData();
        formData.append('inquiry', JSON.stringify(inquiry));
        if (images) {
            for (let index = 0; index < images.length; index++) {
                formData.append('images', images[index]);
            }
        }
        return await this._httpClient.submitForm<CreateInquiryDto>(this.apiInquiryPath.base(), formData);
    }

    /**
     * Tries to updates the given inquiry.
     * @param inquiry an existing inquiry
     */
    public async updateInquiry(inquiry: InquiryDto): Promise<InquiryDto> {
        return await this._httpClient.put<InquiryDto>(this.apiInquiryPath.byId(inquiry.inquiryId), inquiry);
    }

    /**
     * Tries to get an inquiry by the provided id.
     * @param inquiryId the id of the inquiry
     * @returns the inquiry with the given id.
     */
    public async getInquiryById(inquiryId: number): Promise<{data: InquiryDto}> {
        return await this._httpClient.get<{data: InquiryDto}>(this.apiInquiryPath.byId(inquiryId));
    }

    /**
     * Tries to get all inquiries
     * @param page the page to be returned
     * @param size the number of entries per page
     * @returns all inquiries.
     */
    public async getInquiries(page: number, size: number): Promise<InquiriesDto> {
        return await this._httpClient.get<InquiriesDto>(`${this.apiInquiryPath.base()}?page=${page}&size=${size}`);
    }
    /**
     * Tries to get companies that have received inquiry
     * @param inquiryId
     * @returns all inquiries.
     */
    public async getInquiryCompanies(inquiryId: number): Promise<{data: CordelCompanySimpleDto[]}> {
        return await this._httpClient.get<{data: CordelCompanySimpleDto[]}>(
            `${this.apiInquiryPath.byId(inquiryId)}/companies`,
        );
    }

    /**
     * Tries to send inquiry to cordel companies
     * @param inquiryId
     * @param data company ids
     */
    public async sendInquiryToCompanies(inquiryId: number, data: CreateCordelInquiryDto[]): Promise<unknown> {
        return await this._httpClient.post<unknown, CreateCordelInquiryDto[]>(
            `${this.apiInquiryPath.byId(inquiryId)}/cordelInquiries`,
            data,
        );
    }
}
