export const NO = {
    NEXT: 'Neste',
    BACK: 'Tilbake',
    SIGN_IN: 'Logg inn',
    SIGN_OUT: 'Logg ut',
    MY_PROJECTS: 'Mine prosjekter',
    INVOICES: 'Fakturaer',
    DOCUMENTATION: 'Dokumentasjon',
    ACCOUNT_SETTINGS: 'Kontoinnstillinger',
    SETTINGS: 'Innstillinger',
    MY_DISCOUNTS: 'Mine fordeler',
    MY_DISCOUNTS_CONTENT_TEXT:
        'M2 sine kunder får tilgang til et omfattende kundefordelssystem med rabatter og prioritert tilgang til ' +
        'kampanjer. Ta kontakt med din prosjektleder for nærmere informasjon.',
    CONTACT_US: 'Kontakt oss',
    GO_TO_PROJECT: 'Gå til prosjektside',
    SUBMENU: 'Submeny',
    ADD: 'Legg til',
    CLOSE: 'Lukk',
    OPEN: 'Åpne',
    SAVE_CHANGES: 'Lagre endringer',
    CANCEL: 'Avbryt',
    SUBMIT: 'Send inn',
    CONFIRM: 'Bekreft',
    SENDING: 'Sender...',
    DELETE: 'Slett',
    COMPANIES: 'Bedrifter',
    SELECT: 'Velg',
    TEXT_AREA_PLACEHOLDER_DESCRIBE: 'Beskriv her...',
    NO_DATA: 'Ingen data',
    ADD_PHOTO: 'Ta/legg til bilde',
    MAKE_ORDER_BTN_TEXT: 'Bestill befaring',
    INQUIRY_SEND_ERROR: 'Klarte ikke å sende bestilling, prøv igjen',
    ME: 'Meg',

    EXISTING_USER_LOGIN_MODAL_TEXT: 'Du har en bruker. Vennligst logg inn for å fullføre bestillingen.',
    CONTINUE_EXISTING_INQUIRY_MODAL_TEXT:
        'Du har en påbegynt henvendelse. Ønsker du å fortsette, eller starte på nytt?', //TODO: update text
    CONTINUE: 'Fortsett',
    START_OVER: 'Start på nytt',

    COOKIE_ACCEPT_BUTTON_TEXT__OLD: 'Jeg forstår',
    COOKIE_OPTION_NECESSARY: 'Nødvendige',
    COOKIE_OPTION_ANALYTICS: 'Statistikk',
    COOKIE_OPTION_MARKETING: 'Markedsføring',
    COOKIE_OPTION_FUNCTIONAL: 'Funksjonelle',
    COOKIE_ACCEPT_BUTTON_TEXT: 'Godta alle',
    COOKIE_REJECT_BUTTON_TEXT: 'Kun nødvendige',
    COOKIE_ACCEPT_SELECTION_BUTTON_TEXT: 'Lagre valg',
    COOKIE_TEXT:
        'Vi bruker cookies (informasjonskapsler) for at du skal ha en god opplevelse på nettsiden. Noen cookies er ' +
        'nødvendig for at nettsiden skal fungere, mens andre velger du selv om du vil godta, eller ikke.',
    COOKIE_TEXT__OLD:
        'Denne siden bruker informasjonskapsler som er nødvendige for å få nettstedet til å fungere. Disse kan\n' +
        'ikke slås av i systemene våre.',
    READ_MORE: 'Les mer',

    FOOTER_HEADER_CONTACT_INFO: 'Kontakt',
    FOOTER_HEADER_SOCIAL_MEDIA: 'Sosiale medier',
    PRIVACY_TERMS_COOKIE_BTN: 'Personvernerklæring og informasjonskapsler',
    MANAGE_COOKIES_BTN: 'Administrer informasjonskapsler',

    DOWNLOAD_PDF: 'Last ned PDF',

    NOTIFICATIONS: 'Varsler',
    NOTIFICATION_HEADER_OFFER: 'Tilbud til godkjenning',
    NOTIFICATION_HEADER_PAYMENT: 'Betaling',
    NOTIFICATION_HEADER_STATUSUPDATE: 'Statusoppdatering',

    UNSAVED_CHANGES_PROMPT: 'Du har gjort endringer som ikke er lagret. Vil du forlate siden?',
    DISCARD_CHANGES: 'Forkast endringer',

    //Service type
    SERVICES_HEADER: 'Oppussingstjenester',
    CRAFTSMAN_SERVICES_HEADER: 'Håndverkertjenester',
    CRAFTSMAN_TASK_HEADER: 'Hva trenger du hjelp med? (Velg én eller flere)',
    CONSULTATION_TYPE_HEADER: 'Hvordan ønsker du å bli kontaktet?',
    CONSULTATION_TYPE_PHONE: 'Jeg ønsker å bli ringt',
    CONSULTATION_TYPE_EMAIL: 'Jeg ønsker å få en e-post',
    CONSULTATION_TYPE_VISIT: 'Jeg ønsker å bestille befaring',
    //Contact info
    CONTACT_INFO: 'Kontaktinformasjon',
    MY_ADDRESSES: 'Mine adresser',
    INSPECTION_ADDRESS: 'Adresse for befaringen',
    LOCATION_ADDRESS: 'Adresse for oppdraget',
    FORNAME: 'Navn',
    SURNAME: 'Etternavn',
    PHONE: 'Telefonnummer',
    EMAIL: 'E-post',
    ADDRESS: 'Gateadresse',
    POSTAL_CODE: 'Postnr',
    CITY: 'Sted',
    ADD_CHANGE_ADDRESS: 'Jeg ønsker å endre/legge til adresse',
    ADD_ADDRESS: 'Legg til adresse',
    CONSENT: 'Samtykke',
    GDPR_TEXT__FORMAT:
        'Jeg samtykker til at mine personopplysninger behandles iht. M2 sin %href_start%personvernerklæring%href_end%',
    SPECIFICATION_HEADER: 'Noe du vil spesifisere eller legge til (valgfritt)',

    SMS_SETTINGS: 'SMS-innstillinger',
    EMAIL_SETTINGS: 'Epost-innstillinger',
    PHONE_SETTINGS: 'Telefoninnstillinger',
    GENERAL_UPDATES_SETTING: 'Generelle oppdateringer',
    ACTION_REQUIRED_SETTING: 'Beskjed om at handling kreves',
    DELETE_ADDRESS: 'Fjern denne adressen',

    PHOTO_ATTACHMENTS_HEADER: 'Legg ved eller ta bilde (valgfritt)',

    SUMMARY: 'Oppsummering',
    ORDERED_SERVICE: 'Dette har du forespurt',
    TIME_OF_INSPECTION: 'Tid for befaring',
    TIME_OF_PHONECALL: 'Tid for telefonsamtale',
    SPECIFICATION: 'Din kommentar',
    ATTACHED_PHOTOS: 'Vedlagte bilder',
    ATTACHED_PHOTOS_FROM_INSPECTION: 'Vedlagte bilder fra inspeksjon',
    EDIT: 'Rediger',
    SAVE: 'Lagre',

    SUBMIT_REQUEST: 'Send inn forespørsel',
    INQUIRY_CONFIRMATION_HEADER: 'Din forespørsel vil nå bli behandlet!',
    INQUIRY_CONFIRMATION_TEXT: 'Du vil om kort tid motta en bestillingsbekreftelse via SMS og e‑post.',
    INQUIRY_CONFIRMATION_SUBTEXT: 'Dersom du ikke allerede har en bruker vil du få en e-post med innloggingsdetaljer.',

    //Projects
    PROJECT_STATE_ONGOING: 'Under arbeid',
    PROJECT_STATE_OFFER: 'Tilbud',
    PROJECT_STATE_INVOICE: 'Betaling',
    PROJECT_STATE_ENDED: 'Avsluttet',
    SENDING_ANSWER: 'Sender besvarelse',
    FAILED: 'Sender besvarelse',
    STATUS_UPDATE_FEEDBACK: 'Har du en tilbakemelding?',

    PROJECT_TIMEFRAME: 'Tidsramme for prosjekt',
    ABOUT_PROJECT: 'Om prosjektet',
    PROJECT_PHASES_STATE: 'Status på prosjekt',

    ACTIVE_PROJECTS: 'Aktive prosjekter',
    STATUS_PROJECT: 'Status på prosjekt',
    ARCHIVED_PROJECTS: 'Tidligere prosjekter',

    PROJECT: 'Prosjekt',
    CONTACT_PERSON: 'Prosjektleder',
    REF_NUMBER: 'Prosjektnummer',
    END_DATE: 'Sluttdato',
    START_DATE: 'Startdato',
    SERVICES: 'Tjenester',
    DESCRIPTION: 'Beskrivelse',
    PROJECT_DESCRIPTION_CUSTOMER: 'Dine opplysninger',
    JOB: 'Oppgave',
    OFFER_DESCRIPTION: 'Tilbudsbeskrivelse',
    PHOTOS_INSPECTION: 'Bilder fra befaring',

    PHOTOS_ENDED_PROJECT: 'Bilder fra ferdigstilt prosjekt',

    NO_NEW_MESSAGES: 'Ingen nye meldinger',
    NO_ACTIVE_PROJECTS: 'Ingen aktive prosjekt',
    NO_STATUS_UPDATES: 'Ingen statusoppdateringer',

    //Documentation
    CONTRACTS: 'Kontrakter',
    DOCUMENTS: 'Dokumenter',
    DOCUMENTATION_EMPTY_STATE: 'Her kommer din dokumentasjon',
    NO_CONTRACTS: 'Ingen kontrakter',

    //Invoices
    INVOICES_EMPTY_STATE: 'Her kommer dine fakturaer',
    INVOICE_DATE: 'Fakturadato',
    INVOICE_NUMBER: 'Fakturanummer',
    INVOICE_STATUS: 'Status',
    INVOICE_AMOUNT: 'Beløp',
    INVOICE_PAYMENT_DEADLINE: 'Betalingsfrist',
    NO_INVOICES: 'Ingen fakturaer',
    NO_PDF: 'Ingen PDF',

    //Offer
    OFFER_SUMMARY: 'Sammendrag tilbud',
    APPROVE_OFFER_BTN: 'Godkjenn tilbud',
    DENY_OFFER_BTN: 'Jeg ønsker et revidert tilbud',
    OFFER_DECISION_SUBTITLE: 'Noe du vil spesifisere? (Valgfritt)',
    COST_INFO_DESCRIPTION_HEADER: 'Hva',
    COST_INFO_PRICE_HEADER: 'Pris',
    MATERIAL: 'Materiellkostnad',
    HOURS: 'Timer',
    TOTAL_PRICE: 'Totalbeløp',
    VAT: 'Mva 25%',
    OFFER_REJECTED_BANNER_TEXT: 'Venter på revidert tilbud',
    NO_DOCUMENTS: 'Ingen dokumenter',
    // Error messages
    UPDATE_USER_PHONE_ERROR: 'Kunne ikke oppdatere telefonnummer. Vennligst prøv igjen.',
    UPDATE_USER_EMAIL_ERROR: 'Kunne ikke oppdatere e-postadressen. Vennligst prøv igjen.',
    ADD_ADDRESS_ERROR: 'Kunne ikke legge til adresse',
    // Article pages
    ARTICLE_FILTERS: 'Filter',
    ALL_ARTICLES_FILTER_ACTION: 'Alle artikler',
    SERVICES_MENTIONED: 'Relaterte tjenester',
    RELATED_ARTICLES: 'Relaterte saker',

    // CMS Services
    SERVICE_ORDER_ACTION: 'Gå til bestilling',
    SERVICE_REFERENCES_ACTION__FORMAT: 'Referanser for %service_name%',

    // NOTIFICATIONS
    SEND_STATUS_RESPONSE: 'Send inn tilbakemelding',
    CONTACT_US_CONFIRMATION: 'Takk for din henvendelse',

    //-- ADMIN--
    PROJECT_LEADER: 'Prosjektleder',
    INQUIRY: 'Henvendelse',
    INQUIRIES: 'Henvendelser',
    OFFER: 'Tilbud',
    ORDER: 'Ordre',
    ORDER_CREATED: 'Ordre opprettet',
    CREATE_ORDER_IN: 'Opprett ordre i',
    CREATING_ORDER: 'Oppretter orde',
    NO_PHOTOS: 'Ingen bilder',
    NO_DESCRIPTION: 'Ingen beskrivelse',

    //INQUIRY
    CONTACT_TYPE: 'Kunde ønsker å bli kontaktet via',
    PHONE_CALL: 'Telefon',
    REMOVE_COMPANY: 'Fjern håndverker',
    SELECT_COMPANY: 'Velg håndverker',
    SEND_INQUIRY_TO: 'Send henvendelse til',
    SELECT_COMPANY_FROM_LIST: 'Velg håndverker',
    SENDING_INQUIRY_TO: 'Henvendelse sendes til',
    SEND_INQUIRY: 'Send henvendelse',
    INQUIRY_SENT_TO: 'Henvendelse er sendt til',
    SELECT_COMPANY_EMPTY_LIST: 'Alle håndverkere har mottatt henvendelse',
    COMPLETE_OFFER: 'Ferdigstill tilbud',
    OPEN_OFFER: 'Gå til tilbud',
    OFFER_SENT: 'Tilbud sendt',

    INFO_TEXT_CUSTOMER: 'Info fra kunden',
    INFO_TEXT_INSPECTION: 'Info fra befaring',

    // OFFER
    OFFER_INFO_HEADER: 'Tilbudsinformasjon',
    UPLOAD_OFFER: 'Last opp tilbud',
    UPLOAD_PDF: 'Last opp PDF',
    SELECT_PDF: 'Velg PDF',

    OFFER_DESCRIPTION_HEADER: 'Tilbudsbeskrivelse fra M2',
    COMPANY_OFFERS_HEADER: 'Henvendelse sendt til',
    ADD_COMPANY_OFFERS_HEADER: 'Legg ved tilbudsdokument fra følgende firma',
    PRICE: 'Oppsummering pris',
    TOTAL: 'Total',
    SEND_OFFER_TO_CUSTOMER: 'Send tilbud til kunde',
    PRICE_MATERIAL: 'Beløp materiell',
    PRICE_WORK_HOURS: 'Beløp timer',
    TOTAL_VAT: 'Total MVA',

    // ORDER
    PHASE: 'Fase',
    PHASES: 'Faser',
    NO_PHASES_ADDED: 'Ingen faser lagt til',
    ADD_PHASE: 'Legg til fase',
    EDIT_PHASE: 'Rediger fase',
    DELETE_PHASE_TEXT: 'Vil du slette denne fasen?',
    STATUS_UPDATE: 'Statusoppdatering',
    STATUS_UPDATES: 'Statusoppdateringer',
    ADD_NEW_STATUS_UPDATE: 'Opprett ny statusoppdatering',
    RECENT_STATUS_UPDATES: 'Tideligere statusoppdateringer',
    ACCEPTED: 'Godtatt',
    NOT_ACCEPTED: 'Ikke godtatt!',
    OFFER_NOT_ACCEPTED: 'Tilbud ikke godtatt!',
    CUSTOMER_COMMENT: 'Kommentar fra kunde',
    UPDATE_OFFER: 'Oppdater tilbud',
    NO_COMMENT: 'Ingen kommentar',
    NO_ANSWER_GIVEN: 'Ikke besvart',
    NEW_ORDERS: 'Nye ordre',
    MY_ORDERS: 'Mine ordre',
    ALL_ORDERS: 'Alle ordre',
    COMPLETE_THE_PROJECT: 'Ferdigstill prosjekt',
    COMPLETE_PROJECT_TEXT: 'Dette vil markere prosjektet som ferdig og avslutte ordren.',

    // COMPANIES
    ADD_COMPANY: 'Legg til bedrift',
    COMPANY_INFORMATION: 'Bedriftsinformasjon',
    CORDEL_INFORMATION: 'Cordelinformasjon',
    COMPANY_NAME: 'Bedriftsnavn',
    API_KEY: 'API-nøkkel',
    CORDEL_URL: 'Cordel API-url',
    CORDEL_WAREHOUSE_URL: 'Varehus API-url',
    CORDEL_WAREHOUSE_USERNAME: 'Varehus brukernavn',
    CORDEL_WAREHOUSE_PASSWORD: 'Varehus passord',
    ORGANIZATION_NUMBER: 'Organisasjonsnummer',
    COMPANY_TYPE: 'Håndtverksgruppe',
    COMPANY_DESCRIPTION: 'Beskrivelse av selskapet',
    DIALOG_UNSAVED_CHANGES_TEXT:
        'Du har gjort endringer som vil gå tapt dersom du lukker boksen. Vil du forkaste endringene, eller fortsette ' +
        'å legge inn info?',

    IMAGES_FROM_INSPECTION: 'Bilder fra befaring',
    CUSTOMER_RESPONSE: 'Svar fra kunden',
    DEADLINE: 'Frist',

    WRITE_DESCRIPTION: 'Skriv en beskrivelse her',
    MAKE_DONE: 'Ferdigstill',

    active: 'Aktiv',
    done: 'Ferdig',
    coming: 'Kommende',

    //EMPLOYEES
    EMPLOYEES: 'Ansatte',
    ADD_EMPLOYEE: 'Legg til ansatt',
    EMPLOYEE_INFO: 'Ansattdetaljer',
    ROLE: 'Rolle',
    PASSWORD: 'Passord',

    // ERRORS
    FAILED_TO_SAVE: 'Klarte ikke å lagre, prøv igjen',
    CORDEL_WAREHOUSE_DO_NOT_RESPONS: 'Får ikke kontakt med cordel varehus',
    CORDEL_API_DO_NOT_RESPONS: 'Får ikke kontakt med cordel selskap',
    CORDEL_API_CREDENTIALS_ERROR: 'Feil API nøkkel ellser URL for cordel API',
    CORDEL_WAREHOUSE_CREDENTIALS_ERROR: 'Feil brukernavn, passord eller URL for cordel varehus',

    ALL_INQUIRIES: 'Alle henvendelser',
    NEW_INQUIRIES: 'Nye henvendelser',
    MY_INQUIRIES: 'Mine henvendelser',

    OPEN_OFFERS: 'Nye tilbud',
    MY_OFFERS: 'Mine tilbud',
    ALL_OFFERS: 'Alle tilbud',
};

export type TranslationKeys = keyof typeof NO;

export type SupportedLanguage = 'NO';

type Dictionaries = {[key: string]: {[key: string]: string}};
export const dictionaries: Dictionaries = {
    NO,
};
