import {ApolloProvider} from '@apollo/client';
import {LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {Login} from 'components/Login';
import {nb} from 'date-fns/locale';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AppLayout} from './components/AppLayout';
import {CookieConsent, CookieConsentOLD} from './components/CookieConsent';
import {InquiryPages} from './components/inquiryPages/InquiryPageRoutes';
import {AdminPages} from './components/loggedInPages/admin/AdminPages';
import {Protected} from './components/loggedInPages/LoggedInRoutes';
import {ContentPages} from './components/openPages/ContentPageRoutes';
import ScrollToTop from './components/ScrollToTop';
import {apolloClient} from './graphql';
import {LanguageProvider} from './language/LanguageProvider';
import {AppSEO} from './SEO';
import {StateProvider} from './store/appContext';
import {customTheme} from './theme';

export const adminBasePath = '/admin';
export const myPagesBasePath = '/minSide';
export const inquiryBasePath = '/bestilling';

const showNewCookieConsent = localStorage.getItem('CookieConsent_new');

const App: React.FC = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={nb}>
            <LanguageProvider>
                <Router>
                    <ThemeProvider theme={customTheme}>
                        <ApolloProvider client={apolloClient}>
                            <AppSEO />
                            <CssBaseline />
                            <ScrollToTop>
                                <Switch>
                                    <Route path={adminBasePath} component={AdminPages} />
                                    <StateProvider>
                                        <AppLayout>
                                            <Switch>
                                                <Route path={'/login'} component={Login} />
                                                <Route path={myPagesBasePath} component={Protected} />
                                                <Route path={inquiryBasePath} component={InquiryPages} />
                                                <Route path='*' component={ContentPages} />
                                            </Switch>
                                        </AppLayout>
                                    </StateProvider>
                                </Switch>
                                {
                                    /*TODO always use CookieConsent*/
                                    showNewCookieConsent ? <CookieConsent /> : <CookieConsentOLD />
                                }
                            </ScrollToTop>
                        </ApolloProvider>
                    </ThemeProvider>
                </Router>
            </LanguageProvider>
        </LocalizationProvider>
    );
};

export default App;
