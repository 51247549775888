import {useTheme} from '@mui/material';
import React, {createContext, Dispatch} from 'react';
import {useLocation} from 'react-router-dom';
import {ActionType} from './actions';
import {adminAppReducer, AdminState, initialAdminState} from './adminAppReducer';

type InitialContextState = {
    bgColor?: string;
    state: AdminState;
    dispatch: Dispatch<ActionType>;
};

export const AdminAppContext = createContext<InitialContextState>({state: initialAdminState, dispatch: () => ({})});
const {Provider} = AdminAppContext;

export const AdminStateProvider: React.FC = ({children}) => {
    const location = useLocation();
    const {palette} = useTheme();
    const [state, dispatch] = React.useReducer(adminAppReducer, initialAdminState);

    let bgColor = palette.customBackground.light;

    if (location.pathname.match('rejected')) {
        bgColor = palette.customBackground.darker;
    } else if (location.pathname.match('offers')) {
        bgColor = palette.customBackground.medium;
    } else if (location.pathname.match('orders')) {
        bgColor = palette.customBackground.medium;
    } else if (location.pathname.match('companies')) {
        bgColor = palette.customBackground.medium;
    }

    return <Provider value={{bgColor, state, dispatch}}>{children}</Provider>;
};
