import {Box, styled, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../language/LanguageProvider';
import {ProjectSimpleDto} from '../lib/dtos/Project';
import {ServiceDictionary} from '../lib/types/Inquiry';
import {useIsSmallScreen} from '../toolbox';
import {ButtonPrimaryLarge, ButtonPrimaryMedium} from './Buttons';
import {getProjectRoutePath} from './loggedInPages/LoggedInRoutes';
import {getCurrentPhase} from './loggedInPages/phases';
import {getServiceText} from './loggedInPages/serviceText';
import {ProjectPhases} from './ProjectPhase';

const Tile = styled(Box)(({theme}) => ({
    background: theme.palette.customBackground.medium,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(5)} 0`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
        padding: 20,
        paddingBottom: theme.spacing(3),
    },
}));

type Props = {
    project: ProjectSimpleDto;
    availableServices?: ServiceDictionary;
};
export const ProjectTile: React.FC<Props> = ({project, availableServices}) => {
    const theme = useTheme();
    const history = useHistory();
    const isSmallScreen = useIsSmallScreen();
    const {getLangString} = useLanguage();

    const goToProjectPage = () => {
        history.push(getProjectRoutePath(project.projectId));
    };

    const ButtonPrimary = isSmallScreen ? ButtonPrimaryMedium : ButtonPrimaryLarge;
    const {services, street, zip, location} = project;
    const address = `${street}, ${zip} ${location}`;
    const serviceText = availableServices && getServiceText(services, availableServices);
    return (
        <Tile>
            <Box sx={{paddingX: 5, [theme.breakpoints.down('sm')]: {padding: 0}}}>
                <Typography variant={'body2'}>{''}</Typography> {/*//TODO: get dates*/}
                <Box marginTop={2} marginBottom={5}>
                    <Typography variant={'h4'}>{getLangString('PROJECT_STATE_ONGOING')}</Typography>
                    {serviceText && (
                        <Typography variant={'h4_light'} component={'div'} lineHeight={'42px'}>
                            {serviceText},
                        </Typography>
                    )}
                    <Typography variant={'h4_light'} lineHeight={'42px'}>
                        {address}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{margin: 5, mr: 0, [theme.breakpoints.down('sm')]: {marginX: 0}}}>
                <Typography variant={'subtitle1'}>{getLangString('STATUS_PROJECT')}</Typography>
                <ProjectPhases phases={project.order.phases} current={getCurrentPhase(project.order.phases)} />
            </Box>
            <Box sx={{paddingX: 5, [theme.breakpoints.down('sm')]: {padding: 0}}}>
                <ButtonPrimary onClick={goToProjectPage}>{getLangString('GO_TO_PROJECT')}</ButtonPrimary>
            </Box>
        </Tile>
    );
};
