import {PaginationDto} from 'lib/dtos/Pagination';

export type GridActionType<T> =
    | {type: 'ADD_DATA'; payload: {data: T[]; pagination: PaginationDto}}
    | {type: 'SET_PAGE'; payload: {data: number}}
    | {type: 'SET_ROWS'; payload: {data: number}}
    | {type: 'RESET'};

export type GridState<T> = {
    currentPage: number;
    maxVisitedPage: number;
    rowsPerPage: number;
    loadOffset: number;
    cachedData: number;
    totalData: number;
    shouldFetch: boolean;
    gridData: T[];
};

type RecordType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

export const GridPaginationReducer = <T>(state: GridState<T>, action: GridActionType<T>) => {
    const newState = {...state};
    switch (action.type) {
        case 'ADD_DATA': {
            newState.cachedData += action.payload.pagination.result;
            newState.totalData = action.payload.pagination.total;
            const idSet = new Set<number>();
            newState.gridData = [...action.payload.data, ...state.gridData];
            newState.gridData = newState.gridData.filter((f: T & RecordType) => {
                const idName = Object.keys(f).find((f: string) => f.toLowerCase().endsWith('id'));
                if (!idName) return false;
                const id: number = f[idName];
                if (idSet.has(id)) return false;
                idSet.add(id);
                return true;
            });
            if (newState.cachedData >= newState.totalData) {
                newState.shouldFetch = false;
            }
            return newState;
        }
        case 'SET_PAGE': {
            newState.currentPage = action.payload.data;
            if (action.payload.data <= newState.maxVisitedPage) {
                newState.shouldFetch = false;
            } else if (newState.cachedData < newState.totalData) {
                newState.shouldFetch = true;
            }
            if (newState.currentPage > newState.maxVisitedPage) newState.maxVisitedPage += 1;
            return newState;
        }
        case 'SET_ROWS': {
            newState.rowsPerPage = action.payload.data;
            return newState;
        }
        case 'RESET': {
            newState.currentPage = 1;
            newState.maxVisitedPage = 1;
            newState.cachedData = 0;
            newState.totalData = 0;
            newState.shouldFetch = true;
            return newState;
        }
    }
    return newState;
};
