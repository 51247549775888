import {Box} from '@mui/material';
import React, {ChangeEvent, useContext} from 'react';
import {useLanguage} from '../language/LanguageProvider';
import {FileDict} from '../lib/types/Inquiry';
import {updateSpecificationPhotos} from '../store/actions';
import {AppContext} from '../store/appContext';
import {PlusIcon} from './icons/Plus';
import {PhotoAttachment, PhotoContainer} from './PhotoAttachment';

export const PhotoUploader: React.FC<{handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void}> = (props) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const handleClick = () => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };
    const {getLangString} = useLanguage();

    return (
        <>
            <input
                type={'file'}
                accept={'.png, .jpg, .jpeg'}
                multiple={true}
                onChange={props.handleFileChange}
                ref={hiddenFileInput}
                style={{display: 'none'}}
            />
            <PhotoContainer onClick={handleClick}>
                <Box
                    width={98}
                    height={106}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    marginBottom={1}
                    sx={{backgroundColor: 'rgba(18,18,18,0.1)'}}
                >
                    <PlusIcon size={48} />
                </Box>
                <span>{getLangString('ADD_PHOTO')}</span>
            </PhotoContainer>
        </>
    );
};

export const InquiryPhotoAttachments: React.FC = () => {
    const {dispatch, state} = useContext(AppContext);
    const [selectedFiles, setSelectedFiles] = React.useState<FileDict<File> | undefined>(state.inquiry.photos);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.files &&
            Array.from(e.target.files).forEach((file, i) => {
                const timeStamp = Date.now();
                setSelectedFiles((prev) => ({
                    ...prev,
                    [i + '_' + timeStamp]: file,
                }));
            });
    };
    const removeFile = (id: string) => {
        if (selectedFiles) {
            delete selectedFiles[id];
            setSelectedFiles({...selectedFiles});
        }
    };
    React.useEffect(() => {
        dispatch(updateSpecificationPhotos(selectedFiles));
    }, [dispatch, selectedFiles]);

    return (
        <Box display={'flex'} flexWrap={'wrap'}>
            {selectedFiles &&
                Object.keys(selectedFiles).map((f, i) => (
                    <PhotoAttachment
                        key={i}
                        id={f}
                        handleOnClick={removeFile}
                        photoUrl={URL.createObjectURL(selectedFiles[f])}
                        enableLargeView={false}
                        text={'Fjern bilde'}
                    />
                ))}
            <PhotoUploader handleFileChange={handleFileChange} />
        </Box>
    );
};
