import {useTheme} from '@mui/material';
import React from 'react';

export function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState<{width: number; height: number}>({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}
export function useScrollTop() {
    const [scrollTop, setScrollTop] = React.useState<number>(document.documentElement.scrollTop);
    React.useEffect(() => {
        function handleScroll() {
            setScrollTop(document.documentElement.scrollTop);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });
    return scrollTop;
}

export function useIsSmallScreen(): boolean {
    const windowSize = useWindowSize();
    const theme = useTheme();
    return windowSize.width < theme.breakpoints.values.sm;
}

/**
 * Norwegian phone numbers 8 letters
 */
export const noPhoneRegex = /^(\d{8})$/;
/**
 * Phone number starting with land code
 */
export const phoneWithLandCodeRegex = /^(?:\+\d{0,3}) ?(\d{2,32}\s?-?){3,}(\d{2,32}?)$/;
/**
 * Phone number
 */
export const phoneRegex = /^(?:\+\d{0,3})? ?(\d{2,32}\s?-?){3,}(\d{2,32}?)$/;
/**
 * Norwegian organization numbers
 */
export const organizationNumberRegex = /^(\d{9})$/;
/**
 * Norwegian zip codes
 */
export const zipRegex = /^(\d{4})$/;
/**
 * Alphanumeric with some special
 */
export const alphaNumericSpaceSpecialRegex = /^[a-zæøåA-ZÆØÅ0-9 .-_]*$/gi;
/**
 * Alphanumeric with space
 */
export const alphaNumericSpaceRegex = /^[a-zæøåA-ZÆØÅ0-9 ]*$/gi;
/**
 * Alphanumeric with no space
 */
export const alphaNumericRegex = /^[a-zæøåA-ZÆØÅ0-9]*$/gi;
/**
 * No special character
 */
export const noSpecialCharRegex =
    /^(?!.*([!@#$§%^&*()_+=~\\\][{}|;:?/*,+""\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]))/gi;

/**
 * Regex for validating WEB URLs, requires protocol http/https
 */
export const webUrlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/i;
/**
 * Address regex
 * Allow for C.k.street 34, Street.34.3 abc 30000
 */
export const addressRegex = /^[a-zæøå]+ ?\.?([a-zæøå0-9]+ ?\.?)+$/gi;

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export function validateEmail(email: string) {
    return emailRegex.test(String(email).toLowerCase());
}

export const getNorwegianDateString = (date: Date) => {
    return date.toLocaleDateString('nb-NO', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};
export const getTimeString = (date: Date) => date.toLocaleTimeString('nb-NO', {hour: '2-digit', minute: '2-digit'});
export const setHour = (hour: number): Date => {
    const date = new Date(Date.now());
    date.setHours(hour, 0, 0);
    return date;
};
export const isValidInput = (value: string, pattern: string): boolean => {
    const regExp = new RegExp(pattern);
    return regExp.test(value);
};

export const priceRegex = /^\d?(\d? ?)+(,{0,1}\.{0,1})?\d+$/;
export const pricePattern = '(\\d([\\d\\s]+)?([.,](\\d+)?)?)';
export const phonePattern = '^(?:\\+\\d{0,3}) ?(\\d{2,32}\\s?-?){3,}(\\d{2,32}?)$';

export const trimString = (string: string, maxLength: number): string => {
    return string.length > maxLength ? string.substring(0, maxLength) + '...' : string;
};

export const setCookie = (cName: string, cValue: string, expDays?: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (expDays || 365) * 24 * 60 * 60 * 1000);
    document.cookie = cName + '=' + cValue + '; expires=' + d.toUTCString() + ';';
};
export const getCookie = (cName: string) => {
    const name = cName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
