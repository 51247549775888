import {Box, Button, ButtonProps, styled, Theme, Typography} from '@mui/material';
import {SxProps} from '@mui/system';
import React, {ChangeEvent, useRef, useState} from 'react';
import {useLanguage} from '../language/LanguageProvider';

export const InfoField = styled(Box)(({theme}) => ({
    border: `1px solid ${theme.palette.formLine.active}`,
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
        padding: '24px 16px',
    },
    marginRight: -1,
    marginBottom: -1,
}));

const CustomButton: React.FC<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            variant={'text'}
            sx={{
                padding: 0,
                minWidth: 32,
                color: 'text.secondary',
                ':hover': {color: 'text.primary'},
                '&:disabled': {color: 'text.disabled'},
                ...props.sx,
            }}
        >
            {props.children}
        </Button>
    );
};

type Props = {
    onEditClick: () => void;
    sx?: SxProps<Theme>;
};
export const InfoFieldWithEdit: React.FC<Props> = ({onEditClick, sx, children}) => {
    const {getLangString} = useLanguage();
    return (
        <InfoField display={'flex'} justifyContent={'space-between'} sx={sx}>
            <Typography>{children}</Typography>
            <CustomButton onClick={onEditClick} sx={{alignSelf: 'flex-end'}}>
                {getLangString('EDIT')}
            </CustomButton>
        </InfoField>
    );
};

const InputFieldWithSave: React.FC<
    {
        value: string;
        onSaveClick: (value: string, name?: string) => void;
        handleError?: (hasError: boolean) => void;
    } & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
    const {getLangString} = useLanguage();
    const [fieldValue, setFieldValue] = useState<string>(props.value || '');
    const [fieldError, setFieldError] = useState<boolean>(false);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.patternMismatch) {
            setFieldError(true);
            props.handleError && props.handleError(true);
        } else {
            setFieldError(false);
            props.handleError && props.handleError(false);
        }
        setFieldValue(event.target.value);
    };
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, []);

    const onButtonClick = () => {
        props.onSaveClick(fieldValue, props.name);
    };
    return (
        <InfoField
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 0,
                paddingRight: 3,
                borderWidth: 2,
                borderColor: fieldError ? 'error.main' : 'inherit',
            }}
        >
            <input
                {...props}
                ref={inputRef}
                value={fieldValue}
                onChange={handleChange}
                style={{
                    padding: 23,
                    lineHeight: '24px',
                    flexGrow: 1,
                    fontSize: '1rem',
                    background: 'transparent',
                    border: 0,
                    WebkitAppearance: 'none',
                    outline: 'none',
                }}
            />
            <CustomButton disabled={fieldError} onClick={onButtonClick} sx={{color: 'text.primary'}}>
                {getLangString('SAVE')}
            </CustomButton>
        </InfoField>
    );
};

export const EditableInfoFieldWithButtons: React.FC<
    {
        value: string;
        onSave: (value: string, name?: string) => void;
        handleInputError?: (hasError: boolean) => void;
        suffix?: string;
    } & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const handleSave = async (value: string, name?: string) => {
        await props.onSave(value, name);
        setIsEditing(!isEditing);
    };

    if (isEditing) {
        return (
            <InputFieldWithSave
                {...props}
                value={props.value}
                onSaveClick={handleSave}
                handleError={props.handleInputError}
            />
        );
    } else {
        return (
            <InfoFieldWithEdit
                onEditClick={() => setIsEditing(!isEditing)}
                sx={{'& p': {color: !props.value ? 'text.secondary' : 'inherit'}}}
            >
                {props.value ? props.value + (props.suffix || '') : props.placeholder}
            </InfoFieldWithEdit>
        );
    }
};

export const EditableInfoField: React.FC<
    {
        displayedValue: string;
        inputValue: string;
        onSave: (value: string, name?: string) => void;
        handleInputError?: (hasError: boolean) => void;
        suffix?: string;
    } & React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const handleBlur = () => {
        props.onSave(fieldValue, props.name);
        setIsEditing(!isEditing);
    };

    const [fieldValue, setFieldValue] = useState<string>(props.inputValue);
    const [fieldError, setFieldError] = useState<boolean>(false);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.patternMismatch) {
            setFieldError(true);
            props.handleInputError && props.handleInputError(true);
        } else {
            setFieldError(false);
            props.handleInputError && props.handleInputError(false);
        }
        setFieldValue(event.target.value);
    };
    React.useEffect(() => {
        inputRef.current && inputRef.current.focus();
        setFieldValue(props.inputValue);
    }, [isEditing, props.inputValue]);
    if (isEditing) {
        return (
            <Box sx={{border: 2, borderColor: fieldError ? 'error.main' : 'formLine.active', mr: '-1px', mb: '-1px'}}>
                <input
                    {...props}
                    ref={inputRef}
                    value={fieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                        padding: 23,
                        lineHeight: '24px',
                        flexGrow: 1,
                        fontSize: '1rem',
                        background: 'transparent',
                        border: 0,
                        WebkitAppearance: 'none',
                        outline: 'none',
                    }}
                />
            </Box>
        );
    } else {
        return (
            <InfoField
                onClick={() => setIsEditing(!isEditing)}
                sx={{cursor: 'text', '& p': {color: !props.displayedValue ? 'text.secondary' : 'inherit'}}}
            >
                {props.displayedValue ? props.displayedValue + (props.suffix || '') : props.placeholder}
            </InfoField>
        );
    }
};
