import {TextField} from '@mui/material';
import {ChangeEvent, VFC} from 'react';

export const SearchField: VFC<{handleChange: (e: ChangeEvent<HTMLInputElement>) => void}> = ({handleChange}) => (
    <TextField
        sx={{mb: 4}}
        size='small'
        placeholder='Søk her...'
        inputProps={{
            startAdornment: <div>WER</div>,
        }}
        onChange={handleChange}
    />
);
