import {Box, Link, Typography, useTheme} from '@mui/material';
import React, {SyntheticEvent, useState} from 'react';
import Swiper, {SwiperOptions} from 'swiper';
import {ItemSlider} from '../../ItemSlider';
import {Container, CssGrid} from '../../Layout';

export const SliderWithControls: React.FC<{
    heading: string;
    items: React.ReactNode[];
    link?: {
        text: string;
        url: string;
    };
    breakpoints?: {
        [any: string]: SwiperOptions;
    };
}> = (props) => {
    const theme = useTheme();
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const goPrev = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const goNext = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (swiper) {
            swiper.slideNext();
        }
    };

    const update = (instance: Swiper) => {
        setSwiper(instance);
        instance.on('slideChange', () => {
            setIsFirstSlide(instance.isBeginning);
            setIsLastSlide(instance.isEnd);
        });
    };

    const buttonStyle = {
        display: 'block',
        backgroundColor: 'transparent',
        border: 'none',
        transition: 'opacity 200ms ease',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontSize: '2.625rem',
        fontFamily: 'SuisseIntl-Regular',
        cursor: 'pointer',
    };

    return (
        <Container>
            <CssGrid>
                <Box
                    sx={{
                        gridColumn: '1 / span 8',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 3,
                        [theme.breakpoints.up('md')]: {
                            gridColumn: '2 / span 7',
                            mb: 4,
                        },
                        [theme.breakpoints.up('lg')]: {
                            mb: 6,
                        },
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant='sectionHeading'>{props.heading}</Typography>

                        <Box
                            sx={{
                                display: 'none',
                                [theme.breakpoints.up('lg')]: {
                                    display: props.items.length > 2 ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gridRow: 1,
                                    ml: 8,
                                },
                            }}
                        >
                            <button
                                onClick={goPrev}
                                style={{
                                    ...buttonStyle,
                                    opacity: isFirstSlide ? '0.1' : '1',
                                }}
                            >
                                &larr;
                            </button>
                            <button
                                onClick={goNext}
                                style={{
                                    ...buttonStyle,
                                    opacity: isLastSlide ? '0.1' : '1',
                                    marginLeft: '3rem',
                                }}
                            >
                                &rarr;
                            </button>
                        </Box>
                    </Box>
                    {props.link && (
                        <Link
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    fontFamily: 'SuisseIntl-Regular',
                                    textDecorationColor: theme.palette.text.primary,
                                    fontSize: '1.3125rem',
                                },
                            }}
                            href={props.link.url}
                        >
                            {props.link.text}
                        </Link>
                    )}
                </Box>
            </CssGrid>
            <ItemSlider items={props.items} handleSwipe={update} slidesPerViewOverride={props.breakpoints} />
        </Container>
    );
};
