import {Grid} from '@mui/material';
import React from 'react';
import {Control, Controller, UseFormTrigger} from 'react-hook-form';
import {useLanguage} from '../language/LanguageProvider';
import {validateIsRequired, validateZip} from '../lib/validationMessages';
import {CustomInputField} from './InputField';

export type AddressFields = {
    address: string;
    postalCode: string;
    city: string;
};
type AddressProps = {
    addressValue?: string;
    postalCodeValue?: string;
    cityValue?: string;
    control: Control<AddressFields> | undefined;
    trigger: UseFormTrigger<AddressFields>;
};

export const AddressFormFields: React.FC<AddressProps> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <Grid container columnSpacing={0}>
            <Grid item xs={8} sm={4}>
                <Controller
                    name={'address'}
                    rules={{
                        required: validateIsRequired(),
                    }}
                    control={props.control}
                    defaultValue={props.addressValue || ''}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            label={getLangString('ADDRESS')}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            required={true}
                            error={!!fieldState.error}
                            onBlur={() => props.trigger(field.name)}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4} sm={2}>
                <Controller
                    name={'postalCode'}
                    rules={{
                        required: validateIsRequired(),
                        pattern: validateZip(),
                    }}
                    control={props.control}
                    defaultValue={props.postalCodeValue}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            label={getLangString('POSTAL_CODE')}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            required={true}
                            error={!!fieldState.error}
                            onBlur={() => props.trigger(field.name)}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4} sm={2}>
                <Controller
                    name={'city'}
                    rules={{
                        required: validateIsRequired(),
                    }}
                    control={props.control}
                    defaultValue={props.cityValue}
                    render={({field, fieldState}) => (
                        <CustomInputField
                            label={getLangString('CITY')}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            required={true}
                            error={!!fieldState.error}
                            onBlur={() => props.trigger(field.name)}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
