import {Box, Container, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useLanguage} from '../../language/LanguageProvider';
import {AppContext} from '../../store/appContext';
import {CssGrid, DefaultGridPageLayout} from '../Layout';

export const DiscountsPage: React.FC = () => {
    const {bgColor} = useContext(AppContext);
    const {getLangString} = useLanguage();
    return (
        <Container sx={{backgroundColor: bgColor}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    <Box mt={4} mb={20}>
                        <Typography variant={'h6'} mb={2}>
                            {getLangString('MY_DISCOUNTS')}
                        </Typography>
                        <Typography>{getLangString('MY_DISCOUNTS_CONTENT_TEXT')}</Typography>
                    </Box>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
