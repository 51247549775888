import deepmerge from 'deepmerge';
import {ProblemDetailsError} from './ProblemDetails';

interface HttpConfig {
    baseUrl: string;
    accessToken?: string;
}

/**
 * Json http client based on Fetch.
 * Sends all content as JSON, and Accepts json as response.
 * Authorization is based on Bearer token, and will be applied when a token
 * is provided.
 */
export class JsonHttpClient {
    private _baseConfig: HttpConfig;

    /**
     * @param baseUrl the base url used for this client
     * @param accessToken optional access token for Bearer authorization
     */
    constructor(baseConfig: HttpConfig) {
        this._baseConfig = baseConfig;
    }

    private getAuthorizationField() {
        return this._baseConfig.accessToken ? {Authorization: `Bearer ${this._baseConfig.accessToken}`} : {};
    }

    private createRequestConfig(config: RequestInit) {
        const baseConfig = {
            headers: {
                ...this.getAuthorizationField(),
            },
            credentials: 'omit',
        } as RequestInit;

        const completeConfig = deepmerge(baseConfig, config);

        if (config.body && config.body instanceof FormData) {
            completeConfig.body = config.body;
        }
        return completeConfig;
    }

    private getJsonHeader() {
        return {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
    }

    /**
     *
     * @param url url to make request to
     * @param config request config for the request
     * @returns data response or throws error for status > 2xx or network error
     */
    private async makeRequest(url: string, config: RequestInit) {
        const response = await fetch(`${this._baseConfig.baseUrl}${url}`, this.createRequestConfig(config));

        if (!response.ok) {
            throw new ProblemDetailsError({message: `Error while fetching ${url}`, details: await response.json()});
        }
        if (response.status === 204) {
            return await new Promise((resolve) => resolve(null));
        }
        return await response.json();
    }

    /**
     * Performs a GET request to the provided URL
     * @param url url to send get request to
     * @returns response of type T or error
     */
    public get<T>(url: string): Promise<T> {
        return this.makeRequest(url, {method: 'GET', ...this.getJsonHeader()});
    }

    /**
     * Performs a POST request to the provided url with the provided data
     * @param url url to send post request to
     * @param data data to submit
     * @returns response of type T or error
     */
    public submitForm<T>(url: string, data?: FormData): Promise<T> {
        return this.makeRequest(url, {method: 'POST', body: data});
    }

    /**
     * Performs a POST request to the provided url with the provided data
     * @param url url to send post request to
     * @param data data to submit
     * @returns response of type T or error
     */
    public post<T, K>(url: string, data?: K): Promise<T> {
        return this.makeRequest(url, {method: 'POST', body: JSON.stringify(data), ...this.getJsonHeader()});
    }

    /**
     * Performs a PUT request to the provided url with the provided data
     * @param url url to send put request to
     * @param data data to submit
     * @returns response of type T or error
     */
    public put<T>(url: string, data: T): Promise<T> {
        return this.makeRequest(url, {method: 'PUT', body: JSON.stringify(data), ...this.getJsonHeader()});
    }

    /**
     * Performs a DELETE request to the provided url with the provided data
     * @param url url to send delete request to
     * @param data data to submit
     * @returns response of type T or error
     */
    public delete<T, K>(url: string, data?: K): Promise<T> {
        return this.makeRequest(url, {method: 'DELETE', body: JSON.stringify(data), ...this.getJsonHeader()});
    }

    /**
     * Sets the Bearer access token for use in requests.
     * @param accessToken access token for bearer authorization
     */
    public setAccessToken(accessToken: string): void {
        this._baseConfig.accessToken = accessToken;
    }

    /**
     * Clears the access token on this http client
     */
    public clearAccessToken(): void {
        this._baseConfig.accessToken = undefined;
    }
}
