import {Typography, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';

type Props = {
    text: string;
};

export const PageIntro: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <Container>
            <CssGrid>
                <Typography
                    component='div'
                    variant='pageIntro'
                    sx={{
                        '& > *': {
                            margin: '0',
                        },
                        '& > * + *': {},
                        '& a': {
                            color: 'text.primary',
                        },
                        gridColumn: 'span 8',
                        [theme.breakpoints.up('lg')]: {
                            gridColumn: '2 / span 6',
                        },
                    }}
                    dangerouslySetInnerHTML={{
                        __html: props.text,
                    }}
                ></Typography>
            </CssGrid>
        </Container>
    );
};
