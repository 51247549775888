import {Box, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {Dispatch, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useLanguage} from '../../../../language/LanguageProvider';
import {CordelCompanySimpleDto} from '../../../../lib/dtos/Cordel';
import {OfferSimpleDto} from '../../../../lib/dtos/Offer';
import {UpdateProjectDto} from '../../../../lib/dtos/Project';
import {EmployeeDto} from '../../../../lib/dtos/User';
import {useM2Axios} from '../../../../lib/hooks/useM2Axios';
import {M2HttpClient} from '../../../../lib/remote/m2api/ApiHttpClient';
import {M2Response} from '../../../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {ProblemDetails, ProblemDetailsError} from '../../../../lib/remote/ProblemDetails';
import {CordelService} from '../../../../lib/services/CordelService';
import {InquiryService} from '../../../../lib/services/InquiryService';
import {OfferService} from '../../../../lib/services/OfferService';
import {InquiryDetails} from '../../../../lib/types/Admin';
import {ServiceDictionary} from '../../../../lib/types/Inquiry';
import {ActionType, loadInquiryDetails} from '../../../../store/actions';
import {AdminAppContext} from '../../../../store/adminAppContext';
import {getNorwegianDateString, getTimeString} from '../../../../toolbox';
import {BackButton} from '../../../BackButton';
import {ButtonPrimaryMedium, ButtonUnderlined} from '../../../Buttons';
import {InfoField} from '../../../InfoField';
import {LoaderCentered} from '../../../Loader';
import {getServiceTasksText} from '../../serviceText';
import {AdminPageLayoutWithSimpleMenu} from '../AdminPageLayout';
import {getOfferByIDPath} from '../AdminPages';
import {CompanySelect} from '../CompanySelect';
import {ContactInfo} from '../ContactInfo';
import {EmployeeSelect} from '../EmployeeSelect';
import {getAddress} from '../getAddress';
import {Header} from '../Header';
import {ProjectDescription} from '../ProjectDescription';

type CompanyDict = {
    [id: string]: CordelCompanySimpleDto;
};
const SelectedCompany: React.FC<{companyID: number; companyName: string; onDelete: (id: number) => void}> = (props) => {
    const {getLangString} = useLanguage();
    const handleClick = () => {
        props.onDelete(props.companyID);
    };
    return (
        <Box
            key={props.companyID}
            sx={{p: 3, display: 'flex', justifyContent: 'space-between', border: 1, borderColor: 'formline.active'}}
        >
            {props.companyName}
            <ButtonUnderlined onClick={handleClick}>{getLangString('REMOVE_COMPANY')}</ButtonUnderlined>
        </Box>
    );
};

const Content: React.FC<
    InquiryDetails & {serviceDict: ServiceDictionary | undefined; dispatch: Dispatch<ActionType>; offerID?: number}
> = (props) => {
    const {getLangString} = useLanguage();
    const history = useHistory();
    const createAndGoToOfferPage = async () => {
        const service = new OfferService(M2HttpClient);
        try {
            const response = await service.createOffer(props.project.projectId, props.inquiryId);
            history.push(getOfferByIDPath(props.project.projectId, response.offerId));
        } catch (e) {
            return;
        }
    };
    const goToOfferPage = () => {
        history.push(getOfferByIDPath(props.project.projectId, props.offerID));
    };
    const [companies, setCompanies] = React.useState<CompanyDict>();
    React.useEffect(() => {
        const fetchCompanies = async () => {
            const service = new CordelService(M2HttpClient);
            try {
                const response = await service.getCordelCompanies(1, 50);
                setCompanies(
                    response.data.reduce((acc, company) => ({...acc, [company.cordelCompanyId]: company}), {}),
                );
            } catch (e) {
                return;
            }
        };
        fetchCompanies();
    }, []);

    const [selectedCompanies, setSelectedCompanies] = React.useState<CompanyDict | undefined>(undefined);
    const handleCompanySelect = (companyID: number) => {
        if (companies) {
            setSelectedCompanies({
                ...selectedCompanies,
                [companyID]: companies[companyID],
            });
        }
    };
    const removeCompany = (companyId: number) => {
        if (selectedCompanies) {
            delete selectedCompanies[companyId];
            setSelectedCompanies({...selectedCompanies});
        }
    };
    const [isSendingInquiry, setIsSendingInquiry] = React.useState<boolean>();
    const [error, setError] = React.useState<string | undefined>();
    const sendInquiry = async () => {
        const service = new InquiryService(M2HttpClient);
        if (selectedCompanies) {
            try {
                setIsSendingInquiry(true);
                await service.sendInquiryToCompanies(
                    props.inquiryId,
                    Object.keys(selectedCompanies).map((c) => ({
                        cordelCompanyId: selectedCompanies[c as keyof CordelCompanySimpleDto].cordelCompanyId,
                    })),
                );
                setSelectedCompanies(undefined);
                setIsSendingInquiry(false);
                const response = await service.getInquiryById(props.inquiryId);
                props.dispatch(loadInquiryDetails(response.data));
            } catch (e) {
                const error = e as ProblemDetails;
                setError(error.title);
            }
        }
    };
    const hasSelectedCompanies = () => {
        return selectedCompanies && Object.keys(selectedCompanies).length !== 0;
    };
    const getCompaniesNotSelected = (companies: CompanyDict) => {
        const companyIds = Object.keys(companies);
        if (props.cordelInquiries.length > 0) {
            return companyIds
                .filter((c) => !props.cordelInquiries.some((cI) => cI.cordelCompanyId === parseInt(c)))
                .map((c) => companies[c as keyof CordelCompanySimpleDto]);
        }
        return companyIds.map((c) => companies[c as keyof CordelCompanySimpleDto]);
    };
    const [{loading: loadingEmployees, data: employees}] = useM2Axios<
        M2Response<EmployeeDto[]>,
        unknown,
        ProblemDetailsError
    >(`${ApiEndpoints.user.base()}/employees?page=1&size=50`, {
        manual: false,
    });
    const [{loading: updateLoading}, updateProject] = useM2Axios<unknown, UpdateProjectDto, ProblemDetailsError>(
        {url: `${ApiEndpoints.project.byId(props.project.projectId)}`, method: 'PUT'},
        {manual: true},
    );
    const handleEmployeeSelect = (id: number) => {
        const data: UpdateProjectDto = {projectId: props.project.projectId, projectLeaderEmployeeId: id};
        updateProject({data});
    };

    const address = getAddress(props.project);
    const serviceTaskText = props.serviceDict && getServiceTasksText(props.project.services, props.serviceDict);
    return (
        <>
            <Header
                id={props.project.projectId}
                customText={`${getLangString('INQUIRY')} ${getNorwegianDateString(props.created)}`}
                services={props.serviceDict}
                project={props.project}
            />
            <Box>
                <Grid container columnSpacing={0}>
                    <Grid item xs={8} sm={4}>
                        <Typography variant={'h6'}>{getLangString('PROJECT_LEADER')}</Typography>
                        {employees && (
                            <EmployeeSelect
                                employees={employees.data}
                                onSelect={handleEmployeeSelect}
                                currentEmployee={props.project.projectLeader}
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box my={6}>
                <Typography variant={'h6'}>{getLangString('CONTACT_INFO')}</Typography>
                <ContactInfo {...props.contactInfo} address={address} />
            </Box>
            <Box my={6}>
                <Typography variant={'h6'}>{getLangString('CONTACT_TYPE')}</Typography>
                <InfoField>
                    {props.consultationType === 'call' ? (
                        <Box>
                            <Typography>
                                {`${getLangString('PHONE_CALL')} 
                                    ${getTimeString(props.consultationFrom)} - ${getTimeString(props.consultationTo)}`}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography>{getLangString('EMAIL')}</Typography>
                    )}
                </InfoField>
            </Box>
            <Box my={6}>
                <ProjectDescription
                    descriptionHeader={getLangString('INFO_TEXT_CUSTOMER')}
                    descriptionText={props.description}
                    serviceTaskText={serviceTaskText}
                    imageHeader={getLangString('ATTACHED_PHOTOS')}
                    images={props.images}
                />
            </Box>
            <Box mt={7}>
                <Box my={4}>
                    {props.cordelInquiries.length > 0 && (
                        <>
                            <Typography variant={'h6'}>{getLangString('INQUIRY_SENT_TO')}</Typography>
                            {companies &&
                                props.cordelInquiries.map((company) => (
                                    <InfoField key={company.cordelCompanyId}>
                                        {companies[company.cordelCompanyId].name}
                                    </InfoField>
                                ))}
                        </>
                    )}
                </Box>
                <Box sx={{border: 1, borderColor: 'formLine.active', p: 3, position: 'relative'}}>
                    <Box sx={{opacity: isSendingInquiry ? 0.5 : 1}}>
                        <Typography variant={'h6'}>{getLangString('SEND_INQUIRY_TO')}</Typography>
                        {companies && (
                            <CompanySelect
                                onCompanySelect={handleCompanySelect}
                                companies={getCompaniesNotSelected(companies)}
                            />
                        )}
                        {selectedCompanies && (
                            <>
                                <Typography variant={'subtitle1'}>{getLangString('SENDING_INQUIRY_TO')}</Typography>
                                <Box mb={4}>
                                    {Object.keys(selectedCompanies).map((c) => {
                                        const company = selectedCompanies[c as keyof CordelCompanySimpleDto];
                                        return (
                                            <SelectedCompany
                                                key={company.cordelCompanyId}
                                                companyID={company.cordelCompanyId}
                                                companyName={company.name}
                                                onDelete={removeCompany}
                                            />
                                        );
                                    })}
                                </Box>
                                <ButtonPrimaryMedium onClick={sendInquiry} disabled={!hasSelectedCompanies()}>
                                    {getLangString('SEND_INQUIRY')}
                                </ButtonPrimaryMedium>
                            </>
                        )}
                    </Box>
                    {isSendingInquiry && <LoaderCentered text={'Sender henvendelse...'} />}
                </Box>
            </Box>
            <Box my={7}>
                {props.offerID ? (
                    <ButtonPrimaryMedium onClick={goToOfferPage}>{getLangString('OPEN_OFFER')}</ButtonPrimaryMedium>
                ) : (
                    <ButtonPrimaryMedium onClick={createAndGoToOfferPage} disabled={props.cordelInquiries.length === 0}>
                        {getLangString('COMPLETE_OFFER')}
                    </ButtonPrimaryMedium>
                )}
            </Box>
        </>
    );
};
export const InquiryDetailsPage: React.FC = () => {
    const {state, dispatch} = React.useContext(AdminAppContext);
    const params = useParams<{projectID: string; inquiryID: string}>();
    const [isLoading, setIsLoading] = useState<boolean>();

    React.useEffect(() => {
        const getInquiry = async () => {
            const service = new InquiryService(M2HttpClient);
            try {
                setIsLoading(true);
                const response = await service.getInquiryById(parseInt(params.inquiryID));
                dispatch(loadInquiryDetails(response.data));
                setIsLoading(false);
            } catch (e) {
                const error = e as ProblemDetails;
                setIsLoading(false);
                console.log('Error: ', error);
            }
        };
        getInquiry();
    }, [params.inquiryID]);

    const [{data: offers}] = useM2Axios<M2Response<OfferSimpleDto[]>, unknown, ProblemDetailsError>(
        `${ApiEndpoints.offer.base()}`,
        {manual: false},
    );
    const [offerId, setOfferId] = useState<number | undefined>(undefined);

    useEffect(() => {
        const offer: OfferSimpleDto | undefined =
            offers && offers.data?.find((offer) => offer.inquiryId === parseInt(params.inquiryID));
        offer && setOfferId(offer.offerId);
    }, [offers, params.inquiryID]);

    return (
        <AdminPageLayoutWithSimpleMenu>
            <Box mt={2}>
                <BackButton />
            </Box>
            {isLoading ? (
                <LoaderCentered />
            ) : (
                state.inquiryDetails && (
                    <Content
                        {...state.inquiryDetails}
                        serviceDict={state.availableServices}
                        dispatch={dispatch}
                        offerID={offerId}
                    />
                )
            )}
        </AdminPageLayoutWithSimpleMenu>
    );
};
