import {ApolloProvider, useQuery} from '@apollo/client';
import {useIsAuthenticated} from '@azure/msal-react';
import {Box} from '@mui/material';
import React from 'react';
import {apolloClient, GET_NAVIGATION} from '../graphql';
import {deconstructHTML} from '../graphql/helpers';
import {MyPageLink} from './MyPageButton';
import {BurgerMenuLink} from './Navigation';

export const NavMenu: React.FC = () => {
    const {loading, error, data} = useQuery(GET_NAVIGATION);
    const isAuthenticated = useIsAuthenticated();

    if (loading || error) return <Box></Box>;

    const navigationLinks = data.globalSet.navigationLinks;

    return (
        <ApolloProvider client={apolloClient}>
            <Box>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {navigationLinks.map((navigationLink: any) => {
                    const link = deconstructHTML(navigationLink.linkTo);

                    if (link) {
                        return (
                            <Box key={navigationLink.id}>
                                <BurgerMenuLink to={link.url}>{link.text}</BurgerMenuLink>
                            </Box>
                        );
                    }
                })}
                {isAuthenticated && <MyPageLink />}
            </Box>
        </ApolloProvider>
    );
};
