import {Grid, Typography} from '@mui/material';
import {OrderDto} from 'lib/dtos/Order';
import {OrderableCompaniesDto} from 'lib/dtos/OrderableCompanies';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import React from 'react';
import {LoaderCentered} from '../../../Loader';
import {CordelOrdersTable} from './CordelOrdersTable';
import {CreateCordelOrder} from './CreateCordelOrder';
import {PageElement} from './PageElement';

type CordelOrdersManagerProps = {
    order: OrderDto;
    update: () => void;
};

/**
 * Component for creating and editing cordel orders on a
 */
export const CordelOrdersManager: React.FC<CordelOrdersManagerProps> = ({order, update}) => {
    const [{data: orderableCompanies, loading}] = useM2Axios<M2Response<OrderableCompaniesDto[]>>(
        ApiEndpoints.project.orderableCompanies(order.project.projectId),
        {
            manual: false,
        },
    );

    const getList = () => {
        if (loading) {
            return <LoaderCentered />;
        }
        if (orderableCompanies?.data.length) {
            return orderableCompanies.data.map((company, index) => {
                const cordelOrders = order.cordelOrders.filter((co) => co.cordelCompanyId === company.cordelCompanyId);
                return (
                    <Grid key={index} container direction={'column'} mb={6} rowSpacing={1}>
                        <Grid item mb={2}>
                            <Typography variant='h5' sx={{}}>
                                {company.name}
                            </Typography>
                            <Typography>{company.type}</Typography>
                        </Grid>
                        <Grid item>
                            <CordelOrdersTable cordelOrders={cordelOrders} />
                        </Grid>
                        <Grid item mt={3}>
                            <Typography variant='subtitle1'>Opprett ordre i cordel:</Typography>
                            <CreateCordelOrder
                                order={order}
                                orderableCompany={company}
                                onCreated={update}
                            ></CreateCordelOrder>
                        </Grid>
                    </Grid>
                );
            });
        }
    };

    return <PageElement>{getList()}</PageElement>;
};
