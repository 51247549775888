/* eslint-disable @typescript-eslint/no-explicit-any */
//TODO: provide correct types where any is used
import {useQuery} from '@apollo/client';
import {Box, useTheme} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import {PEOPLE_INDEX_PAGE_URL} from '../../cms/helpers';
import {CMS_SEO, GET_PEOPLE_INDEX_PAGE, GET_SEO_DATA} from '../../graphql';
import {SEO} from '../../SEO';
import {colors} from '../../theme';
import {PageIntro} from '../cms/PageIntro';
import {PageOverline} from '../cms/PageOverline';
import {List, ListItem} from '../cms/partials/List';
import {PersonCard} from '../cms/partials/PersonCard';
import {SearchIcon} from '../icons/SearchIcon';
import {Container, CssGrid} from '../Layout';
import {LoaderCenterBlur} from '../Loader';

export const PeopleIndexPage: React.FC = () => {
    const theme = useTheme();
    const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const isCurrentCategory = (value: string | null) => {
        return currentCategoryId == value;
    };

    const {data: SEOData} = useQuery<CMS_SEO>(GET_SEO_DATA(PEOPLE_INDEX_PAGE_URL));
    const {loading, error, data} = useQuery(GET_PEOPLE_INDEX_PAGE);

    if (loading) return <LoaderCenterBlur open={true} />;
    if (error) return <p>Error</p>;

    const entry = data.entry;
    const categories = data.categories;
    const people = data.people;

    const filteredPeople =
        currentCategoryId === null
            ? people
            : people.filter((person: any) => {
                  let foundMatch = false;
                  person.peopleCategory.forEach((category: any) => {
                      if (category.id == currentCategoryId) {
                          foundMatch = true;
                      }
                  });
                  return foundMatch;
              });

    const searchedPeople =
        searchTerm == ''
            ? filteredPeople
            : filteredPeople.filter((person: any) => {
                  const searchString =
                      person.title +
                      person.jobTitle +
                      person.phoneNumber +
                      person.emailAddress +
                      (person.peopleCategory.length ? person.peopleCategory[0].title : '');
                  const regex = new RegExp(`${searchTerm.toLowerCase()}`);
                  return searchString.toLowerCase().search(regex) >= 0;
              });

    return (
        <>
            <SEO cmsSEO={SEOData} />
            <Box
                sx={{
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pb: 14,
                    },
                }}
            >
                <PageOverline {...{text: entry.title}} />
                <PageIntro {...{text: entry.introText}} />

                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                mt: 5,
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '3 / span 4',
                                    mt: 9,
                                },
                            }}
                        >
                            <List columns={{xs: 2}}>
                                <ListItem
                                    text='Alle menneskene'
                                    underline={isCurrentCategory(null)}
                                    mediumFont={isCurrentCategory(null)}
                                    underlineOnHover={true}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentCategoryId(null);
                                    }}
                                />
                                {categories.map((category: any) => {
                                    return (
                                        <ListItem
                                            key={category.id}
                                            text={category.title}
                                            underline={isCurrentCategory(category.id)}
                                            mediumFont={isCurrentCategory(category.id)}
                                            underlineOnHover={true}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentCategoryId(category.id);
                                            }}
                                        />
                                    );
                                })}
                            </List>
                            <Box
                                sx={{
                                    mt: 3,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 5,
                                    },
                                    position: 'relative',
                                    '& > input': {
                                        borderColor: theme.palette.formLine.inActive,
                                        width: '100%',
                                        paddingLeft: '2.5rem',
                                        py: 1,
                                        fontSize: '1.3125rem',
                                        display: 'block',
                                        fontFamily: 'SuisseIntl-Regular',
                                        backgroundColor: 'transparent',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                        borderRadius: '0',
                                    },
                                    '& > input:focus': {
                                        borderColor: theme.palette.formLine.active,
                                        outline: 'none',
                                    },
                                    '& > input::placeholder': {
                                        fontStyle: 'italic',
                                    },
                                }}
                            >
                                <input
                                    type='text'
                                    value={searchTerm}
                                    onChange={updateSearch}
                                    placeholder='Søk her...'
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '2.5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <SearchIcon size={20} color={colors.tanDarker} />
                                </Box>
                            </Box>
                        </Box>
                    </CssGrid>
                </Container>

                <Container>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            columnGap: 1.5,
                            rowGap: 5,
                            mt: 5,
                            [theme.breakpoints.up('sm')]: {
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                columnGap: 2.5,
                            },
                            [theme.breakpoints.up('md')]: {
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                columnGap: 3,
                                mt: 8,
                                rowGap: 8,
                            },
                            [theme.breakpoints.up('lg')]: {
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                mt: 12,
                                rowGap: 10,
                            },
                        }}
                    >
                        {searchedPeople.map((person: any) => {
                            return (
                                <Box
                                    key={person.id}
                                    sx={{
                                        gridColumn: 'span 1',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <PersonCard person={person} />
                                </Box>
                            );
                        })}
                    </Box>
                </Container>
            </Box>
        </>
    );
};
