import {Box, Button, Container, Grid, Typography} from '@mui/material';
import {CustomerProfileDto, PrivacySettings} from 'lib/dtos/Customer';
import {Address} from 'lib/types/Address';
import React, {Dispatch, useCallback, useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLanguage} from '../../language/LanguageProvider';
import {useM2Axios} from '../../lib/hooks/useM2Axios';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {ApiEndpoints} from '../../lib/remote/m2api/M2Endpoints';
import {ProblemDetailsError} from '../../lib/remote/ProblemDetails';
import {CustomerService} from '../../lib/services/CustomerService';
import {ActionType} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {phonePattern} from '../../toolbox';
import {AddressFields, AddressFormFields} from '../AddressForm';
import {ButtonPrimarySmall, ButtonSecondarySmall} from '../Buttons';
import {CloseIcon} from '../icons/CloseIcon';
import {EditableInfoFieldWithButtons, InfoField} from '../InfoField';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {LoaderCenterBlur} from '../Loader';
import {AccountSettings} from './AccountSettings';

const AddressEntry: React.FC<{
    addressID: number;
    street: string;
    zip: string;
    city: string;
    handleDelete: (addressId: number) => void;
    deleting: boolean;
}> = (props) => {
    const {getLangString} = useLanguage();
    const doDelete = () => {
        props.handleDelete(props.addressID);
    };
    return (
        <Box sx={{my: 4, opacity: props.deleting ? 0.5 : 1}}>
            <Grid container columnSpacing={0}>
                <Grid item xs={8} sm={4}>
                    <InfoField>{props.street}</InfoField>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <InfoField>{props.zip}</InfoField>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <InfoField>{props.city}</InfoField>
                </Grid>
            </Grid>
            <Button variant={'text'} sx={{mt: 1}} onClick={doDelete}>
                <CloseIcon size={10} />
                <Typography ml={1}>{getLangString('DELETE_ADDRESS')}</Typography>
            </Button>
        </Box>
    );
};
const service = new CustomerService(M2HttpClient);

const Content: React.FC<{user: CustomerProfileDto; reFetchData: () => void; dispatch: Dispatch<ActionType>}> = (
    props,
) => {
    const {getLangString} = useLanguage();
    const {addresses} = props.user;
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [isAddingNewAddress, setIsAddingNewAddress] = useState<boolean>(false);
    const {handleSubmit, trigger, reset, control} = useForm<AddressFields>();

    const updatePhoneNumber = async (value: string) => {
        setErrorMsg('');
        try {
            await service.putCustomerInfo(props.user.customerId, {
                phoneNumber: value,
                email: props.user.email,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                privacySettings: props.user.privactSettings,
            });
            props.reFetchData();
        } catch (e) {
            setErrorMsg(getLangString('UPDATE_USER_PHONE_ERROR'));
        }
    };
    const updateEmail = async (value: string) => {
        setErrorMsg('');
        try {
            await service.putCustomerInfo(props.user.customerId, {
                phoneNumber: props.user.phoneNumber,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                email: value,
                privacySettings: props.user.privactSettings,
            });
            props.reFetchData();
        } catch (e) {
            setErrorMsg(getLangString('UPDATE_USER_EMAIL_ERROR'));
        }
    };

    const updateSettings = async (key: keyof PrivacySettings, isChecked: boolean) => {
        try {
            await service.putCustomerInfo(props.user.customerId, {
                ...props.user,
                privacySettings: {
                    ...props.user.privactSettings,
                    [key]: isChecked,
                },
            });
            props.reFetchData();
        } catch (e) {
            console.log(e);
        }
    };
    const [{loading: deleting}, deleteAddress] = useM2Axios<unknown>('', {manual: true});
    const handleAddressDelete = async (addressID: number) => {
        await deleteAddress({
            url: `${ApiEndpoints.customer.byId(props.user.customerId)}/addresses/${addressID}`,
            method: 'DELETE',
        });
        props.reFetchData();
    };
    const [{loading: addingAddress, error: addingAddressError}, addNewAddress] = useM2Axios<
        unknown,
        Address,
        ProblemDetailsError
    >('', {
        manual: true,
    });
    const addAddress = async (form: AddressFields) => {
        const address: Address = {
            street: form.address,
            zip: form.postalCode,
            location: form.city,
        };
        await addNewAddress({
            url: `${ApiEndpoints.customer.byId(props.user.customerId)}/addresses`,
            method: 'POST',
            data: address,
        });
        reset();
        props.reFetchData();
        setIsAddingNewAddress(false);
    };

    return (
        <>
            <Grid container columnSpacing={0} my={4}>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('CONTACT_INFO')}
                </Typography>
                <Grid item xs={8}>
                    <InfoField>{`${props.user.firstName} ${props.user.lastName}`}</InfoField>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <EditableInfoFieldWithButtons
                        value={props.user.phoneNumber}
                        onSave={updatePhoneNumber}
                        type={'tel'}
                        pattern={phonePattern}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <EditableInfoFieldWithButtons value={props.user.email} onSave={updateEmail} />
                </Grid>
                <Grid item xs={8}>
                    <Typography marginTop={1} height={24} color={'error'}>
                        {errorMsg}
                    </Typography>
                </Grid>
            </Grid>
            <Box mt={4} mb={8}>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('MY_ADDRESSES')}
                </Typography>
                {!isAddingNewAddress ? (
                    <Button variant={'text'} onClick={() => setIsAddingNewAddress(true)}>
                        +<Typography ml={1}>{getLangString('ADD_ADDRESS')}</Typography>
                    </Button>
                ) : (
                    <Box sx={{opacity: addingAddress ? 0.5 : 1}}>
                        <form onSubmit={handleSubmit(addAddress)}>
                            <AddressFormFields control={control} trigger={trigger} />
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                {addingAddressError && (
                                    <Typography color={'error'}>{getLangString('ADD_ADDRESS_ERROR')}</Typography>
                                )}
                                <Box textAlign={'right'}>
                                    <ButtonPrimarySmall type={'submit'} sx={{m: 1}}>
                                        {getLangString('ADD')}
                                    </ButtonPrimarySmall>
                                    <ButtonSecondarySmall sx={{m: 1}} onClick={() => setIsAddingNewAddress(false)}>
                                        {getLangString('CANCEL')}
                                    </ButtonSecondarySmall>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                )}
                {addresses.map((a) => (
                    <AddressEntry
                        key={a.customerAddressId}
                        addressID={a.customerAddressId}
                        street={a.street}
                        zip={a.zip}
                        city={a.location}
                        handleDelete={handleAddressDelete}
                        deleting={deleting}
                    />
                ))}
            </Box>
            <Box my={8}>
                <Typography variant={'h6'}>{getLangString('SETTINGS')}</Typography>
                <AccountSettings settings={props.user.privactSettings} handleUpdate={updateSettings} />
            </Box>
        </>
    );
};

export const AccountInfoPage: React.FC = () => {
    const {state, dispatch, bgColor} = useContext(AppContext);
    const [{data: profile, loading}, getProfile] = useM2Axios<{data: CustomerProfileDto}>('', {manual: true});

    const fetchProfile = useCallback(() => {
        state.user && getProfile({url: `${ApiEndpoints.customer.byId(state.user.id)}`});
    }, [getProfile, state.user]);
    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    return (
        <Container sx={{backgroundColor: bgColor}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    {profile && <Content user={profile.data} reFetchData={fetchProfile} dispatch={dispatch} />}
                    <LoaderCenterBlur open={loading && !profile} />
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
