import {AxiosError} from 'axios';
import {ErrorCodes} from 'lib/ErrorCodes';
import {ProblemDetailsError} from 'lib/remote/ProblemDetails';
import {useState} from 'react';

export type CodeMap = {
    [key in ErrorCodes]?: string;
};

export type MixedRequestError = ProblemDetailsError | AxiosError<ProblemDetailsError, unknown>;

/**
 * Hook for translating a specific error code in a problem details object/axios<problem details>
 * to a readable message from the codes map.
 *
 * @param codeTranslationMap error codes to translate
 * @param genericMessage generic message if no code mappings is found
 * @returns translate function and the translated error
 */
export const useProblemDetailsError = (codeTranslationMap: CodeMap, genericMessage?: string) => {
    const [translatedError, setMessage] = useState<string | undefined>();

    /**
     * Translate a problem details code to a human readable string(provided in codeTranslationMap),
     * either in an axios response or as a standalone object.
     * If no translation is found for a code, the generic message is displayed.
     * If the generic message is not provided, it will set translated error as undefined(default value if no errors/ no translation available).
     * @param problem problem/axios error to translate
     */
    const translate = (problem: MixedRequestError) => {
        let code: ErrorCodes | undefined;
        if (!problem) setMessage(genericMessage ?? undefined);
        if ((problem as AxiosError)?.isAxiosError) {
            const axiosResponse: AxiosError<ProblemDetailsError, unknown> = problem as unknown as AxiosError<
                ProblemDetailsError,
                unknown
            >;
            code = axiosResponse?.response?.data?.code;
        } else {
            code = (problem as ProblemDetailsError)?.code;
        }

        let translation = genericMessage;
        if (code) {
            translation = codeTranslationMap[code];
        }
        setMessage(translation ?? genericMessage ?? undefined);
    };

    /**
     * Clears the translated error message, and sets it back to undefined
     */
    const clearTranslatedError = () => {
        setMessage(undefined);
    };

    return {translatedError, translate, clearTranslatedError};
};
