import {Typography} from '@mui/material';
import {DataGrid, GridColDef, GridOverlay, GridRenderCellParams} from '@mui/x-data-grid';
import {CordelOrderDTO} from 'lib/dtos/CordelOrderDTO';
import {nbNO} from 'lib/services/nbNO';
import React from 'react';
import {getNorwegianDateString} from 'toolbox';

type CordelOrdersTableProps = {
    cordelOrders: CordelOrderDTO[];
};

const columns: GridColDef[] = [
    {
        field: 'orderNumber',
        filterable: false,
        sortable: false,
        headerName: 'Ordrenummer',
        flex: 1,
        renderCell: (params: GridRenderCellParams<boolean>) => {
            return `#${params.value}`;
        },
    },
    {
        field: 'active',
        filterable: false,
        sortable: false,
        headerName: 'Aktiv',
        flex: 1,
        renderCell: (params: GridRenderCellParams<boolean>) => {
            return params.value ? 'Ja' : 'Nei';
        },
    },
    {
        field: 'orderDate',
        filterable: false,
        sortable: false,
        headerName: 'Opprettet',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Date>) => {
            return getNorwegianDateString(params.value);
        },
    },
];

export const CordelOrdersTable: React.FC<CordelOrdersTableProps> = ({cordelOrders}) => {
    return (
        <DataGrid
            hideFooter={true}
            localeText={nbNO}
            columns={columns}
            autoHeight
            rows={cordelOrders.map((c) => {
                return {...c, id: c.cordelOrderId};
            })}
            components={{
                NoRowsOverlay: () => (
                    <GridOverlay style={{backgroundColor: 'rgba(255,255,255,0.05)'}}>
                        <Typography>Ingen ordre på selskapet</Typography>
                    </GridOverlay>
                ),
            }}
        ></DataGrid>
    );
    // return <span>hei</span>;
};
