import React from 'react';

export const M2Logo: React.FC<{size: number | string; color?: string}> = (props) => (
    <svg id='M2_logo' width={props.size} height={props.size} fill={props.color} viewBox='0 0 80 80'>
        <g transform='translate(0.3 0.2)'>
            <g transform='translate(48.83)'>
                <path
                    d='M553.939,14.525c.4-5.635,3.055-8.552,8.228-8.552,5.441,0,7.631,3.716,7.631,7.3,0,4.777-2.721,6.635-7.166,9.622l-3.6,2.354v7.918a32.7,32.7,0,0,1,3.136-2.309l5.11-3.385c5.308-3.45,9.754-6.635,9.754-14.333,0-8.23-6.107-13.14-14.8-13.14-7.5,0-14.595,3.581-15.063,14.525Z'
                    transform='translate(-547.17)'
                />
                <path d='M680.26,460.192h18.4l-.066-5.972H680.26Z' transform='translate(-668.4 -413.743)' />
            </g>
            <g transform='translate(0 33.4)'>
                <path
                    d='M30.793,396.542l-5.578,13.089h-.2L9.688,374.13H0v45.653H6.968V385.61h.265L22.1,419.783h5.773L37.8,396.542Z'
                    transform='translate(0 -374.13)'
                />
                <rect width='7.1' height='23.241' transform='translate(42.8 22.412)' />
            </g>
        </g>
    </svg>
);
