import {Box, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useLanguage} from 'language/LanguageProvider';
import {CreatePhaseDto, UpdatePhaseDto} from 'lib/dtos/PhaseDto';
import {useState} from 'react';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../../../Buttons';
import {CustomDatePicker} from '../../../CustomDatePicker';
import {CustomDialog} from '../../../CustomDialog';
import {CustomInputField} from '../../../InputField';
import {Phase} from './OrderDetails';

export type SubmitType = 'new' | 'update';

export type SubmitBody =
    | (Omit<CreatePhaseDto, 'displayOrder'> & {
          submitType: 'new';
      })
    | (UpdatePhaseDto & {submitType: 'update'});

export type PhaseDialogProps = {
    open: boolean;
    initialValues?: Phase;
    onClose: () => void;
    onSubmit: (submitBody: SubmitBody) => void;
};

type FormErrors = {titleError: string | undefined; deadlineError: string | undefined};

export const PhaseDialog: React.VFC<PhaseDialogProps> = ({
    open,
    initialValues,
    onClose = () => {
        return;
    },
    onSubmit,
}) => {
    const {getLangString} = useLanguage();
    const [title, setTitle] = useState(initialValues?.title ?? '');
    const [description, setDescription] = useState(initialValues?.description ?? '');
    const [deadline, setDeadline] = useState<Date | null>(initialValues?.deadline ?? null);
    const [formErrors, setFormErrors] = useState<FormErrors>({
        titleError: undefined,
        deadlineError: undefined,
    });

    const handleSubmit = () => {
        setFormErrors({titleError: undefined, deadlineError: undefined});
        try {
            if (!title || !deadline) {
                throw new Error('missing fields');
            }
            if (initialValues) {
                const updateBody: UpdatePhaseDto & {submitType: 'update'} = {
                    title,
                    deadline,
                    description,
                    submitType: 'update',
                    phaseId: initialValues.phaseId,
                    completed: initialValues.completed,
                    displayOrder: initialValues.displayOrder,
                };
                onSubmit(updateBody);
            } else {
                onSubmit({title, deadline, description, submitType: 'new'});
            }
            clearState();
        } catch (err) {
            const titleError = title ? undefined : 'Tittel mangler';
            const deadlineError = deadline ? undefined : 'Frist mangler';
            setFormErrors({titleError, deadlineError});
        }
    };

    const handleClose = () => {
        clearState();
        onClose();
    };

    const clearState = () => {
        setTitle('');
        setDeadline(null);
        setDescription('');
    };

    return (
        <CustomDialog open={open} onClose={handleClose}>
            <DialogTitle>{initialValues ? getLangString('EDIT_PHASE') : getLangString('ADD_PHASE')}</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={0} mb={4}>
                    <Grid item xs={8} sm={4}>
                        <CustomInputField
                            fullWidth
                            onChange={(e) => {
                                const titleError = e.target.value ? undefined : 'Tittle mangler';
                                setFormErrors((prev) => ({...prev, titleError}));
                                setTitle(e.target.value);
                            }}
                            value={title}
                            required
                            label='Tittel'
                            sx={{mt: 2}}
                            error={!!formErrors.titleError}
                            helperText={formErrors.titleError}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <CustomDatePicker
                            label={getLangString('DEADLINE')}
                            value={deadline}
                            handleChange={(date: Date | null) => {
                                const deadlineError = date ? undefined : 'Frist mangler';
                                setFormErrors((prev) => ({...prev, deadlineError}));
                                setDeadline(date);
                            }}
                            handleDateSelect={() => ({})}
                            handleTextBlur={() => ({})}
                            error={!!formErrors.deadlineError}
                            required
                            helperText={formErrors.deadlineError}
                        />
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <CustomInputField
                            multiline
                            fullWidth
                            rows={6}
                            label='Beskrivelse'
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                    </Grid>
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <ButtonSecondaryMedium onClick={handleClose} sx={{minWidth: 108, mr: 1}}>
                        {getLangString('CANCEL')}
                    </ButtonSecondaryMedium>
                    <ButtonPrimaryMedium onClick={handleSubmit} sx={{minWidth: 108}}>
                        {initialValues ? getLangString('SAVE') : getLangString('ADD_PHASE')}
                    </ButtonPrimaryMedium>
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};
