import {Box, IconButton, Theme, useMediaQuery} from '@mui/material';
import {SxProps} from '@mui/system';
import React, {SyntheticEvent, useState} from 'react';
import Swiper, {Mousewheel, SwiperOptions} from 'swiper';
import {Swiper as SwiperWrapper, SwiperSlide} from 'swiper/react/swiper-react';
import {ArrowBackward} from './icons/ArrowBackward';
import {ArrowForward} from './icons/ArrowForward';

export const ItemSlider: React.FC<{
    items: React.ReactNode[];
    handleSwipe?: (instance: Swiper) => void;
    slidesPerViewOverride?: {[p: number]: SwiperOptions};
}> = (props) => {
    return (
        <SwiperWrapper
            modules={[Mousewheel]}
            onSwiper={props.handleSwipe}
            spaceBetween={24}
            slidesPerView={2.6}
            mousewheel={{forceToAxis: true}}
            breakpoints={
                props.slidesPerViewOverride || {
                    '0': {slidesPerView: 1.3, spaceBetween: 12},
                    '900': {slidesPerView: 2.4},
                    '1600': {slidesPerView: 2.7},
                }
            }
            style={{overflow: 'visible'}}
        >
            {props.items.map((item, i) => {
                return <SwiperSlide key={i}>{item}</SwiperSlide>;
            })}
        </SwiperWrapper>
    );
};

export const ItemSliderWithControls: React.FC<{
    items: React.ReactNode[];
    headerTextElement?: React.ReactNode;
    iconSize?: number;
    headerStyleOverrides?: SxProps<Theme>;
    slidesPerViewOverride?: {[p: number]: SwiperOptions};
    maxWidth?: number;
    // layoutConfig?: {gridColumnHeader?: string; headerVaiant?: string; arrowButtonSize?: number};
}> = (props) => {
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const goPrev = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const goNext = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (swiper) {
            swiper.slideNext();
        }
    };
    const updateSlides = (instance: Swiper) => {
        setSwiper(instance);
        instance.on('slideChange', () => {
            setIsFirstSlide(instance.isBeginning);
            setIsLastSlide(instance.isEnd);
        });
    };
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    ...props.headerStyleOverrides,
                }}
            >
                {props.headerTextElement || <Box />}
                <Box>
                    <IconButton onClick={goPrev} disabled={isFirstSlide} sx={{opacity: isFirstSlide ? 0.4 : 1}}>
                        <ArrowBackward size={props.iconSize} />
                    </IconButton>
                    <IconButton onClick={goNext} disabled={isLastSlide} sx={{opacity: isLastSlide ? 0.4 : 1}}>
                        <ArrowForward size={props.iconSize} />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    // CSS Grid/Flexbox bug size workaround for Firefox
                    // @see https://github.com/kenwheeler/slick/issues/982
                    maxWidth: props.maxWidth || '100vw',
                    maxHeight: '100vh',
                    minHeight: 0,
                    minWidth: 0,
                }}
            >
                <ItemSlider
                    items={props.items}
                    handleSwipe={updateSlides}
                    slidesPerViewOverride={props.slidesPerViewOverride}
                />
            </Box>
        </Box>
    );
};

export const PhotoItem: React.FC<{src: string}> = ({src}) => {
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return <img src={src} alt={''} width={'100%'} height={isSmallScreen ? 400 : 600} style={{objectFit: 'cover'}} />;
};
