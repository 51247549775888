import {ConsultationType, ContactInformation, ServiceAssignment, Specification} from '../types/Inquiry';
import {PaginationDto} from './Pagination';
import {ProjectBaseDto} from './Project';
import {UserDto} from './User';

export interface CordelPostalAddress {
    street: string;
    street2: string;
    street3: string;
    postalCode: string;
    postalPlace: string;
}
export interface CordelCustomerEntity {
    firstName?: string;
    surName?: string;
    telephone?: string;
    mobile?: string;
    email?: string;
    creditStatusCode?: string;
    terminated: boolean;
    customerNumber?: number;
    postalAddress: CordelPostalAddress;
    lastUpdated: string;
}
enum InquiryCategoryType {
    Consultation,
    Offer,
    Complaint,
}
enum InquiryStatus {
    Waiting,
    Started,
    OfferCreated,
    ProjectCreate,
    Postponed,
    Canceled,
}
export interface CordelOfficer {
    initials: string;
    name: string;
    telephone: string;
    email: string;
    inquiryOfficer: boolean;
    orderOfficer: boolean;
}
export interface CordelConfigurableField {
    fieldType: number;
    text: string;
}
export interface CordelInquiryEntity {
    cordelCompanyId: number;
    cordelInquiryId: number;
    inquiryNumber: number;
    customer: CordelCustomerEntity;
    description?: string;
    deadLine: string;
    note?: string;
    inquiryCategoryId: InquiryCategoryType;
    status: InquiryStatus;
    configurableFields: CordelConfigurableField[];
    officer: CordelOfficer;
    created: string;
    lastUpdated: string;
}

// Describes an inquiry to be made
export interface CreateInquiryDto {
    service: ServiceAssignment;
    timeSchedule: {
        // Call / inspection...
        consultationType: ConsultationType;
        // // Start date when to expect call/inspection
        fromDate?: Date;
        // // End date when to expect call/inspection
        toDate?: Date;
    };
    specification?: Specification;
    contactInformation: ContactInformation;
    consent: boolean;
}

export enum InquiryState {
    New,
    Started, // There is a cordel inquiry
    Closed, // An offer is created
    Canceled, // An offer is canceled by M2
}
// Describes an actual inquiry
export interface InquiryDto {
    inquiryId: number;
    project: ProjectBaseDto;
    customer: UserDto;
    service: number;
    craftsman: number | undefined;
    task: number | undefined;
    status: InquiryState;
    description: string;
    images: string[];
    cordelInquiries: CordelInquiryEntity[];
    consultationFrom: string;
    consultationTo: string;
    consultationType: string;
    createdAt: string;
    updatedAt: string;
}
export interface InquirySimpleDto {
    inquiryId: number;
    service: number;
    craftsman: number;
    task: number;
    status: InquiryState;
    project: ProjectBaseDto;
    consultationFrom: string;
    consultationTo: string;
    createdAt: string;
    updatedAt: string;
}
export interface InquiriesDto {
    data: InquirySimpleDto[];
    pagination: PaginationDto;
}

export interface CreateCordelInquiryDto {
    cordelCompanyId: number;
}

export interface InquiryNoRelationDto {
    inquiryId: number;
    service: number;
    craftsman: number;
    task: number;
    status: InquiryState;
    consultationFrom: string;
    consultationTo: string;
    createdAt: string;
    updatedAt: string;
}
