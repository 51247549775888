import {Container} from '@mui/material';
import React, {useContext} from 'react';
import {AppContext} from '../../store/appContext';
import {ProjectPageMenu} from '../AppLayout';
import {CssGrid, DefaultGridPageLayout} from '../Layout';

export const ProjectPaymentPage: React.FC = () => {
    const {bgColor} = useContext(AppContext);
    return (
        <Container sx={{backgroundColor: bgColor}}>
            <CssGrid>
                <ProjectPageMenu />
                <DefaultGridPageLayout>Her kommer betaling</DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
