import {FileDto} from 'lib/dtos/File';
import {useM2Axios} from 'lib/hooks/useM2Axios';

const baseUrl = process.env.REACT_APP_API_URL;

export type SecureFileDownloadProps = {
    fileDescriptor: FileDto;
};

/**
 * Hook for downloading files from M2 API which requires Authorization.
 * When the file is downloaded, it will create a download event which triggers the browsers save.
 * When it is downloading, it is not possible to retrigger it, and will silently return without any action.
 */
export const useApiFileDownload = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, getFile] = useM2Axios<ArrayBuffer>({}, {manual: true});

    /**
     * Downloads the files given in url of the descriptor.
     * Returns true if successful, else false.
     */
    async function download({fileDescriptor}: SecureFileDownloadProps) {
        try {
            const a = document.createElement('a');
            // Only use this if it is going through our API
            if (fileDescriptor.url.startsWith(baseUrl)) {
                const file = await getFile({
                    url: fileDescriptor.url,
                    responseType: 'arraybuffer',
                });
                const blob = new Blob([file.request.response]);
                a.href = URL.createObjectURL(blob);
            } else {
                a.href = fileDescriptor.url;
            }
            a.download = fileDescriptor.fileName;
            a.click();
            a.remove();
            return true;
        } catch (error) {
            console.error('Error downloading file: ' + fileDescriptor.url);
        }
        return false;
    }

    return {download};
};
