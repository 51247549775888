import React, {createContext, FC, useState} from 'react';

type UrlContextValue = {
    url: string;
    setUrl: (url: string) => void;
};
const UrlContext = createContext<UrlContextValue | undefined>(undefined);

export const UrlProvider: FC = ({children}) => {
    const [url, setUrl] = useState('');
    const value = {url, setUrl};
    return <UrlContext.Provider value={value}>{children}</UrlContext.Provider>;
};

export const useUrl = () => {
    const context = React.useContext(UrlContext);
    return context;
};
