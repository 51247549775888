import {Tab, Tabs} from '@mui/material';
import {Box} from '@mui/system';
import {AxiosPromise} from 'axios';
import {BackButton} from 'components/BackButton';
import {LoaderCenterBlur} from 'components/Loader';
import {FileDto} from 'lib/dtos/File';
import {InquiryDto} from 'lib/dtos/Inquiry';
import {OrderDto} from 'lib/dtos/Order';
import {StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {M2Response} from 'lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from 'lib/remote/m2api/M2Endpoints';
import React, {useContext, useEffect, useState, VFC} from 'react';
import {useParams} from 'react-router-dom';
import {AdminAppContext} from 'store/adminAppContext';
import {AdminPageLayoutWithSimpleMenu} from '../AdminPageLayout';
import {Header} from '../Header';
import {CordelOrdersManager as CreateCordelOrder} from './CordelOrder';
import {OrderDetails} from './OrderDetails';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`order-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `order-tab-${index}`,
        'aria-controls': `order-tabpanel-${index}`,
    };
}
export const OrderDetailsPage: VFC = () => {
    const [{order, inquire, loading, statuses, inspectionImages}, refetch] = useGetOrderAndInquire();
    const {state} = useContext(AdminAppContext);
    const [shouldCreateCordelOrders, setShouldCreateCordelOrders] = useState(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (order && order.data) {
            if (!order.data.cordelOrders.length) {
                setShouldCreateCordelOrders(true);
                setValue(1);
            }
        }
    }, [order]);
    return (
        <AdminPageLayoutWithSimpleMenu>
            <LoaderCenterBlur open={loading} />
            {order && (
                <>
                    <Box mt={2}>
                        <BackButton />
                    </Box>
                    <Header
                        id={order.data.project.projectId}
                        project={order.data.project}
                        services={state.availableServices}
                    />
                </>
            )}

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label='order panel'
                sx={{
                    button: {
                        alignItems: 'flex-start !important',
                    },
                }}
            >
                <Tab
                    label='Ordre'
                    {...a11yProps(1)}
                    sx={{padding: '.75rem 0 .75rem 0', minWidth: 'fit-content', marginRight: '.75rem'}}
                />
                <Tab
                    label='Cordel ordre'
                    {...a11yProps(2)}
                    sx={{padding: '.75rem 0 .75rem 0', minWidth: 'fit-content'}}
                />
            </Tabs>

            {order && (
                <>
                    <TabPanel value={value} index={0}>
                        {inquire && inspectionImages && statuses && (
                            <>
                                <OrderDetails
                                    order={order.data}
                                    statusUpdates={statuses.data}
                                    refresh={refetch}
                                    inquire={inquire.data}
                                    inspectionImages={inspectionImages.data}
                                    services={state.availableServices}
                                />
                            </>
                        )}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <CreateCordelOrder order={order.data} update={refetch} />
                    </TabPanel>
                </>
            )}
        </AdminPageLayoutWithSimpleMenu>
    );
};

const useGetOrderAndInquire = (): [GetOrderAndInquireResponse, RefetchFunction] => {
    const params = useParams<{orderID: string}>();
    const [loading, setLoading] = useState(true);

    const [{data: order}, refetch] = useM2Axios<M2Response<OrderDto>>(
        ApiEndpoints.order.byId(parseInt(params.orderID)),
    );

    const [{data: statuses}, getStatuses] = useM2Axios<M2Response<StatusUpdateDto[]>>('', {manual: true});
    const [{data: inspectionImages}, getInspectionImages] = useM2Axios<M2Response<FileDto[]>>('', {manual: true});
    const [{data: inquire, loading: loadingInquire}, getInquire] = useM2Axios<M2Response<InquiryDto>>('', {
        manual: true,
    });

    useEffect(() => {
        if (order) {
            const projectId = order.data.project.projectId;
            getStatuses({
                url: ApiEndpoints.project.statuses(projectId),
                params: {includeAnswered: 'true', includeRead: 'true'},
            });
            getInspectionImages({
                url: `${ApiEndpoints.project.inspectionImages(projectId)}`,
            });
            getInquire({url: ApiEndpoints.project.inquiries(projectId)});
        }
    }, [getInquire, getInspectionImages, getStatuses, order]);

    useEffect(() => {
        if (inquire && !loadingInquire) {
            setLoading(false);
        }
    }, [inquire, loadingInquire]);

    return [{order, inquire, loading, statuses, inspectionImages}, refetch];
};

type GetOrderAndInquireResponse = {
    order: M2Response<OrderDto> | undefined;
    inquire: M2Response<InquiryDto> | undefined;
    statuses: M2Response<StatusUpdateDto[]> | undefined;
    inspectionImages: M2Response<FileDto[]> | undefined;
    loading: boolean;
};
type RefetchFunction = () => AxiosPromise;
