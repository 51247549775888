import React from 'react';
import {IconProps} from './IconProps';

export const CloseIcon: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || '32'} height={size || '32'} viewBox='0 0 25.456 25.456'>
        <g fill='none' stroke={color || '#121212'} strokeWidth='2'>
            <line x1='34' transform='translate(0.707 0.707) rotate(45)' />
            <line x1='34' transform='translate(0.707 24.749) rotate(-45)' />
        </g>
    </svg>
);
