import {FileDto} from './File';
import {PaginationDto} from './Pagination';
import {ProjectBaseDto} from './Project';

export enum PaymentInterval {
    None,
    Phase,
    Complete,
}
export enum OfferDecisionStatus {
    Open,
    Accepted,
    Rejected,
    Waiting,
    Canceled,
}
export type CordelCompanyOfferDto = {
    cordelCompanyOfferId: number;
    companyId: number;
    hasUploadedOffer: boolean;
    files: FileDto[];
    uploadedAt: string;
    createdAt: string;
    updatedAt: string;
};

export type OfferDto = {
    offerId: number;
    inquiryId: number;
    project: ProjectBaseDto;
    description: string | undefined;
    workhourCost: number | undefined;
    materialCost: number | undefined;
    totalMvaCost: number | undefined;
    paymentInterval: PaymentInterval | undefined;
    offerDecisionStatus: OfferDecisionStatus;
    offerDecisionReason: string | undefined;
    decisionMadeAt: string | undefined;
    cordelCompanyOffers: CordelCompanyOfferDto[];
    createdAt: string;
    updatedAt: string;
};

export type OfferSimpleDto = {
    offerId: number;
    inquiryId: number;
    project: ProjectBaseDto;
    workhourCost: number;
    materialCost: number;
    offerDecisionStatus: OfferDecisionStatus;
    decisionMadeAt: string;
    createdAt: string;
    updatedAt: string;
};

export type OffersDto = {
    data: OfferSimpleDto[];
    pagination: PaginationDto;
};

export type OfferDecisionDto = {
    decision: OfferDecisionStatus;
    paymentInterval: PaymentInterval;
    decisionReason?: string;
};

export type CreateCompanyOfferDto = {
    offerId: number;
    companyId: number;
};

export type CreateOfferDto = {
    projectId: number;
    inquiryId: number;
};
