import {AccountInfo, AuthError, SilentRequest} from '@azure/msal-browser';
import axios, {AxiosInstance} from 'axios';
import {loginRequest} from 'config/b2cConfig';
import {msalInstance} from 'index';
import {dateMapper} from 'lib/dateMapper';
import {PaginationDto} from 'lib/dtos/Pagination';
import {ProblemDetailsError} from '../ProblemDetails';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance: AxiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use(
    async (request) => {
        const token = await getToken();
        if (!request.headers) {
            request.headers = {};
        }
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        console.error(error);
        throw error;
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        response.data = dateMapper(response.data);
        return response;
    },
    (error) => {
        console.error(error);
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
                console.warn('unauthorized, request login...');
                msalInstance.acquireTokenRedirect({...loginRequest});
            }

            const url = error.config.url;
            const details = error.response?.data;
            throw new ProblemDetailsError({message: `Error while fetching ${url}`, details});
        }
        throw error;
    },
);

export {axiosInstance};

export type M2Response<T = unknown> = {data: T; [key: string]: unknown; pagination: PaginationDto};

export const getToken = async () => {
    const account = msalInstance.getActiveAccount();
    if (account) {
        const accessToken = tryAcquireToken(account);
        if (!accessToken) throw new Error('no access token');
        return accessToken;
    }
};

const tryAcquireToken = async (account: AccountInfo) => {
    const acquireOptions: SilentRequest = {...loginRequest, account};

    try {
        const {accessToken} = await msalInstance.acquireTokenSilent(acquireOptions);
        return accessToken;
    } catch (err) {
        if (err instanceof AuthError && err.message.includes('AADB2C90077')) {
            await msalInstance.acquireTokenRedirect(acquireOptions);
        }
    }
};
