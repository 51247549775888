import {Autocomplete, TextField} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {EmployeeBaseDto, EmployeeDto} from '../../../lib/dtos/User';

type OptionType = {label: string; id: number};
export const EmployeeSelect: React.FC<{
    employees: EmployeeDto[];
    onSelect: (id: number) => void;
    currentEmployee?: EmployeeBaseDto;
}> = ({employees, onSelect, currentEmployee}) => {
    const currentContact: OptionType | undefined = currentEmployee
        ? {
              label: `${currentEmployee.firstName} ${currentEmployee.lastName}`,
              id: currentEmployee.employeeId,
          }
        : undefined;
    const [value, setValue] = useState<OptionType | undefined>(currentContact);
    const getEmployeeOptions = (): OptionType[] => {
        return employees.map((employee) => ({
            label: `${employee.firstName} ${employee.lastName}`,
            id: employee.employeeId,
        }));
    };
    const handleChange = (event: React.SyntheticEvent, newValue: OptionType | undefined) => {
        setValue(newValue);
    };
    const handleSelection = () => {
        if (value) {
            onSelect(value.id);
        }
    };

    return (
        <Autocomplete
            disablePortal
            id='employee-select'
            value={value}
            onChange={handleChange}
            onBlur={handleSelection}
            options={getEmployeeOptions()}
            disableClearable={true}
            disableCloseOnSelect={true}
            renderInput={(params) => <TextField {...params} placeholder='Velg prosjektleder' />}
        />
    );
};
