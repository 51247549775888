import {Box, Typography} from '@mui/material';
import {useLanguage} from 'language/LanguageProvider';
import {PhaseDto} from 'lib/dtos/PhaseDto';
import {VFC} from 'react';
import {getStatus} from './getStatus';
import {PhaseAccordion, PhaseEvents} from './PhaseAccordion';

type PhasesProps = {
    phases: PhaseDto[];
} & PhaseEvents;

export const Phases: VFC<PhasesProps> = ({phases, ...rest}) => {
    const sortedPhases = [...phases].sort((a, b) => a.displayOrder - b.displayOrder);
    const {getLangString} = useLanguage();
    return (
        <Box sx={{mt: 10}}>
            <Typography variant='h6' component={'h2'}>
                {getLangString('PHASES')}
            </Typography>

            {phases.length ? (
                sortedPhases.map((phase, index, phases) => (
                    <PhaseAccordion
                        key={phase.phaseId}
                        phase={{...phase, status: getStatus(phases, index)}}
                        {...rest}
                    />
                ))
            ) : (
                <Typography>{getLangString('NO_PHASES_ADDED')}</Typography>
            )}
        </Box>
    );
};
