import {Grid, Typography} from '@mui/material';
import {Photos, ProjectDescription, ProjectDescriptionProps} from 'components/loggedInPages/admin/ProjectDescription';
import {FileDto} from 'lib/dtos/File';
import {VFC} from 'react';
import {useLanguage} from '../../../../language/LanguageProvider';
import {InfoField} from '../../../InfoField';

export const CustomerInformation: VFC<Pick<ProjectDescriptionProps, 'descriptionText' | 'images' | 'serviceTaskText'>> =
    (props) => {
        const {getLangString} = useLanguage();
        return (
            <ProjectDescription
                {...props}
                descriptionHeader={getLangString('INFO_TEXT_CUSTOMER')}
                imageHeader={getLangString('ATTACHED_PHOTOS')}
            />
        );
    };
export const InspectionInformation: VFC<{images: FileDto[]}> = ({images}) => {
    const {getLangString} = useLanguage();
    return (
        <Photos
            secure={true}
            images={images.map((image) => image.url)}
            imageHeader={getLangString('IMAGES_FROM_INSPECTION')}
        />
    );
};
export const CustomerAnswer: VFC<Pick<ProjectDescriptionProps, 'descriptionText' | 'images'>> = (props) => {
    const {getLangString} = useLanguage();
    return (
        <Grid container>
            <Grid item xs={8} sm={4}>
                <Typography variant={'subtitle1'} mb={2}>
                    {getLangString('CUSTOMER_RESPONSE')}
                </Typography>
                <InfoField style={{background: '#AE91754D'}}>
                    <Typography>{props.descriptionText}</Typography>
                </InfoField>
            </Grid>
            <Grid item xs={8} sm={4} sx={{mt: {xs: 4, sm: 0}}}>
                <Typography variant={'subtitle1'} mb={2}>
                    {getLangString('ATTACHED_PHOTOS')}
                </Typography>
                <Photos images={props.images} />
            </Grid>
        </Grid>
    );
};
