import {useMsal} from '@azure/msal-react';
import {Container} from '@mui/material';
import React, {useContext} from 'react';
import {loginRequest} from '../../config/b2cConfig';
import {AppContext} from '../../store/appContext';

export const SignInPage: React.FC = () => {
    const {bgColor} = useContext(AppContext);
    const {instance, inProgress} = useMsal();

    React.useEffect(() => {
        const redirectToLogin = () => {
            instance.loginRedirect(loginRequest);
        };
        inProgress === 'none' && redirectToLogin();
    }, [instance]);
    return <Container sx={{backgroundColor: bgColor}}>loading...</Container>;
};
