import {useLanguage} from 'language/LanguageProvider';
import {InquiryState} from 'lib/dtos/Inquiry';
import {FC, useContext} from 'react';
import {AdminAppContext} from 'store/adminAppContext';
import {AdminPageLayout} from '../AdminPageLayout';
import {InquiresTable} from './InquiresTable';

export const InquiriesPage: FC = () => {
    const {state} = useContext(AdminAppContext);
    const {getLangString} = useLanguage();

    return (
        <AdminPageLayout>
            <InquiresTable
                title={getLangString('NEW_INQUIRIES')}
                requestParams={{
                    status: InquiryState.New,
                }}
                services={state.availableServices}
            />

            {state.user ? (
                <InquiresTable
                    title={getLangString('MY_INQUIRIES')}
                    requestParams={{
                        employeeId: state.user?.employeeId,
                    }}
                    services={state.availableServices}
                />
            ) : (
                <>Missing employee id</>
            )}

            <InquiresTable title={getLangString('ALL_INQUIRIES')} services={state.availableServices} />
        </AdminPageLayout>
    );
};
