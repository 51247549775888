import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    text: string;
    fontSize: string;
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const BodyText: React.FC<Props> = (props) => {
    const theme = useTheme();

    let fontSize = {
        fontSize: '1rem',
        lineHeight: '1.5',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
            lineHeight: '1.5',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.125rem',
            lineHeight: '1.5',
        },
    };

    if (props.fontSize === 'large') {
        fontSize = {
            fontSize: '1.125rem',
            lineHeight: '1.44',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.44',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.3125rem',
                lineHeight: '1.57',
            },
        };
    }

    if (props.fontSize === 'extraLarge') {
        fontSize = {
            fontSize: '1.125rem',
            lineHeight: '1.44',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.375rem',
                lineHeight: '1.42',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.625rem',
                lineHeight: '1.42',
            },
        };
    }

    return (
        <ContentBlock quickLink={props.quickLink} size='sm'>
            <Container>
                <CssGrid>
                    <Box
                        sx={{
                            gridColumn: 'span 8',
                            [theme.breakpoints.up('md')]: {
                                gridColumn: '3 / span 5',
                            },
                        }}
                    >
                        <Typography
                            component='div'
                            sx={{
                                '& h2, & h3, & h4': {
                                    fontFamily: 'SuisseIntl-Medium',
                                    fontWeight: 'normal',
                                },
                                '& > *': {
                                    ...fontSize,
                                },
                                '& > :first-child': {
                                    mt: 0,
                                },
                                '& > :last-child': {
                                    mb: 0,
                                },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: props.text,
                            }}
                        ></Typography>
                    </Box>
                </CssGrid>
            </Container>
        </ContentBlock>
    );
};
