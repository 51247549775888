import {UserDto} from '../dtos/User';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {ApiEndpoints} from '../remote/m2api/M2Endpoints';

export class UserService {
    private apiContentPath = ApiEndpoints.user;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    public async getUserDetails(): Promise<{data: UserDto}> {
        return await this._httpClient.get<{data: UserDto}>(`${this.apiContentPath.base()}/me`);
    }
}
