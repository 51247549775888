import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const BurgerIcon: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 32 32'>
        <g fill='none' stroke={color || colors.black} strokeWidth='3'>
            <line x1='32' transform='translate(0 11)' />
            <line x1='32' transform='translate(0 21)' />
        </g>
    </svg>
);
