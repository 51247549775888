import {Button, ButtonProps} from '@mui/material';
import React from 'react';

export const ButtonPrimaryLarge: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'large'} color={'primary'}>
        {props.children}
    </Button>
);
export const ButtonSecondaryLarge: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'large'} color={'secondary'}>
        {props.children}
    </Button>
);
export const ButtonTertiaryLarge: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'large'} color={'tertiary'}>
        {props.children}
    </Button>
);

export const ButtonPrimaryMedium: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'medium'} color={'primary'}>
        {props.children}
    </Button>
);
export const ButtonSecondaryMedium: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'medium'} color={'secondary'}>
        {props.children}
    </Button>
);
export const ButtonTertiaryMedium: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'medium'} color={'tertiary'}>
        {props.children}
    </Button>
);
export const ButtonPrimaryTextMedium: React.FC<ButtonProps> = (props) => (
    <Button variant={'text'} size={'medium'} color={'primary'} {...props}>
        {props.children}
    </Button>
);
export const ButtonPrimarySmall: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'small'} color={'primary'}>
        {props.children}
    </Button>
);
export const ButtonSecondarySmall: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'contained'} size={'small'} color={'secondary'}>
        {props.children}
    </Button>
);

export const ButtonUnderlined: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'underlined'} size={'large'}>
        {props.children}
    </Button>
);

export const ButtonSquareBordered: React.FC<ButtonProps> = (props) => (
    <Button {...props} variant={'outlined'} size={'medium'} color={'quaternary'}>
        {props.children}
    </Button>
);
