import {Box, Typography} from '@mui/material';
import {FC} from 'react';
import {NotificationIcon} from './icons/Notification';

export const StatusElement: FC<{text: string; iconColor: string; bgColor?: string}> = ({iconColor, text, bgColor}) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box>
                <NotificationIcon color={iconColor} bgColor={bgColor} />
            </Box>
            <Typography sx={{pl: 1.75, whiteSpace: 'normal'}}>{text}</Typography>
        </Box>
    );
};
