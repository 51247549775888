import React from 'react';
import {colors} from '../../theme';
import {IconProps} from './IconProps';

export const DownloadIcon: React.FC<IconProps> = ({size, color}) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size || 24} height={size || 24} viewBox='0 0 24 24'>
        <g transform='translate(4 0.093)' stroke={color || colors.black} strokeWidth='1'>
            <g id='arrow'>
                <path d='M649.678,362.633l-8,7.685-8-7.685' transform='translate(-633.678 -350.923)' fill='none' />
                <line y1='18.705' transform='translate(8 0.691)' fill='none' />
            </g>
            <line x2='16' transform='translate(0 23.123)' fill='none' />
        </g>
    </svg>
);
