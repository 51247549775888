/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {LogLevel} from '@azure/msal-browser';

export const b2cPolicies = {
    authorities: {
        signIn: {
            authority: process.env.REACT_APP_B2C_SIGN_IN_AUTHORITY!,
        },
        forgotPassword: {
            scopes: [''],
            authority: process.env.REACT_APP_B2C_FORGOT_PASSWORD_AUTHORITY!,
        },
    },
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN!,
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID!,
        authority: b2cPolicies.authorities.signIn.authority!,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin + '/minSide',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: unknown) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const protectedResources = {
    backend: {
        scopes: [process.env.REACT_APP_B2C_API_TOKEN_SCOPES!],
    },
};

export const loginRequest = {
    scopes: [
        // This will be added when we add backend api scope
        ...protectedResources.backend.scopes,
    ],
};
