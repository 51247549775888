import {Box, Container, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {useLanguage} from '../../language/LanguageProvider';
import {Service, ServiceDictionary} from '../../lib/types/Inquiry';
import {saveSelectedServiceTasks} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {ButtonPrimaryLarge} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {ButtonLayout, SelectServiceCheckboxOptions, Specification} from './InquiryDetailComponents';
import {getInquirySummaryPath, getInquiryTimeSelectionPath, useRedirectIfNoActiveInquiry} from './InquiryPageRoutes';

export const InquirySelectServiceTaskPage: React.FC = () => {
    //Redirect to initial inquiry page if there is no active inquiry
    useRedirectIfNoActiveInquiry();

    const {state, dispatch} = useContext(AppContext);
    const {getLangString} = useLanguage();
    const [selectedTasks, setSelectedTasks] = React.useState<Service[]>(state.inquiry.selectedServiceTasks || []);
    const handleChange = (services: Service[]) => {
        setSelectedTasks([...services]);
    };

    const availableServiceTasks: ServiceDictionary | undefined = state.inquiry.selectedService
        ? state.inquiry.selectedService.children
        : undefined;
    const history = useHistory();
    const navigateToNextPage = () => {
        selectedTasks.length && dispatch(saveSelectedServiceTasks(selectedTasks));
        state.inquiry.isEditing ? history.push(getInquirySummaryPath()) : history.push(getInquiryTimeSelectionPath());
    };

    return (
        <Container sx={{pt: 4, pb: 7}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    <Typography variant={'subtitle1'}>{getLangString('CRAFTSMAN_TASK_HEADER')}</Typography>
                    <SelectServiceCheckboxOptions
                        handleChange={handleChange}
                        availableOptions={availableServiceTasks}
                        selectedOptions={selectedTasks}
                    />
                    <Box marginTop={7}>
                        <Specification />
                    </Box>
                    <ButtonLayout>
                        <ButtonPrimaryLarge onClick={navigateToNextPage} disabled={!selectedTasks.length}>
                            {getLangString('NEXT')}
                        </ButtonPrimaryLarge>
                    </ButtonLayout>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};
