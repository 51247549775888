import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {CustomAccordion} from '../Accordion';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';

type Props = {
    id: number;
    heading: string;
    text: string;
    questions: {
        id: string;
        title: string;
        answer: string;
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const Questions: React.FC<Props> = (props) => {
    const theme = useTheme();

    return (
        <ContentBlock quickLink={props.quickLink} size='lg' className='remove-margin-if-last'>
            <Box
                sx={{
                    backgroundColor: 'customBackground.darker',
                    py: 6,
                    [theme.breakpoints.up('md')]: {
                        py: 9,
                    },
                    [theme.breakpoints.up('lg')]: {
                        py: 12,
                    },
                }}
            >
                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '2 / span 5',
                                },
                            }}
                        >
                            <Typography variant='sectionHeading'>{props.heading}</Typography>
                            <Box
                                sx={{
                                    mt: 3,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 3.5,
                                    },
                                }}
                            >
                                <Typography variant='sectionIntro' dangerouslySetInnerHTML={{__html: props.text}} />
                            </Box>
                        </Box>
                    </CssGrid>

                    <CssGrid>
                        <Box
                            sx={{
                                mt: 5,
                                gridColumn: 'span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '2 / span 6',
                                    mt: 8,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            {props.questions.map((question) => {
                                return (
                                    <Box key={question.id}>
                                        <CustomAccordion
                                            id={`questions-${props.id}`}
                                            ariaControls=''
                                            headerElements={
                                                <Box sx={{width: '100%', fontSize: '1rem'}}>{question.title}</Box>
                                            }
                                            detailsElements={
                                                <Box
                                                    sx={{
                                                        fontSize: '1rem',
                                                        '& > :first-child': {mt: 0},
                                                        '& > :last-child': {mb: 0},
                                                    }}
                                                    dangerouslySetInnerHTML={{__html: question.answer}}
                                                ></Box>
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </Box>

                        <Box
                            sx={{
                                display: 'none',
                                [theme.breakpoints.up('lg')]: {
                                    mt: 8,
                                    gridColumn: '2 / span 6',
                                    display: 'block',
                                },
                            }}
                        >
                            {props.questions.map((question) => {
                                return (
                                    <Box
                                        key={question.id}
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(3, 1fr)',
                                            columnGap: 3,
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'text.primary',
                                            pb: 5,
                                            mb: 5,
                                        }}
                                    >
                                        <Box sx={{gridColumn: '1 / span 1', gridRow: '1'}}>{question.title}</Box>
                                        <Typography
                                            sx={{
                                                gridColumn: '2 / span 2',
                                                gridRow: '1',
                                                '& > :first-child': {
                                                    mt: 0,
                                                },
                                                '& > :last-child': {
                                                    mb: 0,
                                                },
                                                [theme.breakpoints.up('md')]: {
                                                    fontSize: '1.0625rem',
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{__html: question.answer}}
                                        ></Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    </CssGrid>
                </Container>
            </Box>
        </ContentBlock>
    );
};
