import {EditCustomerDto} from '../dtos/Customer';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {ApiEndpoints} from '../remote/m2api/M2Endpoints';

export class CustomerService {
    private apiContentPath = ApiEndpoints.customer;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    public async putCustomerInfo(id: number, data: EditCustomerDto): Promise<EditCustomerDto> {
        return await this._httpClient.put<EditCustomerDto>(`${this.apiContentPath.byId(id)}`, data);
    }
}
