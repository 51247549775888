import {Container, Grid, RadioGroup, Typography} from '@mui/material';
import React, {ChangeEvent, useContext} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useInitializeUser} from '../../initUser';
import {useLanguage} from '../../language/LanguageProvider';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {ProblemDetails} from '../../lib/remote/ProblemDetails';
import {UserService} from '../../lib/services/UserService';
import {UserInfo} from '../../lib/types/User';
import {loadUserInfo, saveInquiryContactInfo} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {AddressFields, AddressFormFields} from '../AddressForm';
import {ButtonPrimaryLarge} from '../Buttons';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {LoaderCentered} from '../Loader';
import {RadioButton} from '../RadioButton';
import {ButtonLayout} from './InquiryDetailComponents';
import {getInquirySummaryPath} from './InquiryPageRoutes';

const Content: React.FC<{user: UserInfo}> = ({user}) => {
    const {dispatch} = useContext(AppContext);
    const history = useHistory();
    const {getLangString} = useLanguage();
    const initialAddress = user.addresses[Object.keys(user.addresses)[0]];
    const {handleSubmit, formState, trigger, control} = useForm<AddressFields>();
    const [selectedValue, setSelectedValue] = React.useState<number>(
        initialAddress ? initialAddress.customerAddressId : -1,
    );
    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(parseInt(event.target.value));
    };

    const handleNextClick = (form?: AddressFields) => {
        const contactInfo = {
            personDetails: {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                email: user.email,
            },
            address:
                selectedValue === -1 && form
                    ? {street: form.address, zip: form.postalCode, location: form.city}
                    : {...user.addresses[selectedValue]},
        };
        dispatch(saveInquiryContactInfo(contactInfo));
        history.push(getInquirySummaryPath());
    };

    const buttonDisabled = (): boolean => selectedValue === -1 && !formState.isValid;

    return (
        <Container sx={{py: 7}}>
            <CssGrid>
                <DefaultGridPageLayout>
                    <form
                        onSubmit={handleSubmit(
                            (form) => handleNextClick(form),
                            () => handleNextClick(), //Allow submit also when existing address is selected
                        )}
                    >
                        <Grid container rowSpacing={6}>
                            <Grid item xs={8} sm={4}>
                                <Typography variant={'subtitle1'}>{getLangString('INSPECTION_ADDRESS')}</Typography>
                                <RadioGroup onChange={handleRadioChange} value={selectedValue}>
                                    {Object.keys(user.addresses).map((aID) => (
                                        <RadioButton
                                            key={user.addresses[aID].customerAddressId}
                                            labelText={`${user.addresses[aID].street}, ${user.addresses[aID].zip} ${user.addresses[aID].location}`}
                                            value={user.addresses[aID].customerAddressId}
                                        />
                                    ))}
                                    <RadioButton labelText={getLangString('ADD_CHANGE_ADDRESS')} value={-1} />
                                </RadioGroup>
                            </Grid>
                            {selectedValue === -1 && (
                                <Grid item xs={8} mb={3}>
                                    <Typography variant={'subtitle1'}>{getLangString('ADD_ADDRESS')}</Typography>
                                    <AddressFormFields trigger={trigger} control={control} />
                                </Grid>
                            )}
                        </Grid>
                        <ButtonLayout>
                            <ButtonPrimaryLarge disabled={buttonDisabled()} type={'submit'}>
                                {getLangString('NEXT')}
                            </ButtonPrimaryLarge>
                        </ButtonLayout>
                    </form>
                </DefaultGridPageLayout>
            </CssGrid>
        </Container>
    );
};

export const InquiryContactInfoPrefilled: React.FC = () => {
    const {state, dispatch} = React.useContext(AppContext);

    const {accessToken} = useInitializeUser();
    React.useEffect(() => {
        const getUserDetails = async () => {
            const service = new UserService(M2HttpClient);
            if (accessToken) {
                try {
                    const response = await service.getUserDetails();
                    dispatch(loadUserInfo(response.data));
                } catch (e) {
                    const error = e as ProblemDetails;
                    console.log('Error: ', error);
                }
            }
        };
        getUserDetails();
    }, [accessToken, dispatch]);

    if (state.user) {
        return <Content user={state.user} />;
    }
    return (
        <Container maxWidth={'lg'} sx={{marginTop: 4}}>
            <LoaderCentered />
        </Container>
    );
};
