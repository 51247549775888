import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../../graphql/helpers';
import {Container, CssGrid} from '../../Layout';
import {ArticleCard} from './ArticleCard';
import {SliderWithControls} from './Slider';

type Props = {
    heading: string;
    link?: string;
    narrowViewportLimit?: number;
    articles: {
        id: number;
        title: string;
        slug: string;
        mainImage: {
            id: number;
            url: string;
        }[];
        relatedServices: {
            id: number;
            title: string;
            slug: string;
        }[];
    }[];
};

export const ArticleSlider: React.FC<Props> = (props) => {
    const theme = useTheme();
    const readMoreLink = props.link ? deconstructHTML(props.link) : null;
    return (
        <Box>
            <Box
                sx={{
                    [theme.breakpoints.up('md')]: {
                        display: 'none',
                    },
                }}
            >
                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: '1 / span 8',
                                [theme.breakpoints.up('md')]: {
                                    gridColumn: '2 / span 6',
                                },
                            }}
                        >
                            <Typography variant='sectionHeading'>{props.heading}</Typography>
                        </Box>
                    </CssGrid>

                    <Box
                        sx={{
                            mt: 4,
                            [theme.breakpoints.up('md')]: {
                                display: 'none',
                            },
                        }}
                    >
                        {props.articles.map((article, index: number) => {
                            if (!props.narrowViewportLimit || index < props.narrowViewportLimit) {
                                return (
                                    <CssGrid key={article.id}>
                                        <Box
                                            sx={{
                                                gridColumn: article.mainImage.length > 1 ? 'span 8' : '2 / span 6',
                                                mt: index > 0 ? 5 : 0,
                                            }}
                                        >
                                            <ArticleCard article={article} />
                                        </Box>
                                    </CssGrid>
                                );
                            }
                        })}
                    </Box>
                </Container>
            </Box>

            <Box
                sx={{
                    overflow: 'hidden',
                    display: 'none',
                    [theme.breakpoints.up('md')]: {
                        display: 'block',
                    },
                }}
            >
                <SliderWithControls
                    heading={props.heading}
                    link={
                        readMoreLink
                            ? {
                                  text: readMoreLink.text,
                                  url: readMoreLink.url,
                              }
                            : undefined
                    }
                    items={props.articles.map((article) => {
                        return (
                            <Box key={article.id}>
                                <ArticleCard article={article} isSliderItem={true} />
                            </Box>
                        );
                    })}
                    breakpoints={{
                        '0': {spaceBetween: 10, slidesPerView: 1.8},
                        '600': {spaceBetween: 24, slidesPerView: 2.1},
                        '1200': {spaceBetween: 24, slidesPerView: 2.8},
                    }}
                />
            </Box>
        </Box>
    );
};
