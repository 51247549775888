import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {deconstructHTML} from '../../graphql/helpers';
import {ButtonPrimaryLarge} from '../Buttons';
import {Container, CssGrid} from '../Layout';
import {ContentBlock} from './PageContent';
import {ColumnGrid} from './partials/ColumnGrid';

type Props = {
    id: number;
    heading: string;
    text: string;
    button: string;
    columns: {
        text: string;
        button: string;
        icon: {
            url: string;
        };
    }[];
    quickLink: {
        linkText: string;
        elementId: string;
    }[];
};

export const ColumnBanner: React.FC<Props> = (props) => {
    const theme = useTheme();
    const button = deconstructHTML(props.button);

    return (
        <ContentBlock quickLink={props.quickLink} size='lg' className='remove-margin-if-last'>
            <Box
                sx={{
                    backgroundColor: 'customBackground.medium',
                    pt: 8,
                    pb: 8,
                    [theme.breakpoints.up('md')]: {
                        pt: 9,
                        pb: 16,
                    },
                }}
            >
                <Container>
                    <CssGrid>
                        <Box
                            sx={{
                                gridColumn: 'span 8',
                                textAlign: 'center',
                                [theme.breakpoints.up('lg')]: {
                                    gridColumn: '3 / span 4',
                                },
                            }}
                        >
                            <Typography variant='sectionHeading'>{props.heading}</Typography>
                            <Box
                                sx={{
                                    mt: 3,
                                    [theme.breakpoints.up('md')]: {
                                        mt: 5,
                                    },
                                }}
                            >
                                <Typography
                                    variant='sectionIntro'
                                    dangerouslySetInnerHTML={{__html: props.text}}
                                ></Typography>
                            </Box>
                        </Box>
                    </CssGrid>

                    <ColumnGrid columns={props.columns} />

                    <Box
                        sx={{
                            display: 'flex',
                            mt: 8,
                            justifyContent: 'center',
                            [theme.breakpoints.up('md')]: {
                                mt: 9,
                            },
                        }}
                    >
                        {button !== null && <ButtonPrimaryLarge href={button.url}>{button.text}</ButtonPrimaryLarge>}
                    </Box>
                </Container>
            </Box>
        </ContentBlock>
    );
};
