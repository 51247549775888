import {Dialog, useTheme} from '@mui/material';
import React from 'react';

export const LargePhotoViewer: React.FC<{photo: string; onClose: () => void}> = ({photo, onClose}) => {
    const theme = useTheme();
    return (
        <Dialog
            open={true}
            fullWidth={true}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    [theme.breakpoints.down('sm')]: {
                        margin: 0,
                        width: '100%',
                    },
                },
                '.MuiBackdrop-root': {backgroundColor: 'rgba(0,0,0,0.5)'},
            }}
        >
            <img src={photo} alt={'photo'} width={'100%'} height={'100%'} />
        </Dialog>
    );
};
