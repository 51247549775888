import {DialogContent, DialogTitle, Typography} from '@mui/material';
import {CustomDialog} from 'components/CustomDialog';
import {useLanguage} from 'language/LanguageProvider';
import {CordelCompanyDto} from 'lib/dtos/Cordel';
import React, {useEffect, useState} from 'react';
import {ButtonPrimaryMedium, ButtonSecondaryMedium} from '../../../Buttons';
import {CompanyDialogForm, CompanyFormFields} from './CompanyDialogForm';

export type CompanyDialogFormProps = {
    isNew: boolean;
    data?: CordelCompanyDto;
};
export type CompanyDialogProps = {
    open: boolean;
    initialValues?: CompanyDialogFormProps;
    onClose: () => void;
    onSubmit: (reload: boolean) => void;
    onSaved: (createdId: number) => void;
};

export const CompanyDialog: React.VFC<CompanyDialogProps> = (props) => {
    const {getLangString} = useLanguage();
    const [initialCompany, setInitialCompany] = useState<CompanyFormFields | undefined>(undefined);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [showPrompt, setShowPrompt] = useState<boolean>(false);

    const handleClose = () => {
        setInitialCompany(undefined);
        props.onClose();
    };
    const hasUnsavedChanges = (isDirty: boolean) => {
        setUnsavedChanges(isDirty);
    };
    const handleDialogClose = () => {
        if (unsavedChanges) {
            setShowPrompt(true);
        } else {
            handleClose();
        }
    };
    useEffect(() => {
        const c = props.initialValues?.data;
        const init: CompanyFormFields = {
            cordelCompanyId: c?.cordelCompanyId,
            name: c?.name ? c?.name : '',
            apiKey: '',
            zip: c?.zip ? c?.zip : '',
            type: c?.type ? c?.type : '',
            street: c?.street ? c?.street : '',
            organizationNumber: c?.organizationNumber ? '' + c?.organizationNumber : '',
            location: c?.location ? c?.location : '',
            description: c?.description ? c?.description : '',
            datawarehouseUsername: '',
            datawarehouseUrl: c?.datawarehouseUrl ? c?.datawarehouseUrl : '',
            datawarehousePassword: '',
            contact: {
                cordelCompanyContactId: undefined,
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                position: 'none',
            },
            apiUrl: c?.apiUrl ? c?.apiUrl : '',
        };

        if (c?.contacts?.length) {
            init.contact = {...c.contacts[0]};
        }
        setInitialCompany(init);
    }, [props.initialValues?.data]);

    return (
        <>
            {initialCompany && props.initialValues && (
                <CustomDialog open={props.open} onClose={handleDialogClose} minWidth={'50%'}>
                    <DialogTitle>{getLangString('ADD_COMPANY')}</DialogTitle>
                    <DialogContent>
                        <CompanyDialogForm
                            initialValues={initialCompany}
                            isNew={props.initialValues.isNew}
                            onSaved={props.onSaved}
                            onSubmit={props.onSubmit}
                            onCancel={handleClose}
                            submitDisabled={false}
                            hasUnsavedChanges={hasUnsavedChanges}
                        />
                    </DialogContent>
                </CustomDialog>
            )}
            <CustomDialog open={showPrompt} onClose={() => setShowPrompt(false)}>
                <DialogTitle>
                    <Typography textAlign={'center'} mb={3}>
                        {getLangString('DIALOG_UNSAVED_CHANGES_TEXT')}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    <ButtonSecondaryMedium
                        onClick={() => {
                            setShowPrompt(false);
                            handleClose();
                        }}
                        sx={{mx: 1}}
                    >
                        {getLangString('DISCARD_CHANGES')}
                    </ButtonSecondaryMedium>
                    <ButtonPrimaryMedium
                        onClick={() => setShowPrompt(false)}
                        sx={{my: {xs: 1, sm: 0}, mx: {xs: 0, sm: 1}}}
                    >
                        {getLangString('CONTINUE')}
                    </ButtonPrimaryMedium>
                </DialogContent>
            </CustomDialog>
        </>
    );
};
