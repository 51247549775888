import {Dispatch} from 'react';
import {CreateInquiry} from '../../lib/types/Inquiry';
import {
    ActionType,
    addConsultationType,
    addSpecificationText,
    saveInquiryContactInfo,
    saveSelectedServiceTasks,
    saveSelectedServiceType,
} from '../../store/actions';

export const maybeLoadFromLocalStorage = (dispatch: Dispatch<ActionType>) => {
    const inquiryFromLocalStorage = localStorage.getItem('new_inquiry_data');
    const inquiryData: CreateInquiry | undefined = inquiryFromLocalStorage && JSON.parse(inquiryFromLocalStorage);

    if (inquiryData) {
        if (inquiryData.selectedService) {
            dispatch(saveSelectedServiceType(inquiryData.selectedService));
            inquiryData.selectedServiceTasks && dispatch(saveSelectedServiceTasks(inquiryData.selectedServiceTasks));
        }
        inquiryData.timeSchedule &&
            dispatch(
                addConsultationType(inquiryData.timeSchedule.consultationType, inquiryData.timeSchedule.timeOption),
            );
        inquiryData.specification?.description && dispatch(addSpecificationText(inquiryData.specification.description));
        inquiryData.contactInformation && dispatch(saveInquiryContactInfo(inquiryData.contactInformation));
        localStorage.removeItem('new_inquiry_data');
    }
};
