import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {Box, Container, Grid, GridProps, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {inquiryBasePath} from '../../App';
import {loginRequest} from '../../config/b2cConfig';
import {useLanguage} from '../../language/LanguageProvider';
import {CreateInquiryDto} from '../../lib/dtos/Inquiry';
import {M2HttpClient} from '../../lib/remote/m2api/ApiHttpClient';
import {ProblemDetails} from '../../lib/remote/ProblemDetails';
import {InquiryService} from '../../lib/services/InquiryService';
import {ContactInformation, FileDict, Service, Specification, TimeSchedule} from '../../lib/types/Inquiry';
import {clearInquiryData, editingInquiry, editingInquiryDone} from '../../store/actions';
import {AppContext} from '../../store/appContext';
import {getTimeString} from '../../toolbox';
import {ButtonPrimaryLarge} from '../Buttons';
import {InfoField, InfoFieldWithEdit} from '../InfoField';
import {CssGrid, DefaultGridPageLayout} from '../Layout';
import {LoaderCenterBlur, LoaderCentered} from '../Loader';
import {InquiryPhotoAttachments} from '../PhotoUploader';
import {DialogLoginExistingUser} from './DialogLoginExistingUser';
import {
    getInquiryConfirmationPath,
    getInquiryContactInfoPath,
    getInquirySelectServiceTaskPath,
    getInquiryTimeSelectionPath,
    useRedirectIfNoActiveInquiry,
} from './InquiryPageRoutes';
import {timeInterval} from './InquirySelectDatePage';
import {maybeLoadFromLocalStorage} from './maybeLoadInquiryFromLocalStorage';

const ContactInfoBox: React.FC<{onEditClick: () => void}> = ({onEditClick, children}) => {
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        return <InfoField>{children}</InfoField>;
    } else {
        return <InfoFieldWithEdit onEditClick={onEditClick}>{children}</InfoFieldWithEdit>;
    }
};
const GridContainer: React.FC<GridProps> = (props) => (
    <Grid {...props} container marginBottom={6}>
        {props.children}
    </Grid>
);
type ContentProps = {
    timeSchedule: TimeSchedule;
    specification: Specification | undefined;
    photos: FileDict<File> | undefined;
    contactInformation: ContactInformation;
    service: Service;
};
const SummaryContent: React.FC<ContentProps> = (props) => {
    const history = useHistory();
    const {state, dispatch} = useContext(AppContext);
    const {getLangString} = useLanguage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {instance} = useMsal();
    const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);
    const [errorSendingInquiry, setErrorSendingInquiry] = useState(false);

    const {photos} = props;
    const files: File[] | undefined = photos && Object.keys(photos).map((f) => photos[f]);

    const editMethods: Record<string, () => void> = {
        service: () => {
            dispatch(editingInquiry());
            if (state.inquiry.selectedServiceTasks && state.inquiry.selectedServiceTasks.length) {
                history.push(getInquirySelectServiceTaskPath(props.service.id));
            } else {
                history.push(inquiryBasePath);
            }
        },
        time: () => {
            dispatch(editingInquiry());
            history.push(getInquiryTimeSelectionPath());
        },
        contactInfo: () => {
            dispatch(editingInquiry());
            history.push(getInquiryContactInfoPath());
        },
    };

    const handleSubmitInquiry = async () => {
        setErrorSendingInquiry(false);
        const service = new InquiryService(M2HttpClient);
        const inquiry: CreateInquiryDto = {
            // service: props.service,
            service: {
                type: props.service.id,
                tasks: state.inquiry.selectedServiceTasks
                    ? state.inquiry.selectedServiceTasks.map((task) => task.id)
                    : [],
            },
            timeSchedule: {
                consultationType: props.timeSchedule.consultationType,
                fromDate: props.timeSchedule.timeOption && timeInterval[props.timeSchedule.timeOption].from,
                toDate: props.timeSchedule.timeOption && timeInterval[props.timeSchedule.timeOption].to,
            },
            specification: props.specification || {},
            contactInformation: props.contactInformation,
            consent: true,
        };
        setIsLoading(true);
        try {
            await service.createInquiry(inquiry, files);
            setIsLoading(false);
            dispatch(clearInquiryData());
            history.push(getInquiryConfirmationPath());
        } catch (e) {
            const error = e as ProblemDetails;
            if (error.code === 'EMAIL_ALREADY_EXIST') {
                //Prompt user to log in, and save inquiry data in localStorage in order to continue after log in
                setShowLoginDialog(true);
                localStorage.setItem('new_inquiry_data', JSON.stringify(state.inquiry));
            } else {
                setErrorSendingInquiry(true);
            }
            setIsLoading(false);
        }
    };
    const handleLogIn = () => {
        instance.loginRedirect(loginRequest);
    };
    const handleDoNotLogIn = () => {
        setShowLoginDialog(false);
        localStorage.removeItem('new_inquiry_data');
    };
    //TODO: handle errors and loading
    return (
        <>
            <LoaderCenterBlur text={getLangString('SENDING')} open={isLoading} />
            <GridContainer rowSpacing={6} marginTop={0} marginBottom={3}>
                <Grid item xs={8}>
                    <Typography variant={'subtitle1'}>{getLangString('ORDERED_SERVICE')}</Typography>
                    <InfoFieldWithEdit onEditClick={editMethods['service']}>
                        {props.service.title}
                        {state.inquiry.selectedServiceTasks && state.inquiry.selectedServiceTasks.length
                            ? ' - ' + state.inquiry.selectedServiceTasks.map((task) => task.title).join(', ')
                            : ''}
                    </InfoFieldWithEdit>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Typography variant={'subtitle1'}>{getLangString('SPECIFICATION')}</Typography>
                    <InfoFieldWithEdit
                        onEditClick={editMethods['service']}
                        sx={{minHeight: 150, height: 'fit-content', whiteSpace: 'pre-line'}}
                    >
                        {props.specification ? props.specification.description : <br />}
                    </InfoFieldWithEdit>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <Typography variant={'subtitle1'}>{getLangString('ATTACHED_PHOTOS')}</Typography>
                    <InquiryPhotoAttachments />
                </Grid>
            </GridContainer>
            {props.timeSchedule.consultationType === 'call' && (
                <GridContainer columnSpacing={0}>
                    <Grid item xs={8}>
                        <Typography variant={'subtitle1'}>{getLangString('TIME_OF_PHONECALL')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <InfoFieldWithEdit onEditClick={editMethods['time']}>
                            {props.timeSchedule.timeOption ? (
                                `${getTimeString(timeInterval[props.timeSchedule.timeOption].from)} - 
                                ${getTimeString(timeInterval[props.timeSchedule.timeOption].to)}`
                            ) : (
                                <br />
                            )}
                        </InfoFieldWithEdit>
                    </Grid>
                </GridContainer>
            )}
            <GridContainer columnSpacing={0}>
                <Grid item xs={8}>
                    <Typography variant={'subtitle1'}>{getLangString('CONTACT_INFO')}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <ContactInfoBox onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.personDetails.firstName}{' '}
                        {props.contactInformation.personDetails.lastName}
                    </ContactInfoBox>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <ContactInfoBox onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.personDetails.email}
                    </ContactInfoBox>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <ContactInfoBox onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.personDetails.phoneNumber}
                    </ContactInfoBox>
                </Grid>
            </GridContainer>
            <Typography variant={'subtitle1'}>
                {props.timeSchedule.consultationType === 'inspection'
                    ? getLangString('INSPECTION_ADDRESS')
                    : getLangString('LOCATION_ADDRESS')}
            </Typography>
            <GridContainer columnSpacing={0}>
                <Grid item xs={8} sm={4}>
                    <InfoFieldWithEdit onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.address.street}
                    </InfoFieldWithEdit>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <InfoFieldWithEdit onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.address.zip}
                    </InfoFieldWithEdit>
                </Grid>
                <Grid item xs={4} sm={2}>
                    <InfoFieldWithEdit onEditClick={editMethods['contactInfo']}>
                        {props.contactInformation.address.location}
                    </InfoFieldWithEdit>
                </Grid>
            </GridContainer>
            <Box textAlign={'center'} marginTop={9}>
                {errorSendingInquiry && (
                    <Typography color={'error'} sx={{mb: 2}}>
                        {getLangString('INQUIRY_SEND_ERROR') + '.'}
                    </Typography>
                )}
                <ButtonPrimaryLarge onClick={handleSubmitInquiry}>{getLangString('SUBMIT_REQUEST')}</ButtonPrimaryLarge>
            </Box>
            <DialogLoginExistingUser open={showLoginDialog} onClose={handleDoNotLogIn} onSignIn={handleLogIn} />
        </>
    );
};

export const InquirySummaryPage: React.FC = () => {
    //Redirect to initial inquiry page if there is no active inquiry
    useRedirectIfNoActiveInquiry();

    const {state, dispatch} = useContext(AppContext);
    const {getLangString} = useLanguage();
    React.useEffect(() => {
        const clearEditState = () => {
            dispatch(editingInquiryDone());
        };
        clearEditState();
    }, [dispatch]);

    React.useEffect(() => {
        if (state.inquiry.selectedService && state.inquiry.timeSchedule && state.inquiry.contactInformation) {
            return;
        } else {
            maybeLoadFromLocalStorage(dispatch);
        }
    }, []);

    if (state.inquiry.selectedService && state.inquiry.timeSchedule && state.inquiry.contactInformation) {
        return (
            <Container sx={{py: 7}}>
                <CssGrid>
                    <DefaultGridPageLayout>
                        <Typography variant={'h6'}>{getLangString('SUMMARY')}</Typography>
                        <SummaryContent
                            timeSchedule={state.inquiry.timeSchedule}
                            contactInformation={state.inquiry.contactInformation}
                            service={state.inquiry.selectedService}
                            specification={state.inquiry.specification}
                            photos={state.inquiry.photos}
                        />
                    </DefaultGridPageLayout>
                </CssGrid>
            </Container>
        );
    }

    return (
        <Container>
            <LoaderCentered />
        </Container>
    );
};
