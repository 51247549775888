import {ApiEndpoints} from '../remote/m2api/M2Endpoints';
import {JsonHttpClient} from '../remote/JsonHttpClient';
import {OrderContentDto} from '../dtos/OrderContent';

export class ContentService {
    private apiContentPath = ApiEndpoints.content;

    private _httpClient: JsonHttpClient;

    constructor(httpClient: JsonHttpClient) {
        this._httpClient = httpClient;
    }

    public async getOrderContentDetails(): Promise<OrderContentDto> {
        return await this._httpClient.get<OrderContentDto>(this.apiContentPath.orderDetails());
    }
}
