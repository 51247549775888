import {ErrorCodes} from 'lib/ErrorCodes';

export interface ProblemDetails {
    code?: ErrorCodes;
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    errors?: string[];
}
/**
 * Error representing the error format that asp.net core reports errors.
 */
export class ProblemDetailsError extends Error implements ProblemDetails {
    constructor({message, details}: {message?: string; details: ProblemDetails}) {
        super(message);
        if (details) {
            this.code = details.code;
            this.type = details.type;
            this.title = details.title;
            this.status = details.status;
            this.detail = details.detail;
            this.instance = details.instance;
            this.errors = details.errors;
        }
    }
    /**
     * Specific error code that "explains" the error that can be used to
     * inform the user of the error in a more detailed way.
     */
    readonly code?: ErrorCodes;
    /**
     * The type of error, can be a link to the RFC explanation of the error code (400, 404..)
     */
    readonly type?: string;
    /**
     * A title of the error, explaining the error with a few words
     */
    readonly title?: string;
    /**
     * A HTTP status code (400, 404, 500)
     */
    readonly status?: number;
    /**
     * A more detailed explanation of the error
     */
    readonly detail?: string;
    /**
     * The path of where the error occurred
     */
    readonly instance?: string;
    /**
     * List of errors from validation etc
     */
    readonly errors?: string[];
}
