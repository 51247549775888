import {useTheme} from '@mui/material';
import React, {createContext, Dispatch} from 'react';
import {useLocation} from 'react-router-dom';
import {ActionType} from './actions';
import {appReducer, initialState, State} from './appReducer';

type InitialContextState = {
    bgColor?: string;
    state: State;
    dispatch: Dispatch<ActionType>;
};

export const AppContext = createContext<InitialContextState>({state: initialState, dispatch: () => ({})});
const {Provider} = AppContext;

export const StateProvider: React.FC = ({children}) => {
    const location = useLocation();
    const {palette} = useTheme();
    const [state, dispatch] = React.useReducer(appReducer, initialState);

    let bgColor = palette.customBackground.light;
    if (location.pathname.match('tilbud')) {
        bgColor = palette.customBackground.medium;
    } else if (location.pathname.match('aktiv')) {
        bgColor = palette.customBackground.medium;
    } else if (location.pathname.match('betaling')) {
        bgColor = palette.customBackground.darker;
    } else if (location.pathname.match('avsluttet')) {
        bgColor = palette.customBackground.dark;
    } else if (location.pathname.match('confirmation')) {
        bgColor = palette.customBackground.darker;
    } else if (location.pathname.match('artikler') || location.pathname.match('artikler/*')) {
        bgColor = palette.customBackground.dark;
    } else if (location.pathname.match('offers')) {
        bgColor = palette.customBackground.medium;
    }

    return <Provider value={{bgColor, state, dispatch}}>{children}</Provider>;
};
