import {Add} from '@mui/icons-material';
import {Box, Button, Grid, Typography} from '@mui/material';
import {AreYouSureDialog} from 'components/AreYouSureDialog';
import {ButtonPrimaryMedium} from 'components/Buttons';
import {LoaderCenterBlur} from 'components/Loader';
import {ContactInfo, ContactInfoProps} from 'components/loggedInPages/admin/ContactInfo';
import {PhaseDialog, SubmitBody} from 'components/loggedInPages/admin/orderPage/PhaseDialog';
import {compareDesc, format} from 'date-fns';
import {useLanguage} from 'language/LanguageProvider';
import {FileDto} from 'lib/dtos/File';
import {InquiryDto} from 'lib/dtos/Inquiry';
import {CreatePhaseDto, PhaseDto, UpdatePhaseDto} from 'lib/dtos/PhaseDto';
import {ProjectStatus, UpdateProjectDto} from 'lib/dtos/Project';
import {CreateStatusUpdateDto, StatusUpdateDto} from 'lib/dtos/StatusUpdates';
import {useM2Axios} from 'lib/hooks/useM2Axios';
import {ProblemDetailsError} from 'lib/remote/ProblemDetails';
import * as React from 'react';
import {useState, VFC} from 'react';
import {OrderDto} from '../../../../lib/dtos/Order';
import {EmployeeDto} from '../../../../lib/dtos/User';
import {M2Response} from '../../../../lib/remote/m2api/m2AxiosInstance';
import {ApiEndpoints} from '../../../../lib/remote/m2api/M2Endpoints';
import {ServiceDictionary} from '../../../../lib/types/Inquiry';
import {CustomInputField} from '../../../InputField';
import {getServiceTasksText} from '../../serviceText';
import {EmployeeSelect} from '../EmployeeSelect';
import {getAddress} from '../getAddress';
import {StatusUpdateListItem} from '../StatusUpdateListItem';
import {CustomerInformation, InspectionInformation} from './InfoBlock';
import {PageElement} from './PageElement';
import {Phases} from './Phases';
import {ProjectTimeFrame} from './ProjectTimeFrame';

export type OrderDetailsProps = {
    order: OrderDto;
    inquire: InquiryDto;
    inspectionImages: FileDto[];
    statusUpdates: StatusUpdateDto[];
    refresh: () => void;
    services?: ServiceDictionary;
};

export const OrderDetails: VFC<OrderDetailsProps> = ({
    order,
    inquire,
    statusUpdates,
    inspectionImages,
    refresh,
    services,
}) => {
    const {project, phases} = order;
    const {customer} = project;
    const address: string = getAddress(project);
    const {firstName, lastName, email, phoneNumber} = customer;
    const contactInfoProps: ContactInfoProps = {firstName, lastName, email, phoneNumber, address};
    const [statusDescription, setStatusDescription] = useState<string>('');
    const [openPhaseModal, setOpenPhaseModal] = useState(false);
    const [editPhase, setEditPhase] = useState<Phase>();
    const [showFinishProjectPrompt, setShowFinishProjectPrompt] = useState<boolean>(false);
    const {getLangString} = useLanguage();

    const [{loading: createLoading}, addNewPhase] = useM2Axios<unknown, CreatePhaseDto[], ProblemDetailsError>(
        {url: `api/Orders/${order.orderId}/phases`, method: 'POST'},
        {manual: true},
    );
    const [{loading: updateLoading}, updatePhase] = useM2Axios<unknown, UpdatePhaseDto[], ProblemDetailsError>(
        {url: `api/Orders/${order.orderId}/phases`, method: 'PUT'},
        {manual: true},
    );
    const [{loading: statusUpdating}, updateProjectStatus] = useM2Axios<unknown, UpdatePhaseDto[], ProblemDetailsError>(
        {url: ApiEndpoints.project.state(project.projectId, ProjectStatus.Finished), method: 'POST'},
        {manual: true},
    );
    const [{loading: deleteLoading}, deletePhase] = useM2Axios<unknown, unknown, ProblemDetailsError>('', {
        manual: true,
    });
    const [{loading: createStatusUpdateLoading}, createNewStatusUpdate] = useM2Axios<
        CreateStatusUpdateDto,
        CreateStatusUpdateDto,
        ProblemDetailsError
    >('', {
        manual: true,
    });

    const customerInformationProps = {
        descriptionText: inquire.description,
        images: inquire.images,
        serviceTaskText: services && getServiceTasksText(order.project.services, services),
    };

    const getDisplayOrderForNewItem = () => {
        return phases.reduce((acc, phase) => (acc > phase.displayOrder ? acc : phase.displayOrder), 0) + 1;
    };

    const handlePhaseDelete = async (phase: Phase) => {
        await deletePhase({url: `${ApiEndpoints.order.byId(order.orderId)}/phases/${phase.phaseId}`, method: 'DELETE'});
        refresh();
    };
    const handleSubmit = async (submitBody: SubmitBody) => {
        const {title, deadline, description} = submitBody;
        if (submitBody.submitType === 'new') {
            const displayOrder = getDisplayOrderForNewItem();
            const data: CreatePhaseDto[] = [{title, deadline, displayOrder, description}];
            await addNewPhase({data});
        } else {
            const {completed, phaseId, displayOrder} = submitBody;
            const data = [{title, deadline, description, completed, phaseId, displayOrder}];
            await updatePhase({data});
        }

        closeModal();
        refresh();
    };
    const closeModal = () => {
        setOpenPhaseModal(false);
        setEditPhase(undefined);
    };

    const handleMarkFinish = async (phase: Phase) => {
        try {
            const updatePhaseBody: UpdatePhaseDto = {...phase, completed: true};
            await updatePhase({data: [updatePhaseBody]});
            refresh();
        } catch (error) {
            //
        }
    };

    const doCreateStatusUpdate = async () => {
        try {
            await createNewStatusUpdate({
                url: ApiEndpoints.statusUpdate.base(),
                method: 'POST',
                data: {
                    projectId: project.projectId,
                    statusText: statusDescription,
                },
            });
            setStatusDescription('');
            refresh();
        } catch (error) {
            //
        }
    };

    const doSetProjectState = async (projectStatus: ProjectStatus) => {
        try {
            setShowFinishProjectPrompt(false);
            await updateProjectStatus({params: {status: projectStatus}});
            refresh();
        } catch (error) {
            //
        }
    };

    const doHandleCompleteProject = () => {
        setShowFinishProjectPrompt(true);
    };
    const [{loading: loadingEmployees, data: employees}] = useM2Axios<
        M2Response<EmployeeDto[]>,
        unknown,
        ProblemDetailsError
    >(`${ApiEndpoints.user.base()}/employees?page=1&size=50`, {
        manual: false,
    });
    const [{loading: updateProjectLoading}, updateProject] = useM2Axios<unknown, UpdateProjectDto, ProblemDetailsError>(
        {url: `${ApiEndpoints.project.byId(project.projectId)}`, method: 'PUT'},
        {manual: true},
    );
    const handleEmployeeSelect = (id: number) => {
        const data: UpdateProjectDto = {
            projectId: project.projectId,
            fromDate: project.fromDate,
            toDate: project.toDate,
            projectLeaderEmployeeId: id,
        };
        updateProject({data});
    };
    const handleTimeframeChange = (fromDate: Date | undefined, toDate: Date | undefined) => {
        const data: UpdateProjectDto = {
            projectId: project.projectId,
            projectLeaderEmployeeId: project.projectLeader?.employeeId,
            fromDate,
            toDate,
        };
        updateProject({data});
    };
    return (
        <>
            <LoaderCenterBlur open={createLoading || updateLoading || statusUpdating || createStatusUpdateLoading} />
            {(openPhaseModal || editPhase) && (
                <PhaseDialog open onClose={closeModal} onSubmit={handleSubmit} initialValues={editPhase} />
            )}
            <Grid container columnSpacing={0} marginTop={4}>
                <Grid item xs={8} sm={4}>
                    <Typography variant={'h6'}>{getLangString('PROJECT_LEADER')}</Typography>
                    {employees && (
                        <EmployeeSelect
                            employees={employees.data}
                            onSelect={handleEmployeeSelect}
                            currentEmployee={project.projectLeader}
                        />
                    )}
                </Grid>
            </Grid>
            <PageElement>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('CONTACT_INFO')}
                </Typography>
                <ContactInfo {...contactInfoProps} />
            </PageElement>
            <PageElement>
                <CustomerInformation {...customerInformationProps} />
            </PageElement>
            <PageElement>
                <InspectionInformation images={inspectionImages} />
            </PageElement>
            <PageElement>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('PROJECT_TIMEFRAME')}
                </Typography>
                <ProjectTimeFrame
                    fromDate={project.fromDate}
                    toDate={project.toDate}
                    updateProject={handleTimeframeChange}
                />
            </PageElement>
            <PageElement>
                <Phases
                    phases={phases}
                    onMarkFinish={handleMarkFinish}
                    onDeletePhase={handlePhaseDelete}
                    onEdit={(phase) => setEditPhase(phase)}
                />
                <Box mt={7}>
                    <Button
                        variant='underlined'
                        endIcon={<Add />}
                        onClick={() => {
                            setOpenPhaseModal(true);
                        }}
                    >
                        {getLangString('ADD_PHASE')}
                    </Button>
                </Box>
            </PageElement>
            {project.projectStatus !== ProjectStatus.Finished && (
                <PageElement>
                    <ButtonPrimaryMedium
                        onClick={doHandleCompleteProject}
                        disabled={phases.length > 0 ? !phases[phases.length - 1].completed : false}
                    >
                        {getLangString('COMPLETE_THE_PROJECT')}
                    </ButtonPrimaryMedium>
                </PageElement>
            )}
            <PageElement>
                <Typography variant={'h6'} mb={2}>
                    {getLangString('STATUS_UPDATE')} {format(new Date(), 'dd.MM.yyyy')}
                </Typography>
                <CustomInputField
                    multiline
                    fullWidth={true}
                    minRows={5}
                    placeholder={'Skriv en oppdatering her...'}
                    value={statusDescription}
                    onChange={(e) => setStatusDescription(e.target.value)}
                />
                <Button
                    disabled={statusDescription === ''}
                    onClick={doCreateStatusUpdate}
                    size={'medium'}
                    sx={{mt: 2}}
                    variant={statusDescription === '' ? 'outlined' : 'contained'}
                >
                    {getLangString('ADD_NEW_STATUS_UPDATE')}
                </Button>
            </PageElement>
            {statusUpdates.length > 0 && (
                <>
                    <Typography variant='h6'>{getLangString('RECENT_STATUS_UPDATES')}</Typography>
                    {statusUpdates
                        .sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt)))
                        .map((s, i) => {
                            return <StatusUpdateListItem statusUpdate={s} startExpanded={i === 0} key={i} />;
                        })}
                </>
            )}
            <AreYouSureDialog
                title={getLangString('COMPLETE_THE_PROJECT')}
                onConfirm={() => doSetProjectState(ProjectStatus.Finished)}
                open={showFinishProjectPrompt}
                onCancel={() => setShowFinishProjectPrompt(false)}
            >
                <Typography mb={1}>{getLangString('COMPLETE_PROJECT_TEXT')}</Typography>
            </AreYouSureDialog>
        </>
    );
};

export type Phase = PhaseDto & {status: PhaseStatus};

export type PhaseStatus = 'done' | 'active' | 'coming';
